import { CarbonIconProps } from '@carbon/icons-react';
import { CSSProperties, DefaultTheme } from 'styled-components';

import { ICONS, EIconType } from './constants/Icon';
import { Wrapper } from './Icon.styles';

type Props = {
  i: EIconType;
  size?: CarbonIconProps['size'];
  color?: keyof DefaultTheme['colors'];
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
};

export const Icon: React.FC<Props> = ({
  i,
  size = '16',
  color,
  onClick,
  style,
  className = ''
}) => {
  const I = ICONS[i];

  return (
    <Wrapper color={color} onClick={onClick} className="anticon">
      <I className={`${className} Icon${i} icon-fill`} role="img" size={size} style={style} />
    </Wrapper>
  );
};
