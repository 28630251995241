import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import { IScrappedExamGroup, IScrappedExamGroupFilter } from './scrapped-exam-group.contract';

export class ScrappedExamGroupService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getScrappedExamGroups(
    search?: string,
    pagination?: IPagination,
    filters?: IScrappedExamGroupFilter
  ) {
    const query = createQueryString({
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      filters
    });
    return this.fetcher<IPaginatedResponse<IScrappedExamGroup>>(`/scrapped-exam-groups?${query}`);
  }

  public removeScrappedExamGroup(_id: string) {
    return this.deleter(`/scrapped-exam-groups/${_id}`);
  }

  public removeManyExamGroups(groupsIds: string[]) {
    return this.deleter(`/scrapped-exam-groups`, {
      data: { groupsIds }
    });
  }
}
