import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSkipPlanModal } from '../../hooks/useSkipPlanModal';

import { Wrapper } from './SkipPlanModal.styles';

type Props = {
  onSkipPlan: () => void;
};

export const SkipPlanModal: React.FC<Props> = ({ onSkipPlan }) => {
  const { isOpen, setIsOpen } = useSkipPlanModal();
  const { t } = useTranslation('StudentOnboardingTranslations');

  const handleSkipPlan = () => {
    onSkipPlan();
    setIsOpen(false);
  };

  return (
    <Modal
      centered
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={t('SURE_DONT_WANT_TO_PLAN')}
      onCancel={() => setIsOpen(false)}
      footer={
        <Wrapper>
          <Button onClick={handleSkipPlan}>{t('PLAN_LATER')}</Button>
          <Button type="primary" onClick={() => setIsOpen(false)}>
            {t('CONTINUE_PLAN')}
          </Button>
        </Wrapper>
      }>
      <p>{t('SKIP_PLAN_SUBTITLE')}</p>
    </Modal>
  );
};
