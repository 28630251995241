import { Flex, Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const Wrapper = styled(Flex)`
  margin: 8px 0;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black2};
  }
`;

export const ContentWrapper = styled(Flex)`
  width: 100%;
`;

export const VideoWrapper = styled(Flex)`
  max-width: 110px;
  max-height: 64px;

  & > div > video {
    border-radius: 8px;
  }
`;

export const TitleWrapper = styled(Paragraph)`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0 !important;
`;

export const TimeWrapper = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black7};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
