import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs .ant-tabs-tabpane {
    height: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > :first-child {
    margin-bottom: 0;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
