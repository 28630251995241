import { ETicketStatus } from '@constants';
import { ITicket } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeWidget } from './components';
import { Wrapper } from './TicketStatusBar.styles';
import { getFirstMessageCreationDate } from './utils';

import { Icon } from '@/components/Icon';
import { TicketStatusBadge } from '@/components/TicketStatusBadge';
import { useIsMobile } from '@/hooks';
import { getFormattedDatesDifference } from '@/utils';

type Props = {
  ticket: ITicket;
  onChange?: (status: ETicketStatus) => void;
};

export const TicketStatusBar: React.FC<Props> = ({ ticket, onChange }) => {
  const { t } = useTranslation();
  const { status, issuer, ticketRelatedContent, createdAt, solvedAt, messages } = ticket;
  const isMobile = useIsMobile();

  const firstMessageCreationDate = useMemo(
    () => getFirstMessageCreationDate(issuer._id, messages),
    [issuer._id, messages]
  );

  return (
    <Wrapper>
      <div>
        <TicketStatusBadge status={status} editable={!!onChange} onChange={onChange} />
        {solvedAt && (
          <TimeWidget
            title={t('RESOLUTION_TIME')}
            subtitle={getFormattedDatesDifference(createdAt, solvedAt, t)}
          />
        )}
        {firstMessageCreationDate && (
          <TimeWidget
            title={t('FIRST_RESPONSE_TIME')}
            subtitle={getFormattedDatesDifference(createdAt, firstMessageCreationDate, t)}
          />
        )}
      </div>
      {!isMobile && (
        <div>
          <div>
            <Icon i="UserAvatar" />
            <span>{`${issuer.name} ${issuer.lastName}`}</span>
          </div>
          <div>
            <Icon i="Book" />
            <span>{ticketRelatedContent.course.name}</span>
          </div>
          <div>
            <Icon i="Calendar" />
            <span>{dateProvider.format(createdAt, 'dd/MM/yy')}</span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
