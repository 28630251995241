import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TutoringGroupService = sdk.getService('TutoringGroupService');

export const useStudentTutorDetail = (studentId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['tutoring-groups', studentId],
    queryFn: () => TutoringGroupService.findStudentTutor(studentId),
    enabled: !!studentId
  });

  return {
    tutor: data,
    isLoading,
    error
  };
};
