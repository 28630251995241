import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :first-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

export const DescriptionWrapper = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

export const RemainingTimeWrapper = styled.span`
  font-weight: 700;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 72px;
  align-self: stretch;
  max-height: 700px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column-reverse;
    gap: 16px;
    max-height: 600px;
  }
`;

export const LinkWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding: 16px;
  }
`;
