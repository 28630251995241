import styled from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  cursor: pointer;
  border: ${({ selected, theme }) => (selected ? `2px solid ${theme.colors.green4}` : '')};
  background: ${({ selected, theme }) => (selected ? theme.colors.green1 : theme.colors.white)};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.green4};
  }
`;

export const EmojiWrapper = styled.div`
  font-size: 32px;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubscriptionInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black2};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
