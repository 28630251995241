import { EOnboardingChosenReason } from '@constants';
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OptionsWrapper,
  ItemWrapper,
  ButtonWrapper,
  ModalWrapper
} from './ChosenReasonPicker.styles';

type Props = {
  value?: EOnboardingChosenReason;
  onChange?: (value: EOnboardingChosenReason) => void;
};

const OPTIONS = Object.values(EOnboardingChosenReason);

export const ChosenReasonPicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('StudentOnboardingTranslations');
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (option: EOnboardingChosenReason) => {
    if (option === EOnboardingChosenReason.OTHER) return setIsOpen(true);
    onChange?.(option);
  };

  return (
    <OptionsWrapper>
      {OPTIONS.map(option => (
        <ItemWrapper
          isSelected={value === option}
          key={option}
          onClick={() => handleChange(option)}>
          {t(`${option}_OPTION`)}
        </ItemWrapper>
      ))}
      <Modal
        open={isOpen}
        centered
        closable
        title={t('TELL_US')}
        footer={null}
        onCancel={() => setIsOpen(false)}>
        <ModalWrapper>
          <div>{t('WHY_DID_YOU_CHOOSE_US')}</div>
          <Form.Item name={['onBoarding', 'otherReason']}>
            <Input.TextArea rows={4} onChange={({ target: { value: v } }) => setName(v)} />
          </Form.Item>
          <ButtonWrapper>
            <Button
              type="primary"
              disabled={name.length < 3}
              onClick={() => {
                setIsOpen(false);
                onChange?.(EOnboardingChosenReason.OTHER);
              }}>
              {t('CONTINUE')}
            </Button>
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </OptionsWrapper>
  );
};
