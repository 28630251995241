import styled from 'styled-components';

export const ActivityStatus = styled.div<{ active: boolean }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.colors.green6 : theme.colors.red6)};
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black2};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: left;
  font-size: 18px;
`;
