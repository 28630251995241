import { Checkbox, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { QuestionCountWrapper, Wrapper } from './CourseContentReview.styles';

import { useReviewQuestionsResume, useReviewQuestionsStore } from '@/store';

export const CourseContentReview = () => {
  const { questionReviewResume = [] } = useReviewQuestionsResume();
  const { selectedContent, setSelectedContent } = useReviewQuestionsStore();
  const { t } = useTranslation('StudentQuizTranslations');

  const onChange = (list: string[], isChecked: boolean) => {
    if (isChecked) return setSelectedContent([...selectedContent, ...list]);
    setSelectedContent(selectedContent.filter(content => !list.includes(content)));
  };

  const onHeaderChange = (courseId: string) => {
    const sections = questionReviewResume.find(c => c._id === courseId)?.sections;
    if (sections && sections.length) {
      const allSections = sections.map(section => section._id);
      const allSelected = allSections.every(section => selectedContent.includes(section));
      setSelectedContent(
        allSelected
          ? selectedContent.filter(content => !allSections.includes(content))
          : [...selectedContent, ...allSections]
      );
    }
  };

  return (
    <Collapse>
      {questionReviewResume.map(course => {
        const isCourseChecked = course.sections.every(section =>
          selectedContent.includes(section._id)
        );
        const isCourseCheckedIndeterminate = course.sections.some(section =>
          selectedContent.includes(section._id)
        );
        return (
          <Collapse.Panel
            key={course._id}
            showArrow={true}
            header={
              <Wrapper onClick={e => e.stopPropagation()}>
                <Checkbox
                  value={course.sections.map(section => section._id)}
                  onChange={e => {
                    e.stopPropagation();
                    onHeaderChange(course._id);
                  }}
                  checked={isCourseChecked}
                  indeterminate={isCourseCheckedIndeterminate}>
                  {course.courseName}
                </Checkbox>
                <QuestionCountWrapper>
                  <b>{course.totalQuestions}</b> {t('REVIEW_MODE_FAILED_QUESTIONS')}
                </QuestionCountWrapper>
              </Wrapper>
            }>
            {course.sections.map(section => (
              <Wrapper key={section._id}>
                <Checkbox
                  value={section._id}
                  onChange={e => {
                    e.stopPropagation();
                    onChange([section._id], e.target.checked);
                  }}>
                  {section.sectionName}
                </Checkbox>
                <QuestionCountWrapper>
                  <b>{section.totalQuestions}</b> {t('REVIEW_MODE_FAILED_QUESTIONS')}
                </QuestionCountWrapper>
              </Wrapper>
            ))}
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};
