import { Avatar } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.green1};
`;

export const StyledAvatar = styled(Avatar)`
  flex: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
