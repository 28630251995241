import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 260px;
  height: 285px;
  padding: 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: 2px solid ${({ theme }) => theme.colors.black9};
  box-shadow: -8px 8px 0px 0px ${({ theme }) => theme.colors.black9};
  background: ${({ theme }) => theme.colors.white};
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.orange6};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const SubstractWrapper = styled.div`
  color: ${({ theme }) => theme.colors.red6};
  display: flex;
  align-items: center;
  font-weight: 700;
`;
