import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ScrappedExamService = sdk.getService('ScrappedExamService');

export const useMutateScrappedExams = () => {
  const { t } = useTranslation('ExamGroupsTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['scrapped-exams']
    });

  const { mutate: deleteScrappedExam, isPending } = useMutation({
    mutationFn: (_id: string) => ScrappedExamService.deleteScrappedExam(_id),
    onSuccess: () => {
      onSuccess();
      message.success(t('SCRAPPED_EXAM_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    isWorking: isPending,
    deleteScrappedExam
  };
};
