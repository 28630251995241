import { SubtitleWrapper, TitleWrapper, Wrapper } from './TimeWidget.styles';

import { useIsMobile } from '@/hooks';

type Props = {
  title: string;
  subtitle: string;
};

export const TimeWidget: React.FC<Props> = ({ title, subtitle }) => {
  const isMobile = useIsMobile();

  if (isMobile) return null;

  return (
    <Wrapper>
      <TitleWrapper>{title}</TitleWrapper>
      <SubtitleWrapper>{subtitle}</SubtitleWrapper>
    </Wrapper>
  );
};
