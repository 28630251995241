import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const ExamService = sdk.getService('ExamService');

export const useExamDetail = (exam?: string) => {
  const { _id } = useParams<{ _id: string }>();
  const examId = _id ?? exam;

  const { isLoading, data, error } = useQuery({
    queryKey: ['exams', examId],
    queryFn: () => ExamService.getExam(examId!),
    enabled: !!examId
  });

  return {
    exam: data,
    isLoading,
    error
  };
};
