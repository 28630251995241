import { IDeliverableFilter } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const DeliverableService = sdk.getService('DeliverableService');

interface IStore {
  page: number;
  limit: number;
  filter?: IDeliverableFilter;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: IDeliverableFilter) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: IDeliverableFilter) =>
    set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

export const useDeliverablesList = () => {
  const { page, limit, filter, setPageAndLimit, setFilter } = store();
  const [search, setSearch] = useState('');

  const queryKey = [
    'deliverables',
    `page-${page}`,
    `limit-${limit}`,
    JSON.stringify(filter),
    search
  ];

  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => DeliverableService.getDeliverables(search, { page, limit }, undefined, filter),
    enabled: !!filter
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    deliverables: data?.docs,
    isLoading,
    error,
    setPageAndLimit,
    pagination,
    setSearch,
    filter,
    setFilter
  };
};
