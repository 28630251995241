import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.white};

  .ant-form {
    width: 100%;
  }
`;

export const CloseFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & > :first-child {
    color: ${({ theme }) => theme.colors.black7};
  }
`;

export const UploadedFileWrapper = styled.a`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  border-radius: 2px;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const UploadedFileInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;

export const IconWrapper = styled(Icon)`
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 2px;
  height: 24px;
  width: 24px;
  padding: 4px;
`;

export const FileNameWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
`;
