import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const AvatarWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const PasswordWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
`;

export const LogoutWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 500;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }
`;
