import { IUpdateNotification } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const NotificationService = sdk.getService('NotificationService');

export const useMutateNotifications = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['notifications'] });

  const { mutate: updateNotification, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateNotification) => NotificationService.updateNotification(update),
    onSuccess
  });

  const { mutate: removeNotifications, isPending: isRemoving } = useMutation({
    mutationFn: (removeIds: string[]) => NotificationService.removeNotifications(removeIds),
    onSuccess
  });

  const markAsRead = async (notificationId: string) =>
    updateNotification({
      notificationId,
      notification: {
        isRead: true
      }
    });

  return {
    updateNotification,
    removeNotifications,
    markAsRead,
    isWorking: isUpdating || isRemoving
  };
};
