import styled from 'styled-components';

export const Title = styled.h3`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black9};
  margin: 0;
  margin-bottom: 16px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.black9};
  margin: 0;
  font-size: 14px;
  margin-bottom: 24px;
`;

export const Group = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black9};
  margin: 0;
  margin-bottom: 12px;
`;
