export enum StudentPreviewToolbarTab {
  INFO = 'INFO',
  PROGRESS = 'PROGRESS',
  HISTORY = 'HISTORY',
  SPECIFIC_INFO = 'SPECIFIC_INFO',
  LAST_14_DAYS = 'LAST_14_DAYS'
}

export enum EditStudentToolbarTab {
  GENERAL_INFO = 'GENERAL_INFO',
  SPECIFIC_INFO = 'SPECIFIC_INFO'
}

export enum EUserStatus {
  NEW = 'NEW',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SLEEP = 'SLEEP'
}

export enum EStudentPaymentStatus {
  PAID = 'PAID',
  DOWNTURN = 'DOWNTURN',
  NON_PAYMENT = 'NON_PAYMENT',
  EXAMINED = 'EXAMINED',
  FORMER_STUDENT = 'FORMER_STUDENT'
}

export enum EStudentsTableTabs {
  ALL_STUDENTS = 'ALL_STUDENTS',
  PROMOTIONS = 'PROMOTIONS'
}
