import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const HeaderWrapper = styled(Flex)`
  width: 100%;
`;
