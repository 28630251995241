import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useTeachersList } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: string;
  multiple?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  onChange?: (value: string) => void;
};

export const TeacherSelector: React.FC<Props> = ({
  value,
  onChange,
  multiple,
  placeholder,
  allowClear = false
}) => {
  const { t } = useTranslation();
  const { teachers = [] } = useTeachersList(true);

  return (
    <Select
      showSearch
      onChange={onChange}
      allowClear={allowClear}
      mode={multiple ? 'multiple' : undefined}
      value={handleInputValue(value, multiple)}
      placeholder={placeholder ?? t('SELECT_USER')}
      options={teachers.map(({ _id, name, lastName }) => ({
        label: `${name} ${lastName}`,
        value: _id
      }))}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};
