import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubtitleWrapper = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
  margin-bottom: 4px;
`;
