import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;
