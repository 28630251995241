import styled from 'styled-components';

type Props = {
  isLocked?: boolean;
};

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-direction: column;

  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const IndexWrapper = styled.div<Props>`
  height: 28px;
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 28px;

  border-radius: 4px;
  background: ${({ theme, isLocked }) => (isLocked ? theme.colors.black4 : theme.colors.purple5)};

  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    gap: 16px;
  }
`;
