import {
  EGamificationRewardType,
  FIRST_DAY_ACTIONS,
  IDailyActions,
  SECOND_DAY_ACTIONS,
  THIRD_DAY_ACTIONS
} from '@constants';
import { IReward } from '@sdk/contracts';
import { dateProvider } from '@utils';

export const getDailyActionsSteps = (rewards: IReward[], streak: number): IDailyActions => {
  if (streak <= 1) return FIRST_DAY_ACTIONS;

  if (streak >= 3) {
    if (areActionsCompletedOnPreviousDay(rewards, SECOND_DAY_ACTIONS)) {
      return THIRD_DAY_ACTIONS;
    }
    if (areActionsCompletedOnPreviousDay(rewards, FIRST_DAY_ACTIONS)) {
      return SECOND_DAY_ACTIONS;
    }
    return FIRST_DAY_ACTIONS;
  }

  const previousActions = getActionsForStreak(streak - 1);
  if (areActionsCompletedOnPreviousDay(rewards, previousActions)) {
    return getActionsForStreak(streak);
  }
  return FIRST_DAY_ACTIONS;
};

const getActionsForStreak = (streak: number) => {
  const modStreak = (streak - 1) % 3;
  if (modStreak === 0) return FIRST_DAY_ACTIONS;
  if (modStreak === 1) return SECOND_DAY_ACTIONS;
  return THIRD_DAY_ACTIONS;
};

const areActionsCompletedOnPreviousDay = (
  rewards: IReward[],
  actions: EGamificationRewardType[]
) => {
  const previousDay = dateProvider.subtractToNow(1, 'days');

  return actions.every(action =>
    rewards.some(
      reward => reward.type === action && dateProvider.isSameDay(reward.createdAt, previousDay)
    )
  );
};
