import { encodeQueryParams } from '@utils';

import { RequestService } from '../request';

import { INebulonQuestionAnswer, IOpenSearchMetadata } from './nebulon-question-answering.contract';

export class NebulonQuestionAnsweringService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public async generateQuestionAnswer(question: string, filter?: IOpenSearchMetadata) {
    const courseId = filter?.courseId;
    const queryParams = encodeQueryParams({ question, courseId });
    return this.poster<INebulonQuestionAnswer, string>(
      `/nebulon/question-answering?${queryParams}`
    );
  }

  public async generatePublicQuestionAnswer(question: string, filter?: IOpenSearchMetadata) {
    const courseId = filter?.courseId;
    const queryParams = encodeQueryParams({ question, courseId });
    return this.poster(`/nebulon/question-answering/public?${queryParams}`);
  }
}
