import { EVoteReason } from '@constants';
import { Form, Input } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReasonPicker } from '../ReasonPicker';

import { useNpsVoteLogic } from './../../../../hooks';

type Props = {
  vote: number;
  reasons?: EVoteReason[];
};

export const VoteReason: React.FC<Props> = () => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { vote, reasons } = useNpsVoteLogic();

  const title = useMemo(() => {
    if (vote < 7) return t('REGULAR_NPS_DETRACTOR_TITLE');
    if (vote < 9) return t('REGULAR_NPS_NEUTRAL_TITLE');
    return t('REGULAR_NPS_PROMOTER_TITLE');
  }, [vote]);

  return (
    <>
      <h3>{title}</h3>
      <Form.Item name="reasons">
        <ReasonPicker vote={vote} />
      </Form.Item>
      {reasons?.some(reason => reason === EVoteReason.OTHER) && (
        <Form.Item name="comment">
          <Input.TextArea rows={5} placeholder={t('WRITE_HERE')} />
        </Form.Item>
      )}
    </>
  );
};
