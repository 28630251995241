import { Select } from 'antd';
import { useMemo } from 'react';

import { getTimeZones, showTimeZone } from '@/utils';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

const TIMEZONES = getTimeZones();

export const TimeZoneSelector: React.FC<Props> = ({ value, onChange }) => {
  const options = useMemo(
    () =>
      TIMEZONES.map(timeZone => ({
        label: showTimeZone(timeZone),
        value: timeZone
      })),
    [TIMEZONES]
  );

  return (
    <Select
      showSearch
      value={value}
      options={options}
      onChange={onChange}
      style={{ minWidth: '200px' }}
    />
  );
};
