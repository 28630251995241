export enum ECourseStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED'
}

export enum EDripKind {
  BY_DAYS_AFTER = 'BY_DAYS_AFTER',
  BY_DATE = 'BY_DATE'
}

export const MIN_COURSE_PROGRESS_TO_ACCESS_QUIZ = 75;

export enum ECoursesTabs {
  PANEL = 'PANEL',
  GANTT = 'GANTT'
}

export enum ECoursesTasksTabs {
  COURSE = 'COURSE',
  TEACHER = 'TEACHER'
}

export const RANKING_PONDERATION = {
  QUIZ: 0.3,
  KNOWLEDGE: 0.7
};
