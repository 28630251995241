import { Card, Checkbox, Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CourseContentReview } from './CourseContentReview';
import { Container } from './QuestionReviewContent.styled';

import { useReviewQuestionsResume, useReviewQuestionsStore } from '@/store';

const CheckboxGroup = Checkbox.Group;

export const QuestionReviewContent = () => {
  const { questionReviewResume = [] } = useReviewQuestionsResume();
  const { selectedContent, setSelectedContent } = useReviewQuestionsStore();
  const { t } = useTranslation('StudentQuizTranslations');

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const allOptions = questionReviewResume.flatMap(({ sections }) =>
      sections.map(section => section._id)
    );
    setSelectedContent(e.target.checked ? allOptions : []);
  };

  const isIndeterminate = useMemo(
    () =>
      !!selectedContent.length &&
      selectedContent.length < questionReviewResume.flatMap(({ sections }) => sections).length,
    [selectedContent, questionReviewResume]
  );

  const isAllChecked = useMemo(
    () =>
      selectedContent.length === questionReviewResume.flatMap(({ sections }) => sections).length,
    [selectedContent, questionReviewResume]
  );

  return (
    <Container>
      <Card>
        <Checkbox
          indeterminate={isIndeterminate}
          onChange={onCheckAllChange}
          checked={isAllChecked}>
          {t('REVIEW_MODE_CHECK_ALL')}
        </Checkbox>
        <Divider />
        <CheckboxGroup value={selectedContent} style={{ display: 'flex', flexDirection: 'column' }}>
          <CourseContentReview />
        </CheckboxGroup>
      </Card>
    </Container>
  );
};
