import { IAccountToRecover } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';

const AuthService = sdk.getService('AuthService');

export const useRecoverPassword = () => {
  const { mutate: recoverPassword, isPending } = useMutation({
    mutationFn: (accountToRecover: IAccountToRecover) =>
      AuthService.recoverPassword(accountToRecover)
  });

  return { recoverPassword, isWorking: isPending };
};
