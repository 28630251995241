import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StudyPlanBadge } from '../StudyPlanBadge';

import {
  HeaderWrapper,
  ItemWrapper,
  SubscriptionsWrapper,
  Wrapper
} from './FilterSubscriptions.styles';

import { useMySubscriptions } from '@/store';

export const FilterSubscriptions = () => {
  const { t } = useTranslation('DefaultTranslations');
  const { planificableSubscriptions } = useMySubscriptions();

  if (!planificableSubscriptions) return null;

  return (
    <Wrapper>
      <HeaderWrapper>
        <StudyPlanBadge />
        <h2>{t('IS_THERE_SOMETHING_YOU_ARENT_STUDYING')}</h2>
        <p>{t('FILTER_SUBSCRIPTIONS')}</p>
      </HeaderWrapper>
      <Form.Item
        name={['planification', 'subscriptions']}
        initialValue={planificableSubscriptions.map(s => s._id)}>
        <Checkbox.Group style={{ width: '100%' }}>
          <SubscriptionsWrapper>
            {planificableSubscriptions?.map(subscription => (
              <ItemWrapper key={subscription._id}>
                <Checkbox value={subscription._id}>{subscription.course.name}</Checkbox>
              </ItemWrapper>
            ))}
          </SubscriptionsWrapper>
        </Checkbox.Group>
      </Form.Item>
    </Wrapper>
  );
};
