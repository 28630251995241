/* eslint-disable indent */
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ButtonsWrapper, StyledButton } from './FooterButtons.styles';

import { useIsMobile } from '@/hooks';
import { useMyPlanification } from '@/store';

type Props = {
  handleEdit: () => void;
  handleNext: () => void;
};

export const FooterButtons: React.FC<Props> = ({ handleEdit, handleNext }) => {
  const { t } = useTranslation('DefaultTranslations');
  const isMobile = useIsMobile('sm');
  const { planification, isCorrectTime } = useMyPlanification();
  const hasHoursAndMinutes = planification?.studyHours !== undefined;

  return (
    <ButtonsWrapper>
      <Button
        block={isMobile}
        onClick={() => (hasHoursAndMinutes || isCorrectTime ? handleEdit() : handleNext())}>
        {t(
          hasHoursAndMinutes
            ? 'PLANIFICATION_RESULT_EDIT_HOURS'
            : isCorrectTime
            ? 'PLANIFICATION_RESULT_EDIT_END_DATE'
            : 'PLANIFICATION_RESULT_LEAVE'
        )}
      </Button>
      <StyledButton
        type="primary"
        block={isMobile}
        onClick={() => (hasHoursAndMinutes || isCorrectTime ? handleNext() : handleEdit())}>
        {t(
          hasHoursAndMinutes || isCorrectTime
            ? 'PLANIFICATION_RESULT_DONE'
            : 'PLANIFICATION_RESULT_EDIT'
        )}
      </StyledButton>
    </ButtonsWrapper>
  );
};
