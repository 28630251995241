import { Select } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 528px;
  overflow: scroll;
`;

export const ActivitiesWrapper = styled.div`
  overflow: hidden scroll;
`;

export const StyledSelect: typeof Select = styled(Select)`
  width: 100%;
`;
