import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-collapse-item {
    border-radius: 0 !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black3};
  }

  .ant-collapse-header {
    padding: 24px 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px 0px 24px 48px !important;
  }
`;
