import { IAcademy } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  data?: Partial<IAcademy> | null;
  setData: (academy: Partial<IAcademy> | null) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useAcademyForm = createStore<IStore>(set => ({
  isOpen: false,
  data: null,
  setData: (data: Partial<IAcademy> | null) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
