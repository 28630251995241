import Cookies from 'js-cookie';

import { sdk } from '@/services';

const MAIN_TOKEN_COOKIE = 'token';
const IMPERSONATE_TOKEN_COOKIE = 'impersonateToken';

const COOKIES_SETTINGS = {
  domain: import.meta.env.VITE_COOKIE_DOMAIN,
  path: '/'
};

const AuthService = sdk.getService('AuthService');

export const useImpersonate = () => {
  const mainToken = Cookies.get(MAIN_TOKEN_COOKIE);
  const impersonateToken = Cookies.get(IMPERSONATE_TOKEN_COOKIE);

  const isImpersonating = !!impersonateToken;

  const impersonateUser = async (email: string) => {
    const { token } = await AuthService.impersonate(email);
    if (token && mainToken) {
      Cookies.set(IMPERSONATE_TOKEN_COOKIE, mainToken, COOKIES_SETTINGS);
      Cookies.set(MAIN_TOKEN_COOKIE, token, COOKIES_SETTINGS);
      window.location.href = '/';
    }
  };

  const stopImpersonating = () => {
    if (mainToken && impersonateToken) {
      Cookies.set(MAIN_TOKEN_COOKIE, impersonateToken, COOKIES_SETTINGS);
      Cookies.remove(IMPERSONATE_TOKEN_COOKIE, COOKIES_SETTINGS);
      window.location.href = '/academy/students';
    }
  };

  return {
    impersonateUser,
    isImpersonating,
    stopImpersonating
  };
};
