import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  .ant-select {
    width: 100%;
  }

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;
