import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const DeliverableService = sdk.getService('DeliverableService');

export const useStudentDeliverables = () => {
  const queryKey = ['deliverables', 'my-deliverables'];

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => DeliverableService.findStudentDeliverables()
  });

  return {
    deliverables: data,
    isLoading
  };
};
