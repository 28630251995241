import { EAutonomousCommunity, EFeatures, EVerticalNames } from '@constants';
import { ICreatePlanificationForm, IExamDate, IUserOnboarding } from '@sdk/contracts';
import { Drawer, Form, message } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { ChosenReason } from './components/ChosenReason';
import { ContractSign } from './components/ContractSign';
import { DiscoverySource } from './components/DiscoverySource';
import { DoYouKnowOtherPeople } from './components/DoYouKnowOtherPeople';
import { LoadingPlatform } from './components/LoadingPlatform';
import { NotificationSettings } from './components/NotificationSettings';
import { OnboardingFooter } from './components/OnboardingFooter';
import { SkipPlanModal } from './components/SkipPlanModal';
import { StudentInfo } from './components/StudentInfo';
import { StudySteps } from './components/StudySteps';
import { Welcome } from './components/Welcome';
import { ContentWrapper, Wrapper, StyledForm, SlidesWrapper, SlideWrapper } from './styles';

import {
  DoYouHaveExamDate,
  ExamDate,
  FilterSubscriptions,
  RestDays,
  StudyPlanIntro
} from '@/components/Planification';
import { useIsMobile, useTagFeatures } from '@/hooks';
import { useProfile, useMutateProfile, useMutatePlanification, useMySubscriptions } from '@/store';

interface FormValues {
  name: string;
  lastName: string;
  legalId?: string;
  birthday?: Date;
  examDate?: IExamDate;
  autonomousCommunities?: EAutonomousCommunity[];
  onBoarding?: IUserOnboarding;
  planification?: ICreatePlanificationForm;
}

const NOTIFICATION_VERTICALS = [EVerticalNames.OPOS, EVerticalNames.UNI];

export const OnboardingPage = () => {
  const { t } = useTranslation('StudentOnboardingTranslations');
  const { profile, isOnboardingCompleted } = useProfile();
  const { planificableSubscriptions } = useMySubscriptions();
  const { createPlanification, removePlanification, isWorking } = useMutatePlanification();
  const { updateProfile } = useMutateProfile();
  const { canRender } = useTagFeatures();
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [isPlatformReady, setIsPlatformReady] = useState(true);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [isRestDaysConfirmationOpen, setIsRestDaysConfirmationOpen] = useState(false);
  const navigateTo = useNavigate();
  const isMobile = useIsMobile('sm');

  const legalId = Form.useWatch('legalId', form);
  const birthday = Form.useWatch('birthday', form);
  const planificationForm: ICreatePlanificationForm = Form.useWatch(['planification'], form);
  const hasExamDate = planificationForm?.hasExamDate;
  const examDate = planificationForm?.endDate;
  const studyHours = planificationForm?.studyHours;
  const studyMinutes = planificationForm?.studyMinutes;
  const restWeekdays = planificationForm?.restWeekdays;
  const subscriptionsSelected = planificationForm?.subscriptions;

  const isFirstFormCompleted = legalId && birthday;

  const hasFinishedOnboarding = step === 13 && isContractSigned && !isMobile;
  const isExamDateFormEmpty =
    step === 9 && !examDate && (studyHours === undefined || studyMinutes === undefined);
  const isRestWeekdaysEmpty = step === 10 && !restWeekdays?.length;

  if (!profile) return null;
  if (isOnboardingCompleted) return <Navigate to="/dashboard" replace={true} />;

  const handleNextStep = () => {
    switch (step) {
      case 0:
      case 1:
      case 8:
      case 9:
        setStep(prevIndex => (prevIndex + 1) % slides.length);
        break;
      case 5:
        form.submit();
        if (!profile.tags.some(tag => NOTIFICATION_VERTICALS.includes(tag.vertical))) {
          if (canRender(EFeatures.PLANIFICATION)) {
            setStep(prevIndex => (prevIndex + 2) % slides.length);
          } else {
            setStep((slides.length - 2) % slides.length);
          }
          break;
        }
        setStep(prevIndex => (prevIndex + 1) % slides.length);
        break;
      case 6:
        form.submit();
        if (canRender(EFeatures.PLANIFICATION)) {
          setStep(prevIndex => (prevIndex + 1) % slides.length);
          break;
        }
        setStep((slides.length - 2) % slides.length);
        break;
      case 10:
        if (isRestWeekdaysEmpty && !isRestDaysConfirmationOpen) {
          setIsRestDaysConfirmationOpen(true);
          break;
        }
        setStep(prevIndex => (prevIndex + 1) % slides.length);
        break;
      case 11:
        createPlanification(
          {
            ...planificationForm,
            userId: profile._id,
            endDate: planificationForm.endDate ? new Date(planificationForm.endDate) : undefined,
            restDates: (planificationForm.restDates ?? []).map(date => new Date(date)),
            restWeekdays: planificationForm.restWeekdays ?? [],
            includeAllActivities: true
          },
          {
            onSuccess: () => {
              setStep(prevIndex => (prevIndex + 1) % slides.length);
            }
          }
        );
        break;
      case 13:
        goToDashboard();
        break;
      default:
        form.submit();
        setStep(prevIndex => (prevIndex + 1) % slides.length);
    }
  };

  const handleStepBack = () => setStep(prev => prev - 1);

  const handleEditPlanification = () => {
    removePlanification();
    form.resetFields(['planification']);
    form.setFieldValue(
      ['planification', 'subscriptions'],
      planificableSubscriptions?.map(s => s._id)
    );
    setStep(8);
  };

  const handleUpdateProfile = async (values: Partial<FormValues>) =>
    updateProfile({
      ...values,
      notificationSettings: {
        examGroupNotificationSettings: {
          isActive: true,
          examGroupNotifications: profile.tags.map(tag => ({
            tag: tag._id,
            autonomousCommunities: values.autonomousCommunities ?? []
          }))
        }
      }
    });

  const goToDashboard = () => {
    message.success(t('WELCOME_MESSAGE'));
    navigateTo('/dashboard');
  };

  const handleSkipPlan = () => setStep((slides.length - 2) % slides.length);

  const isButtonDisabled =
    !isPlatformReady ||
    (step === 2 && !isFirstFormCompleted) ||
    isExamDateFormEmpty ||
    (step === 10 && restWeekdays?.length === 7) ||
    (step === 11 && !subscriptionsSelected?.length) ||
    (step === 13 && !isContractSigned);

  const slidesItems = [
    <Welcome />,
    <StudySteps />,
    <StudentInfo />,
    <DiscoverySource handleNext={handleNextStep} />,
    <ChosenReason handleNext={handleNextStep} />,
    <DoYouKnowOtherPeople handleNext={handleNextStep} />,
    <NotificationSettings />,
    <StudyPlanIntro />,
    <DoYouHaveExamDate handleNext={handleNextStep} />,
    <ExamDate hasExamDate={!!hasExamDate} />,
    <RestDays
      isOpen={isRestDaysConfirmationOpen}
      setIsOpen={setIsRestDaysConfirmationOpen}
      onConfirm={handleNextStep}
    />,
    <FilterSubscriptions />,
    <LoadingPlatform step={step} setIsPlatformReady={setIsPlatformReady} />,
    <ContractSign
      step={step}
      isContractSigned={isContractSigned}
      setIsContractSigned={setIsContractSigned}
    />
  ];

  const slides = slidesItems.map((content, index) => ({ key: index, content }));

  return (
    <Drawer styles={{ body: { padding: 0 } }} closable={false} open footer={null} width="100%">
      <Wrapper>
        <StyledForm
          form={form}
          layout="vertical"
          onFinish={handleUpdateProfile}
          initialValues={{
            ...profile,
            birthday: profile?.birthday && dayjs(profile.birthday)
          }}>
          <ContentWrapper>
            <SlidesWrapper style={{ transform: `translateX(-${step * 100}%)` }}>
              {slides.map(slide => (
                <SlideWrapper key={slide.key}>{slide.content}</SlideWrapper>
              ))}
            </SlidesWrapper>
            <OnboardingFooter
              step={step}
              isPlatformReady={isPlatformReady}
              hasFinishedOnboarding={hasFinishedOnboarding}
              isDisabled={isButtonDisabled}
              isWorking={isWorking}
              handleNextStep={handleNextStep}
              handleStepBack={handleStepBack}
              handleEditPlanification={handleEditPlanification}
            />
          </ContentWrapper>
        </StyledForm>
      </Wrapper>
      <SkipPlanModal onSkipPlan={handleSkipPlan} />
    </Drawer>
  );
};
