import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  height: 100%;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
`;
