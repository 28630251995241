import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { SubtitleWrapper, VoteWrapper, Wrapper } from '../NpsVote.styles';

import { VoteSelector } from './VoteSelector';

export const NpsScore = () => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <Wrapper>
      <h3>{t('HOW_LIKELY_IS_IT_THAT_YOU_WOULD_RECOMMEND_UCADEMY')}</h3>
      <VoteWrapper>
        <Form.Item name="vote">
          <VoteSelector />
        </Form.Item>
        <SubtitleWrapper>
          <div>{t('NOT_PROBABLE')}</div>
          <div>{t('VERY_PROBABLE')}</div>
        </SubtitleWrapper>
      </VoteWrapper>
    </Wrapper>
  );
};
