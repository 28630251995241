import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useReferralModal = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
