import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { Description, Wrapper } from './ActivityPreview.styles';
import { PdfPreview } from './PdfPreview';
import { VideoPreview } from './VideoPreview';

import { useActivityPreview } from '@/hooks';
import { useActivityDetail } from '@/store';

const TOP_MARGIN = 20;

export const ActivityPreview = () => {
  const { t } = useTranslation(['CourseTranslations', 'DefaultTranslations']);
  const { activityId, setActivityId } = useActivityPreview();
  const { isLoading, activity } = useActivityDetail(activityId);

  const handleCancel = () => setActivityId();

  if (isLoading || !activity?.resource) return null;

  const { type, name, description, resource } = activity;

  const WIDTH = type === 'PDF' ? 800 : undefined;

  return (
    <Modal
      width={WIDTH}
      destroyOnClose
      open={!!activity}
      onCancel={handleCancel}
      style={{ top: TOP_MARGIN }}
      title={t('CourseTranslations:PREVIEW_ACTIVITY_TITLE')}
      footer={
        <Button type="primary" onClick={handleCancel}>
          {t('DefaultTranslations:CLOSE')}
        </Button>
      }>
      <h4>{name}</h4>
      <Wrapper>
        {type === 'VIDEO' && <VideoPreview file={resource.file} />}
        {type === 'INTERACTIVE_VIDEO' && <VideoPreview file={resource.file} />}
        {type === 'PDF' && <PdfPreview file={resource.file} />}
        <Description>{description}</Description>
      </Wrapper>
    </Modal>
  );
};
