import { Avatar } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

export const DetailSubtitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const NameWrapper = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const StyledAvatar = styled(Avatar)`
  flex: none;
`;

export const TicketDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const DetailTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;
