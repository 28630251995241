import { ETaskStatus } from '@constants';
import { ISectionTask, ITaskItem } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useProfile } from '../profile';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

type Props = {
  selectedCourse?: string;
  selectedTeacher?: string;
};

export const useCourseTasks = ({ selectedCourse, selectedTeacher }: Props) => {
  const {
    profile,
    roleCheck: { isTeacher }
  } = useProfile();
  const teacherId = isTeacher ? profile?._id : selectedTeacher;
  const courseId = selectedCourse ?? undefined;

  const { isLoading, data } = useQuery({
    queryKey: ['courses', 'tasks', courseId, teacherId],
    queryFn: () => CourseService.findCoursesTasks(courseId, teacherId),
    enabled: !!courseId || !!teacherId
  });

  const [approvedTasks = [], reviewTasks = [], pendingTasks = []] = useMemo(() => {
    if (!data) return [[], [], []];

    const allTasks: ITaskItem[] = data.coursesTasks
      .map(courseTask => {
        const course = data.courses.find(c => c._id === courseTask.courseId);
        const section = course?.sections.find(s => s._id === courseTask.sectionId);
        const activity = section?.activities.find(a => a._id === courseTask.activityId);
        if (!course || !section || !activity) return null;

        return {
          course,
          section,
          activity,
          task: courseTask.task
        };
      })
      .filter((courseTask): courseTask is ITaskItem => courseTask !== null);

    const appTasks = allTasks.filter(
      courseTask => courseTask.task.statusInfo.currentStatus === ETaskStatus.APPROVED
    );

    const revTasks = allTasks.filter(
      courseTask => courseTask.task.statusInfo.currentStatus === ETaskStatus.IN_REVIEW
    );

    const penTasks = allTasks.filter(
      courseTask =>
        courseTask.task.statusInfo.currentStatus === ETaskStatus.PENDING ||
        courseTask.task.statusInfo.currentStatus === ETaskStatus.REJECTED
    );

    return [appTasks, revTasks, penTasks];
  }, [data, profile, teacherId]);

  const sectionTasks = useMemo((): ISectionTask[] => {
    if (!courseId || !data) return [];

    const sectionsMap = new Map<string, ISectionTask>();

    data.coursesTasks.forEach(courseTask => {
      const course = data.courses.find(c => c._id === courseTask.courseId);
      const section = course?.sections.find(s => s._id === courseTask.sectionId);
      if (!section) return;

      if (!sectionsMap.has(courseTask.sectionId)) {
        sectionsMap.set(courseTask.sectionId, {
          section,
          tasks: []
        });
      }

      const sectionTask = sectionsMap.get(courseTask.sectionId);
      if (!sectionTask) return;

      sectionTask.tasks.push(courseTask.task);
    });

    return Array.from(sectionsMap.values()).filter(sectionTask => sectionTask.tasks.length > 0);
  }, [data, profile, courseId, teacherId]);

  const hasDifferentCourses = useMemo(() => {
    if (!data) return false;
    const courses = new Set(data.coursesTasks.map(courseTask => courseTask.courseId));
    return courses.size > 1;
  }, [data]);

  return {
    isLoading,
    isEmpty: data?.coursesTasks.length === 0,
    hasDifferentCourses,
    sectionTasks,
    approvedTasks,
    reviewTasks,
    pendingTasks
  };
};
