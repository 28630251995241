import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: Date;
  onChange?: (value: Date) => void;
};

export const ExamDatePicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('DefaultTranslations');

  return (
    <DatePicker
      format="DD/MM/YYYY"
      value={value}
      onChange={onChange}
      placeholder={t('SELECT_A_DATE')}
      minDate={dayjs().add(15, 'day')}
    />
  );
};
