import styled from 'styled-components';

export const EmptyTitleWrapper = styled.span`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const EmptyDescriptionWrapper = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
