import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const BreadCrumbWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.green6};
  cursor: pointer;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
