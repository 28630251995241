export const CommonTicketsTranslations = {
  YOU_HAVE_NOT_DOUBT_TICKETS: {
    es: 'No tienes dudas',
    en: 'You have not doubt tickets',
    cat: 'No tens dubtes',
    eus: 'Ez duzu zalantzarik'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Filtroak'
  },
  OPEN_DOUBTS: {
    es: 'Dudas abiertas',
    en: 'Open doubts',
    cat: 'Dubtes oberts',
    eus: 'Zalantzak irekiak'
  },
  RESOLVED_DOUBTS: {
    es: 'Dudas resueltas',
    en: 'Resolved doubts',
    cat: 'Dubtes resolts',
    eus: 'Zalantzak ebaztuta'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  SELECT_A_COURSE_TO_FILTER: {
    es: 'Selecciona un curso para filtrar',
    en: 'Select a course to filter',
    cat: 'Selecciona un curs per filtrar',
    eus: 'Hautatu ikastaro bat iragazteko'
  },
  SELECT_A_STUDENT_TO_FILTER: {
    es: 'Selecciona un estudiante para filtrar',
    en: 'Select a student to filter',
    cat: 'Selecciona un estudiant per filtrar',
    eus: 'Hautatu ikasle bat iragazteko'
  },
  TICKET_STATUS_UPDATED: {
    es: 'Estado del ticket actualizado: {{status}}',
    en: 'Ticket status updated: {{status}}',
    cat: 'Estat del ticket actualitzat: {{status}}',
    eus: 'Ticketaren egoera eguneratua: {{status}}'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  CLEAR_SEARCH_FILTER: {
    es: 'Borrar filtro',
    en: 'Clear filter',
    cat: 'Esborrar filtre',
    eus: 'Iragazki garbitu'
  },
  TAG: {
    es: 'Categoría',
    en: 'Category',
    cat: 'Categoria',
    eus: 'Kategoria'
  },
  DELETE_TICKET: {
    es: 'Eliminar duda',
    en: 'Delete doubt',
    cat: 'Eliminar dubte',
    eus: 'Zalantza ezabatu'
  },
  TICKET_REMOVED_SUCCESSFULLY: {
    es: 'Duda eliminada con éxito!',
    en: 'Doubt deleted successfully!',
    cat: 'Dubte eliminat correctament!',
    eus: 'Zalantza ongi ezabatua!'
  },
  ASSIGN_TEACHER: {
    es: 'Asignar nuevo profesor',
    en: 'Assign a new teacher',
    cat: 'Assignar nou professor',
    eus: 'Irakasle berria asignatu'
  },
  ASSIGNEE: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  VISIBILITY: {
    es: 'Visibilidad',
    en: 'Visibility',
    cat: 'Visibilitat',
    eus: 'Ikusgarritasuna'
  }
};
