import styled from 'styled-components';

import { WidgetWrapper } from '../styles';

export const Wrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  & > :first-child {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 20px;
    & > :first-child {
      flex-shrink: 0;
    }
    & > :nth-child(2) {
      flex-grow: 1;
    }
  }
  & > :last-child {
    text-align: right;
  }
`;

export const MessagesWrapper = styled.div`
  & > * {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 6px;
    & > :first-child {
      flex-shrink: 0;
    }
    & > :nth-child(2) {
      & > :first-child {
        font-weight: 600;
      }
      p {
        margin-top: 0px !important;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;
