import styled from 'styled-components';

type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  hasProgress: boolean;
};

export const Wrapper = styled.div<Props>`
  width: fit-content;
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: ${({ hasProgress }) => (hasProgress ? '4px 4px 4px 14px' : '4px 14px')};
  border-radius: 33px;
  border: 1px solid
    ${({ theme, isDisabled, isChecked }) =>
      isDisabled ? theme.colors.black4 : isChecked ? theme.colors.purple6 : theme.colors.black4};
  background: ${({ theme, isDisabled, isChecked }) =>
    isDisabled ? theme.colors.black2 : isChecked ? theme.colors.purple3 : theme.colors.white};

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme, isDisabled, isChecked }) =>
    isDisabled ? theme.colors.black5 : isChecked ? theme.colors.purple7 : theme.colors.black9};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  align-items: center;

  :hover {
    color: ${({ theme }) => theme.colors.purple7};
    border: 1px solid ${({ theme }) => theme.colors.purple6};
    background: ${({ theme }) => theme.colors.purple2};
    span:first-of-type {
      background: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

type ProgressProps = {
  isChecked: boolean;
};

export const ProgressWrapper = styled.span<ProgressProps>`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  background: ${({ theme, isChecked }) => (isChecked ? theme.colors.white : theme.colors.black2)};
  padding: 2px 8px 2px 8px;
  border-radius: 33px;
  color: ${({ theme }) => theme.colors.black9};
`;
