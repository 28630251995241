import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.black3};

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    position: relative;
    width: 25%;
    background-color: transparent;
    border: none;
    padding: 0;
  }
`;

export const QuestionText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: center;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    border-radius: 8px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black2};
    margin-bottom: 32px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: -30px;
`;

export const QuestionCount = styled.span`
  color: ${({ theme }) => theme.colors.purple6};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  vertical-align: sub;
`;
