import { IQuestion } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  question?: IQuestion;
  setQuestion: (question?: IQuestion) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useManualQuestionForm = createStore<IStore>(set => ({
  setQuestion: (question?: IQuestion) => set({ question, isOpen: Boolean(question) }),
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
