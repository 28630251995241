/* eslint-disable @typescript-eslint/no-explicit-any */
function isObject(object: any) {
  return object != null && typeof object === 'object';
}

/**
 * It returns true if the two objects have the same keys and values, and false otherwise
 * @param {any} object1 - The first object to compare.
 * @param {any} object2 - any - The second object to compare.
 * @returns A function that takes two objects and returns true if they are equal, false otherwise.
 */
export const areObjectEquals = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1 || {});
  const keys2 = Object.keys(object2 || {});
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !areObjectEquals(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};
