import {
  ICourse,
  IReorderSections,
  IReorderActivities,
  IReorderFolders,
  IReorderMaterial,
  IActivity
} from '@sdk/contracts';
import { create as createStore } from 'zustand';

import { useMutateActivity } from '../activities';

import { useMutateCourses } from './useMutateCourses';

interface IStore {
  course?: ICourse;
  update: (course: ICourse) => void;
}

const store = createStore<IStore>(set => ({
  update: (course: ICourse) => {
    set(state => ({
      ...state,
      course
    }));
  }
}));

export const useLocalCourse = () => {
  const { course, update } = store();
  const {
    reorderCourseSections: reorderSections,
    reorderCourseActivities: reorderActivities,
    reorderCourseFolders: reorderFolders,
    reorderCourseMaterial: reorderMaterial
  } = useMutateCourses();

  const { updateActivity } = useMutateActivity();

  const reorderCourseSections = ({ courseId, newSectionsOrder }: IReorderSections) => {
    if (course) {
      const { sections } = course;
      const orderedSections = sections.sort((a, b) => {
        return newSectionsOrder.indexOf(a._id) - newSectionsOrder.indexOf(b._id);
      });
      update({ ...course, sections: orderedSections });
    }
    reorderSections({ courseId, newSectionsOrder });
  };

  const reorderCourseActivities = ({
    courseId,
    sectionId,
    newActivitiesOrder
  }: IReorderActivities) => {
    if (course) {
      const { sections } = course;
      const sectionToUpdate = sections.find(s => s._id === sectionId);
      if (sectionToUpdate) {
        const { activities } = sectionToUpdate;
        const orderedActivities = activities.sort((a, b) => {
          return newActivitiesOrder.indexOf(a._id) - newActivitiesOrder.indexOf(b._id);
        });
        update({
          ...course,
          sections: sections.map(s =>
            s._id === sectionId ? { ...s, activities: orderedActivities } : s
          )
        });
      }
    }
    reorderActivities({ courseId, sectionId, newActivitiesOrder });
  };

  const reorderCourseFolders = ({ courseId, newFoldersOrder }: IReorderFolders) => {
    if (course) {
      const { foldersWithMaterial } = course;
      const orderedFolders = foldersWithMaterial.sort((a, b) => {
        return newFoldersOrder.indexOf(a._id) - newFoldersOrder.indexOf(b._id);
      });
      update({ ...course, foldersWithMaterial: orderedFolders });
    }
    reorderFolders({ courseId, newFoldersOrder });
  };

  const reorderCourseMaterial = ({ courseId, folderId, newMaterialOrder }: IReorderMaterial) => {
    if (course) {
      const { foldersWithMaterial } = course;
      const folderToUpdate = foldersWithMaterial.find(f => f._id === folderId);
      if (folderToUpdate) {
        const { material } = folderToUpdate;
        const orderedMaterial = material?.sort((a, b) => {
          return newMaterialOrder.indexOf(a._id) - newMaterialOrder.indexOf(b._id);
        });
        update({
          ...course,
          foldersWithMaterial: foldersWithMaterial.map(f =>
            f._id === folderId ? { ...f, material: orderedMaterial } : f
          )
        });
      }
    }
    reorderMaterial({ courseId, folderId, newMaterialOrder });
  };

  const updateLocalActivity = (sectionId: string, activity: IActivity) => {
    if (course) {
      const { sections } = course;
      const section = sections.find(s => s._id === sectionId);
      if (section) {
        const newActivities = section.activities.map(a => (a._id === activity._id ? activity : a));
        const newSection = { ...section, activities: newActivities };
        update({ ...course, sections: sections.map(s => (s._id === sectionId ? newSection : s)) });
      }
      updateActivity({ courseName: course.name, activity });
    }
  };

  return {
    course,
    update,
    reorderCourseSections,
    reorderCourseActivities,
    reorderCourseFolders,
    reorderCourseMaterial,
    updateLocalActivity
  };
};
