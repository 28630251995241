import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  width: 50%;
  align-self: stretch;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    align-self: center;
    height: unset;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PointsWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.orange6};
`;

export const TextWrapper = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
