import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  border-radius: 4px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.purple6};
`;

export const TitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContentWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.colors.white};
`;
