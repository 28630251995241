import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserStatsByCourseService = sdk.getService('UserStatsByCourseService');

export const useMyAverageQuizScore = () => {
  const queryKey = ['user-stats-by-course', 'me', 'average-score'];
  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => UserStatsByCourseService.getMyAverageScore()
  });

  return {
    isLoading,
    averageScore: data
  };
};
