import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useReferral = () => {
  const { t } = useTranslation('CommonDashboardTranslations');

  const { mutate: sendReferralInvitation, isPending } = useMutation({
    mutationFn: (email: string) => UserService.sendReferralInvitation(email),
    onSuccess: () => notification.success({ message: t('INVITATION_SENT') }),
    onError: () => notification.error({ message: t('INVITATION_FAILED') })
  });

  return {
    isSending: isPending,
    sendReferralInvitation
  };
};
