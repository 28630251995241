import { IExamGroup } from '@sdk/contracts';
import { create as createStore } from 'zustand';

type IStore = {
  examGroup?: IExamGroup;
  isOpen: boolean;
  setExamGroup: (examGroup?: IExamGroup) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const store = createStore<IStore>(set => ({
  isOpen: false,
  setExamGroup: examGroup => set({ examGroup }),
  setIsOpen: isOpen => set({ isOpen })
}));

export const useExamGroupForm = () => {
  const { isOpen, examGroup, setExamGroup, setIsOpen } = store();

  return {
    isOpen,
    examGroup,
    setExamGroup,
    setIsOpen
  };
};
