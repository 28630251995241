/* eslint-disable max-len */
import { EFeatures } from '@constants';
import { ISortedPlanificationActivity } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useProfile } from '../profile';

import { useTagFeatures } from '@/hooks';
import { sdk } from '@/services';
import {
  getCurrentWeekInfo,
  getHowIsPlanificationGoing,
  getPlanificationEvents,
  getSortedPlanificationActivitiesByDate
} from '@/utils';

const PlanificationService = sdk.getService('PlanificationService');

const MIN_DAILY_STUDY_TIME = 30 * 60;
const MAX_DAILY_STUDY_TIME = 8 * 60 * 60;

export const useMyPlanification = () => {
  const {
    profile,
    roleCheck: { isStudent }
  } = useProfile();
  const { canRender } = useTagFeatures();

  const userId = profile?._id;

  const { isLoading, data, error } = useQuery({
    queryKey: ['planification', userId],
    queryFn: () => PlanificationService.getPlanificationByUser(userId!),
    enabled: !!userId && isStudent
  });
  const dailyStudyTime = useMemo(() => data?.dailyStudyTime ?? 0, [data]);

  const sortedPlanificationActivitiesByDate = useMemo(
    () => getSortedPlanificationActivitiesByDate(data),
    [data]
  );

  const currentWeekInfo = useMemo(
    () => getCurrentWeekInfo(sortedPlanificationActivitiesByDate),
    [sortedPlanificationActivitiesByDate]
  );

  const howIsGoing = useMemo(() => getHowIsPlanificationGoing(data), [data]);

  const planificationEvents = useMemo(
    () => (canRender(EFeatures.PLANIFICATION) ? getPlanificationEvents(data) : []),
    [data, canRender]
  );

  const getActivitiesByCourseAndSections = (date: string) => {
    const activities = sortedPlanificationActivitiesByDate[date];

    return activities.reduce((acc, activity) => {
      const { subscription, section } = activity;
      const courseId = subscription?.course?._id;
      const sectionId = section?._id;

      if (!courseId || !sectionId) return acc;

      if (!acc[courseId]) {
        acc[courseId] = {
          courseName: subscription?.course?.name,
          sections: {}
        };
      }

      if (!acc[courseId].sections[sectionId]) {
        acc[courseId].sections[sectionId] = [];
      }

      acc[courseId].sections[sectionId].push(activity);
      return acc;
    }, {} as Record<string, { courseName: string; sections: Record<string, ISortedPlanificationActivity[]> }>);
  };

  return {
    planification: canRender(EFeatures.PLANIFICATION) ? data : undefined,
    sortedPlanificationActivitiesByDate,
    currentWeekInfo,
    howIsGoing,
    planificationEvents,
    getActivitiesByCourseAndSections,
    isMaxTimeExceeded: dailyStudyTime > MAX_DAILY_STUDY_TIME,
    isLessThanMinTime: dailyStudyTime < MIN_DAILY_STUDY_TIME,
    isCorrectTime:
      data?.studyHours !== undefined
        ? true
        : dailyStudyTime >= MIN_DAILY_STUDY_TIME && dailyStudyTime <= MAX_DAILY_STUDY_TIME,
    isLoading,
    error
  };
};
