import { IQuestionReview } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  selectedContent: string[];
  questionsToReview: IQuestionReview[];
  setQuestionsToReview: (questionsToReview: IQuestionReview[]) => void;
  updateQuestion: (question: IQuestionReview) => void;
  setSelectedContent: (selectedContent: string[]) => void;
}

export const useReviewQuestionsStore = createStore<IStore>(set => ({
  selectedContent: [],
  questionsToReview: [],
  setQuestionsToReview: (questionsToReview: IQuestionReview[]) => {
    set(state => ({
      ...state,
      questionsToReview
    }));
  },
  updateQuestion: (reviewdQuestion: IQuestionReview) => {
    set(state => ({
      ...state,
      questionsToReview: state.questionsToReview.map(questionToReview =>
        questionToReview._id === reviewdQuestion._id ? reviewdQuestion : questionToReview
      )
    }));
  },
  setSelectedContent: (selectedContent: string[]) => {
    set(state => ({
      ...state,
      selectedContent
    }));
  }
}));
