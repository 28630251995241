import { IShareActivityTranscription, IUpdateTranscription } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const TranscriptionService = sdk.getService('TranscriptionService');

export const useMutateTranscriptions = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['transcriptions'] });
  const { t } = useTranslation('CourseTranslations');

  const { mutate: generateTranscription, isPending: isGenerating } = useMutation({
    mutationFn: (activityId: string) => TranscriptionService.generateTranscription(activityId),
    onSuccess
  });

  const { mutate: generateAllCourseTranscriptions, isPending: isGeneratingAll } = useMutation({
    mutationFn: (courseId: string) =>
      TranscriptionService.generateAllCourseTranscriptions(courseId),
    onSuccess: () => {
      onSuccess();
      message.loading(t('GENERATE_ALL_TRANSCRIPTIONS_SUCCESS'));
    }
  });

  const { mutate: updateTranscription, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateTranscription) => TranscriptionService.updateTranscription(update),
    onSuccess
  });

  const { mutate: sendToSearchIndex, isPending: isSendingOne } = useMutation({
    mutationFn: (activityId: string) => TranscriptionService.sendToSearchIndex(activityId),
    onSuccess
  });

  const { mutate: sendAllToSearchIndex, isPending: isSendingAll } = useMutation({
    mutationFn: (courseId: string) => TranscriptionService.sendAllToSearchIndex(courseId),
    onSuccess: () => {
      onSuccess();
      message.loading(t('SEND_ALL_TO_INDEX_SUCCESS'));
    }
  });

  const { mutate: shareActivityTranscription, isPending: isSharing } = useMutation({
    mutationFn: (data: IShareActivityTranscription) =>
      TranscriptionService.shareActivityTranscription(data),
    onSuccess
  });

  return {
    generateTranscription,
    updateTranscription,
    generateAllCourseTranscriptions,
    sendToSearchIndex,
    sendAllToSearchIndex,
    shareActivityTranscription,
    invalidateQueries: onSuccess,
    isWorking:
      isGenerating || isGeneratingAll || isUpdating || isSendingOne || isSendingAll || isSharing
  };
};
