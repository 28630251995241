import { EGamificationRewardType } from '@constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const GamificationService = sdk.getService('GamificationService');

export const useMutateMyGamificationRewards = () => {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['gamification'] });

  const { mutate: claimReward, isPending } = useMutation({
    mutationFn: (rewardType: EGamificationRewardType) =>
      GamificationService.claimReward(rewardType),
    onSuccess
  });

  return {
    claimReward,
    isWorking: isPending
  };
};
