import { IFile } from '@sdk/contracts';

import { sdk } from '@/services';

const NebulonService = sdk.getService('NebulonService');

//TODO: Not used now
export const useGenerateAIQuestions = () => {
  const generateAIQuestions = async (file: IFile | null) => {
    if (!file) return;
    const extension = file.mimetype.split('/')[1];
    return NebulonService.generateAIQuestions(`${file._id}.${extension}`);
  };

  const predictNumberOfQuestions = async (file: IFile | null) => {
    if (!file) return;
    const extension = file.mimetype.split('/')[1];
    return NebulonService.predictNumberOfQuestions(`${file._id}.${extension}`);
  };

  return { generateAIQuestions, predictNumberOfQuestions };
};
