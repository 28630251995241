import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
`;

export const StyledH2 = styled.h2`
  max-width: 300px;
  position: relative;
`;

export const StyledImage = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
`;
