import { IResetPasswordRequest } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { sdk } from '@/services';

const AuthService = sdk.getService('AuthService');

export const useResetPassword = () => {
  const queryClient = useQueryClient();
  const navigateTo = useNavigate();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['profile'] });

  const { mutate: resetPassword, isPending } = useMutation({
    mutationFn: (resetPasswordRequest: IResetPasswordRequest) =>
      AuthService.resetPassword(resetPasswordRequest),
    onSuccess: () => {
      onSuccess();
      navigateTo('/dashboard', { replace: true });
    }
  });

  return { resetPassword, isWorking: isPending };
};
