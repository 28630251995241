import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { IQuiz } from '../quiz';
import { RequestService } from '../request';

import { IQuestion, IQuestionsFilters, IQuestionWithRelatedContent } from './question.contract';

export class QuestionService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getQuestions(pagination: IPagination, filters: IQuestionsFilters, search: string) {
    const query = createQueryString({
      search,
      limit: pagination.limit,
      page: pagination.page,
      filters
    });
    return this.fetcher<IPaginatedResponse<IQuestionWithRelatedContent>>(`/question?${query}`);
  }

  async getQuestion(questionId: string) {
    return this.fetcher<IQuestion>(`/question/${questionId}`);
  }

  async createQuestion(question: IQuestion) {
    return this.poster<IQuestion, IQuestion>(`/question`, question);
  }

  async createQuestions(questions: Omit<IQuestion, '_id'>[]) {
    return this.poster<IQuestion[], Omit<IQuestion, '_id'>[]>(`/question/bulk`, questions);
  }

  async updateQuestion(question: IQuestion) {
    return this.putter<IQuestion, IQuestion>(`/question/${question._id}`, question);
  }

  async removeQuestion(questionId: string) {
    return this.deleter(`/question/${questionId}`);
  }

  async removeQuestions(questions: string[]) {
    return this.deleter(`/question`, {
      data: { questions }
    });
  }

  async getQuestionQuizzes(questionId?: string) {
    return this.fetcher<IQuiz[]>(`/question/${questionId}/quizzes`);
  }
}
