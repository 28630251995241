import { ISubscription } from '@sdk/contracts';

export const getNextActivityTrack = (subscription: ISubscription, activity?: string) => {
  const { course, currentActivity = '' } = subscription || {};
  const { sections = [] } = course || {};
  const activityCompleted = activity ?? currentActivity;

  const activities = sections.map(s => s.activities).flat();

  const unlockedActivities = activities.filter(a => !a.isLocked);

  const currentActivityIndex = unlockedActivities.findIndex(a => a._id === activityCompleted);
  const nextActivity = unlockedActivities[currentActivityIndex + 1];

  if (nextActivity) {
    const nextActivitySection = sections.find(s =>
      s.activities.some(a => a._id === nextActivity._id)
    );
    if (nextActivitySection) {
      return {
        course: course._id,
        section: nextActivitySection._id,
        activity: nextActivity._id
      };
    }
  }

  return null;
};
