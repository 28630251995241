import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const MyAcademyStudentsService = sdk.getService('MyAcademyStudentsService');

export const useStudentsDetail = (studentId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['students', studentId],
    queryFn: () => MyAcademyStudentsService.getStudent(studentId!),
    enabled: !!studentId
  });

  return {
    student: data,
    isLoading,
    error
  };
};
