import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  & > .ant-tag {
    user-select: none;
  }
`;
