import styled from 'styled-components';

type Props = {
  color: string;
  bordercolor: string;
};

export const StyledTag = styled.div<Props>`
  border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
  padding: 2px 8px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const TextWrapper = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;
