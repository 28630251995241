import styled from 'styled-components';

export const Wrapper = styled.div`
  & > form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      display: grid;
      position: relative;
      grid-template-columns: 0.7fr 0.3fr;

      & > :nth-child(2) {
        margin-bottom: auto;
        position: sticky;
        top: 0;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FinishButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
  border-top: ${({ theme }) => `1px solid ${theme.colors.black3}`};
  height: 60px;
  margin: 0 -30px;
  padding: 0 30px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    position: unset;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > i {
    font-size: 32px;
    margin-bottom: 12px;
  }

  & > :last-child {
    margin-bottom: 20px;
  }
`;
