import styled, { DefaultTheme } from 'styled-components';

type Props = {
  color?: keyof DefaultTheme['colors'];
};

export const Wrapper = styled.span<Props>`
  cursor: pointer;
  svg.icon-fill {
    fill: ${({ theme, color }) => (color ? theme.colors[color] : '')};
  }
`;
