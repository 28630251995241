import { RequestService } from '../request/request.service';

import { IUpdateSectionTrack } from './section-tracking.contract';

export class SectionTrackingService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public updateSectionTrack({ subscriptionId, sectionTrack }: IUpdateSectionTrack) {
    return this.putter(`/subscriptions/${subscriptionId}/section-track`, sectionTrack);
  }
}
