import { IActivity } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  activity?: IActivity;
  setActivity: (activity?: IActivity) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setActivity: (activity?: IActivity) => set({ activity })
}));

export const useIntroductionForm = () => {
  const { isOpen, setIsOpen, activity, setActivity } = store();

  return {
    isOpen,
    setIsOpen,
    activity,
    setActivity
  };
};
