import { IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useMutateUsers = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['users'] });

  const { mutate: createUser, isPending: isCreating } = useMutation({
    mutationFn: (create: IUser) => UserService.createUser(create),
    onSuccess
  });

  const { mutate: updateUser, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUser) => UserService.updateUser(update),
    onSuccess
  });

  return {
    createUser,
    updateUser,
    isWorking: isCreating || isUpdating
  };
};
