import { EFeatures } from '@constants';
import { filter, groupBy, map } from 'lodash';
import { useMemo } from 'react';

import { useMySubscriptions, useProfile } from '@/store';

export const useTagFeatures = () => {
  const { selectedSubscription, isLoading: isLoadingSubscriptions } = useMySubscriptions();
  const { profile: { tags = [] } = {}, isLoading: isLoadingProfile } = useProfile();

  const availableFeatures = useMemo(() => {
    const groupedTags = [...tags, ...(selectedSubscription?.course.tags || [])];
    const groupedData = groupBy(groupedTags, '_id');
    const duplicates = filter(groupedData, group => group.length > 1);
    return map(duplicates, group => group[0])[0]?.features || [];
  }, [tags, selectedSubscription?.course.tags]);

  const canRender = (feature?: keyof typeof EFeatures) => {
    if (!feature) return true;
    return availableFeatures.includes(feature as EFeatures);
  };

  return {
    isLoading: isLoadingSubscriptions || isLoadingProfile,
    availableFeatures,
    canRender
  };
};
