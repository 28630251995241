import { EQuizMode } from '@constants';

export const QuizResultsTranslations = {
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continua',
    eus: 'Jarraitu'
  },
  ARE_YOU_SURE_TO_REPEAT: {
    es: '¿Seguro que quieres repetir el test?',
    en: 'Are you sure to repeat the test?',
    cat: 'Segur que vols repetir el test?',
    eus: 'Seguru zaude proba berriro egin nahi duzula?'
  },
  LAST_RESULT_WILL_BE_ERASED: {
    es: 'El último resultado se borrará.',
    en: 'The last result will be erased.',
    cat: 'L`últim resultat s`eliminarà.',
    eus: 'Azken emaitza ezabatuko da.'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten'
  },
  REVIEW_RESULTS: {
    es: 'Revisar resultados',
    en: 'Review results',
    cat: 'Revisar resultats',
    eus: 'Aztertu emaitzak'
  },
  CORRECT_RESPONSES: {
    es: 'Aciertos',
    en: 'Correct answers',
    cat: 'Encerts',
    eus: 'Zuzenak'
  },
  WRONG_RESPONSES: {
    es: 'Fallos',
    en: 'Mistakes',
    cat: 'Errors',
    eus: 'Akatsak'
  },
  BLANK_RESPONSES: {
    es: 'Blancos',
    en: 'Blanks',
    cat: 'Blancs',
    eus: 'Hutsak'
  },
  SCORE: {
    es: 'Nota:',
    en: 'Score:',
    cat: 'Nota:',
    eus: 'Puntuazioa:'
  },
  PUNTUATION_SYSTEM: {
    es: 'Sistema de puntuación',
    en: 'Punctuation system',
    cat: 'Sistema de puntuació',
    eus: 'Puntuazio sistema'
  },
  CORRECT: {
    es: 'Correcto',
    en: 'Correct',
    cat: 'Correcte',
    eus: 'Zuzena'
  },
  WRONG: {
    es: 'Incorrecto',
    en: 'Wrong',
    cat: 'Incorrecte',
    eus: 'Okerra'
  },
  BLANK: {
    es: 'En blanco',
    en: 'Blank',
    cat: 'En blanc',
    eus: 'Hutsik'
  },
  CONTENT: {
    es: 'Temas',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  DURATION: {
    es: 'Duración:',
    en: 'Duration:',
    cat: 'Durada:',
    eus: 'Iraupena:'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Askatasun modua'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Modo simulacro',
    en: 'Simulator mode',
    cat: 'Mode simulació',
    eus: 'Simulazio modua'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Modo actividad',
    en: 'Activity mode',
    cat: 'Mode activitat',
    eus: 'Jarduera modua'
  },
  REPEAT_TEST: {
    es: 'Repetir test',
    en: 'Repeat test',
    cat: 'Repetir test',
    eus: 'Proba berriro egin'
  },
  RETURN_TO_ACTIVITY: {
    es: 'Volver a la actividad',
    en: 'Return to activity',
    cat: 'Tornar a l`activitat',
    eus: 'Itzuli jarduerara'
  },
  RESULTS: {
    es: 'Resultados',
    en: 'Results',
    cat: 'Resultats',
    eus: 'Emaitzak'
  },
  TIME: {
    es: '¡Tiempo!',
    en: 'Time!',
    cat: 'Temps!',
    eus: 'Denbora!'
  }
};
