import styled from 'styled-components';

export const CsatWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  background: ${({ theme }) => theme.colors.green1};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 24px;

  .IconFaceSatisfied:hover {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceSatisfiedFilled {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceNeutral:hover {
    fill: ${({ theme }) => theme.colors.orange6};
  }
  .IconFaceNeutralFilled {
    fill: ${({ theme }) => theme.colors.orange6};
  }
  .IconFaceDissatisfied:hover {
    fill: ${({ theme }) => theme.colors.red6};
  }
  .IconFaceDissatisfiedFilled {
    fill: ${({ theme }) => theme.colors.red6};
  }
`;
