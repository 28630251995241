import { useQuery } from '@tanstack/react-query';

import { useReviewQuestionsStore } from './useReviewQuestionsStore';

import { sdk } from '@/services';

const QuestionReviewService = sdk.getService('QuestionReviewService');

export const useReviewQuestions = () => {
  const { selectedContent } = useReviewQuestionsStore();
  const { isLoading, data } = useQuery({
    queryKey: ['question-review', selectedContent],
    queryFn: () => QuestionReviewService.findQuestionReviewBySections(selectedContent),
    enabled: !!selectedContent.length
  });

  return {
    isLoading,
    questionsToReview: data
  };
};
