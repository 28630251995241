import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
  .ant-select,
  .ant-picker {
    width: 100%;
  }
`;
