export enum EAutonomousCommunity {
  ANDALUCIA = 'ANDALUCIA',
  PAIS_VASCO = 'PAIS_VASCO',
  CANARIAS = 'CANARIAS',
  VALENCIA = 'VALENCIA',
  CATALUNYA = 'CATALUNYA',
  MADRID = 'MADRID',
  ARAGON = 'ARAGON',
  GALICIA = 'GALICIA',
  EXTREMADURA = 'EXTREMADURA',
  CASTILLA_LEON = 'CASTILLA_LEON',
  CASTILLA_LA_MANCHA = 'CASTILLA_LA_MANCHA',
  ASTURIAS = 'ASTURIAS',
  BALEARES = 'BALEARES',
  MELILLA = 'MELILLA',
  CANTABRIA = 'CANTABRIA',
  LA_RIOJA = 'LA_RIOJA',
  MURCIA = 'MURCIA',
  CEUTA = 'CEUTA',
  NAVARRA = 'NAVARRA'
}

export enum EProvince {
  ALMERIA = 'Almería',
  CADIZ = 'Cádiz',
  CORDOBA = 'Córdoba',
  GRANADA = 'Granada',
  HUELVA = 'Huelva',
  JAEN = 'Jaén',
  MALAGA = 'Málaga',
  SEVILLA = 'Sevilla',
  ALAVA = 'Álava',
  GUIPUZCOA = 'Guipúzcoa',
  VIZCAYA = 'Vizcaya',
  LAS_PALMAS = 'Las Palmas',
  SANTA_CRUZ_DE_TENERIFE = 'Santa Cruz de Tenerife',
  VALENCIA = 'Valencia',
  CASTELLON = 'Castellón',
  ALICANTE = 'Alicante',
  BARCELONA = 'Barcelona',
  GERONA = 'Gerona',
  LERIDA = 'Lérida',
  TARRAGONA = 'Tarragona',
  MADRID = 'Madrid',
  HUESCA = 'Huesca',
  TERUEL = 'Teruel',
  ZARAGOZA = 'Zaragoza',
  A_CORUNA = 'A Coruña',
  LUGO = 'Lugo',
  OURENSE = 'Orense',
  PONTEVEDRA = 'Pontevedra',
  BADAJOZ = 'Badajoz',
  CACERES = 'Cáceres',
  AVILA = 'Ávila',
  BURGOS = 'Burgos',
  LEON = 'León',
  PALENCIA = 'Palencia',
  SALAMANCA = 'Salamanca',
  SEGOVIA = 'Segovia',
  SORIA = 'Soria',
  VALLADOLID = 'Valladolid',
  ZAMORA = 'Zamora',
  ALBACETE = 'Albacete',
  CIUDAD_REAL = 'Ciudad Real',
  CUENCA = 'Cuenca',
  GUADALAJARA = 'Guadalajara',
  TOLEDO = 'Toledo',
  ASTURIAS = 'Asturias',
  BALEARES = 'Baleares',
  MELILLA = 'Melilla',
  CANTABRIA = 'Cantabria',
  LA_RIOJA = 'La Rioja',
  MURCIA = 'Murcia',
  CEUTA = 'Ceuta',
  NAVARRA = 'Navarra'
}

export const PROVINCES = Object.values(EProvince);

export const AUTONOMOUS_COMMUNITIES = Object.values(EAutonomousCommunity);

