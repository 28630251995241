import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {
  color: string;
  backgroundColor: string;
};

export const ScoreWrapper = styled.div<Props>`
  width: fit-content;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding: 0px 8px;
    font-size: 24px;
    line-height: 32px;
  }
`;
