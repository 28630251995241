import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useOnlineUsersList = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['online-users'],
    queryFn: UserService.getOnlineUsers,
    refetchInterval: 30000
  });

  const isUserOnline = (userId: string) => {
    return data?.some(user => user._id === userId);
  };

  return { onlineUsers: data, isLoading, isUserOnline };
};
