import { ETicketStatus } from '@constants';
import styled from 'styled-components';

import { TICKET_STATUS_COLORS } from '@/constants';

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

export const StatusWrapper = styled.div<{ status: ETicketStatus }>`
  cursor: pointer;

  & > :first-child {
    padding: 3px 7px;
    font-weight: 600;
    border-radius: 5px;
    color: ${({ status }) => (status === ETicketStatus.IN_PROGRESS ? 'black' : 'white')};
    background-color: ${({ status }) => TICKET_STATUS_COLORS[status]};
  }
`;
