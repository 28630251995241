import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { QuizActivitySelector } from '../QuizActivitySelector';

import { LinkWrapper, SubtitleWrapper } from './QuizType.styles';

import { Animated } from '@/components/Animated';
import { useActivityForm } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: { quiz: string };
  onChange?: ({ quiz }: { quiz: string }) => void;
};

export const QuizType: React.FC<Props> = ({ value, onChange }) => {
  const { _id } = useParams<{ _id: string }>();
  const { sectionId } = useActivityForm();
  const handleChange = (quiz: string) => onChange?.({ quiz });
  const navigateTo = useNavigate();

  const handleNavigateToTests = () => navigateTo(`/content/tests`);

  return (
    <>
      <SubtitleWrapper>
        <Trans
          i18nKey="TEACHERS_CREATE_QUIZ_LINK"
          components={{ a: <LinkWrapper onClick={handleNavigateToTests} /> }}
        />
      </SubtitleWrapper>
      <Animated>
        <QuizActivitySelector
          courseId={_id}
          sectionId={sectionId}
          onChange={handleChange}
          value={handleInputValue(value?.quiz)}
          allowClear
        />
      </Animated>
    </>
  );
};
