import styled from 'styled-components';

type WrapperProps = {
  width: number;
};

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width};
  padding-left: 36px;
  position: relative;

  img,
  .ant-image-mask {
    border-radius: 10px;
  }
  & > button {
    position: absolute;
    z-index: 900;
    top: 0px;
    left: 212px;
    cursor: pointer;
  }
`;
