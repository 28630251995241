import { Button } from 'antd';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
  margin-top: 24px;
`;

export const StyledButton = styled(Button)`
  border-radius: 50px;
`;
