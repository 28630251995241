import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const LinkWrapper = styled.div`
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    text-align: end;
  }
`;
