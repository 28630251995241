import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 8px 24px 24px 0;
  gap: 24px;

  .ant-empty {
    text-align: center;
  }
  .ant-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-card-body {
      margin-bottom: auto;
    }
    .ant-switch-checked {
      background-color: ${({ theme }) => theme.colors.green6};
    }
    .ant-switch-checked:hover {
      background-color: ${({ theme }) => theme.colors.green4};
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
