import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;

  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;
