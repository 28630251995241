import { create as createStore } from 'zustand';

interface IStore {
  eventId?: string;
  setEventId: (eventId?: string) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useEventForm = createStore<IStore>(set => ({
  isOpen: false,
  setEventId: (eventId?: string) => set({ eventId }),
  setIsOpen: (isOpen: boolean) => {
    set({ isOpen });

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    if (isOpen && !searchParams.has('create')) {
      searchParams.set('create', 'true');
    } else if (!isOpen && searchParams.has('create')) {
      searchParams.delete('create');
    }
    window.history.replaceState(null, '', url.href);
  }
}));
