import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.green6};
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
