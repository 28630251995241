import { EVoteReason, EPostStatus } from '@constants';

export const CommonDashboardTranslations = {
  HI: {
    es: 'Hola, {{name}}!',
    en: 'Hi, {{name}}!',
    cat: 'Hola, {{name}}!',
    eus: 'Kaixo, {{name}}!'
  },
  HI_STUDENT: {
    es: '¡Hola {{name}}! ¡A por todas con el estudio!',
    en: 'Hi {{name}}! Go for it with the study!',
    cat: 'Hola {{name}}! Endavant amb l’estudi!',
    eus: 'Kaixo {{name}}! Ikasketekin aurrera!'
  },
  NEWS: {
    es: 'Noticias',
    en: 'News',
    cat: 'Notícies',
    eus: 'Berriak'
  },
  COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak'
  },
  FILES: {
    es: 'Archivos',
    en: 'Files',
    cat: 'Fitxers',
    eus: 'Fitxategiak'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  NEXT_EVENTS: {
    es: 'Próximos eventos',
    en: 'Next events',
    cat: 'Propers esdeveniments',
    eus: 'Hurrengo ekitaldiak'
  },
  REPORTS: {
    es: 'Reportes',
    en: 'Reports',
    cat: 'Informes',
    eus: 'Txostenak'
  },
  NO_EVENTS_IN_THIS_MONTH: {
    es: 'No hay eventos programados para {{month}}.',
    en: 'No events scheduled for {{month}}.',
    cat: 'No hi ha esdeveniments programats per {{month}}.',
    eus: '{{month}}rako ez dago ekitaldirik egiteko.'
  },
  POST_TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  POST_CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  DOUBTS_TITLE: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  INFO_OPTION_STUDENTS: {
    es: 'alumnos',
    en: 'students',
    cat: 'alumnes',
    eus: 'ikasleak'
  },
  INFO_OPTION_TEACHERS: {
    es: 'profesores',
    en: 'teachers',
    cat: 'professors',
    eus: 'irakasleak'
  },
  INFO_OPTION_COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak'
  },
  NO_NEWS_TO_SHOW: {
    es: 'No hay noticias para mostrar.',
    en: 'No news to show.',
    cat: 'No hi ha notícies per mostrar.',
    eus: 'Ez dago albisterik erakusteko.'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  DOWNLOAD_CSAT: {
    es: 'Descargar CSAT',
    en: 'Download CSAT',
    cat: 'Descarregar CSAT',
    eus: 'CSAT deskargatu'
  },
  DOWNLOAD_TICKETS: {
    es: 'Descargar dudas',
    en: 'Download tickets',
    cat: 'Descarregar tickets',
    eus: 'Dudak deskargatu'
  },
  DOWNLOAD_ACTIVITY_RATING: {
    es: 'Descargar valoraciones',
    en: 'Download activity rating',
    cat: 'Descarregar valoracions',
    eus: 'Balorazioak deskargatu'
  },
  DOWNLOAD_UPLOADED_FILES: {
    es: 'Descargar archivos subidos',
    en: 'Download uploaded files',
    cat: 'Descarregar fitxers pujats',
    eus: 'Igotako fitxategiak deskargatu'
  },
  NEXT_LIVE_CLASSES: {
    es: 'Próximas clases en directo',
    en: 'Next live classes',
    cat: 'Properes classes en viu',
    eus: 'Hurrengo klaseak zuzenean'
  },
  CREATE_LIVE_CLASS: {
    es: 'Crear clase',
    en: 'Create class',
    cat: 'Crear classe',
    eus: 'Sortu klase'
  },
  YOUR_CLASSES: {
    es: 'Das clase en estos cursos:',
    en: 'You teach in these courses:',
    cat: 'Donar classe en aquests cursos:',
    eus: 'Klaseak eman ikastaro hauek:'
  },
  PENDING_TASKS: {
    es: 'Actividades pendientes de realizar',
    en: 'Pending activities to perform',
    cat: 'Activitats pendents de realitzar',
    eus: 'Egin beharreko jarduera pendienteak'
  },
  PENDING_TASKS_COUNT: {
    es: '{{ totalTasks }} actividades pendientes',
    en: '{{ totalTasks }} pending activities',
    cat: '{{ totalTasks }} activitats pendents',
    eus: '{{ totalTasks }} jarduera pendiente'
  },
  TASK_NOT_ON_YOUR_COURSES: {
    es: 'Esta tarea está asociada a un curso que no impartes.',
    en: 'This task is associated with a course you do not teach.',
    cat: 'Aquesta tasca està associada a un curs que no impartiu.',
    eus: 'Jarduera hau irakas ezazun ikastarora lotuta dago.'
  },
  DEADLINE_DATE: {
    es: 'Fecha máxima de entrega:',
    en: 'Deadline date:',
    cat: 'Data màxima d’entrega:',
    eus: 'Entregako epea:'
  },
  ASSIGNED_TO: {
    es: 'Asignada a:',
    en: 'Assigned to:',
    cat: 'Assignada a:',
    eus: 'Asignatua:'
  },
  EDIT_CONTENT: {
    es: 'Editar contenido',
    en: 'Edit content',
    cat: 'Editar contingut',
    eus: 'Editatu edukia'
  },
  TASK_HISTORY: {
    es: 'Historial de la tarea',
    en: 'Task history',
    cat: 'Historial de la tasca',
    eus: 'Lanaren historia'
  },
  OPEN_DOUBTS: {
    es: 'Dudas abiertas',
    en: 'Open doubts',
    cat: 'Dubtes oberts',
    eus: 'Zabalik dauden zalantzak'
  },
  NO_SCHEDULED_EVENTS_TITLE: {
    es: 'No hay eventos',
    en: 'There are no events',
    cat: 'No hi ha esdeveniments',
    eus: 'Ez dago ekitaldirik'
  },
  NO_SCHEDULED_EVENTS_DESCRIPTION: {
    es: 'No hay ningún evento de tu formación programado para los próximos 30 días.',
    en: 'There are no events from your formation scheduled for the next 30 days.',
    cat: 'No hi ha cap esdeveniment de la teva formació programat pels propers 30 dies.',
    eus: 'Ez dago zure formazioko ekitaldirik programatuta hurrengo 30 egunetarako.'
  },
  NO_PENDING_TASKS_TITLE: {
    es: 'Vaya, vaya, vaya...',
    en: 'Oops, oops, oops...',
    cat: 'Uy, uy, uy...',
    eus: 'Epa, epa, epa...'
  },
  NO_PENDING_TASKS_DESCRIPTION: {
    es: 'Parece que eres tan crack que no tienes ninguna tarea pendiente. Si quieres tareas puedes solicitarlas por el chat.',
    en: 'It seems that you are so crack that you do not have any pending tasks. If you want tasks you can request them through the chat.',
    cat: 'Sembla que ets tan crack que no tens cap tasca pendent. Si vols tasques pots sol·licitar-les pel xat.',
    eus: 'Zuretzat ez dago zeregin pendienteik, zuk zereginak eskatu ditzakezu txaten bidez.'
  },
  NO_OPEN_DOUBTS_TITLE: {
    es: 'Sin dudas abiertas',
    en: 'Without open doubts',
    cat: 'Sense dubtes oberts',
    eus: 'Zalantzarik gabe'
  },
  NO_OPEN_DOUBTS_DESCRIPTION: {
    es: 'O tus alumnos son genios y no tienen dudas o eres el más rápido del oeste respondiéndolas.',
    en: 'Either your students are geniuses and have no doubts or you are the fastest in the west answering them.',
    cat: 'O els teus alumnes són genis i no tenen dubtes o ets el més ràpid de l’oest responent-los.',
    eus: 'Zure ikasleak genioak dira eta ez dute zalantzarik edo zure erantzunak oso azkarak dira.'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image',
    cat: 'Imatge',
    eus: 'Irudia'
  },
  [EPostStatus.PUBLISHED]: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua'
  },
  TOPIC: {
    es: 'Tema',
    en: 'Topic',
    cat: 'Tema',
    eus: 'Gaia'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Formations',
    cat: 'Formacions',
    eus: 'Formazioak'
  },
  SELECT_TOPIC: {
    es: 'Selecciona un tema',
    en: 'Select a topic',
    cat: 'Selecciona un tema',
    eus: 'Hautatu gaia'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update',
    cat: 'Actualitzar',
    eus: 'Eguneratu'
  },
  ADD: {
    es: 'Agregar',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu'
  },
  NEW_TOPIC: {
    es: 'Nuevo tema',
    en: 'New topic',
    cat: 'Nou tema',
    eus: 'Gaia berria'
  },
  SLUG: {
    es: 'Slug',
    en: 'Slug',
    cat: 'Slug',
    eus: 'Slug'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu'
  },
  DELETE_POST_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este post?',
    en: 'Are you sure to delete this post?',
    cat: 'Estàs segur d`eliminar aquest post?',
    eus: 'Ziur zaude post hau ezabatu nahi duzula?'
  },
  LECTURE_TIME: {
    es: 'Tiempo de lectura',
    en: 'Reading time',
    cat: 'Temps de lectura',
    eus: 'Irakurtzeko denbora'
  },
  SHORT_DESCRIPTION: {
    es: 'Descripción corta',
    en: 'Short description',
    cat: 'Descripció curta',
    eus: 'Deskribapen laburra'
  },
  CLASSES_AND_SYLLABUS: {
    es: 'Clases y temario',
    en: 'Classes and syllabus',
    cat: 'Classes i temari',
    eus: 'Klaseak eta syllabus'
  },
  GO_TO_CONTENT: {
    es: 'Ir al contenido',
    en: 'Go to content',
    cat: 'Anar al contingut',
    eus: 'Joan edukira'
  },
  CHANGE_COURSE: {
    es: 'Cambiar curso',
    en: 'Change course',
    cat: 'Canviar curs',
    eus: 'Aldatu edukira'
  },
  WHAT_TO_DO_TODAY: {
    es: 'Qué hacer hoy',
    en: 'What to do today',
    cat: 'Què fer avui',
    eus: 'Egun hau zer egin'
  },
  LIVE_CLASSES: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en viu',
    eus: 'Klaseak zuzenean'
  },
  MY_EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Azterketa taldeak'
  },
  QUIZZES: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Proves',
    eus: 'Probatzak'
  },
  FREE_MODE_TITLE: {
    es: 'Test personalizado',
    en: 'Custom test',
    cat: 'Prova personalitzada',
    eus: 'Eredu personalizatua'
  },
  REVIEW_MODE_TITLE: {
    es: 'Corrige tus fallos',
    en: 'Review your mistakes',
    cat: 'Repassa els teus errors',
    eus: 'Berrikusi zure erroreak'
  },
  PRACTICE_MODE_TITLE: {
    es: 'Haz un examen',
    en: 'Take a test',
    cat: 'Fes una prova',
    eus: 'Egin proba'
  },
  FREE_MODE_DESCRIPTION: {
    es: 'Elige cuántas preguntas quieres responder y practica a tu ritmo.',
    en: 'Choose how many questions you want to answer and practice at your own pace.',
    cat: 'Tria zenbat galdera egin nahi duzun eta praktikatu zure ereduaren arabera.',
    eus: 'Aukeratu zenbat galdera egin nahi duzun eta praktikatu zure ereduaren arabera.'
  },
  REVIEW_MODE_DESCRIPTION: {
    es: 'Vuelve a responder las preguntas que fallaste en los tests anteriores.',
    en: 'Review the questions you have failed from any test you have taken.',
    cat: 'Repassa les preguntes que hagis fallat de qualsevol prova que hagis fet.',
    eus: 'Berrikusi zure galdu dituzun galderak egin dituzun edozein probatik.'
  },
  PRACTICE_TESTS_DESCRIPTION: {
    es: 'Realiza un examen real con cronómetro y practica experiencia.',
    en: 'Take a real exam with a timer and practice experience.',
    cat: 'Fes una prova real amb cronòmetre i practica experiència.',
    eus: 'Egin proba erreala denbora kronometroarekin eta praktika errealitatea.'
  },
  FREE_MODE_BUTTON_TEXT: {
    es: 'Iniciar test',
    en: 'Start test',
    cat: 'Començar prova',
    eus: 'Probatzak hasita'
  },
  REVIEW_MODE_BUTTON_TEXT: {
    es: 'Repasar fallos',
    en: 'Review mistakes',
    cat: 'Repassar errors',
    eus: 'Berrikusi erroreak'
  },
  PRACTICE_MODE_BUTTON_TEXT: {
    es: 'Haz un examen',
    en: 'Take a test',
    cat: 'Fes una prova',
    eus: 'Egin proba'
  },
  TEST_HISTORY: {
    es: 'Historial de tests',
    en: 'Test history',
    cat: 'Historial de proves',
    eus: 'Probatzak historia'
  },
  INVITE_A_FRIEND_AND_EARN: {
    es: 'Invita a un amigo y gana {{amount}}€* 💶🫰🤑',
    en: 'Invite a friend and earn {{amount}}€* 💶🫰🤑',
    cat: 'Convida un amic i guanya {{amount}}€* 💶🫰🤑',
    eus: 'Deitu lagun bati eta irabazi {{amount}}€* 💶🫰🤑'
  },
  INVITE_4_FRIENDS_AND_EARN: {
    es: 'Invita a 4 amigos y consigue 600€',
    en: 'Invite 4 friends and get 600€',
    cat: 'Convida 4 amics i aconsegueix 600€',
    eus: 'Deitu 4 lagunei eta lortu 600€'
  },
  EMAIL_OF_YOUR_FRIEND: {
    es: 'Email de tu amigo o amiga',
    en: 'Email of your friend',
    cat: 'Email del teu amic o amiga',
    eus: 'Zure lagunaren emaila'
  },
  REFERRAL_MODAL_DESCRIPTION: {
    es: '*Nos pondremos en contacto contigo en caso de que se convierta en alumno de Ucademy para realizarte el pago.',
    en: '*We will contact you in case he becomes a Ucademy student to make the payment.',
    cat: '*Ens posarem en contacte amb tu en cas que es converteixi en alumne de Ucademy per realitzar-te el pagament.',
    eus: '*Zurekin harremanetan jarriko gara Ucademyko ikasle bihurtzen bada ordainketa egiteko.'
  },
  SEND_INVITATION: {
    es: 'Enviar invitación',
    en: 'Send invitation',
    cat: 'Enviar invitació',
    eus: 'Bidali gonbidapena'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten'
  },
  INVITATION_SENT: {
    es: '¡Invitación enviada!',
    en: 'Invitation sent!',
    cat: 'Invitació enviada!',
    eus: 'Gonbidapena bidalia!'
  },
  INVITATION_FAILED: {
    es: '¡Ups! Algo ha fallado al enviar la invitación.',
    en: 'Oops! Something went wrong sending the invitation.',
    cat: '¡Ups! Alguna cosa ha fallat en enviar la invitació.',
    eus: '¡Ups! Zerbait gaizki joan da gonbidapena bidaltzean.'
  },
  HOW_LIKELY_IS_IT_THAT_YOU_WOULD_RECOMMEND_UCADEMY: {
    es: '¿Con qué probabilidad recomendarías Ucademy a un amigo?',
    en: 'How likely is it that you would recommend Ucademy to a friend?',
    cat: 'Amb quina probabilitat recomanaries Ucademy a un amic?',
    eus: 'Zer probabilitatearekin gomendatuko zenukezu Ucademy lagun bati?'
  },
  NOT_PROBABLE: {
    es: 'No es probable',
    en: 'Not probable',
    cat: 'No és probable',
    eus: 'Ez da probagarria'
  },
  VERY_PROBABLE: {
    es: 'Muy probable',
    en: 'Very probable',
    cat: 'Molt probable',
    eus: 'Oso probagarria'
  },
  NEXT: {
    es: 'Siguiente',
    en: 'Next',
    cat: 'Següent',
    eus: 'Hurrengoa'
  },
  WRITE_HERE: {
    es: 'Escribe aquí...',
    en: 'Write here...',
    cat: 'Escriu aquí...',
    eus: 'Idatzi hemen...'
  },
  NPS_THANK_YOU: {
    es: 'Muchas gracias. Tendremos en cuenta tus comentarios para convertir Ucademy en lo que TÚ quieres. ¡Suerte y ánimo en el estudio!',
    en: 'Thank you very much. We will take your comments into account to make Ucademy what YOU want. Good luck and encouragement in your studies!',
    cat: 'Moltes gràcies. Tindrem en compte els teus comentaris per convertir Ucademy en el que TU vols. Sort i ànim en l’estudi!',
    eus: 'Eskerrik asko. Kontuan hartuko ditugu zure iruzkinak Ucademy zure nahi den modura bihurtzeko. Zorte on eta animo ikasketetan!'
  },
  CAN_YOU_WRITE_A_REVIEW: {
    es: 'Por último, por favor te lo rogamos de ❤️corazón❤️: ¿Nos puedes poner un comentario en trustpilot? Es para enseñárselo a nuestras mamás y que estén orgullosas.',
    en: 'Finally, we kindly ask you from the ❤️heart❤️: Can you leave us a review on trustpilot? It is to show it to our moms and make them proud.',
    cat: 'Per últim, per favor t’ho demanem de ❤️cor❤️: Ens pots posar un comentari a trustpilot? És per ensenyar-ho a les nostres mares i que estiguin orgulloses.',
    eus: 'Azkenik, mesedez, bihotzetik ❤️: Trustpilot-en iruzkin bat utzi dezakezu? Gure amek erakusteko eta harro egoteko.'
  },
  CLICK_HERE: {
    es: 'Haz click aquí 😁',
    en: 'Click here 😁',
    cat: 'Fes clic aquí 😁',
    eus: 'Klik egin hemen 😁'
  },
  REGULAR_NPS_DETRACTOR_TITLE: {
    es: '¿Nos puedes contar que no te ha gustado? Prometemos mejorarlo.',
    en: 'Can you tell us what you did not like? We promise to improve it.',
    cat: 'Ens pots dir què no t’ha agradat? Prometem millorar-ho.',
    eus: 'Esan dezakezu zer ez zait gustatu? Hobetzeko promesa egiten dugu.'
  },
  REGULAR_NPS_NEUTRAL_TITLE: {
    es: '¿Qué tendríamos que mejorar para que la próxima sea un 10?',
    en: 'What should we improve to get a 10 next time?',
    cat: 'Què hauríem de millorar perquè la propera sigui un 10?',
    eus: 'Zer hobetu behar genuke hurrengoan 10 bat jartzeko?'
  },
  REGULAR_NPS_PROMOTER_TITLE: {
    es: '¿Qué es lo que más valoras de Ucademy?',
    en: 'What do you value most about Ucademy?',
    cat: 'Què és el que més valores de Ucademy?',
    eus: 'Zer baloratzen duzu gehien Ucademyren?'
  },
  [`${EVoteReason.OTHER}_PROMOTER`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste'
  },
  [`${EVoteReason.LIVE_CLASSES}_PROMOTER`]: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Klaseak zuzenean'
  },
  [`${EVoteReason.VIDEO_CONTENT}_PROMOTER`]: {
    es: 'Contenido en formato vídeo',
    en: 'Content in video format',
    cat: 'Contingut en format vídeo',
    eus: 'Edukia bideo formatuan'
  },
  [`${EVoteReason.STUDY_MATERIAL}_PROMOTER`]: {
    es: 'Apuntes',
    en: 'Study material',
    cat: 'Apunts',
    eus: 'Apunteak'
  },
  [`${EVoteReason.TEACHERS}_PROMOTER`]: {
    es: 'El profesorado',
    en: 'The teaching staff',
    cat: 'El professorat',
    eus: 'Irakasleak'
  },
  [`${EVoteReason.QUIZZES}_PROMOTER`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_PROMOTER`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea'
  },
  [`${EVoteReason.VIDEO_CONTENT}_DETRACTOR`]: {
    es: 'Contenido en formato vídeo',
    en: 'Content in video format',
    cat: 'Contingut en format vídeo',
    eus: 'Edukia bideo formatuan'
  },
  [`${EVoteReason.TEACHERS}_DETRACTOR`]: {
    es: 'Seguimiento del profesorado',
    en: 'Teaching staff follow-up',
    cat: 'Seguiment del professorat',
    eus: 'Irakasleen jarraipen gehiago'
  },
  [`${EVoteReason.STUDY_MATERIAL}_DETRACTOR`]: {
    es: 'Material de estudio (apuntes)',
    en: 'Study material (notes)',
    cat: 'Material d’estudi (apunts)',
    eus: 'Ikaslearen arreta taldea'
  },
  [`${EVoteReason.LIVE_CLASSES}_DETRACTOR`]: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Klaseak zuzenean'
  },
  [`${EVoteReason.QUIZZES}_DETRACTOR`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_DETRACTOR`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea'
  },
  [`${EVoteReason.TEACHERS}_NEUTRAL`]: {
    es: 'Más seguimiento del profesorado',
    en: 'More follow-up by the teaching staff',
    cat: 'Més seguiment del professorat',
    eus: 'Irakasleen jarraipen gehiago'
  },
  [`${EVoteReason.LIVE_CLASSES}_NEUTRAL`]: {
    es: 'Más clases en directo',
    en: 'More live classes',
    cat: 'Més classes en directe',
    eus: 'Klase gehiago zuzenean'
  },
  [`${EVoteReason.QUIZZES}_NEUTRAL`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota'
  },
  [`${EVoteReason.VIDEO_CONTENT}_NEUTRAL`]: {
    es: 'Mejorar la calidad del contenido',
    en: 'Improve content quality',
    cat: 'Millorar la qualitat del contingut',
    eus: 'Edukien kalitatea hobetu'
  },
  [`${EVoteReason.STUDY_MATERIAL}_NEUTRAL`]: {
    es: 'Completar el temario',
    en: 'Complete the syllabus',
    cat: 'Completar el temari',
    eus: 'Syllabus osatu'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_NEUTRAL`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea'
  },
  [`${EVoteReason.OTHER}_NEUTRAL`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste'
  },
  [`${EVoteReason.OTHER}_DETRACTOR`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste'
  },

  IS_EVERYTHING_CLEAR: {
    es: '¿Tienes claro cómo estudiar con nosotros?',
    en: 'Is everything clear on how to study with us?',
    cat: 'Tens clar com estudiar amb nosaltres?',
    eus: 'Argi daukazu nola ikasi gurekin?'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez'
  },
  ONBOARDING_NPS_PROMOTER_TITLE: {
    es: '¿Qué es lo que más te ha gustado en tus primeros días?',
    en: 'What did you like the most in your first days?',
    cat: 'Què és el que més t’ha agradat en els teus primers dies?',
    eus: 'Zer gustatu zaizu gehien zure lehenengo egunetan?'
  },
  ONBOARDING_NPS_DETRACTOR_TITLE: {
    es: '¿Qué echas de menos/cambiarías del servicio?',
    en: 'What do you miss/change about the service?',
    cat: 'Què trobes a faltar/canviaries del servei?',
    eus: 'Zer falta zaizu/aldatu nahi duzu zerbitzuari buruz?'
  },
  WHAT_DO_YOU_NOT_UNDERSTAND: {
    es: '¿Qué no tienes claro?',
    en: 'What do you not understand?',
    cat: 'Què no tens clar?',
    eus: 'Zer ez duzu argi?'
  },
  YOU_CAN_TALK_WITH_US_ON_WHATSAPP: {
    es: 'Si quieres consultar cualquier duda sobre el funcionamiento de la app, puedes hablar con nosotros por WhatsApp.',
    en: 'If you want to ask any questions about the operation of the app, you can talk to us on WhatsApp.',
    cat: 'Si vols consultar qualsevol dubte sobre el funcionament de l’app, pots parlar amb nosaltres per WhatsApp.',
    eus: 'Apparen funtzionamenduari buruzko zalantza edo galdera batzuk badituzu, gurekin harremanetan jarri zaitezke WhatsApp-en.'
  },
  GO_TO_WHATSAPP: {
    es: 'Ir a WhatsApp',
    en: 'Go to WhatsApp',
    cat: 'Anar a WhatsApp',
    eus: 'Joan WhatsApp-era'
  },
  ADVENTURE_STARTS_HERE: {
    es: 'Aquí empieza la aventura',
    en: 'The adventure begins here',
    cat: 'L’aventura comenca aquí',
    eus: 'Aventura hasi hemen'
  },
  CONTINUE_YOUR_STUDY: {
    es: 'Continúa tu aprendizaje',
    en: 'Continue your learning',
    cat: 'Continúa el teu aprenentatge',
    eus: 'Jarraitu zure ikastetxea'
  },
  START: {
    es: 'Empezar',
    en: 'Start',
    cat: 'Començar',
    eus: 'Hasita'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  CLASS_STARTED_AT: {
    es: 'Empezó hace {{time}} min',
    en: 'Started {{time}} min ago',
    cat: 'Començà fa {{time}} min',
    eus: 'Has emandako {{time}} minutu'
  },
  CLASS_STARTING_IN: {
    es: 'Comienza en {{time}} min',
    en: 'Starts in {{time}} min',
    cat: 'Comença en {{time}} min',
    eus: 'Has emandako {{time}} minutu'
  },
  JOIN_CLASS: {
    es: 'Unirme a la clase',
    en: 'Join the class',
    cat: 'Unir-me a la classe',
    eus: 'Klasean sartu'
  },
  NO_GOOGLE_MEET_LINK: {
    es: 'No se ha añadido ningún enlace de Google Meet',
    en: 'No Google Meet link added',
    cat: 'No s’ha afegit cap enllaç de Google Meet',
    eus: 'Google Meet esteka ez da gehitu'
  },
  HOW_WAS_THE_CLASS: {
    es: '{{name}}, ¿qué te ha parecido la clase de {{class}} del {{date}}?',
    en: '{{name}}, how was the class of {{class}} on {{date}}?',
    cat: '{{name}}, què t’ha semblat la classe de {{class}} el {{date}}?',
    eus: '{{name}}, zer zela klasea {{class}} {{date}}?'
  },
  HOW_WAS_THE_CLASS_DESCRIPTION: {
    es: 'Vota tranqui, tu opinión es anónima. Esto nos ayuda a mejorar el servicio que te ofrecemos.',
    en: 'Vote quietly, your opinion is anonymous. This helps us improve the service we offer you.',
    cat: 'Vota tranquilament, la teva opinió és anònima. Això ens ajuda a millorar el servei que et oferim.',
    eus: 'Ordena zehazki, zure opinioa anonimoa da. Zerbitzu berriak eskaintzeko laguntza eskaintzen du.'
  },
  CLASS_RATING: {
    es: 'Valoración de la clase',
    en: 'Class rating',
    cat: 'Classificació de la classe',
    eus: 'Klasearen ordena'
  },
  TEACHER_RATING: {
    es: 'Valoración del profesor',
    en: 'Teacher rating',
    cat: 'Classificació del professor',
    eus: 'Irakaslearen ordena'
  },
  CLASS_DIFFICULTY: {
    es: 'Dificultad de la clase',
    en: 'Class difficulty',
    cat: 'Dificultat de la classe',
    eus: 'Klasearen zailtasuna'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  YOUR_VOTE_HAS_BEEN_SENT: {
    es: '¡Tu voto ha sido enviado! ¡Gracias por tu opinión!',
    en: 'Your vote has been sent! Thank you for your opinion!',
    cat: 'El teu voto ha estat enviat! Gràcies per la teva opinió!',
    eus: 'Zure ordena bidali da! Zerindu zure opinioa!'
  },
  YOU_HAVE_PENDING_VOTES: {
    es: 'Tienes {{count}} clases pendientes de valorar',
    en: 'You have {{count}} classes pending to rate',
    cat: 'Tens {{count}} classes pendents de classificar',
    eus: 'Zure {{count}} klaseak egin dituzten ordenak daude'
  },
  I_DIDNT_ATTEND_THE_CLASS: {
    es: 'No asistí a la clase',
    en: "I didn't attend the class",
    cat: 'Ez asistí a la classe',
    eus: 'Ez asistitu zenuen klasea'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: '¡Gracias por tu valoración!',
    en: 'Thank you for your rating!',
    cat: 'Gràcies per la teva valoració!',
    eus: 'Zerindu zure ordena!'
  },
  YOU_SOMETHING_TO_SAY: {
    es: '¿Tienes algo que destacar o mejorar en esta clase?',
    en: 'Do you have something to highlight or improve in this class?',
    cat: '¿Tens alguna cosa que destacar o millorar en aquesta classe?',
    eus: 'Zer duzu zerbait argitaratu edo hobetu zenuen klasean?'
  },
  WRITE_YOUR_COMMENT: {
    es: 'Escribe tu comentario...',
    en: 'Write your comment...',
    cat: 'Escriu el teu comentari...',
    eus: 'Idatzi zure iruzkin hau...'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  CHAT_WIDGET_TITLE: {
    es: 'Chat con tutor',
    en: 'Chat with tutor',
    cat: 'Chat amb tutor',
    eus: 'Ikastetxearen tutorarekin harremanetan'
  },
  NO_MESSAGES: {
    es: 'No hay nuevos mensajes',
    en: 'No new messages',
    cat: 'No hi ha nous missatges',
    eus: 'Ez dago mezu berriak'
  },
  SKIP_VOTE: {
    es: 'Omitir votación',
    en: 'Skip vote',
    cat: 'Ometre vot',
    eus: 'Ezabatu ordena'
  },
  POST_TAGS_COUNT_one: {
    es: '{{count}} formación',
    en: '{{count}} training',
    cat: '{{count}} formació',
    eus: '{{count}} ikastetxe'
  },
  POST_TAGS_COUNT_other: {
    es: '{{count}} formaciones',
    en: '{{count}} trainings',
    cat: '{{count}} formacions',
    eus: '{{count}} ikastetxeak'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu'
  },
  POST_CREATED_SUCCESSFULLY: {
    es: 'Post creado correctamente',
    en: 'Post created successfully',
    cat: 'Post creat correctament',
    eus: 'Posta behar bezala sortua'
  },
  POST_UPDATED_SUCCESSFULLY: {
    es: 'Post actualizado correctamente',
    en: 'Post updated successfully',
    cat: 'Post actualitzat correctament',
    eus: 'Posta behar bezala eguneratua'
  },
  POST_DELETED_SUCCESSFULLY: {
    es: 'Post eliminado correctamente',
    en: 'Post deleted successfully',
    cat: 'Post eliminat correctament',
    eus: 'Posta behar bezala ezabatua'
  },
  TOPIC_CREATED_SUCCESSFULLY: {
    es: 'Tema creado correctamente',
    en: 'Topic created successfully',
    cat: 'Tema creat correctament',
    eus: 'Gai behar bezala sortua'
  },

  TOPIC_REMOVED_SUCCESSFULLY: {
    es: 'Tema eliminado correctamente',
    en: 'Topic removed successfully',
    cat: 'Tema eliminat correctament',
    eus: 'Gai behar bezala ezabatua'
  },
  RELATED_POSTS: {
    es: 'Artículos relacionados 👇',
    en: 'Related posts 👇',
    cat: 'Articles relacionats 👇',
    eus: 'Erlazionatutako artikuluak 👇'
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_POST: {
    es: '¿Estás seguro de querer eliminar este post?',
    en: 'Are you sure you want to delete this post?',
    cat: 'Estàs segur de voler eliminar aquest post?',
    eus: 'Zer zaude ziur zaude zerbitzua ezabatu nahi duzula?'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  SCHEDULE_POST: {
    es: 'Programar publicación',
    en: 'Schedule post',
    cat: 'Programar publicació',
    eus: 'Posta planifikatu'
  },
  SCHEDULE_POST_DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  SCHEDULE_POST_TIMEZONE: {
    es: 'Zona horaria',
    en: 'Timezone',
    cat: 'Zona horària',
    eus: 'Denbora-zonal'
  },
  SCHEDULED_POST: {
    es: 'Programado',
    en: 'Scheduled',
    cat: 'Programat',
    eus: 'Planifikatua'
  },
  PUBLISHED_POST: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua'
  },
  CREATE_NEWS: {
    es: 'Crear noticia',
    en: 'Create news',
    cat: 'Crear notícia',
    eus: 'Berri sortu'
  },
  EDIT_NEWS: {
    es: 'Editar noticia',
    en: 'Edit news',
    cat: 'Editar notícia',
    eus: 'Berriaren editatu'
  },
  PUBLISH_NEWS: {
    es: 'Publicar',
    en: 'Publish',
    cat: 'Publicar',
    eus: 'Argitaratu'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Guardar canvis',
    eus: 'Aldatuak gorde'
  },
  SAVE_DRAFT: {
    es: 'Guardar borrador',
    en: 'Save draft',
    cat: 'Guardar borrador',
    eus: 'Gorde zuzendua'
  },
  DRAFT_POST: {
    es: 'Borrador',
    en: 'Draft',
    cat: 'Borrador',
    eus: 'Zuzendua'
  },
  FEATURED_NEWS: {
    es: 'Noticia destacada',
    en: 'Featured news',
    cat: 'Notícia destacada',
    eus: 'Berri argitaratua'
  },
  FEATURE_POST: {
    es: 'Publicar como destacada',
    en: 'Publish as featured',
    cat: 'Publicar com a destacada',
    eus: 'Argitaratu berri zehaztasuneko atala bezala'
  },
  FEATURE_POST_TOOLTIP: {
    es: 'La noticia destacada aparece fija en la sección de noticias.',
    en: 'The featured post appears fixed in the news section.',
    cat: 'La notícia destacada apareix fixa en la secció de notícies.',
    eus: 'Berri argitaratua zehaztasuneko atala erakusten da.'
  },
  NO_TAGS_SELECTED_ALERT: {
    es: 'No has seleccionado ninguna formación. Si no seleccionas ninguna, esta noticia se considerará como una noticia global, por lo que todos los alumnos la verán.',
    en: 'If you do not select any training, this news will be considered as a global news, so all students will see it.',
    cat: 'Si no selecciones cap formació, aquesta notícia es considerarà com a notícia global, per la qual cosa tots els alumnes la veuran.',
    eus: 'Ez baduzu ikastetxe bat hautatu, berria globala izango da, eta guztiak ikusi dituzte.'
  },
  DOWNLOAD_LIVE_CLASS_VOTE: {
    es: 'Descargar votos de clases en directo',
    en: 'Download live class votes',
    cat: 'Descargar votos de classes en directe',
    eus: 'Ekitaldi-klaseen ordenak deskargatu'
  }
};
