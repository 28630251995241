import { ICreateNote, IUpdateNote, IRemoveNote } from '../note';
import { RequestService } from '../request/request.service';

export class ActivityNoteService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public create({ subscriptionId, activityId, note }: ICreateNote) {
    return this.poster(`/subscriptions/notes/${subscriptionId}/${activityId}`, note);
  }

  public update({ subscriptionId, activityId, noteId, note }: IUpdateNote) {
    return this.putter(`/subscriptions/notes/${subscriptionId}/${activityId}/${noteId}`, note);
  }

  public remove({ subscriptionId, activityId, noteId }: IRemoveNote) {
    return this.deleter(`/subscriptions/notes/${subscriptionId}/${activityId}/${noteId}`);
  }
}
