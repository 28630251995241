import { create as createStore } from 'zustand';

interface IStore {
  isFormulaEditorOpen: boolean;
  setIsFormulaEditorOpen: (isFormulaEditorOpen: boolean) => void;
}

const store = createStore<IStore>(set => ({
  isFormulaEditorOpen: false,
  setIsFormulaEditorOpen: isFormulaEditorOpen => set({ isFormulaEditorOpen })
}));

export const useInsertFormulaModal = () => {
  const { isFormulaEditorOpen, setIsFormulaEditorOpen } = store();
  return { isFormulaEditorOpen, setIsFormulaEditorOpen };
};
