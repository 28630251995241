import { IAnswer, IQuestionReview } from '@sdk/contracts';
import { Form, Radio } from 'antd';
import React from 'react';

import {
  AnswersWrapper,
  FormulaWrapper,
  Image,
  ImageLink,
  ImageWrapper
} from './UnReviewedQuestion.styles';

import { Box } from '@/components/Utils';

interface UnReviewedQuestionProps {
  questionData: IQuestionReview;
}

export const UnReviewedQuestion: React.FC<UnReviewedQuestionProps> = ({ questionData }) => {
  return (
    <AnswersWrapper>
      <Form.Item name={['responses', questionData._id]}>
        <Radio.Group
          options={questionData.question.answers.map((answer: IAnswer, value: number) => {
            const { text, formula, file } = answer;
            return {
              value,
              label: (
                <Box alignItems="center" gap={20} key={`answers-${questionData._id}-${value}`}>
                  <div>{text}</div>
                  {formula && <FormulaWrapper dangerouslySetInnerHTML={{ __html: formula }} />}
                  {file && (
                    <ImageWrapper>
                      <ImageLink href={file.url} target="_blank">
                        <Image src={file.url} alt={file.name} />
                      </ImageLink>
                    </ImageWrapper>
                  )}
                </Box>
              )
            };
          })}
        />
      </Form.Item>
    </AnswersWrapper>
  );
};
