import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  text-align: center;
  max-width: 340px;

  & > :nth-child(1) {
    font-size: 65px;
    margin-bottom: 20px;
  }

  & > :nth-child(2) {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  & > :nth-child(3) {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;
