import { AUTONOMOUS_COMMUNITIES, EAutonomousCommunity } from '@constants';
import { Select } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: EAutonomousCommunity | EAutonomousCommunity[];
  onChange?: (value: EAutonomousCommunity | EAutonomousCommunity[]) => void;
  allowClear?: boolean;
  mode?: 'multiple';
  disabled?: boolean;
};

export const AutonomousCommunitySelector: React.FC<Props> = ({
  value,
  onChange,
  allowClear = false,
  mode,
  disabled = false
}) => {
  const { t } = useTranslation('DefaultTranslations');
  const options = useMemo(
    () =>
      AUTONOMOUS_COMMUNITIES.map(community => ({
        label: t(community),
        value: community
      })).sort((a, b) => a.label.localeCompare(b.label)),
    [AUTONOMOUS_COMMUNITIES]
  );

  return (
    <Select
      mode={mode}
      value={value}
      options={options}
      onChange={onChange}
      allowClear={allowClear}
      placeholder={t('AUTONOMOUS_COMMUNITY')}
      style={{ minWidth: '200px' }}
      disabled={disabled}
    />
  );
};
