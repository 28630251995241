import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TutoringGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043);
  background: ${({ theme }) => theme.colors.white};

  & > first-child {
    font-weight: 600;
    font-size: 16px;
  }

  & > :last-child {
    margin-left: auto;
  }
`;
