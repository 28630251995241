import { RequestService } from '../request/request.service';

import {
  ICreateSection,
  IMoveActivityBetweenSections,
  IRemoveSection,
  ISection,
  IUpdateSection
} from './section.contract';

export class SectionService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public createSection = async ({ course, section }: ICreateSection) => {
    return this.poster(`/courses/${course._id}/section`, section);
  };

  public getSection = async (sectionId: string) => {
    return this.fetcher<ISection>(`/sections/${sectionId}`);
  };

  public updateSection = async (section: IUpdateSection) => {
    return this.putter<ISection, IUpdateSection>(`/sections/${section._id}`, section);
  };

  public removeSection = async ({ courseId, sectionId }: IRemoveSection) => {
    return this.deleter(`/courses/${courseId}/section/${sectionId}`);
  };

  public moveActivityBetweenSections = async ({
    originSection,
    destinationSection,
    activities
  }: IMoveActivityBetweenSections) => {
    return this.putter(`/sections/${originSection}/move-activity`, {
      destinationSection,
      activityIds: activities.map(a => a._id)
    });
  };
}
