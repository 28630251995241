export enum EQuestionDifficulty {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD'
}

export enum EQuestionType {
  MANUAL = 'MANUAL',
  AI = 'AI'
}
