import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

export const useMyEventsList = () => {
  const queryKey = ['events', 'my-events'];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => EventService.getMyEvents()
  });

  return {
    events: data,
    isLoading,
    error
  };
};
