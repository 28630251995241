import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { sdk } from '@/services';

const MyAcademyStudentsService = sdk.getService('MyAcademyStudentsService');

// TODO: Use useStudentsList hook instead
export const useAllStudentsForTutoringGroupsSelectors = () => {
  const [search, setSearch] = useState('');

  const { isLoading, data, error } = useQuery({
    queryKey: ['tutoring-groups', 'students', 'selectors', search],
    queryFn: () => MyAcademyStudentsService.findAllStudentsForTutoringGroupsSelectors(search),
    enabled: !!search
  });

  return {
    isLoading,
    students: data,
    setSearch,
    error
  };
};
