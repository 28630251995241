import { OptionWrapper, Wrapper } from './VoteSelector.styles';

type Props = {
  value?: number;
  onChange?: (value: number) => void;
};

const OPTIONS = Array.from({ length: 11 }, (_, i) => i);

export const VoteSelector: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Wrapper>
      {OPTIONS.map(option => (
        <OptionWrapper
          key={option}
          isSelected={option === value}
          onClick={() => onChange?.(option)}>
          {option}
        </OptionWrapper>
      ))}
    </Wrapper>
  );
};
