import { FlagProvider } from '@unleash/proxy-client-react';
import { FC, PropsWithChildren } from 'react';

const unleashParams = {
  URL: 'https://app.unleash-hosted.com/euaa004/api/frontend',
  APP_NAME: 'ucademy_app',
  ENVIRONMENT: import.meta.env.PROD ? 'prod' : 'dev',
  REFRESH_INTERVAL: 5
};
const config = {
  url: unleashParams.URL,
  clientKey: import.meta.env.VITE_UNLEASH_API_TOKEN,
  refreshInterval: unleashParams.REFRESH_INTERVAL,
  appName: unleashParams.APP_NAME,
  environment: unleashParams.ENVIRONMENT
};

export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  return <FlagProvider config={config}>{children}</FlagProvider>;
};
