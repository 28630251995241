import { EVoteReason, NPS_VOTE_REASONS } from '@constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemWrapper, Wrapper } from './ReasonPicker.styles';

type Props = {
  vote: number;
  value?: EVoteReason[];
  onChange?: (value: EVoteReason[]) => void;
};

export const ReasonPicker: React.FC<Props> = ({ value, onChange, vote }) => {
  const { t } = useTranslation('CommonDashboardTranslations');

  const position = useMemo(() => {
    if (vote < 7) return 'DETRACTOR';
    if (vote < 9) return 'NEUTRAL';
    return 'PROMOTER';
  }, [vote]);

  const handleChange = (reason: EVoteReason) => {
    if (value?.includes(reason)) {
      onChange?.(value.filter(r => r !== reason));
    } else {
      onChange?.(value ? [...value, reason] : [reason]);
    }
  };

  return (
    <Wrapper>
      {NPS_VOTE_REASONS.map(reason => (
        <ItemWrapper
          key={reason}
          isSelected={value?.includes(reason) ?? false}
          onClick={() => handleChange(reason)}>
          {t(`${reason}_${position}`)}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};
