import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useAITeachersList = () => {
  const queryKey = ['ai-teachers'];

  const { isLoading, data: teachers } = useQuery({
    queryKey,
    queryFn: () => UserService.getAITeachers()
  });

  return {
    isLoading,
    teachers
  };
};
