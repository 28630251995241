import { FC } from 'react';

import { MathButton } from './MathButton';

import { Box } from '@/components/Utils';
import { texSymbols } from '@/constants';

type Props = {
  latex: string;
  setLatex: (latex: string) => void;
};

const BOX_STYLE_PROPS = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: '4px',
  gridAutoRows: 'minmax(4px, auto)'
};

export const TexSymbols: FC<Props> = ({ latex, setLatex }) => {
  return (
    <Box {...BOX_STYLE_PROPS}>
      {texSymbols.map((symbol, i) => (
        <MathButton key={i} btn={symbol} index={i} latex={latex} setLatex={setLatex} />
      ))}
    </Box>
  );
};
