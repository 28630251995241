import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0px !important;
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
`;
