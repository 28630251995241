import styled from 'styled-components';

export const HighlightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > h2 {
    margin: 0;
  }
`;

export const StyledImage = styled.img`
  padding-bottom: 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > h2 {
    margin: 0;
  }
`;
