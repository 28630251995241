import { IFoldersWithMaterial } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  data?: IFoldersWithMaterial;
  setData: (data: IFoldersWithMaterial | undefined) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useFolderForm = createStore<IStore>(set => ({
  isOpen: false,
  data: undefined,
  setData: (data: IFoldersWithMaterial | undefined) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
