import { EPostStatus } from '@constants';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  status?: EPostStatus;
};

export const StatusTag: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  if (!status) return null;

  switch (status) {
    case EPostStatus.SCHEDULED:
      return <Tag color="purple">{t('SCHEDULED_POST')}</Tag>;
    case EPostStatus.PUBLISHED:
      return <Tag color="green">{t('PUBLISHED_POST')}</Tag>;
    case EPostStatus.DRAFT:
      return <Tag color="default">{t('DRAFT_POST')}</Tag>;
    default:
      return null;
  }
};
