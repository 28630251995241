import { IUpdateProductTour } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const ProductTourService = sdk.getService('ProductTourService');

export const useMutateProductTour = () => {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['product-tour'] });

  const { mutate: updateProductTour, isPending: isUpdating } = useMutation({
    mutationFn: async (update: IUpdateProductTour) =>
      ProductTourService.updateUserProductTour(update),
    onSuccess
  });

  return {
    updateProductTour,
    isWorking: isUpdating
  };
};
