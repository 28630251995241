import { AnalyticsTrackEvents } from '@constants';
import { Button } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BackSideWrapper,
  DescriptionWrapper,
  FrontSideWrapper,
  ItemWrapper,
  TitleWrapper,
  Wrapper
} from './QuizModeCard.styles';

import { Icon } from '@/components/Icon';
import { useIsMobile, useMixpanel } from '@/hooks';

interface IQuizModeItem {
  title: string;
  description: string;
  buttonText: string;
  image: string;
  path: string;
  backgroundColor: string;
  borderColor: string;
}
type Props = {
  data: IQuizModeItem;
};

export const QuizModeCard: React.FC<Props> = ({ data }) => {
  const [flip, setFlip] = useState(true);
  const { title, description, buttonText, image, path, backgroundColor, borderColor } = data;
  const navigateTo = useNavigate();
  const { fireEvent } = useMixpanel();
  const isMobile = useIsMobile('sm');

  const handleNavigate = () => {
    navigateTo(path);
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'QUIZZES'
    });
  };

  return (
    <Wrapper
      onMouseEnter={() => setFlip(false)}
      onMouseLeave={() => setFlip(true)}
      onClick={handleNavigate}>
      <FrontSideWrapper
        style={{ transform: isMobile ? undefined : flip ? 'rotateY(0deg)' : 'rotateY(180deg)' }}>
        <ItemWrapper>
          <img width={isMobile ? 32 : 100} height={isMobile ? 32 : 100} src={image} alt={title} />
          <TitleWrapper>{title}</TitleWrapper>
        </ItemWrapper>
        {isMobile && <Icon size={32} i="ArrowRight" />}
      </FrontSideWrapper>
      {!isMobile && (
        <BackSideWrapper
          style={{ transform: isMobile ? undefined : flip ? 'rotateY(180deg)' : 'rotateY(360deg)' }}
          backgroundColor={backgroundColor}
          borderColor={borderColor}>
          <DescriptionWrapper>{description}</DescriptionWrapper>
          <Button type="primary">{buttonText}</Button>
        </BackSideWrapper>
      )}
    </Wrapper>
  );
};
