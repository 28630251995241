import { IChangePasswordRequest, IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');
const AuthService = sdk.getService('AuthService');

export const useMutateProfile = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['profile'] });

  const { mutate: updateProfile, isPending: isUpdating } = useMutation({
    mutationFn: (update: Partial<IUser>) => UserService.updateMe(update),
    onSuccess
  });

  const { mutate: changePassword, isPending: isChanging } = useMutation({
    mutationFn: (passwords: IChangePasswordRequest) => AuthService.changePassword(passwords),
    onSuccess
  });

  return {
    updateProfile,
    changePassword,
    isWorking: isUpdating || isChanging
  };
};
