import { dateProvider } from '@utils';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePostViewer } from '../../../hooks';

import {
  DateWrapper,
  ImageWrapper,
  InfoWrapper,
  PostListWrapper,
  PostWrapper,
  SubtitleWrapper,
  TitleWrapper,
  Wrapper
} from './RelatedPosts.style';

import { useRelatedPosts } from '@/store';

type Props = {
  postId: string;
};

export const RelatedPostsToPost: React.FC<Props> = ({ postId }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { relatedPosts } = useRelatedPosts(postId);
  const { setPostId } = usePostViewer();

  const twoFirstRelatedPosts = relatedPosts?.slice(0, 2);

  if (!twoFirstRelatedPosts?.length) return null;

  return (
    <Wrapper>
      <TitleWrapper>{t('RELATED_POSTS')}</TitleWrapper>
      <PostListWrapper>
        {twoFirstRelatedPosts?.map(post => (
          <PostWrapper key={post._id} onClick={() => setPostId(post._id)}>
            <ImageWrapper>
              <Image
                width="100%"
                height={145}
                preview={false}
                alt={post.title}
                src={post.featuredImage?.url}
              />
            </ImageWrapper>
            <InfoWrapper>
              <SubtitleWrapper>{post.topic.name}</SubtitleWrapper>
              <TitleWrapper>{post.title}</TitleWrapper>
              <DateWrapper>{dateProvider.format(post.createdAt, 'dd MMM, yyyy')}</DateWrapper>
            </InfoWrapper>
          </PostWrapper>
        ))}
      </PostListWrapper>
    </Wrapper>
  );
};
