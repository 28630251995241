import styled from 'styled-components';

export const SourceContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  padding-top: 8px;
`;

export const DocumentWrapper = styled.a`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.green6};
`;

export const StyledList = styled.ul`
  margin-block-start: unset;
  margin-block-end: unset;
  padding-inline-start: 24px;
  color: ${({ theme }) => theme.colors.green6};
`;
