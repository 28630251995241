import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8px;
`;

export const OptionWrapper = styled.div<{ selected?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.purple2 : theme.colors.white};
  border: ${({ theme, selected }) =>
    `${selected ? 2 : 1}px solid ${selected ? theme.colors.purple6 : theme.colors.black5}`};
`;
