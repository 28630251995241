import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserStatsByCourseService = sdk.getService('UserStatsByCourseService');

export const useStudentAverageQuizScore = (studentId: string) => {
  const queryKey = ['user-stats-by-course', 'average-score', studentId];
  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => UserStatsByCourseService.getUserAverageScore(studentId),
    enabled: !!studentId
  });

  return {
    isLoading,
    averageScore: data
  };
};
