import { RequestService } from '../request/request.service';

import { IAcademy } from './academy.contract';

export class AcademyService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getAcademies = async () => {
    return this.fetcher<IAcademy[]>('/academies');
  };

  public createAcademy = async (academy: IAcademy) => {
    return this.poster<IAcademy, IAcademy>('/academies', academy);
  };

  public getAcademy = async (academyId: string) => {
    return this.fetcher<IAcademy>(`/academies/${academyId}`);
  };

  public updateAcademy = async (academy: IAcademy) => {
    return this.putter<IAcademy, IAcademy>(`/academies/${academy._id}`, academy);
  };

  public removeAcademy = async (academyId: string) => {
    return this.deleter(`/academies/${academyId}`);
  };

  public getAcademyByDomain = async (domain: string) => {
    return this.fetcher<IAcademy>(`/academies/by-domain/${domain}`);
  };
}
