import { Avatar } from 'antd';
import { useState } from 'react';

import { InfoItem } from './components/InfoItem';
import { OnlineUsers } from './components/OnlineUsers';
import { TitleWrapper, Wrapper } from './InfoWidget.styles';

import { Icon } from '@/components/Icon';
import { WidgetBase } from '@/components/WidgetBase';
import { useMyAcademyDetail, useMyAcademyStatsDetail } from '@/store';
import { WidgetProps } from '@/types';

const AVATAR_SIZE = 100;

export const InfoWidget: React.FC<WidgetProps> = props => {
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const { academy } = useMyAcademyDetail();
  const { stats } = useMyAcademyStatsDetail();

  return (
    <WidgetBase {...props}>
      <Wrapper>
        <Avatar
          size={AVATAR_SIZE}
          src={academy?.media?.avatar?.url}
          onClick={() => setShowOnlineUsers(true)}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<Icon i="Education" style={{ fontSize: 52 }} />}
        />
        <div>
          <TitleWrapper>{academy?.name}</TitleWrapper>
          <div>
            <InfoItem title="INFO_OPTION_STUDENTS" value={stats?.students ?? 0} />
            <InfoItem title="INFO_OPTION_TEACHERS" value={stats?.teachers ?? 0} />
            <InfoItem title="INFO_OPTION_COURSES" value={stats?.courses ?? 0} />
          </div>
        </div>
      </Wrapper>
      <OnlineUsers isOpen={showOnlineUsers} onClose={() => setShowOnlineUsers(false)} />
    </WidgetBase>
  );
};
