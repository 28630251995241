import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';
import { getMultiplierFromStreak } from '@/utils';

const SessionLogService = sdk.getService('SessionLogService');

export const useStudentStreak = (studentId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['session-logs', 'streak', studentId],
    queryFn: () => SessionLogService.getUserStreak(studentId),
    enabled: !!studentId
  });

  const streak = data?.streak ?? 0;

  return {
    streak,
    multiplier: getMultiplierFromStreak(streak),
    isLoading
  };
};
