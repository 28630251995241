import { ICurrentWeekInfo, ISortedPlanificationActivity } from '@sdk/contracts';
import { dateProvider } from '@utils';

export const getCurrentWeekInfo = (
  sortedPlanificationActivitiesByDate: Record<string, ISortedPlanificationActivity[]>
): ICurrentWeekInfo => {
  if (
    !sortedPlanificationActivitiesByDate ||
    Object.keys(sortedPlanificationActivitiesByDate).length === 0
  ) {
    return { currentWeek: 0, currentDay: 0 };
  }

  const today = dateProvider.startOfDay();
  const startDate = dateProvider.startOfDay(
    dateProvider.parse(Object.keys(sortedPlanificationActivitiesByDate)[0])
  );
  const daysDiff = dateProvider.diff(startDate, today, 'days');

  if (daysDiff < 0) return { currentWeek: 1, currentDay: 0 };

  const currentWeek = Math.floor(daysDiff / 7) + 1;
  const currentDay = (daysDiff % 7) + 1;

  return { currentWeek, currentDay };
};
