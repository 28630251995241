import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const UsefulResponseWrapper = styled.div`
  align-self: flex-end;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    align-self: auto;
  }
`;
