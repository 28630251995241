import { Spin } from 'antd';

import { Wrapper } from './FullPageLoader.styles';

export const FullPageLoader = () => {
  return (
    <Wrapper>
      <Spin data-testid="loader" />
    </Wrapper>
  );
};
