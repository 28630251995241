import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const LiveClassVoteService = sdk.getService('LiveClassVoteService');

export const useCheckLiveClassVote = () => {
  const queryKey = ['live-class-vote', 'check-pending-votes'];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => LiveClassVoteService.checkPendingVotes()
  });

  return {
    pendingVotes: data,
    isPending
  };
};
