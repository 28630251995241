import { useTranslation } from 'react-i18next';

import { ItemWrapper, Wrapper } from './EverythingClearSelector.styles';

type Props = {
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const EverythingClearSelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <Wrapper>
      <ItemWrapper isSelected={value === true} onClick={() => onChange?.(true)}>
        {t('YES')}
      </ItemWrapper>
      <ItemWrapper isSelected={value === false} onClick={() => onChange?.(false)}>
        {t('NO')}
      </ItemWrapper>
    </Wrapper>
  );
};
