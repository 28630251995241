import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 24px 0;
`;

export const EmptyPlanificationTextWrapper = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
