import { IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const MyAcademyTeachersService = sdk.getService('MyAcademyTeachersService');
const MyAcademyService = sdk.getService('MyAcademyService');

export const useMutateAITeacher = () => {
  const { t } = useTranslation('AITeachersTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['ai-teachers'] });

  const { mutate: createAITeacher, isPending: isCreating } = useMutation({
    mutationFn: (newTeacher: Partial<IUser>) => MyAcademyTeachersService.createTeacher(newTeacher),
    onSuccess: () => {
      onSuccess();
      message.success(t('TEACHER_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateAITeacher, isPending: isUpdating } = useMutation({
    mutationFn: (updatedTeacher: Partial<IUser>) =>
      MyAcademyTeachersService.updateTeacher(updatedTeacher),
    onSuccess: () => {
      onSuccess();
      message.success(t('TEACHER_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeAITeacher, isPending: isDeleting } = useMutation({
    mutationFn: (teacherId: string) => MyAcademyService.removeUser(teacherId),
    onSuccess: () => {
      onSuccess();
      message.success(t('TEACHER_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    createAITeacher,
    updateAITeacher,
    removeAITeacher,
    isWorking: isCreating || isUpdating || isDeleting
  };
};
