import { IUser } from '@sdk/contracts';
import * as Sentry from '@sentry/react';
import { useState } from 'react';

const REPLAY = new Sentry.Replay();
const BROWSER_TRACING = Sentry.browserTracingIntegration();
const BROWSER_PROFILING = Sentry.browserProfilingIntegration();

export const useSentry = () => {
  const [initialized, setInitialized] = useState(false);

  const init = (profile: IUser) => {
    if (initialized || !import.meta.env.PROD) return;
    Sentry.init({
      dsn: 'https://a2021070541a4e5aade6bb4542a3e745@o4504486824837120.ingest.sentry.io/4504486828572672',
      integrations: [REPLAY, BROWSER_TRACING, BROWSER_PROFILING],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: 'production'
    });
    setInitialized(true);
    if (profile)
      Sentry.setUser({
        id: profile._id,
        email: profile.email,
        username: `${profile.name} ${profile.lastName}`
      });
  };

  return {
    init
  };
};
