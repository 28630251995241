import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const MyAcademyService = sdk.getService('MyAcademyService');

export const useMyAcademyDetail = () => {
  const queryKey = ['academies', 'detail'];
  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => MyAcademyService.getMyAcademy()
  });

  return {
    academy: data,
    isLoading
  };
};
