import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useAllCoursesWithSectionsByTag = (tag?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['courses', 'with-sections', 'by-tag', tag],
    queryFn: () => CourseService.findAllCoursesWithSectionsByTagForSelectors(tag!),
    enabled: !!tag
  });

  return {
    courses: data,
    isLoading,
    error
  };
};
