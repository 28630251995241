import { RequestService } from '../request';

import { IProductTour, IUpdateProductTour } from './product-tour.contract';

export class ProductTourService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getUserProductTour() {
    return this.fetcher<IProductTour>(`/product-tour`);
  }

  async updateUserProductTour(updateProductTour: IUpdateProductTour) {
    return this.putter<IProductTour, IUpdateProductTour>(`/product-tour`, updateProductTour);
  }
}
