import { lazy } from 'react';

import { IRoute } from '../../types/routes';

const DashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);
const StudentsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.StudentsPage }))
);
const CoursesPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursesPage }))
);
const CoursePage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursePage }))
);
const QuizzesPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.QuizzesPage }))
);
const QuizPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.QuizPage }))
);
const QuizReviewPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.QuizReviewPage }))
);
const EventsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventsPage }))
);
const EventPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventPage }))
);

const SectionReviewsStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.SectionReviewsStatsPage }))
);

const TicketsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TicketsPage }))
);

export const INSPECTOR: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: DashboardPage,
    showInMenu: true
  },
  {
    title: 'COURSES',
    path: '/content/courses',
    icon: 'Notebook',
    Component: CoursesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES',
        path: ':_id',
        icon: 'Undefined',
        Component: CoursePage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'STUDENTS',
    path: 'students',
    icon: 'Undefined',
    Component: StudentsPage,
    showInMenu: true
  },
  {
    title: 'TEST',
    path: '/content/tests',
    icon: 'TestTool',
    Component: QuizzesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'ALL_TEST',
        path: ':_id',
        icon: 'Undefined',
        Component: QuizPage,
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: ':_id/review',
        icon: 'Undefined',
        Component: QuizReviewPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'LIVE_EVENTS',
    path: '/content/calendar',
    icon: 'EventSchedule',
    Component: EventsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'LIVE_EVENTS',
        path: ':_id',
        icon: 'Undefined',
        Component: EventPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'DOUBTS',
    path: '/tickets',
    icon: 'MailAll',
    Component: TicketsPage,
    showInMenu: true
  },
  {
    title: 'STATS',
    path: '/stats',
    icon: 'ChartClusterBar',
    Component: SectionReviewsStatsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'SECTION_REVIEWS',
        path: 'section-reviews',
        icon: 'Undefined',
        Component: SectionReviewsStatsPage,
        showInMenu: true
      }
    ]
  }
];
