import { EGamificationRewardType, GAMIFICATION_REWARDS } from '@constants';
import { multiply } from 'lodash';

import { roundToDecimal } from './roundToDecimal';

export const getGamificationPoints = (multiplier: number, type: EGamificationRewardType) => {
  const value = GAMIFICATION_REWARDS[type].points || 0;
  const gamificationPoints = multiply(multiplier, value);
  return roundToDecimal(gamificationPoints, 1);
};
