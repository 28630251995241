import styled from 'styled-components';

type Props = {
  isCurrent: boolean;
  isLocked?: boolean;
  isCompleted?: boolean;
};

export const Wrapper = styled.div<Props>`
  margin-bottom: 10px;
  color: ${({ theme, isCurrent }) => (isCurrent ? theme.colors.green6 : 'unset')};
  background: ${({ theme, isCurrent }) => (isCurrent ? `${theme.colors.green4}10` : 'unset')};
  border-left: ${({ theme, isCurrent }) =>
    isCurrent ? `5px solid ${theme.colors.green4}` : 'unset'};
  padding: ${({ isCurrent }) => (isCurrent ? '0px 16px' : '0px 21px')};
  display: flex;
  gap: 16px;
  align-items: flex-start;
  cursor: ${({ isLocked }) => (isLocked ? 'not-allowed' : 'pointer')};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  color: ${({ theme }) => theme.colors.black9};
`;

export const IconWrapper = styled.div<Props>`
  margin-top: 6px;
  color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
  pointer-events: none;
`;

export const SpanWrapper = styled.span<Props>`
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
`;

export const NameWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > span {
    color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
  }
`;
