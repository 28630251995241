import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TranscriptionService = sdk.getService('TranscriptionService');

export const useTranscriptionDetailByActivity = (activityId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['transcriptions', 'by-activity', activityId],
    queryFn: () => TranscriptionService.getTranscriptionByActivity(activityId!),
    enabled: !!activityId
  });

  return {
    transcription: data,
    isLoading,
    error
  };
};
