import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;

  .ant-select {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.black9};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  padding: 4px 16px;
  cursor: pointer;
  font-weight: 600;

  :hover {
    border-color: ${({ theme }) => theme.colors.green7};
    color: ${({ theme }) => theme.colors.green7};
  }
`;

export const ClearFiltersWrapper = styled.div`
  font-weight: 600;
  cursor: pointer;
  padding: 4px 8px;
  margin-top: 8px;
`;
