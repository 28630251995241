export const DeliverableListTranslations = {
  DELIVERABLE: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Lliurament',
    eus: 'Entregable'
  },
  ALL_DELIVERABLES: {
    es: 'Todos los entregables',
    en: 'All deliverables',
    cat: 'Tots els lliuraments',
    eus: 'Entregable guztiak'
  },
  DELIVERABLE_HAS_BEEN_UPDATED: {
    es: 'El entregable ha sido actualizado',
    en: 'The deliverable has been updated',
    cat: 'El lliurament ha estat actualitzat',
    eus: 'Entregablea eguneratu da'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  RATE: {
    es: 'Puntuar',
    en: 'Rate',
    cat: 'Puntuar',
    eus: 'Puntuatu'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  SCORE: {
    es: 'Nota',
    en: 'Score',
    cat: 'Nota',
    eus: 'Nota'
  },
  DELETE_DELIVERABLE: {
    es: 'Eliminar entregable',
    en: 'Delete deliverable',
    cat: 'Eliminar lliurament',
    eus: 'Entregablea ezabatu'
  },
  DELIVERABLE_REMOVED_SUCCESSFULLY: {
    es: '¡Entregable eliminado con éxito!',
    en: 'Deliverable deleted successfully!',
    cat: 'Lliurament eliminat correctament!',
    eus: 'Entregablea ondo ezabatua!'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: 'Gracias por tu feedback 😊',
    en: 'Thanks for your feedback 😊',
    cat: 'Gràcies pel teu feedback 😊',
    eus: 'Eskerrik asko zure feedbackagatik 😊'
  },
  DELIVERABLE_NAME: {
    es: 'Nombre del entregable',
    en: 'Deliverable name',
    cat: 'Nom del lliurament',
    eus: 'Entregablearen izena'
  },
  YOU_HAVE_NOT_DELIVERED_DELIVERABLES: {
    es: 'No hay entregables',
    en: 'There are no deliverables',
    cat: 'No hi ha lliurables',
    eus: 'Entregagarriak ez daude'
  },
  DELIVERABLE_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  UPLOAD_DATE: {
    es: 'Fecha de entrega',
    en: 'Upload date',
    cat: 'Data de lliurament',
    eus: 'Entregako data'
  },
  CSAT: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Balorazioa'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  TOTAL: {
    es: 'Total: {{total}}',
    en: 'Total: {{total}}',
    cat: 'Total: {{total}}',
    eus: 'Guztira: {{total}}'
  }
};
