import styled from 'styled-components';

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const ImageWrapper = styled.div`
  height: auto;
  width: 200px;
  margin: 12px;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;
