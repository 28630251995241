import styled from 'styled-components';

import TITLE_VECTOR from '@/assets/onboarding_student_info_vector.svg';

export const StyledSpan = styled.span`
  background-image: url(${TITLE_VECTOR});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 20px;
`;
