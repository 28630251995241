import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LottieWrapper, StyledH1, StyledLottie, TextWrapper } from './LoadingWidget.styles';

import SAND_CLOCK_LOTTIE from '@/assets/lotties/sand_clock_loader.json';
import UMY_ONBOARDING_GENERATING_PLATFORM from '@/assets/umy_onboarding_generating_platform.svg';
import { useProfile } from '@/store';

type Props = {
  visibleText: number;
};

export const LoadingWidget: React.FC<Props> = ({ visibleText }) => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { profile } = useProfile();

  if (!profile) return null;

  const { name: userName } = profile;

  return (
    <>
      <StyledH1>
        {t('GENERATING_PLATFORM', {
          name: userName.charAt(0).toLocaleUpperCase() + userName.slice(1)
        })}
      </StyledH1>
      <LottieWrapper>
        <StyledLottie animationData={SAND_CLOCK_LOTTIE} autoPlay loop />
        <img src={UMY_ONBOARDING_GENERATING_PLATFORM} alt="Generating platform" />
      </LottieWrapper>
      {visibleText === 1 && <TextWrapper>{t('PREPARING_PLATFORM')}</TextWrapper>}
      {visibleText === 2 && (
        <TextWrapper>
          <Trans i18nKey="FUN_FACT" components={{ a: <strong /> }} />
        </TextWrapper>
      )}
    </>
  );
};
