import { Tag } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.purple3};
  color: ${({ theme }) => theme.colors.purple6};
  margin-inline-end: unset;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  flex: none;
`;

export const StyledIcon: typeof Icon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red6};
`;
