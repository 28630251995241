export enum EEventType {
  EVENT = 'EVENT',
  LIVE_CLASS = 'LIVE_CLASS'
}

export enum EEventWeekdays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export const EVENT_TYPES = [EEventType.EVENT, EEventType.LIVE_CLASS];

export const ALL_WEEKDAYS = Object.values(EEventWeekdays);

export enum GOOGLE_MEET_EVENT_TYPES {
  CONFERENCE_STARTED = 'google.workspace.meet.conference.v2.started',
  CONFERENCE_ENDED = 'google.workspace.meet.conference.v2.ended',
  PARTICIPANT_JOINED = 'google.workspace.meet.participant.v2.joined',
  PARTICIPANT_LEFT = 'google.workspace.meet.participant.v2.left',
  RECORDING_FILE_GENERATED = 'google.workspace.meet.recording.v2.fileGenerated',
  TRANSCRIPT_FILE_GENERATED = 'google.workspace.meet.transcript.v2.fileGenerated',
  MEET_EXPIRED = 'google.workspace.events.subscription.v1.expired',
  MEET_EXPIRATION_REMINDER = 'google.workspace.events.subscription.v1.expirationReminder'
}
