import { useTranslation } from 'react-i18next';

import { ItemWrapper, Wrapper } from './KnowOtherPeoplePicker.styles';

type Props = {
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const KnowOtherPeoplePicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('StudentOnboardingTranslations');

  const handleChange = (option: boolean) => onChange?.(option);

  return (
    <Wrapper>
      <ItemWrapper onClick={() => handleChange(true)} isSelected={!!value}>
        {t('YES')}
      </ItemWrapper>
      <ItemWrapper onClick={() => handleChange(false)} isSelected={value === false}>
        {t('NO')}
      </ItemWrapper>
    </Wrapper>
  );
};
