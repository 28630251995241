export const AcademiesTranslations = {
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  DOMAIN: {
    es: 'Dominio',
    en: 'Domain',
    cat: 'Domini',
    eus: 'Dominioa'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  MANAGER: {
    es: 'Gerente',
    en: 'Manager',
    cat: 'Gerent',
    eus: 'Kudeatzailea'
  },
  NEW_ACADEMY: {
    es: 'Nueva Academia',
    en: 'New Academy',
    cat: 'Nova Acadèmia',
    eus: 'Akademia Berria'
  },
  EDIT_ACADEMY: {
    es: 'Editar Academia',
    en: 'Edit Academy',
    cat: 'Editar Acadèmia',
    eus: 'Akademia Aldatu'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde'
  },
  CREATE_ACADEMY: {
    es: 'Crear Academia',
    en: 'Create Academy',
    cat: 'Crear Acadèmia',
    eus: 'Akademia Sortu'
  },
  ACADEMIES_TOTAL_COUNT: {
    es: '{{total}} academias',
    en: '{{total}} academies',
    cat: '{{total}} acadèmies',
    eus: '{{total}} akademia'
  },
  CERTIFICATE_TEMPLATE: {
    es: 'Plantilla de Certificado',
    en: 'Certificate Template',
    cat: 'Plantilla de Certificat',
    eus: 'Agiria Txantiloia'
  }
};
