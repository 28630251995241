import styled from 'styled-components';

export const Wrapper = styled.div<{ expandgrid: boolean }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ expandgrid }) => (expandgrid ? '200' : '300')}px, 1fr)
  );
  grid-gap: 16px;
`;
