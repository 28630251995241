import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 240px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.black4}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

type Props = {
  isPublished: boolean;
};

export const ExamDateWrapper = styled.div<Props>`
  color: ${({ theme, isPublished }) => (isPublished ? theme.colors.black9 : theme.colors.black5)};

  & > ul {
    padding-inline-start: 16px;
  }
`;
