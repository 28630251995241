import { EActivityType } from '@constants';

export const ManagerActivityStatsTranslations = {
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala'
  },
  STARTED_PERCENTAGE: {
    es: '% comenzados',
    en: '% started',
    cat: '% començats',
    eus: '% hasita'
  },
  COMPLETED_PERCENTAGE: {
    es: '% finalizados',
    en: '% completed',
    cat: '% completats',
    eus: '% osatuta'
  },
  TICKETS_COUNT: {
    es: 'Nº dudas',
    en: 'Ticket count',
    cat: 'Nº dubtes',
    eus: 'Duda kopurua'
  },
  CSAT: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT'
  },
  RATING: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Balorazioa'
  },
  CHECKPOINT_COUNT: {
    es: 'Nº checkpoints',
    en: 'Checkpoint count',
    cat: 'Nº checkpoints',
    eus: 'Checkpoint kopurua'
  },
  SUCCESSFUL_CHECKPOINT_PERCENTAGE: {
    es: '% éxito checkpoints',
    en: '% successful checkpoints',
    cat: '% èxit checkpoints',
    eus: '% ondo checkpoints'
  },
  NOT_RESOLVED_REPORTS: {
    es: 'Reportes sin resolver',
    en: 'Not resolved reports',
    cat: 'Reports sense resoldre',
    eus: 'Ez-garbitutako txostenak'
  },
  ACTIVITIES_TOTAL_COUNT: {
    es: '{{total}} actividades',
    en: '{{total}} activities',
    cat: '{{total}} activitats',
    eus: '{{total}} jarduera'
  },
  ACTIVITIES: {
    es: 'Actividades',
    en: 'Activities',
    cat: 'Activitats',
    eus: 'Jarduerak'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  [EActivityType.VIDEO]: {
    es: 'Video',
    en: 'Video',
    cat: 'Vídeo',
    eus: 'Bideoa'
  },
  [EActivityType.PDF]: {
    es: 'PDF',
    en: 'PDF',
    cat: 'PDF',
    eus: 'PDF'
  },
  [EActivityType.INTERACTIVE_VIDEO]: {
    es: 'Video interactivo',
    en: 'Interactive video',
    cat: 'Vídeo interactiu',
    eus: 'Interaktiboa den bideoa'
  },
  [EActivityType.DELIVERABLE]: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Lliurament',
    eus: 'Entregaketa'
  },
  [EActivityType.QUIZ]: {
    es: 'Test',
    en: 'Quiz',
    cat: 'Test',
    eus: 'Test'
  }
};
