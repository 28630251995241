import { Button } from 'antd';
import styled, { css } from 'styled-components';

type ButtonProps = {
  step: number;
};

const getContentJustify = (step: number) => {
  switch (step) {
    case 6:
      return 'center';
    default:
      return 'flex-end';
  }
};

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const ButtonWrapper = styled.div<ButtonProps>(
  ({ step }) => css`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: ${getContentJustify(step)};
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      margin-top: 16px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
      margin-top: 100px;
    }
  `
);

export const StyledButton = styled(Button)<ButtonProps>(
  ({ step }) => css`
    z-index: 10;
    border-radius: 50px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      align-self: ${step === 1 ? `center` : step === 3 ? 'flex-start' : `unset`};
    }
  `
);

export const StyledUmyImg1 = styled.img`
  position: absolute;
  bottom: -400px;
  right: -350px;
  transition: bottom 0.5s ease-in-out;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    right: -280px;
  }
`;

export const StyledUmyImg2 = styled.img`
  position: absolute;
  bottom: -400px;
  left: -350px;
  transition: bottom 0.5s ease-in-out;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    left: -180px;
  }
`;
