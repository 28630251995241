import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 6;

const PostService = sdk.getService('PostService');

interface IStore {
  limit: number;
  setLimit: (limit: number) => void;
}

const store = createStore<IStore>(set => ({
  limit: DEFAULT_LIMIT,
  setLimit: (limit: number) => set({ limit })
}));

export const usePostsList = () => {
  const { limit, setLimit } = store();
  const queryKey = ['posts', `page-${DEFAULT_PAGE}`, `limit-${limit}`];

  const { isPending, data } = useQuery({
    queryKey,
    queryFn: () => PostService.getPosts({ page: DEFAULT_PAGE, limit }),
    placeholderData: keepPreviousData
  });

  const loadMorePosts = () => {
    if (isPending || (data?.docs.length ?? 0) < limit) return;
    setLimit(limit + DEFAULT_LIMIT);
  };

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return { isPending, loadMorePosts, pagination, posts: data?.docs };
};
