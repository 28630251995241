import Lottie from 'lottie-react';

import LIVE_EVENT from '@/assets/lotties/live_event.json';
import { useCurrentLiveEvents } from '@/store';

const LOTTIE_SIZE = '24px';

export const LiveEventLottie = () => {
  const { isAnyEventLive } = useCurrentLiveEvents();

  if (!isAnyEventLive) return null;

  return (
    <div style={{ width: LOTTIE_SIZE, height: LOTTIE_SIZE }}>
      <Lottie animationData={LIVE_EVENT} />
    </div>
  );
};
