import styled from 'styled-components';

export const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    background-position-x: -100px;
  }
`;
