import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useMySubscriptions } from './useMySubscriptions';

import { sdk } from '@/services';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useMySubscriptionStudyStatus = () => {
  const { _id } = useParams<{ _id: string }>();
  const { selectedSubscription } = useMySubscriptions();
  const subscriptionId = _id ?? selectedSubscription?._id;

  const { isLoading, data } = useQuery({
    queryKey: ['study-status', subscriptionId],
    queryFn: () => SubscriptionService.getSectionsStudyStatus(subscriptionId!),
    enabled: !!selectedSubscription
  });

  return {
    studyStatus: data,
    isLoading
  };
};
