export const PUBLIC_CHAT_OPTIONS = [
  {
    label: 'Educación',
    options: [
      { label: 'Educación Primaria', value: '63ecea5bf7cc00d55bea1bcd' },
      { label: 'Educación Infantil', value: '63ecec88f7cc00d55bea2369' },
      { label: 'Técnico de Educación Infantil', value: '63fc744e434dc3fd861d6b64' },
      { label: 'Haur Hezkuntza', value: '63ecf0fdf7cc00d55bea3919' },
      { label: 'Lehen Hezkuntza', value: '63ecf563f7cc00d55bea5566' }
    ]
  },
  {
    label: 'Administración',
    options: [
      { label: 'Administración Local', value: '63ed1175f7cc00d55beabd6e' },
      { label: 'Administrativo del Estado', value: '63ed0d4af7cc00d55beaa95a' },
      { label: 'Administrativo Seguridad Social', value: '64a2d01cd1eb751b753e400d' },
      { label: 'Auxiliar Administrativo del Estado', value: '63ed0f59f7cc00d55beab298' },
      { label: 'Auxiliar de Enfermería', value: '63ecff04f7cc00d55bea8b1d' },
      { label: 'Agente de Hacienda', value: '63edf6e16237daea851c3857' },
      { label: 'Auxilio Judicial', value: '63ed017af7cc00d55bea9247' },
      { label: 'Ayudante Instituciones Penitenciarias', value: '63ed1686f7cc00d55bead0da' },
      { label: 'Celador', value: '63ecfeaff7cc00d55bea8951' },
      { label: 'Correos', value: '63ecff81f7cc00d55bea8dce' },
      { label: 'Gestión Administración Civil del Estado', value: '63ed10bdf7cc00d55beab7bb' },
      { label: 'Gestión Procesal', value: '63ed08f5f7cc00d55bea9ebd' },
      { label: 'Técnico de Hacienda', value: '63edf8d56237daea851c3caa' },
      { label: 'Tramitación Procesal', value: '63ed0508f7cc00d55bea9822' }
    ]
  },
  {
    label: 'Selectividad',
    options: [
      { label: 'Historia de España', value: '63ee00ba6237daea851c56da' },
      { label: 'Biología', value: '63ee0a7a6237daea851c7b5d' },
      { label: 'Dibujo Técnico II', value: '63ee09016237daea851c75f7' },
      { label: 'Economía', value: '63edf9c46237daea851c41d4' },
      { label: 'Filosofía', value: '63ee070c6237daea851c6f5c' },
      { label: 'Física', value: '63ee060b6237daea851c6bc5' },
      { label: 'Historia del Arte', value: '63ee02ba6237daea851c5fae' },
      { label: 'Geografía', value: '63ee04e56237daea851c6759' },
      { label: 'Inglés', value: '63edff8e6237daea851c52d0' },
      { label: 'Latín', value: '63edfd886237daea851c4a6c' },
      { label: 'Matemáticas II', value: '63edfe086237daea851c4cf8' },
      { label: 'Lengua y Literatura', value: '63edfa1d6237daea851c42e4' },
      { label: 'Química', value: '63edfc9a6237daea851c4857' },
      { label: 'Matemáticas II Ciencias Sociales', value: '63edfef36237daea851c5000' }
    ]
  },
  {
    label: 'Fuerzas y Cuerpos de Seguridad del Estado',
    options: [
      { label: 'Policía Local', value: '63ed15eef7cc00d55beace60' },
      { label: 'Policía Nacional', value: '63ed1524f7cc00d55beacbc2' },
      { label: 'Guardia Civil', value: '63ed13fef7cc00d55beac8e8' }
    ]
  }
];

export const getChatTopicsAsString = () => {
  let output = '';
  PUBLIC_CHAT_OPTIONS.forEach(({ options, label }, index) => {
    const topicIndex = index + 1;
    output += `${topicIndex} ${label}\n`;
    options.forEach(({ label }, index) => {
      output += ` ${topicIndex}.${index + 1}. ${label}\n`;
    });
  });
  return output;
};

export const getSelectTopicName = (topicId: string) => {
  let topicName = '';
  PUBLIC_CHAT_OPTIONS.forEach(({ options }) => {
    options.forEach(({ label, value }) => {
      if (value === topicId) {
        topicName = label;
      }
    });
  });
  return topicName;
};
