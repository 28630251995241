import { Tag } from 'antd';
import styled from 'styled-components';

type Props = {
  color: string;
};

export const StyledTag = styled(Tag)<Props>`
  border: transparent;
  padding: 2px 8px;
  background-color: ${({ color }) => color};
`;

export const TextWrapper = styled.div<Props>`
  color: ${({ color }) => color};
`;
