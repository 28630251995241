import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  gap: 16px;
  height: 100%;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  & > :first-child {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black7};
  }
`;

export const FooterItemWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black2};

  & > :first-child {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black7};
  }

  & > :last-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
`;

export const LinkNameWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.link};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;
