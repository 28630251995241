import { ETicketStatus } from '@constants';
import { message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { MessageSender } from './MessageSender';
import { ReassignTeacher } from './ReassignTeacher';
import { Wrapper } from './TicketViewer.styles';

import { TicketMessages } from '@/components/TicketMessages';
import { TicketStatusBar } from '@/components/TicketStatusBar';
import { TICKET_MODAL_WIDTH } from '@/constants';
import { useIsMobile } from '@/hooks';
import { useMutateMyTickets, useMyTicketDetail } from '@/store';

export const TicketViewer = () => {
  const { t } = useTranslation(['CommonTicketsTranslations', 'DefaultTranslations']);
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('ticketId');
  const { pathname } = useLocation();
  const navigateTo = useNavigate();
  const { ticket } = useMyTicketDetail(ticketId);
  const { updateTicket } = useMutateMyTickets();
  const isMobile = useIsMobile();

  if (!ticket) return null;

  const { _id, status } = ticket;

  const isSenderDisabled =
    status === ETicketStatus.CLOSED_NOT_RESOLVED || status === ETicketStatus.CLOSED_RESOLVED;

  const handleStatusChange = (newStatus: ETicketStatus) => {
    updateTicket(
      { _id, status: newStatus },
      {
        onSuccess: () => {
          message.info(
            t('CommonTicketsTranslations:TICKET_STATUS_UPDATED', {
              status: t(`DefaultTranslations:${newStatus}`)
            })
          );
        }
      }
    );
  };

  const handleClose = () => {
    if (!ticketId) return;
    navigateTo(pathname.replace(ticketId, ''));
  };

  return (
    <Modal
      footer={null}
      closable={false}
      open={Boolean(ticketId)}
      onCancel={handleClose}
      title={<TicketStatusBar ticket={ticket} onChange={handleStatusChange} />}
      width={isMobile ? '100%' : TICKET_MODAL_WIDTH}
      styles={{ body: { height: '100%', overflowY: 'auto' } }}>
      <Wrapper>
        <TicketMessages ticket={ticket} />
        {!isSenderDisabled && <MessageSender ticketId={ticket._id} />}
        <ReassignTeacher teacherId={ticket.assignee?._id} name={ticket.assignee?.name} />
      </Wrapper>
    </Modal>
  );
};
