import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 16px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 4px;
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
  font-weight: 500;
  padding-bottom: 8px;
`;
