import { ETicketSelectOptions } from '@constants';

export const StudentCourseTranslations = {
  NEW: {
    en: 'New',
    es: 'Nuevo',
    cat: 'Nou',
    eus: 'Berria'
  },
  CANT_LOAD_SUBSCRIPTION: {
    en: "Can't load subscription",
    es: 'No se puede cargar la suscripción',
    cat: 'No es pot carregar la subscripció',
    eus: 'Ezin da harpidetza kargatu'
  },
  GENERAL: {
    en: 'General',
    es: 'General',
    cat: 'General',
    eus: 'Orokorra'
  },
  DESCRIPTION: {
    en: 'Description',
    es: 'Descripción',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  DESCRIPTION_SUBTITLE: {
    es: 'Pregunta cualquier duda sobre temario, convocatorias... lo que necesites',
    en: 'Ask any question about the syllabus, calls... whatever you need',
    cat: 'Pregunta qualsevol dubte sobre el temari, convocatòries... el que necessitis',
    eus: 'Galdetu edozein zalantza izan dezakezun gai hori buruz'
  },
  NOTES: {
    en: 'Take notes',
    es: 'Tomar apuntes',
    cat: 'Preneu notes',
    eus: 'Egin oharra'
  },
  CONTENT: {
    en: 'Content',
    es: 'Contenido',
    cat: 'Contingut',
    eus: 'Edukiak'
  },
  CANT_LOAD_ACTIVITY: {
    en: "Can't load activity",
    es: 'No se puede cargar la actividad',
    cat: "No es pot carregar l'activitat",
    eus: 'Ezin da jarduera kargatu'
  },
  AN_ERROR_HAS_OCCURED_WHILE_LOADING_THE_ACTIVITY: {
    en: 'An error has occurred while loading the activity try to reload the page, choose another activity or contact the academy',
    es: 'Ha ocurrido un error al cargar la actividad, intenta recargar la página, elige otra actividad o contacta a la academia',
    cat: "S'ha produït un error en carregar l'activitat. Intenta tornar a carregar la pàgina, tria una altra activitat o contacta amb l'acadèmia",
    eus: 'Ekintza kargatzerakoan errore bat gertatu da. Saiatu orrialdea berriz kargatu, aukeratu beste ekintza bat edo harremanetan jarri akademiarekin'
  },
  RELOAD_THE_PAGE: {
    en: 'Reload the page',
    es: 'Recargar la página',
    cat: 'Torna a carregar la pàgina',
    eus: 'Orrialdea berriz kargatu'
  },
  CANT_LOAD_ACTIVITY_RESOURCE: {
    en: "Can't load activity resource",
    es: 'No se puede cargar el recurso de la actividad',
    cat: "No es pot carregar el recurs de l'activitat",
    eus: 'Ezin da jarduera baliabidea kargatu'
  },
  ATTACHMENTS_TITLE: {
    en: 'Attachments',
    es: 'Material adicional',
    cat: 'Adjunts',
    eus: 'Erantsiak'
  },
  EXTERNAL_LINK: {
    en: 'External link',
    es: 'Enlace externo',
    cat: 'Enllaç extern',
    eus: 'Kanpo estekak'
  },
  SECTIONS: {
    en: 'Sections',
    es: 'Secciones',
    cat: 'Seccions',
    eus: 'Atalak'
  },
  DURATION: {
    en: 'of duration',
    es: 'de duración',
    cat: 'de durada',
    eus: 'iraupena'
  },
  COURSE_DETAIL: {
    en: 'Course Details',
    es: 'Detalles del curso',
    cat: 'Detalls del curs',
    eus: 'Ikastaroaren xehetasunak'
  },
  TEACHERS: {
    en: 'Teachers',
    es: 'Profesores',
    cat: 'Professors',
    eus: 'Irakasleak'
  },
  HOURS_ABBREVIATION: {
    en: 'hrs',
    es: 'hrs',
    cat: 'h',
    eus: 'ord'
  },
  I_NEED_HELP: {
    en: 'I need help',
    es: 'Tengo una consulta',
    cat: 'Necessito ajuda',
    eus: 'Laguntza behar dut'
  },
  TEACHER: {
    en: 'Teacher',
    es: 'Profesor',
    cat: 'Professor',
    eus: 'Irakasle'
  },
  WE_ARE_WORKING_IN_THIS_ACTIVITY_WILL_BE_AVAILABLE_SOON: {
    en: 'We are working in this activity, it will be available soon 😄',
    es: 'Estamos trabajando en esta actividad, estará disponible pronto 😄',
    cat: 'Estem treballant en aquesta activitat, estarà disponible aviat 😄',
    eus: 'Jardueran lan egiten ari gara, laster eskuragarri egongo da 😄'
  },
  ACTIVITY_REVIEW_QUESTION: {
    es: '¿Qué te ha parecido esta actividad?',
    en: 'What do you think about this activity?',
    cat: 'Què et sembla aquesta activitat?',
    eus: 'Zer deritzoz jarduera honi buruz?'
  },
  SEND_REVIEW: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  THANKS_FOR_THE_ACTIVITY_REVIEW: {
    es: 'Gracias por tu valoración',
    en: 'Thanks for your review',
    cat: 'Gràcies per la teva valoració',
    eus: 'Mila esker zure berrikuspenagatik'
  },
  CONGRATS: {
    es: '¡Felicidades!',
    en: 'Congrats!',
    cat: 'Felicitats!',
    eus: 'Zorionak!'
  },
  FINISHED_ACTIVITY: {
    es: 'Has finalizado: {{activityName}}',
    en: 'You have finished: {{activityName}}',
    cat: 'Has finalitzat: {{activityName}}',
    eus: 'Amaitu duzu: {{activityName}}'
  },
  DELIVER: {
    es: 'Entregar',
    en: 'Deliver',
    cat: 'Lliurar',
    eus: 'Banatu'
  },
  DELIVERED: {
    es: 'Entregado',
    en: 'Delivered',
    cat: 'Lliurat',
    eus: 'Banatua'
  },
  DELIVERABLE_CREATED_SUCCESSFULLY: {
    es: 'Entregable entregado correctamente',
    en: 'Deliverable delivered successfully',
    cat: 'Lliurament realitzat amb èxit',
    eus: 'Banaketa arrakastatsua'
  },
  CHECK_DELIVERY_STATUS: {
    es: 'Consultar estado de la entrega',
    en: 'Check delivery status',
    cat: 'Comprovar l’estat del lliurament',
    eus: 'Banaketaren egoera egiaztatu'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  NOTE_ERROR: {
    es: 'Error',
    en: 'Error',
    cat: 'Error',
    eus: 'Errorea'
  },
  NOTE_CREATED: {
    es: 'Nota creada',
    en: 'Note created',
    cat: 'Nota creada',
    eus: 'Oharra sortu da'
  },
  NOTE_UPDATED: {
    es: 'Nota actualizada',
    en: 'Note updated',
    cat: 'Nota actualitzada',
    eus: 'Oharra eguneratu da'
  },
  NOTE_REMOVED: {
    es: 'Nota eliminada',
    en: 'Note deleted',
    cat: 'Nota eliminada',
    eus: 'Oharra ezabatu da'
  },
  NOTE_EDITOR_PLACEHOLDER: {
    es: 'Escribe aquí tu nota...',
    en: 'Write your note here...',
    cat: 'Escriu aquí la teva nota...',
    eus: 'Idatzi hemen zure oharra...'
  },
  PROBLEM_SELECTOR_PLACEHOLDER: {
    es: 'Selecciona una opción',
    en: 'Select an option',
    cat: 'Selecciona una opció',
    eus: 'Hautatu aukera bat'
  },
  PROBLEM_REPORT_SELECTOR_TITLE: {
    es: 'Tipo de problema',
    en: 'Problem type',
    cat: 'Tipus de problema',
    eus: 'Arazo mota'
  },
  ERROR_REPORT_MODAL_TITLE: {
    es: 'Reportar un problema',
    en: 'Report an error',
    cat: 'Informar d’un problema',
    eus: 'Errorea jakinarazi'
  },
  PROBLEM_REPORT_THANK_YOU: {
    es: '¡Gracias por informarnos!',
    en: 'Thanks for your report!',
    cat: 'Gràcies per informar-nos!',
    eus: 'Eskerrik asko gureari jakinarazteagatik!'
  },
  PROBLEM_REPORT_RECEIVED: {
    es: 'Hemos recibido tu reporte y estamos trabajando para resolver el problema lo antes posible.',
    en: 'We have received your report and are working to resolve the issue as soon as possible.',
    cat: 'Hem rebut el teu informe i estem treballant per resoldre el problema el més aviat possible.',
    eus: 'Jaso dugu zure txostena eta arazoari ahalik eta azkarren aurre egiteko lan egiten ari gara.'
  },
  SHARE_MODAL_TITLE: {
    es: 'Compartir clase',
    en: 'Share class',
    cat: 'Compartir classe',
    eus: 'Klasea partekatu'
  },
  SHARE_MODAL_TEXT: {
    es: '¿Quieres compartir esta clase con tus amigos? ¡Puedes compartir una versión limitada de esta clase a quien tú quieras!',
    en: '¿Quieres compartir esta clase con tus amigos? ¡Puedes compartir una versión limitada de esta clase a quien tú quieras!',
    cat: 'Vols compartir aquesta classe amb els teus amics? Pots compartir una versió limitada d’aquesta classe amb qui vulguis!',
    eus: 'Zure lagunekin klase hau partekatu nahi duzu? Klase honen bertsio mugatu bat partekatu dezakezu nahi duzun pertsonarekin!'
  },
  SHARE_MODAL_COPY_LABEL: {
    es: 'Copia el enlace',
    en: 'Copy link',
    cat: 'Copia l’enllaç',
    eus: 'Kopiatu esteka'
  },
  SHARE_MODAL_COPY_BUTTON: {
    es: 'Copiar',
    en: 'Copy',
    cat: 'Copia',
    eus: 'Kopiatu'
  },
  SHARE_MODAL_EMAIL_LABEL: {
    es: 'O comparte por email',
    en: 'Or share by email',
    cat: 'O comparteix per correu electrònic',
    eus: 'Edo partekatu email bidez'
  },
  SHARE_MODAL_EMAIL_PLACEHOLDER: {
    es: 'Escribe una dirección de email',
    en: 'Write an email address',
    cat: 'Escriu una adreça de correu electrònic',
    eus: 'Idatzi email helbide bat'
  },
  SHARE_MODAL_DEFAULT_TEXT: {
    es: '¡Ey! Mira este video de Ucademy.',
    en: '¡Ey! Look at this Ucademy video',
    cat: 'Ey! Mira aquest vídeo d’Ucademy.',
    eus: 'Ey! Ikusi Ucademy-ren bideo hau'
  },
  SHARE_MODAL_SUBMIT: {
    es: 'Enviar',
    en: 'Submit',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena'
  },
  COPIED_TO_CLIPBOARD: {
    es: 'Copiado al portapapeles',
    en: 'Copied to clipboard',
    cat: 'Copiat al porta-retalls',
    eus: 'Irudiratzean kopiatua'
  },
  ACCESS_TO_CERTIFICATION_QUIZ: {
    es: 'Acceso al test de certificación',
    en: 'Access to certification quiz',
    cat: 'Accés al test de certificació',
    eus: 'Zerbitzu testaren sarbidea'
  },
  ACCESS_TO_CERTIFICATION_QUIZ_TEXT: {
    es: 'Para acceder al test de certificación, debes haber completado al menos el 75% del curso.',
    en: 'To access the certification test, you must have completed at least 75% of the course.',
    cat: 'Per accedir al test de certificació, has de completar com a mínim el 75% del curs.',
    eus: 'Zerbitzu testa eskuratzeko, gutxienez ikastaroaren %75a osatu behar duzu.'
  },
  LABELS: {
    es: 'Etiquetas',
    en: 'Labels',
    cat: 'Etiquetes',
    eus: 'Etiketak'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Nº de preguntas',
    en: 'Nº of questions',
    cat: 'Nº de preguntes',
    eus: 'Galderen zenbakia'
  },
  TEST_LENGTH: {
    es: 'Duración del test',
    en: 'Test length',
    cat: 'Durada del test',
    eus: 'Zerbitzu testaren iraupena'
  },
  START_TEST: {
    es: 'Comenzar test',
    en: 'Start test',
    cat: 'Iniciar el test',
    eus: 'Hasi testa'
  },
  RETRY_QUIZ: {
    es: 'Repetir test',
    en: 'Retry test',
    cat: 'Repetir el test',
    eus: 'Birpentsatu testa'
  },
  RECAP_MODAL_TITLE: {
    es: 'Repasa el contenido',
    en: 'Review class',
    cat: 'Repassa el contingut',
    eus: 'Berrikusi klasea'
  },
  RECAP_MODAL_SEE_AGAIN: {
    es: 'Ver de nuevo',
    en: 'Repeat',
    cat: 'Torna a veure',
    eus: 'Errepikatu'
  },
  RECAP_MODAL_END_BUTTON: {
    es: 'Finalizar',
    en: 'End',
    cat: 'Finalitzar',
    eus: 'Amaitu'
  },
  RECAP_MODAL_SKIP_BUTTON: {
    es: 'Saltar repaso',
    en: 'Skip recap',
    cat: 'Saltar repàs',
    eus: 'Berrikuspena utzi'
  },
  RECAP_MODAL_CONFIRM_BUTTON: {
    es: 'Confirmar respuesta',
    en: 'Confirm answer',
    cat: 'Confirmar la resposta',
    eus: 'Berrespen erantzuna'
  },
  RECAP_MODAL_SUCCESS_TITLE: {
    es: '¡Has acertado todas las preguntas!',
    en: 'You got all the questions right!',
    cat: 'Has encertat totes les preguntes!',
    eus: 'Galdera guztiak asmatu dituzu!'
  },
  RECAP_MODAL_SUCCESS_SUBTITLE: {
    es: '¡Enhorabuena! Ya estás un poco más preparado para tu examen',
    en: 'Congratulations! You are now a little more prepared for your exam',
    cat: 'Felicitats! Ara estàs una mica més preparat per al teu examen',
    eus: 'Zorionak! Orain gehiago prest zaude zure azterketa egiteko'
  },
  RECAP_MODAL_REVIEW_TITLE: {
    es: '¡Test completado! Has acertado {{count}} de {{total}}',
    en: 'Test completed! You got {{count}} out of {{total}}',
    cat: 'Test completat! Has encertat {{count}} de {{total}}',
    eus: 'Eginda dagoen proba! {{count}} zuzendu dituzu {{total}}-etatik'
  },
  RECAP_MODAL_ANSWERS_BUTTON: {
    es: 'Ver respuestas',
    en: 'View answers',
    cat: 'Veure respostes',
    eus: 'Erantzunak ikusi'
  },
  RECAP_MODAL_WRONG_SUBTITLE: {
    es: 'Siempre puedes volver a ver el contenido para repasar. Estas son las respuestas correctas:',
    en: 'You can always go back to the content to review. These are the correct correct answers:',
    cat: 'Sempre pots tornar a veure el contingut per repassar. Aquestes són les respostes correctes:',
    eus: 'Zure erantzunak berrikusi nahi izanez gero, edukiari itzultzeko aukera daukazu. Hauek dira erantzun zuzenak:'
  },
  RECAP_MODAL_WRONG_TITLE: {
    es: '{{count}} de {{total}} preguntas acertadas',
    en: '{{count}} of {{total}} questions answered correctly',
    cat: '{{count}} de {{total}} preguntes encertades',
    eus: '{{count}} {{total}} galderak zuzen erantzun dituzu'
  },
  RECAP_MODAL_QUESTIONS_TITLE: {
    es: 'Pregunta {{count}} de {{total}}:',
    en: 'Question {{count}} of {{total}}:',
    cat: 'Pregunta {{count}} de {{total}}:',
    eus: '{{count}} galdera {{total}}-etatik'
  },
  RESULTS: {
    es: 'Resultados',
    en: 'Results',
    cat: 'Resultats',
    eus: 'Emaitzak'
  },
  CORRECT_ANSWERS: {
    es: 'aciertos',
    en: 'correct answers',
    cat: 'encerts',
    eus: 'zuzen erantzunak'
  },
  INCORRECT_ANSWERS: {
    es: 'fallos',
    en: 'incorrect answers',
    cat: 'errors',
    eus: 'erantzun okerrak'
  },
  BLANK_ANSWERS: {
    es: 'preguntas sin responder',
    en: 'blank answers',
    cat: 'preguntes sense resposta',
    eus: 'galderak erantzun gabe'
  },
  SPENT_TIME: {
    es: 'minutos',
    en: 'minutes',
    cat: 'minuts',
    eus: 'minutuak'
  },
  SEE_ANSWERS: {
    es: 'Ver respuestas',
    en: 'See answers',
    cat: 'Veure respostes',
    eus: 'Erantzunak ikusi'
  },
  YOUR_WORK: {
    es: 'Tu trabajo',
    en: 'Your work',
    cat: 'La teva feina',
    eus: 'Zure lanbidea'
  },
  EMPTY_DELIVERABLE: {
    es: 'No se ha adjuntado ningún trabajo',
    en: 'No work attached',
    cat: 'No s`ha adjuntat cap treball',
    eus: 'Ez da lanik gehitu'
  },
  ADD_DELIVERY: {
    es: 'Añadir entrega',
    en: 'Add delivery',
    cat: 'Afegir entrega',
    eus: 'Bidali entrega'
  },
  SET_AS_COMPLETE: {
    es: 'Marcar como completado',
    en: 'Set as completed',
    cat: 'Marcar com a completat',
    eus: 'Markatu osatuta'
  },
  SET_AS_INCOMPLETE: {
    es: 'Marcar como incompleto',
    en: 'Set as incomplete',
    cat: 'Marcar com a incomplet',
    eus: 'Markatu osatugabe'
  },
  COMMENTS: {
    es: 'Comentarios',
    en: 'Comments',
    cat: 'Comentaris',
    eus: 'Iruzkinak'
  },
  ADD_COMMENT: {
    es: 'Añadir comentario',
    en: 'Add comment',
    cat: 'Afegir comentari',
    eus: 'Iruzkin bat gehitu'
  },
  NEXT_SECTION: {
    es: 'Tema siguiente',
    en: 'Next section',
    cat: 'Tema següent',
    eus: 'Hurrengo atala'
  },
  PREV_SECTION: {
    es: 'Tema anterior',
    en: 'Previous section',
    cat: 'Tema anterior',
    eus: 'Aurreko atala'
  },
  RATING: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Ebaluazioa'
  },
  DOWNLOAD_MATERIAL_ERROR: {
    es: 'Error al descargar el material',
    en: 'Download material error',
    cat: 'Error en descarregar el material',
    eus: 'Materiala deskargatzean errorea'
  },
  TICKETS: {
    es: 'Dudas resueltas',
    en: 'Resolved questions',
    cat: 'Dubtes resolts',
    eus: 'Galdera ebaztuak'
  },
  CREATE_TICKET: {
    es: 'Quiero resolver una duda ahora',
    en: 'I want to solve a question now',
    cat: 'Vull resoldre un dubte ara',
    eus: 'Orain galdera bat ebaztu nahi dut'
  },
  CREATE_TICKET_SUBTITLE: {
    es: '¡Ninguna pregunta es una tontería! Consulta cualquier duda que tengas sobre este tema ¡Estamos para ayudarte!',
    en: 'No question is silly! Ask any question you have about this topic. We are here to help!',
    cat: 'Cap pregunta és una tonteria! Consulta qualsevol dubte que tinguis sobre aquest tema. Estem aquí per ajudar-te!',
    eus: 'Inork ez du galdera txarra! Galdetu edozein zalantza izan dezakezun gai hori buruz. Hemen gaude zure laguntzeko!'
  },
  CREATE_TICKET_TOOLTIP: {
    es: 'La primera respuesta te la dará Umy, que además te dirá de qué apuntes ha sacado su respuesta. Si su respuesta no te gusta, podrás preguntarle a un profesor humano o reformular la pregunta',
    en: 'The first answer will be given by Umy, who will also tell you where he got his answer from. If you don’t like his answer, you can ask a human teacher or rephrase the question',
    cat: 'La primera resposta te la donarà Umy, que a més et dirà de quins apunts ha tret la seva resposta. Si la seva resposta no t’agrada, podràs preguntar a un professor humà o reformular la pregunta',
    eus: 'Lehenengo erantzuna Umyk emango dizu, baita ere zertarako apunteak hartu dituen esango dizu bere erantzuna. Bere erantzuna ez baduzu gustatzen, irakasle humanori galdera egin dezakezu edo galdera berreraformulatu'
  },
  COURSE_TICKETS: {
    es: 'Preguntas de este curso',
    en: 'Questions about this course',
    cat: 'Preguntes d`aquest curs',
    eus: 'Kurtsu honi buruzko galderak'
  },
  [ETicketSelectOptions.ALL]: {
    es: 'Todo el curso',
    en: 'All the course',
    cat: 'Tot el curs',
    eus: 'Kurtsu osoa'
  },
  [ETicketSelectOptions.SECTION]: {
    es: 'Este tema',
    en: 'This section',
    cat: 'Aquest tema',
    eus: 'Atal honetako'
  },
  [ETicketSelectOptions.ACTIVITY]: {
    es: 'Esta lección',
    en: 'This lesson',
    cat: 'Aquesta lliçó',
    eus: 'Ikasgai honetako'
  },
  MESSAGE_COUNT: {
    es: '{{count}} respuesta(s)',
    en: '{{count}} answer(s)',
    cat: '{{count}} resposta(es)',
    eus: '{{count}} erantzun'
  },
  SET_TICKET_AS_RESOLVED: {
    es: 'Marcar como resuelto',
    en: 'Set as resolved',
    cat: 'Marcar com a resolt',
    eus: 'Markatu erabatua'
  },
  TICKET_RESOLVED_SUCCESSFULLY: {
    es: '¡Genial! Tu pregunta se ha marcado como resuelta.',
    en: 'Great! Your question has been marked as solved.',
    cat: 'Genial! La teva pregunta s`ha marcat com a resolta.',
    eus: 'Zorionak! Zure galdera ebazten duela jakin duzu.'
  },
  CSAT_PLACEHOLDER: {
    es: '¿Estás satisfecho/a con la resolución de tu pregunta?',
    en: 'Are you satisfied with the resolution of your question?',
    cat: 'Estàs satisfet/eta amb la resolució de la teva pregunta?',
    eus: 'Zalantzarik gabeko galderaren ebazpenarekin apurka ari zara?'
  },
  THANKS_FOR_FEEDBACK: {
    es: '¡Gracias por tu feedback!',
    en: 'Thanks for your feedback!',
    cat: 'Gràcies pel teu feedback!',
    eus: 'Eskerrik asko zure iritzia emateagatik!'
  },
  DELETE_CONFIRMATION: {
    es: '¿Quieres eliminar esta pregunta?',
    en: 'Do you want to delete this question?',
    cat: 'Vols eliminar aquesta pregunta?',
    eus: 'Galdera hau ezabatu nahi duzu?'
  },
  RETURN: {
    es: 'Volver',
    en: 'Return',
    cat: 'Tornar',
    eus: 'Itzuli'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  REQUIRED_DESCRIPTION: {
    es: 'Por favor, ingresa la descripción de la pregunta',
    en: 'Please enter the question description',
    cat: 'Si us plau, introdueix la descripció de la pregunta',
    eus: 'Mesedez, sartu galderaren deskribapena'
  },
  TITLE: {
    es: 'Título (opcional)',
    en: 'Title (optional)',
    cat: 'Títol (opcional)',
    eus: 'Izenburua (aukerakoa)'
  },
  TITLE_PLACEHLODER: {
    es: '¿De qué trata tu duda?',
    en: 'What is your question about?',
    cat: 'De què tracta el teu dubte?',
    eus: 'Zer da zure zalantzaren gaia?'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Ticket creado correctamente',
    en: 'Ticket created successfully',
    cat: 'Tiquet creat amb èxit',
    eus: 'Tiketa behar bezala sortu da'
  },
  SEE_MORE_QUESTIONS: {
    es: 'Ver más preguntas',
    en: 'See more questions',
    cat: 'Veure més preguntes',
    eus: 'Ikusi galdera gehiago'
  },
  EMPTY_TITLE: {
    es: 'No has hecho ninguna pregunta',
    en: 'You have not asked any questions',
    cat: 'No has fet cap pregunta',
    eus: 'Ez duzu inor galderarik egin'
  },
  EMPTY_SUBTITLE: {
    es: 'Haz click arriba en “Hacer una pregunta” para empezar a plantear una duda.',
    en: 'Click on "Ask a question" above to start asking a question.',
    cat: 'Fes clic a dalt a "Fer una pregunta" per començar a plantejar una pregunta.',
    eus: '"Galdera egin" gainean klikatu galderak egiteko hasi aurretik.'
  },
  SECTION_FILTER: {
    es: 'Tema',
    en: 'Section',
    cat: 'Tema',
    eus: 'Atala'
  },
  SECTION_FILTER_PLACEHOLDER: {
    es: 'Selecciona un tema para filtrar',
    en: 'Select a section to filter',
    cat: 'Selecciona un tema per filtrar',
    eus: 'Atala hautatu iragazteko'
  },
  DELETE_FILTERS: {
    es: 'Borrar filtros',
    en: 'Reset filters',
    cat: 'Reinicia els filtres',
    eus: 'Iragazkiak berrezarri'
  },
  TICKET_HAS_BEEN_SET_AS_RESOLVED: {
    es: 'El ticket ha sido marcado como resuelto',
    en: 'The ticket has been set as resolved',
    cat: 'El tiquet s’ha marcat com a resolt',
    eus: 'Tiketa ebazten markatuta dago'
  },
  TICKETS_FORM_ADVICE: {
    es: 'Solo puedes subir archivos y texto por separado',
    en: 'You can only upload files and text separately',
    cat: 'Només pots pujar arxius i text per separat',
    eus: 'Bakarrik fitxategiak eta testua aldi berean igo dezakezu'
  },
  SEND_QUESTION: {
    es: 'Preguntar ahora',
    en: 'Ask now',
    cat: 'Pregunta ara',
    eus: 'Orain galdera egin'
  },
  SEND_QUESTION_TO_TEACHER: {
    es: 'Enviar pregunta a un profesor',
    en: 'Send question to a teacher',
    cat: 'Enviar pregunta a un professor',
    eus: 'Irakasle bati galdera bidali'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Busca una pregunta',
    en: 'Search a ticket',
    cat: 'Cerca una pregunta',
    eus: 'Bilatu galdera bat'
  },
  REMOVE_NOTE_CONFIRMATION: {
    es: '¿Estás seguro?',
    en: 'Are you sure?',
    cat: 'N’estàs segur?',
    eus: 'Zihur zaude?'
  },
  AI: {
    es: 'IA',
    en: 'AI',
    cat: 'IA',
    eus: 'IA'
  },
  FIRST_TIP: {
    es: '1. Sé claro y específico al formular la pregunta, evitando ambigüedades.',
    en: '1. Be clear and specific when asking the question, avoiding ambiguities.',
    cat: '1. Sigues clar i específic en formular la pregunta, evitant ambigüitats.',
    eus: '1. Zehaztu galdera formulatzen duzun bitartean argi eta zehatza izan, ambiguitateak saihestuz.'
  },
  SECOND_TIP: {
    es: '2. Utiliza lenguaje sencillo y concreto.',
    en: '2. Use simple and concrete language.',
    cat: '2. Utilitza llenguatge senzill i concret.',
    eus: '2. Erabili hizkera sinple eta zehatza.'
  },
  THIRD_TIP: {
    es: '3. Divide preguntas complejas en partes más pequeñas para obtener respuestas más precisas.',
    en: '3. Divide complex questions into smaller parts to get more accurate answers.',
    cat: '3. Divideix preguntes complexes en parts més petites per obtenir respostes més precises.',
    eus: '3. Zailtasun handiko galderak zati txikiagoetan banatu erantzun zehatzagoak lortzeko.'
  },
  TICKET_SOLVED: {
    es: 'Duda solucionada',
    en: 'Question solved',
    cat: 'Dubte solucionat',
    eus: 'Galdera ebaztua'
  },
  GET_TEACHER_ANSWER: {
    es: 'Que me responda un profesor',
    en: 'Get a teacher answer',
    cat: 'Que em respongui un professor',
    eus: 'Irakasle batek erantzun'
  },
  GENERATING_ANSWER: {
    es: 'Estoy leyendo tu pregunta, el temario, apuntes e información que necesito para responder tu pregunta a la velocidad de la luz ⚡',
    en: 'I am reading your question, the syllabus, notes and information I need to answer your question at the speed of light ⚡',
    cat: 'Estic llegint la teva pregunta, el temari, apunts i informació que necessito per respondre la teva pregunta a la velocitat de la llum ⚡',
    eus: 'Zure galdera, temarioa, apunteak eta informazioa irakurten ari naiz zure galdera argiaren abiadurarekin erantzuteko ⚡'
  },
  SOURCE_DOCUMENTS: {
    es: '😌 No es por ir de guay, pero... He generado esta respuesta a partir de fragmentos de estos apuntes:',
    en: '😌 Not to brag, but... I generated this answer from fragments of these notes:',
    cat: '😌 No és per anar de guai, però... He generat aquesta resposta a partir de fragments d’aquests apunts:',
    eus: '😌 Ez da guai egoteko, baina... Erantzun hau sortu dut apunte hauen zatiak erabiliz:'
  },
  ONLY_MY_TICKETS: {
    es: 'Sólo mis dudas',
    en: 'Only my tickets',
    cat: 'Només els meus dubtes',
    eus: 'Nire zalantzak bakarrik'
  },
  CANCEL_CREATE_TICKET: {
    es: 'Cancelar pregunta',
    en: 'Cancel question',
    cat: 'Cancel·lar pregunta',
    eus: 'Utzi galdera'
  },
  REPHRASE_QUESTION: {
    es: 'Reformular pregunta',
    en: 'Rephrase question',
    cat: 'Reformular pregunta',
    eus: 'Berformulatu galdera'
  },
  WRITE_YOUR_QUESTION: {
    es: 'Escribe tu pregunta',
    en: 'Write your question',
    cat: 'Escriu la teva pregunta',
    eus: 'Idatzi zure galdera'
  },
  WRITE_YOUR_QUESTION_TOOLTIP_1: {
    es: '<a>Al grano:</a> Sé claro y específico al formular la pregunta, evitando ambigüedades.',
    en: '<a>Get to the point:</a> Be clear and specific when asking the question, avoiding ambiguities.',
    cat: '<a>Al gra:</a> Sigues clar i específic en formular la pregunta, evitant ambigüitats.',
    eus: '<a>Argi eta zehatza:</a> Zehaztu galdera formulatzen duzun bitartean argi eta zehatza izan, ambiguitateak saihestuz.'
  },
  WRITE_YOUR_QUESTION_TOOLTIP_2: {
    es: '<a>Escribe bien</a>: Utiliza un lenguaje sencillo y correcto.',
    en: '<a>Write well:</a> Use simple and correct language.',
    cat: '<a>Escriu bé:</a> Utilitza un llenguatge senzill i correcte.',
    eus: '<a>Idatzi ondo:</a> Erabili hizkera sinple eta zuzena.'
  },
  WRITE_YOUR_QUESTION_TOOLTIP_3: {
    es: '<a>Evita preguntar varias cosas</a>: De una en una. Así será más fácil responderte.',
    en: '<a>Avoid asking several things</a>: One at a time. This way it will be easier to answer you.',
    cat: '<a>Evita preguntar diverses coses</a>: Una a una. Així serà més fàcil respondre’t.',
    eus: '<a>Ez galdezka hainbeste</a>: Bat bat. Horrela erantzun errazagoa izango da.'
  },
  WRITE_YOUR_QUESTION_TOOLTIP_4: {
    es: '<a>Tu pregunta será pública</a>: Así todos los usuarios podrán resolver antes sus dudas.',
    en: '<a>Your question will be public</a>: This way all users can solve their doubts sooner.',
    cat: '<a>La teva pregunta serà pública</a>: Així tots els usuaris podran resoldre abans els seus dubtes.',
    eus: '<a>Zure galdera publikoa izango da</a>: Horrela erabiltzaile guztiak bere zalantzak lehenago ebaz dezakete.'
  },
  DO_YOU_NEED_TECHNICAL_SUPPORT: {
    es: '¿Necesitas asistencia técnica?',
    en: 'Do you need technical support?',
    cat: 'Necessites assistència tècnica?',
    eus: 'Zure zalantzak teknikoki laguntza behar du?'
  },
  TECHNICAL_ASSITANCE_SUBTITLE: {
    es: 'Si tu duda no tiene que ver con lo que estás estudiando puedes preguntarnos aquí directamente',
    en: 'If your question is not related to what you are studying, you can ask us directly here',
    cat: 'Si la teva pregunta no té a veure amb el que estàs estudiant ens pots preguntar aquí directament',
    eus: 'Zure zalantzak zure ikasketekin ez du zerikusirik, hemen zuzenean galdetu dezakezu'
  },
  GET_ASSISTANCE: {
    es: 'Obtener asistencia',
    en: 'Get assistance',
    cat: 'Obtenir assistència',
    eus: 'Lortu laguntza'
  },
  UNUSEFUL_ANSWER_TITLE: {
    es: 'Uups... ¡Siento no haber podido responder tu duda! <a>Antes de mandársela a un profesor, ¿quieres hacer algún cambio?</a> Y solo recordarte que podrás ver la respuesta también en la sección de “Mis dudas”',
    en: 'Uups... I am sorry I could not answer your question! <a>Before sending it to a teacher, do you want to make any changes?</a> And just a reminder that you will be able to see the answer also in the "My questions" section',
    cat: 'Uups... Sent no haver pogut respondre la teva pregunta! <a>Abans d’enviar-la a un professor, vols fer algun canvi?</a> I només recordar-te que podràs veure la resposta també a la secció de “Els meus dubtes”',
    eus: 'Uups... Barkatu ezin izan dudarik erantzun! <a>Irakasle bati bidaltzen aurretik, aldatu nahi duzu?</a> Eta gogorarazi nahi dizugu erantzuna “Nire zalantzak” atalean ere ikus dezakezula'
  },
  COURSE_TEACHERS: {
    es: 'Profesores del curso:',
    en: 'Course teachers:',
    cat: 'Professors del curs:',
    eus: 'Ikastaroaren irakasleak:'
  },
  ESTIMATED_RESPONSE_TIME: {
    es: 'Tiempo estimado de respuesta:',
    en: 'Estimated response time:',
    cat: 'Temps estimat de resposta:',
    eus: 'Erantzunaren denbora estimatua:'
  },
  LESS_THAN_24_HOURS: {
    es: 'Menos de 24 horas',
    en: 'Less than 24 hours',
    cat: 'Menys de 24 hores',
    eus: '24 ordu baino gutxiago'
  },
  THIS_CONTENT_IS_LOCKED: {
    es: 'Ups, de momento este contenido está bloqueado.',
    en: 'Ups, this content is currently locked.',
    cat: 'Ups, de moment aquest contingut està bloquejat.',
    eus: 'Ups, une honetan eduki hau blokeatuta dago.'
  }
};
