import { IQuestion } from '../question';
import { RequestService } from '../request';

export class NebulonService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  //TODO: Make poster instead of fetcher
  public async generateAIQuestions(fileId: string) {
    return this.fetcher<IQuestion[]>(`/generate-questions/${fileId}`);
  }

  public async predictNumberOfQuestions(fileId: string) {
    return this.fetcher<number>(`/generate-questions/predict-number/${fileId}`);
  }
}
