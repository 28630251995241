import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useQuizSectionsByCourse = (courseId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['quizzes', 'sections-by-course', courseId],
    queryFn: () => QuizService.getQuizSectionsByCourse(courseId!),
    enabled: !!courseId
  });

  return {
    quizSections: data,
    isLoading,
    error
  };
};
