import styled from 'styled-components';

export const Wrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
  flex: 2;
  gap: 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: start;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const SkeletonImageWrapper = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;
