import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';

import { Wrapper, ToolbarWrapper } from './PdfViewer.styles';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

type Props = {
  url: string;
  height?: number | string;
};

const DEFAULT_HEIGHT = 300;
const WORKWER_VERSION = '2.16.105';

export const PdfViewer: React.FC<Props> = ({ url, height = DEFAULT_HEIGHT }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>
  });

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${WORKWER_VERSION}/build/pdf.worker.min.js`}>
      <Wrapper style={{ height, width: '-webkit-fill-available' }}>
        <ToolbarWrapper>
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        </ToolbarWrapper>
        <Viewer
          fileUrl={url}
          theme={{
            theme: 'dark'
          }}
          plugins={[toolbarPluginInstance]}
        />
      </Wrapper>
    </Worker>
  );
};
