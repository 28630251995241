import { IGenerateFreeModeQuiz } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useGenerateFreeModeQuiz = () => {
  const { t } = useTranslation('StudentQuizzesTranslations');
  const navigateTo = useNavigate();

  const { mutateAsync: generateFreeModeQuiz, isPending: isWorking } = useMutation({
    mutationFn: (values: IGenerateFreeModeQuiz) => QuizService.generateFreeModeQuiz(values)
  });

  const onFinishForm = async (values: IGenerateFreeModeQuiz) => {
    try {
      const { quiz, exactQuestions } = await generateFreeModeQuiz(values);
      if (!exactQuestions)
        Modal.info({
          centered: true,
          title: t('FREE_QUESTIONS_INFO_TITLE'),
          content: t('FREE_QUESTIONS_INFO_CONTENT')
        });
      navigateTo(`/tests/${quiz._id}`);
    } catch (error) {
      Modal.error({
        centered: true,
        title: t('FREE_QUESTIONS_ERROR_TITLE'),
        content: t('FREE_QUESTIONS_ERROR_CONTENT'),
        onOk: () => window.location.reload()
      });
    }
  };

  return {
    isWorking,
    onFinishForm
  };
};
