import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const DeliverableService = sdk.getService('DeliverableService');

export const useDeliverableDetail = (deliverableId: string | null) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['deliverables', deliverableId],
    queryFn: () => DeliverableService.getDeliverable(deliverableId!),
    enabled: !!deliverableId
  });

  return {
    deliverable: data,
    isLoading,
    error
  };
};
