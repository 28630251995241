import { Modal } from 'antd';

import { SubscriptionItem } from './SubscriptionItem';
import { Wrapper } from './SubscriptionSelectionModal.styles';

import { useMySubscriptions } from '@/store';

export const SubscriptionSelectionModal = () => {
  const { isOpen, filteredSubscriptions, setIsOpen } = useMySubscriptions();

  return (
    <Modal
      styles={{ body: { maxHeight: '80vh', overflowY: 'auto' } }}
      width={900}
      centered
      closable={false}
      onCancel={() => setIsOpen(false)}
      open={isOpen}
      footer={null}>
      <Wrapper expandgrid={(filteredSubscriptions?.length ?? 0) > 4}>
        {filteredSubscriptions
          ?.sort((a, b) => a.course.name.localeCompare(b.course.name))
          .map(subscription => (
            <SubscriptionItem key={subscription._id} subscription={subscription} />
          ))}
      </Wrapper>
    </Modal>
  );
};
