import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PromptService = sdk.getService('PromptService');

export const usePromptList = () => {
  const queryKey = ['prompts'];

  const { data: prompts, isLoading } = useQuery({
    queryKey,
    queryFn: () => PromptService.getPrompts()
  });

  return {
    prompts,
    isLoading
  };
};
