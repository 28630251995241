import { Select } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 30%;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
