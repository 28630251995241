import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  transform: translateY(50%);
  i.fa-image {
    font-size: 60px;
  }

  .ant-upload {
    border: 1px solid ${({ theme }) => theme.colors.black1};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-upload:hover {
    border: 1px dashed ${({ theme }) => theme.colors.black1};
  }
`;
