import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const ChatService = sdk.getService('ChatService');

export const useMutateChatMessage = () => {
  const queryClient = useQueryClient();
  const params = useParams();

  const { tutorId, studentId } = params;
  const queryKey = ['chat', tutorId, studentId];
  const onSuccess = () => queryClient.invalidateQueries({ queryKey });

  const { mutate: setAllMessagesAsRead, isPending: isUpdating } = useMutation({
    mutationFn: (chatId: string) => ChatService.setAllMessagesAsRead(chatId),
    onSuccess
  });

  return {
    setAllMessagesAsRead,
    isWorking: isUpdating
  };
};
