import { ETicketSelectOptions, ETicketStatus } from '@constants';
import { ITicketsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';
import { areObjectEquals } from '@/utils';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 3;

const TICKET_STATUS_LIST = Object.values(ETicketStatus);

const INITIAL_FILTERS = {
  type: ETicketSelectOptions.ALL,
  status: TICKET_STATUS_LIST
};

const TicketService = sdk.getService('TicketService');

interface IStore {
  page: number;
  limit: number;
  filters?: ITicketsFilters;
  search: string;
  setSearch: (search: string) => void;
  setLimit: (limit: number) => void;
  setFilters: (filters?: ITicketsFilters) => void;
  resetFilters: () => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  filters: INITIAL_FILTERS,
  setSearch: (search: string) =>
    set(state => ({ ...state, search, page: INITIAL_PAGE, limit: INITIAL_LIMIT })),
  setLimit: (limit: number) => set(state => ({ ...state, limit })),
  setFilters: (filters?: ITicketsFilters) =>
    set(state => ({
      ...state,
      page: INITIAL_PAGE,
      limit: INITIAL_LIMIT,
      filters: { ...state.filters, ...filters }
    })),
  resetFilters: () =>
    set(state => ({
      ...state,
      page: INITIAL_PAGE,
      limit: INITIAL_LIMIT,
      filters: INITIAL_FILTERS,
      search: ''
    }))
}));

type Props = {
  courseId?: string;
  sectionId?: string;
  activityId?: string;
};

export const useActivityTicketsList = ({ courseId, sectionId, activityId }: Props) => {
  const { page, limit, filters, search, setSearch, setLimit, setFilters, resetFilters } = store();
  const queryKey = [
    'tickets',
    `activity-${activityId}`,
    `section-${sectionId}`,
    `course-${courseId}`,
    `page-${page}`,
    `limit-${limit}`,
    search,
    JSON.stringify(filters)
  ];

  const queryFn = () => {
    return TicketService.getCourseTickets(
      search,
      { page, limit },
      { ...filters, course: courseId, section: sectionId, activity: activityId }
    );
  };

  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn
  });

  const loadMoreTickets = () => {
    setLimit(limit + INITIAL_LIMIT);
  };

  const hasInitialFilters =
    areObjectEquals(filters, INITIAL_FILTERS) && search === '' && filters?.issuer === undefined;

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    tickets: data?.docs ?? [],
    isLoading,
    error,
    pagination,
    filters,
    setFilters,
    search,
    setSearch,
    loadMoreTickets,
    resetFilters,
    hasInitialFilters
  };
};
