import { create as createStore } from 'zustand';

interface IStore {
  sectionIdToReview?: string;
  sectionCourseId?: string;
  setSectionIdToReview: (sectionId?: string) => void;
  setSectionCourseId: (sectionCourseId?: string) => void;
}

export const useSectionReviewModal = createStore<IStore>(set => ({
  setSectionIdToReview: (sectionIdToReview?: string) => set({ sectionIdToReview }),
  setSectionCourseId: (sectionCourseId?: string) => set({ sectionCourseId })
}));
