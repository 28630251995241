import styled, { css } from 'styled-components';

type WrapperProps = {
  color: string;
  background: string;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ color, background }) => css`
    display: flex;
    align-items: center;
    padding: 3px 7px;
    border-radius: 5px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${color};
    background: ${background};
    flex-shrink: 0;
    width: max-content;
    height: min-content;
  `
);
