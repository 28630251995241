import { EProductTourTargets } from '@constants';
import { useTranslation } from 'react-i18next';

import { MyExamGroups } from '@/components/MyExamGroups';
import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const ExamGroupsWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <WidgetBase id={EProductTourTargets.EXAM_GROUPS_WIDGET} title={t('MY_EXAM_GROUPS')} {...props}>
      <MyExamGroups />
    </WidgetBase>
  );
};
