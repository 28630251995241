import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSkipPlanModal } from '../../hooks/useSkipPlanModal';

import {
  ButtonsWrapper,
  ButtonWrapper,
  CurvedArrowVector,
  SkipPlanButton,
  StepperWrapper,
  StraightLeftArrowVector,
  StraightRightArrowVector,
  StyledButton,
  StyledUmyImg1,
  StyledUmyImg2,
  StyledUmyImg3
} from './OnboardingFooter.styles';

import CURVED_ARROW_VECTOR from '@/assets/purple_curved_arrow_vector.svg';
import STRAIGHT_ARROW_LEFT_VECTOR from '@/assets/purple_straight_left_arrow_vector.svg';
import STRAIGHT_ARROW_RIGHT_VECTOR from '@/assets/purple_straight_right_arrow_vector.svg';
import UMY_ONBOARDING_MARKETING from '@/assets/umy_bullet_point_summary.svg';
import UMY_ONBOARDING_OBJECTIVES from '@/assets/umy_onboarding_objectives.svg';
import UMY_ONBOARDING_WRONG_TIME from '@/assets/umy_onboarding_wrong_time.svg';
import { Icon } from '@/components/Icon';
import { FooterButtons } from '@/components/Planification';
import { Stepper } from '@/components/Stepper';
import { useIsMobile } from '@/hooks';
import { useMyPlanification } from '@/store';

type Props = {
  step: number;
  isPlatformReady: boolean;
  hasFinishedOnboarding: boolean;
  isDisabled: boolean;
  isWorking: boolean;
  handleNextStep: () => void;
  handleStepBack: () => void;
  handleEditPlanification: () => void;
};

const ONBOARDING_LENGTH = 8;

export const OnboardingFooter: React.FC<Props> = ({
  step,
  isPlatformReady,
  hasFinishedOnboarding,
  isDisabled,
  isWorking,
  handleNextStep,
  handleStepBack,
  handleEditPlanification
}) => {
  const { t } = useTranslation(['DefaultTranslations', 'StudentOnboardingTranslations']);
  const isMobile = useIsMobile('sm');
  const { setIsOpen } = useSkipPlanModal();
  const { planification, isCorrectTime } = useMyPlanification();

  const getButtonText = () => {
    switch (step) {
      case 0:
        return t('StudentOnboardingTranslations:START');
      case 7:
        return t('DefaultTranslations:PLAN_MY_STUDY');
      case 13:
        return t('StudentOnboardingTranslations:START_NOW');
      default:
        return t('DefaultTranslations:CONTINUE');
    }
  };

  const handleSkipPlan = () => setIsOpen(true);

  return (
    <>
      <ButtonWrapper step={step}>
        {hasFinishedOnboarding && (
          <>
            <CurvedArrowVector src={CURVED_ARROW_VECTOR} alt="Purple curved arrow vector" />
            <StraightRightArrowVector src={STRAIGHT_ARROW_RIGHT_VECTOR} alt="Purple arrow vector" />
          </>
        )}
        {step > 0 && step < 8 && (
          <StepperWrapper>
            <Stepper steps={ONBOARDING_LENGTH} step={step} />
          </StepperWrapper>
        )}
        {step === 7 && (
          <ButtonsWrapper>
            <Button block onClick={handleSkipPlan}>
              {t('DefaultTranslations:GO_TO_STUDY')}
            </Button>
            <StyledButton block type="primary" onClick={handleNextStep} step={step}>
              {getButtonText()}
            </StyledButton>
          </ButtonsWrapper>
        )}
        {step > 7 && step < 12 && (
          <SkipPlanButton onClick={handleSkipPlan}>
            {t('StudentOnboardingTranslations:SKIP_AND_STUDY')}
          </SkipPlanButton>
        )}
        {step > 8 && step < 12 && (
          <Flex gap={16}>
            <Button onClick={handleStepBack}>{t('DefaultTranslations:GO_BACK')}</Button>
            <Button
              type="primary"
              loading={isWorking}
              disabled={isDisabled}
              onClick={handleNextStep}>
              {getButtonText()}
            </Button>
          </Flex>
        )}
        {(step < 3 || (step > 5 && step < 7) || (step === 12 && !planification) || step > 12) && (
          <StyledButton
            icon={<Icon i="ArrowRight" />}
            type="primary"
            onClick={handleNextStep}
            disabled={isDisabled}
            step={step}>
            {getButtonText()}
          </StyledButton>
        )}
        {planification && isPlatformReady && step === 12 && (
          <FooterButtons handleEdit={handleEditPlanification} handleNext={handleNextStep} />
        )}
        {hasFinishedOnboarding && (
          <StraightLeftArrowVector
            src={STRAIGHT_ARROW_LEFT_VECTOR}
            alt="Purple straight arrow vector"
          />
        )}
      </ButtonWrapper>
      {!isMobile && (
        <>
          <StyledUmyImg1
            className={
              step === 1 ||
              step === 7 ||
              (step === 12 && isPlatformReady && planification && isCorrectTime)
                ? 'umy-image'
                : ''
            }
            src={UMY_ONBOARDING_OBJECTIVES}
            alt="Umy Onboarding Objectives"
          />
          <StyledUmyImg2
            className={step === 3 || step === 4 ? 'umy-image' : ''}
            src={UMY_ONBOARDING_MARKETING}
            alt="Umy Onboarding Marketing"
          />
          <StyledUmyImg3
            className={
              step === 12 && isPlatformReady && planification && !isCorrectTime ? 'umy-image' : ''
            }
            src={UMY_ONBOARDING_WRONG_TIME}
            alt="Umy Onboarding Wrong Time"
          />
        </>
      )}
    </>
  );
};
