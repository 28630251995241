import styled from 'styled-components';

export const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin: 0 0 25px 0;
  h4 {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-weight: 300;
    font-size: 20px;
    margin: 0;
  }
`;
