import { ITaskStatusLog } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  logs?: ITaskStatusLog[];
  setLogs: (logs?: ITaskStatusLog[]) => void;
}

export const useTaskLogsModal = createStore<IStore>(set => ({
  setLogs: logs => set({ logs })
}));
