import { ICheckAnswer } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuestionReviewService = sdk.getService('QuestionReviewService');

export const useMutateReviewQuestions = () => {
  const { mutate: submitAnswer, isPending } = useMutation({
    mutationFn: (check: ICheckAnswer) => QuestionReviewService.checkAnswer(check)
  });

  return {
    isWorking: isPending,
    submitAnswer
  };
};
