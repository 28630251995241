import { ICreatePrompt, IUpdatePrompt } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const PromptService = sdk.getService('PromptService');

export const useMutatePrompts = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('AdminPromptsTranslations');

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['prompts'] });

  const { mutate: createPrompt, isPending: isCreating } = useMutation({
    mutationFn: (newPrompt: ICreatePrompt) => PromptService.createPrompt(newPrompt),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMPT_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removePrompt, isPending: isRemoving } = useMutation({
    mutationFn: (promptId: string) => PromptService.removePrompt(promptId),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMPT_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: updatePrompt, isPending: isUpdating } = useMutation({
    mutationFn: (prompt: IUpdatePrompt) => PromptService.updatePrompt(prompt),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMPT_UPDATED_SUCCESSFULLY'));
    }
  });

  return {
    createPrompt,
    removePrompt,
    updatePrompt,
    isWorking: isCreating || isRemoving || isUpdating
  };
};
