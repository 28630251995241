import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

export const ItemWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: ${({ theme, isSelected }) =>
    isSelected ? `2px solid ${theme.colors.purple6}` : `1px solid ${theme.colors.black5}`};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.purple2 : theme.colors.white};
  transition: background 0.3s, border 0.3s;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
