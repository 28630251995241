import { ITutoringGroup } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  data?: Partial<ITutoringGroup>;
  isOpen: boolean;
  setData: (data?: Partial<ITutoringGroup>) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useTutoringGroupFrom = createStore<IStore>(set => ({
  isOpen: false,
  setData: (data?: Partial<ITutoringGroup>) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
