import { IScrappedExamsFilter } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const ScrappedExamService = sdk.getService('ScrappedExamService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filter: IScrappedExamsFilter;
  setFilter: (filter: IScrappedExamsFilter) => void;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  filter: { onlyStateExams: false },
  setFilter: (filter: IScrappedExamsFilter) =>
    set(state => ({ ...state, page: INITIAL_PAGE, filter })),
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

export const useScrappedExamsList = () => {
  const { page, limit, search, setPageAndLimit, setSearch, filter, setFilter } = store();
  const queryKey = ['scrapped-exams', `page-${page}`, `limit-${limit}`, search, filter];

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => ScrappedExamService.getScrappedExams(search, { page, limit }, filter)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    scrappedExams: data?.docs,
    isLoading,
    pagination,
    setPageAndLimit,
    search,
    setSearch,
    filter,
    setFilter
  };
};
