import { EFeatures } from '@constants';
import { dateProvider } from '@utils';
import { Avatar, Badge } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  TitleWrapper,
  MessageWrapper,
  InfoWrapper,
  SenderWrapper,
  NameWrapper,
  DateWrapper,
  ContentWrapper
} from './ChatWidget.styles';
import { EmptyChatMessages } from './EmptyChatMessages';

import { LoadingCircleSpinner } from '@/components/Loaders';
import { WidgetBase } from '@/components/WidgetBase';
import { useTagFeatures } from '@/hooks';
import { useCurrentStudentTutorDetail, useProfile, useStudentPendingChatMessages } from '@/store';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

const AVATAR_SIZE = 32;

export const ChatWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { canRender } = useTagFeatures();
  const { pendingMessages, isLoading } = useStudentPendingChatMessages();

  const { profile } = useProfile();
  const { tutor, isLoading: isLoadingTutor } = useCurrentStudentTutorDetail();
  const navigateTo = useNavigate();

  const onClick = () => {
    if (!tutor || !profile) return;
    navigateTo(`/chat/${tutor._id}/${profile._id}`);
  };

  const lastMessage = useMemo(() => {
    if (!pendingMessages) return null;
    return pendingMessages.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )[0];
  }, [pendingMessages]);

  if (!canRender(EFeatures.TUTORING) || (!isLoadingTutor && !tutor)) return null;

  return (
    <WidgetBase
      {...props}
      title={
        <TitleWrapper>
          <div>{t('CHAT_WIDGET_TITLE')}</div>
          <Badge count={pendingMessages?.length} />
        </TitleWrapper>
      }>
      <Wrapper onClick={onClick}>
        {isLoading && <LoadingCircleSpinner />}
        {!isLoading && pendingMessages && pendingMessages.length === 0 && <EmptyChatMessages />}
        {lastMessage && (
          <MessageWrapper>
            <Avatar size={AVATAR_SIZE} src={lastMessage.sender.avatar?.url}>
              {!lastMessage.sender.avatar?.url && lastMessage.sender.name[0]}
            </Avatar>
            <InfoWrapper>
              <SenderWrapper>
                <NameWrapper>{lastMessage.sender.name}</NameWrapper>
                <DateWrapper>{dateProvider.format(lastMessage.createdAt, 'HH:mm')}</DateWrapper>
              </SenderWrapper>
              <ContentWrapper dangerouslySetInnerHTML={{ __html: lastMessage.content ?? '' }} />
            </InfoWrapper>
          </MessageWrapper>
        )}
      </Wrapper>
    </WidgetBase>
  );
};
