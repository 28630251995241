import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.green6};
    border-color: ${({ theme }) => theme.colors.green6};
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form {
    width: 100%;
  }
`;

export const InputTitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black7};
`;
