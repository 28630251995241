export enum EProfileTabs {
  SUMMARY = 'SUMMARY',
  PROFILE = 'PROFILE',
  TRAINING_INFO = 'TRAINING_INFO',
  CERTIFICATES = 'CERTIFICATES',
  EXAM_GROUPS = 'EXAM_GROUPS',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  EXAMS_SCORES = 'EXAMS_SCORES',
  PLANIFICATION = 'PLANIFICATION'
}
