export const useLocalStorage = () => {
  const getString = (key: string): string | null => {
    const item = localStorage.getItem(key);
    if (item === null) {
      return null;
    }

    return item;
  };

  const getNumber = (key: string): number | null => {
    const item = getString(key);
    if (item === null) {
      return null;
    }

    const n = Number(item);
    if (isNaN(n)) {
      console.error(`Item with key ${key} is not a number (value: ${item})`);
      return null;
    }

    return n;
  };

  const getObject = <T>(key: string): T | null => {
    const item = getString(key);
    if (item === null) {
      return null;
    }

    return JSON.parse(item) as T;
  };

  const save = (key: string, item: string | object): void => {
    let itemString: string;
    if (typeof item !== 'string') {
      itemString = JSON.stringify(item);
    } else {
      itemString = item;
    }

    try {
      localStorage.setItem(key, itemString);
    } catch (e) {
      console.error('The Storage is full!');
    }
  };

  const remove = (key: string): void => {
    localStorage.removeItem(key);
  };

  return { getString, getNumber, getObject, save, remove };
};
