import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 60px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding: unset;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    flex-direction: row;
  }
`;

export const ReturnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black5};
  cursor: pointer;
  margin-bottom: 16px;

  :hover {
    color: ${({ theme }) => theme.colors.black9};
  }
`;
