import styled from 'styled-components';

export const ShadowTags = styled.div`
  width: 50px;
  background: rgb(255 255 255 / 37%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgb(255 255 255 / 16%), rgb(255 255 255));
`;
