import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 26px;
  margin-bottom: 20px;

  .ant-empty {
    .ant-empty-image {
      height: 138px;
      & > div {
        height: 172px;
      }
    }
  }
`;
