import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';
import { calculateTodayGamificationPoints } from '@/utils';

const GamificationService = sdk.getService('GamificationService');

export const useStudentGamificationRewards = (studentId: string) => {
  const queryKey = ['gamification', studentId];

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => GamificationService.getUserRewards(studentId),
    enabled: !!studentId
  });

  return {
    rewards: data,
    todayGamificationPoints: calculateTodayGamificationPoints(data?.rewards),
    isLoading
  };
};
