import styled from 'styled-components';

export const StyledIframe = styled.iframe`
  border: 0;
  box-shadow: none;
  width: 100%;
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    height: 70vh;
  }
`;
