import { Form } from 'antd';
import Lottie from 'lottie-react';
import styled from 'styled-components';

import ONBOARDING_BACKGROUND_PNG from '@/assets/onboarding_background.png';

export const StyledForm: typeof Form = styled(Form)`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  background-image: url(${ONBOARDING_BACKGROUND_PNG});
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 380px) {
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: fit-content;
  width: 100vw;
  padding: 50px;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 850px;
    padding: 70px 40px 0px 40px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    overflow: visible;
    padding: 40px 60px 0px 60px;
  }

  .umy-image {
    bottom: 0;
  }
`;

export const SlidesWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

export const SlideWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 90vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 700px;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 0;
  }
`;

export const StyledLottie = styled(Lottie)`
  width: 160px;
  height: 160px;
  align-self: center;
`;
