export enum ETicketStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED_NOT_RESOLVED = 'CLOSED_NOT_RESOLVED',
  CLOSED_RESOLVED = 'CLOSED_RESOLVED'
}

export enum ETicketSelectOptions {
  ALL = 'ALL',
  SECTION = 'SECTION',
  ACTIVITY = 'ACTIVITY'
}

export enum ETicketVisibilityOptions {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export enum ETicketFilter {
  ALL = 'ALL',
  OPEN = 'OPEN'
}

export const TICKET_TYPE_QUERY_MAP = {
  [ETicketSelectOptions.ALL]: 'ticketRelatedContent.course',
  [ETicketSelectOptions.SECTION]: 'ticketRelatedContent.section',
  [ETicketSelectOptions.ACTIVITY]: 'ticketRelatedContent.activity'
};
