import { create as createStore } from 'zustand';

interface IStore {
  logs: string[];
  setLogs: (logs: string[]) => void;
}

export const useLogs = createStore<IStore>(set => ({
  logs: [],
  setLogs: (logs: string[]) => set({ logs })
}));
