import { ICreateProblemReport, IUpdateProblemReportStatus } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ProblemReportService = sdk.getService('ProblemReportService');

export const useMutateProblemReports = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('ProblemReportsTranslations');

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['problem-reports'] });

  const { mutate: reportProblem, isPending: isCreating } = useMutation({
    mutationFn: async (create: ICreateProblemReport) =>
      ProblemReportService.createProblemReport(create),
    onSuccess: () => message.success(t('REPORT_SENT_SUCCESSFULLY'))
  });

  const { mutate: updateStatus, isPending: isUpdatingStatus } = useMutation({
    mutationFn: async (update: IUpdateProblemReportStatus) =>
      ProblemReportService.updateProblemReportStatus(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('STATUS_SUCCESSFULLY_UPDATED'));
    }
  });

  const { mutate: removeProblemReport, isPending: isRemoving } = useMutation({
    mutationFn: async (removeId: string) => ProblemReportService.removeProblemReport(removeId),
    onSuccess: () => {
      onSuccess();
      message.success(t('REPORT_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    reportProblem,
    updateStatus,
    removeProblemReport,
    isWorking: isCreating || isUpdatingStatus || isRemoving
  };
};
