import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PlanificationService = sdk.getService('PlanificationService');

export const usePlanification = (userId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['planification', userId],
    queryFn: () => PlanificationService.getPlanificationByUser(userId!),
    enabled: !!userId
  });

  return {
    planification: data,
    isLoading,
    error
  };
};
