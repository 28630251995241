import { useQuery } from '@tanstack/react-query';
import { parseCookies } from '@utils';

import { sdk } from '@/services';

const TutoringGroupService = sdk.getService('TutoringGroupService');

export const useCurrentStudentTutorDetail = () => {
  const { token } = parseCookies();

  const { isLoading, data, error } = useQuery({
    queryKey: ['tutoring-groups', 'me'],
    queryFn: () => TutoringGroupService.findCurrentStudentTutor(),
    enabled: !!token
  });

  return {
    tutor: data,
    isLoading,
    error
  };
};
