import { EExamTypes } from '@constants';

export const StudentProfileTranslations = {
  PROFILE: {
    es: 'Mi perfil',
    en: 'My profile',
    cat: 'El meu perfil',
    eus: 'Nire profila'
  },
  EDIT_PROFILE: {
    es: 'Editar perfil',
    en: 'Edit profile',
    cat: 'Editar perfil',
    eus: 'Editatu profila'
  },
  TRAINING_INFO: {
    es: 'Datos de tu formación',
    en: 'Your training data',
    cat: 'Dades de la teva formació',
    eus: 'Zure prestakuntzaren datuak'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena'
  },
  CERTIFICATES: {
    es: 'Mis certificados',
    en: 'My certificates',
    cat: 'Els meus certificats',
    eus: 'Nire egiaztagiriak'
  },
  PERSONAL_INFO: {
    es: 'Datos personales',
    en: 'Personal info',
    cat: 'Dades personals',
    eus: 'Datu pertsonalak'
  },
  EMAIL: {
    es: 'Correo electrónico',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa'
  },
  CONTRACT: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua'
  },
  CONTRACT_DETAIL: {
    es: 'Accede desde aquí a las condiciones del servicio',
    en: 'Click here to access the terms of service.',
    cat: 'Accedeix des d`aquí a les condicions del servei',
    eus: 'Sartu hemen zerbitzuaren baldintzak'
  },
  VIEW_CONTRACT: {
    es: 'Ver contrato',
    en: 'View contract',
    cat: 'Veure contracte',
    eus: 'Ikusi kontratua'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi'
  },
  PROFILE_UPDATED_SUCCESSFULLY: {
    es: '¡Perfil actualizado con éxito!',
    en: 'Profile updated successfully!',
    cat: 'Perfil actualitzat amb èxit!',
    eus: 'Profila ongi eguneratua!'
  },
  TRAINING_INFO_UPDATED_SUCCESSFULLY: {
    es: '¡Datos de tu formación actualizados con éxito!',
    en: 'Your training data has been successfully updated!',
    cat: 'Dades de la teva formació actualitzades amb èxit!',
    eus: 'Zure prestakuntzaren datuak ongi eguneratu dira!'
  },
  LANGUAGE: {
    es: 'Idioma',
    en: 'Language',
    cat: 'Idioma',
    eus: 'Hizkuntza'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  COURSES_SELECT: {
    es: 'Elige los cursos que quieras que se vean en la página de inicio',
    en: 'Choose the courses you want to be displayed on the home page',
    cat: 'Tria els cursos que vulguis que es vegin a la pàgina d`inici',
    eus: 'Aukeratu hasiera orrian ikusi nahi dituzun ikastaroak'
  },
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak'
  },
  EXAM_GROUPS_FINDER: {
    es: 'Buscador de convocatorias',
    en: 'Exam groups finder',
    cat: 'Cercador de convocatòries',
    eus: 'Deialdien bilatzailea'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad',
    en: 'Autonomous community',
    cat: 'Comunitat',
    eus: 'Autonomia erkidegoa'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Titulua'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  PUBLICATION_DATE: {
    es: 'Fecha de publicación',
    en: 'Publication date',
    cat: 'Data de publicació',
    eus: 'Argitaratze data'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  ADD: {
    es: 'Añadir',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu'
  },
  EXAM_GROUPS_EMPTY: {
    es: 'Aquí aparecerán las convocatorias a las que te apuntes. Todavía no has añadido ninguna convocatoria',
    en: 'Here you will see the exam groups you sign up for. You have not added any exam group yet',
    cat: 'Aquí apareixeran les convocatòries a les que t`apuntis. Encara no has afegit cap convocatòria',
    eus: 'Hemen agertuko dira deialdira apuntatzen zara. Oraindik ez duzu deialdirik gehitu'
  },
  LEAVE_EXAM_GROUP: {
    es: 'Abandonar convocatoria',
    en: 'Leave exam group',
    cat: 'Abandonar convocatòria',
    eus: 'Utzi deialdia'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  AT_LEAST_ONE_COURSE_MUST_BE_SELECTED: {
    es: 'Debes seleccionar al menos un curso',
    en: 'You must select at least one course',
    cat: 'Has de seleccionar almenys un curs',
    eus: 'Gutxienez ikastaro bat aukeratu behar duzu'
  },
  EXAM_GROUPS_TOTAL_COUNT: {
    es: '{{total}} convocatorias',
    en: '{{total}} exam groups',
    cat: '{{total}} convocatòries',
    eus: '{{total}} convocatorias'
  },
  LEAVE_GROUP_CONFIRMATION: {
    es: '¿Estás seguro de que quieres abandonar la convocatoria?',
    en: 'Are you sure you want to leave the exam group?',
    cat: 'Estàs segur que vols abandonar la convocatòria?',
    eus: 'Ziur zaude deialdia utzi nahi duzula?'
  },
  NOTIFICATION_SETTINGS: {
    es: 'Notificaciones',
    en: 'Notifications',
    cat: 'Notificacions',
    eus: 'Jakinarazpenak'
  },
  LATER: {
    es: 'Más adelante',
    en: 'Later',
    cat: 'Més endavant',
    eus: 'Geroago'
  },
  EXAM_GROUPS_NOTIFICATIONS_TOOLTIP: {
    es: 'Configura sobre qué Comunidades Autónomas quieres recibir notificaciones de Convocatorias',
    en: 'Configure which Autonomous Communities you want to receive notifications from',
    cat: 'Configura sobre quines Comunitats Autònomes vols rebre notificacions de Convocatòries',
    eus: 'Konfiguratu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso'
  },
  EXAM_GROUPS_NOTIFICATIONS_NAME: {
    es: 'Elige sobre qué Comunidades Autónomas quieres ser notificado',
    en: 'Choose which Autonomous Communities you want to be notified about',
    cat: 'Tria sobre quines Comunitats Autònomes vols ser notificat',
    eus: 'Hautatu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso'
  },
  TOTAL_PROGRESS: {
    es: 'Avance total',
    en: 'Total progress',
    cat: 'Progrés total',
    eus: 'Aurrerapen guztira'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota'
  },
  TOTAL_POINTS: {
    es: 'Puntuación total',
    en: 'Total points',
    cat: 'Puntuació total',
    eus: 'Puntuazio guztira'
  },
  BADGES: {
    es: 'Medallas',
    en: 'Badges',
    cat: 'Medalles',
    eus: 'Medalak'
  },
  DAILY_STREAK: {
    es: 'Racha diaria',
    en: 'Daily streak',
    cat: 'Ratxa diària',
    eus: 'Eguneroko erronka'
  },
  STREAK_DAYS: {
    es: '{{days}} días',
    en: '{{days}} days',
    cat: '{{days}} dies',
    eus: '{{days}} egun'
  },
  DAILY_COMPLETED_TASKS: {
    es: 'Tareas diarias completadas',
    en: 'Daily completed tasks',
    cat: 'Tasques diàries completades',
    eus: 'Eguneroko zereginak osatuak'
  },
  GAMIFICATION_INFO: {
    es: 'Consigue puntos de conocimiento para tener un extra en el ranking. Para saber cómo conseguir más, puedes preguntar por el chat.',
    en: 'Get knowledge points to have an extra in the ranking. To know how to get more, you can ask for the chat.',
    cat: 'Aconsegueix punts de coneixement per tenir un extra en el ranking. Per saber com aconseguir-ne més, pots preguntar pel xat.',
    eus: 'Lortu ezagutza puntuak rankingean gehigarri bat izateko. Gehiago lortzeko, txat bidez galdetu dezakezu.'
  },
  REFERRAL_PROMO_TITLE: {
    es: 'Gana 600€',
    en: 'Win 600€',
    cat: 'Guanya 600€',
    eus: '600€ irabazi'
  },
  REFERRAL_PROMO_SUBTITLE: {
    es: 'Invita a 4 amigos',
    en: 'Invite 4 friends',
    cat: 'Convida 4 amics',
    eus: 'Gonbidatu 4 lagun'
  },
  REFERRAL_PROMO_DESCRIPTION: {
    es: 'Gana {{amount}}€ por cada amigo que se venga a Ucademy',
    en: 'Win {{amount}}€ for each friend who comes to Ucademy',
    cat: 'Guanya {{amount}}€ per cada amic que vingui a Ucademy',
    eus: '{{amount}}€ irabazi lagun bakoitzeko Ucademyra etortzen denean'
  },
  REFERRAL_PROMO_BUTTON: {
    es: 'Invitar a un amigo',
    en: 'Invite a friend',
    cat: 'Convida un amic',
    eus: 'Gonbidatu lagun bat'
  },
  RANKING: {
    es: 'Ranking',
    en: 'Ranking',
    cat: 'Ranking',
    eus: 'Ranking'
  },
  GO_TO_RANKING: {
    es: 'Ir a los rankings',
    en: 'Go to rankings',
    cat: 'Anar als rankings',
    eus: 'Joan ranking-era'
  },
  NOT_JOINED_ANY_EXAM_GROUP: {
    es: '¡Ups!, todavía no te has unido a ninguna convocatoria',
    en: 'Oops!, you have not joined any exam group yet',
    cat: "Ups!, encara no t'has unit a cap convocatòria",
    eus: 'Ups!, oraindik ez zara deialdirik batera'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu'
  },
  MY_EXAM_GROUPS: {
    es: 'Mis convocatorias',
    en: 'My exam groups',
    cat: 'Les meves convocatòries',
    eus: 'Nire deialdiak'
  },
  HOW_DO_EXAM_GROUPS_WORK: {
    es: '¿Cómo funcionan las convocatorias?',
    en: 'How do exam groups work?',
    cat: 'Com funcionen les convocatòries?',
    eus: 'Nola funtzionatzen dute deialdiak?'
  },
  EXAM_GROUPS_EXPLANATION_1: {
    es: 'En Ucademy publicamos todas las convocatorias oficiales del BOE en la plataforma todos los días. Cuando el gobierno autonómico, provincial o estatal pública una convocatoria, no salen siempre todos los datos al principio. Algunos se actualizan más adelante. Cuando el gobierno correspondiente los actualiza, nosotros también.',
    en: 'At Ucademy we publish all the official BOE calls on the platform every day. When the regional, provincial or state government publishes a call, not all the data comes out at the beginning. Some are updated later. When the corresponding government updates them, we do too.',
    cat: 'A Ucademy publiquem totes les convocatòries oficials del BOE a la plataforma cada dia. Quan el govern autonòmic, provincial o estatal publica una convocatòria, no surten sempre totes les dades al principi. Algunes s`actualitzen més endavant. Quan el govern corresponent les actualitza, nosaltres també.',
    eus: 'Ucademyk egunero BOEko deialdi ofizial guztiak argitaratzen ditu plataforma. Autonomia, probintzia edo estatuko gobernuak deialdia argitaratzen duenean, ez dira beti datu guztiak agertzen hasieran. Batzuk geroago eguneratzen dira. Gobernu horrek eguneratzen dituenean, guk ere eguneratzen dugu.'
  },
  EXAM_GROUPS_EXPLANATION_2: {
    es: 'Así que no te preocupes si hay algún dato en el que pone “No disponible”. Lo actualizaremos en cuanto se publique, y si estás apuntado a la convocatoria, te lo notificaremos.',
    en: "So don't worry if there is any data that says 'Not available'. We will update it as soon as it is published, and if you are signed up for the call, we will notify you.",
    cat: "Així que no et preocupis si hi ha alguna dada que posa 'No disponible'. L'actualitzarem en quant es publiqui, i si estàs apuntat a la convocatòria, t'ho notificarem.",
    eus: 'Beraz, ez kezkatu "Ez dagoela" esaten duen edozein datu. Eguneratuko dugu argitaratzen denean, eta deialdian apuntatuta badagozu, jakinaraziko dizugu.'
  },
  EXAM_GROUPS_EXPLANATION_3: {
    es: 'Lo mismo con las convocatorias disponibles: Si no la encuentras o ves vacío el listado, es porque no ha salido.',
    en: 'The same with the available calls: If you do not find it or see the list empty, it is because it has not come out.',
    cat: 'El mateix amb les convocatòries disponibles: Si no la trobes o veus buit el llistat, és perquè no ha sortit.',
    eus: 'Berdin deialdiak eskuragarriak direnean: Ez baduzu aurkitzen edo zerrenda hutsa ikusten baduzu, ez da agertu.'
  },
  JOINED_ON: {
    es: 'Se unió en {{month}} de {{year}}',
    en: 'Joined on {{month}} of {{year}}',
    cat: 'Es va unir en {{month}} de {{year}}',
    eus: '{{year}}ko {{month}}an batu zen'
  },
  IN_WHICH_CA_DO_YOU_APPLY: {
    es: '¿En qué Comunidad Autónoma te presentas?',
    en: 'In which Autonomous Community do you apply?',
    cat: 'A quina Comunitat Autònoma et presentes?',
    eus: 'Zein Autonomia Erkidegotan aurkeztuko zara?'
  },
  WHAT_FIELD_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué rama quieres estudiar?',
    en: 'Which field do you want to study?',
    cat: 'Quina branca vols estudiar?',
    eus: 'Zein arlotan ikasi nahi duzu?'
  },
  WHAT_CAREER_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué carrera te gustaría estudiar?',
    en: 'Which career would you like to study?',
    cat: 'Quina carrera t`agradaria estudiar?',
    eus: 'Zein karrera ikasi nahi duzu?'
  },
  WHAT_SUBJECTS_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué asignaturas vas a estudiar?',
    en: 'Which subjects are you going to study?',
    cat: 'Quines assignatures estudiaràs?',
    eus: 'Zein ikasgai ikasiko dituzu?'
  },
  WHEN_ARE_YOU_GOING_TO_APPLY: {
    es: '¿Cuándo te vas a presentar?',
    en: 'When are you going to apply?',
    cat: 'Quan et presentaràs?',
    eus: 'Noiz aurkeztuko zara?'
  },
  WHAT_IS_YOUR_BIRTH_COUNTRY: {
    es: '¿Cuál es tu país de nacimiento?',
    en: 'What is your country of birth?',
    cat: 'Quin és el teu país de naixement?',
    eus: 'Zein da zure jaiotze-herria?'
  },
  WHAT_IS_YOUR_AGE: {
    es: '¿Cuántos años tienes?',
    en: 'How old are you?',
    cat: 'Quants anys tens?',
    eus: 'Zenbat urte dituzu?'
  },
  I_DONT_KNOW_YET: {
    es: 'Aún no lo sé',
    en: "I don't know yet",
    cat: 'Encara no ho sé',
    eus: 'Oraindik ez dakit'
  },
  HAVE_YOU_TAKEN_AN_OPPOSITION: {
    es: '¿Te has presentado alguna vez a una oposición?',
    en: 'Have you ever taken an opposition?',
    cat: 'T`has presentat alguna vegada a una oposició?',
    eus: 'Egin duzu inoiz oposizio bat?'
  },
  WHEN_DID_YOU_TAKE_IT: {
    es: '¿Cuándo te presentaste?',
    en: 'When did you take it?',
    cat: 'Quan et vas presentar?',
    eus: 'Noiz aurkeztu zinen?'
  },
  WHAT_OPPOSITION_DID_YOU_TAKE: {
    es: '¿A qué oposición?',
    en: 'What opposition did you take?',
    cat: 'A quina oposició?',
    eus: 'Zein oposizio?'
  },
  WHAT_STUDIES_DO_YOU_HAVE: {
    es: '¿Qué estudios tienes?',
    en: 'What studies do you have?',
    cat: 'Quins estudis tens?',
    eus: 'Zeintzuk dituzu ikasketak?'
  },
  WHAT_IS_YOUR_OCCUPATION: {
    es: '¿Cuál es tu profesión?',
    en: 'What is your occupation?',
    cat: 'Quina és la teva professió?',
    eus: 'Zein da zure profesioa?'
  },
  ARE_YOU_CURRENTLY_WORKING: {
    es: '¿Estás trabajando actualmente?',
    en: 'Are you currently working?',
    cat: 'Estàs treballant actualment?',
    eus: 'Lan egiten ari zara orain?'
  },
  WHAT_IS_YOUR_CURRENT_OCCUPATION: {
    es: '¿Cuál es tu profesión actual?',
    en: 'What is your current occupation?',
    cat: 'Quina és la teva professió actual?',
    eus: 'Zein da zure uneko profesioa?'
  },
  IN_WHICH_CA_DO_YOU_LIVE: {
    es: '¿En qué Comunidad Autónoma vives?',
    en: 'In which Autonomous Community do you live?',
    cat: 'A quina Comunitat Autònoma vius?',
    eus: 'Zein Autonomia Erkidegotan bizi zara?'
  },
  WHEN_DO_YOU_PREFER_LIVE_CLASSES: {
    es: '¿Cuándo prefieres que sean las clases en directo?',
    en: 'When do you prefer live classes?',
    cat: 'Quan prefereixes que siguin les classes en directe?',
    eus: 'Noiz nahi duzu direla klaseak zuzenean?'
  },
  CERTIFIED_GRADES_CERTIFICATE_OR_TITLE: {
    es: 'Certificado de notas o título compulsado',
    en: 'Certified grades certificate or title',
    cat: 'Certificat de notes o títol compulsat',
    eus: 'Nota egiaztatua edo titulua'
  },
  WE_NEED_IT_FOR_THE_CAM: {
    es: 'Lo necesitamos para la CAM (administración pública)',
    en: 'We need it for the CAM (public administration)',
    cat: 'Ho necessitem per la CAM (administració pública)',
    eus: 'Behar dugu CAM (administrazio publikoa)'
  },
  WE_NEED_IT_FOR_THE_CAM_IN_PDF_FORMAT: {
    es: 'Lo necesitamos para la CAM (administración pública) y en formato PDF.',
    en: 'We need it for the CAM (public administration) and in PDF format.',
    cat: 'Ho necessitem per la CAM (administració pública) i en format PDF.',
    eus: 'Behar dugu CAM (administrazio publikoa) eta PDF formatuan.'
  },
  DO_YOU_NEED_STUDENT_VISA: {
    es: '¿Necesitas visado de estudiante?',
    en: 'Do you need a student visa?',
    cat: 'Necessites visat d`estudiant?',
    eus: 'Behar duzu ikasle biza?'
  },
  ATTACH_THE_HOMOLOGATION_REQUEST: {
    es: 'Adjunta la solicitud de homologación',
    en: 'Attach the homologation request',
    cat: 'Adjunta la sol·licitud d`homologació',
    eus: 'Erantsi omologazio eskaera'
  },
  ENROLLMENT: {
    es: 'Matrícula',
    en: 'Enrollment',
    cat: 'Matrícula',
    eus: 'Matrikula'
  },
  YOUR_SOCIAL_SECURITY_NUMBER: {
    es: 'Tu número de la Seguridad Social',
    en: 'Your Social Security number',
    cat: 'El teu número de la Seguretat Social',
    eus: 'Zure Segurtasun Sozial zenbakia'
  },
  PHOTOCOPY_OF_YOUR_ID: {
    es: 'Fotocopia de tu DNI/NIE',
    en: 'Photocopy of your ID',
    cat: 'Fotocòpia del teu DNI/NIE',
    eus: 'Zure NIEren argazkia'
  },
  PORTRAIT_PHOTO: {
    es: 'Foto de carnet',
    en: 'Portrait photo',
    cat: 'Foto de carnet',
    eus: 'Argazki erretratu'
  },
  FRONT_AND_BACK_SIDES: {
    es: 'Parte delantera y trasera',
    en: 'Front and back sides',
    cat: 'Part frontal i posterior',
    eus: 'Aurrealdea eta atzealdea'
  },
  FRONTAL_BUST_PHOTO: {
    es: 'Foto del busto de frente, como la que figura en tu DNI o NIE.',
    en: 'Frontal bust photo, like the one on your ID.',
    cat: 'Foto del bust de front, com la que figura al teu DNI o NIE.',
    eus: 'Aurrealdeko busto argazkia, zure DNI edo NIEan agertzen den bezala.'
  },
  EXAMS_SCORES: {
    es: 'Notas de exámenes',
    en: 'Exam scores',
    cat: "Notes d'exàmens",
    eus: 'Ikasle-notak'
  },
  [EExamTypes.ONSITE]: {
    es: 'Exámenes presenciales',
    en: 'Onsite exams',
    cat: 'Exàmens presencials',
    eus: 'Presencialen examenak'
  },
  [EExamTypes.DELIVERABLE]: {
    es: 'Documentos entregables',
    en: 'Deliverable documents',
    cat: 'Documents entregables',
    eus: 'Entregatutako dokumentuak'
  },
  [EExamTypes.ONLINE]: {
    es: 'Exámenes online',
    en: 'Online exams',
    cat: 'Exàmens online',
    eus: 'Onlineen examenak'
  },
  MY_EXAMS_SCORES: {
    es: 'Mis notas de exámenes',
    en: 'My exam scores',
    cat: "Les meves notes d'exàmens",
    eus: 'Nire ikasle-notak'
  },
  MY_EXAMS_SCORES_DESCRIPTION: {
    es: 'Exámenes y archivos online y presenciales puntuados.',
    en: 'Online and onsite exams and documents scored.',
    cat: 'Exàmens i documents online i presencials qualificats.',
    eus: 'Onlineen eta presencialen examenak eta dokumentuak kalifikatuta.'
  },
  PLANIFICATION: {
    es: 'Planificación del estudio',
    en: 'Study plan',
    cat: 'Pla de treball',
    eus: 'Ikasketa plan'
  },
  REPLANIFY: {
    es: 'Replanificar',
    en: 'Replanify',
    cat: 'Replanificar',
    eus: 'Berreplanifikatu'
  },
  ARE_YOU_SURE_TO_REPLANIFY: {
    es: '¿Estás seguro de querer replanificar?',
    en: 'Are you sure you want to replanify?',
    cat: 'Estàs segur que vols replanificar?',
    eus: 'Ziur zaude replanifikatu nahi duzula?'
  },
  PLANIFICATION_RESULT_END_DATE: {
    es: 'Día de finalización del estudio según tu planificación:',
    en: 'Study end date according to your plan:',
    cat: "Data finalització de l'estudi segons el teu pla:",
    eus: 'Ikasketa bukaeraren data zure planifikazioaren arabera:'
  },
  PLANIFICATION_RESULT_DAILY_TIME: {
    es: 'Tiempo de estudio mínimo al día con tu planificación:',
    en: 'Minimum study time per day according to your plan:',
    cat: "Temps mínim d'estudi al dia segons el teu pla:",
    eus: 'Ikasketa-denbora minimoa eguneko zure planifikazioaren arabera:'
  },
  PLANIFICATION_REST_DAYS_TITLE: {
    es: 'Días que no vas a estudiar',
    en: 'Days you will not study',
    cat: 'Günler, en que no et vas a estudiar',
    eus: 'Ikasketa ez egiteko egunak'
  },
  PLANIFICATION_SUBSCRIPTIONS_TITLE: {
    es: 'Contenido de la planificación',
    en: 'Planification content',
    cat: 'Contingut del pla de treball',
    eus: 'Ikasketa planifikazioaren edukia'
  },
  PLANIFICATION_DATES_TITLE: {
    es: 'Planificar según...',
    en: 'Planify according to...',
    cat: 'Planificar segons...',
    eus: 'Planifikatu zehaztutako arabera...'
  },
  PLANIFICATION_DATES_STUDY_HOURS: {
    es: 'Horas de estudio al día',
    en: 'Study hours per day',
    cat: 'Estudi al dia',
    eus: 'Ikasketa-denbora egunekoa'
  },
  PLANIFICATION_DATES_END_DATE: {
    es: 'Fecha de finalización deseada',
    en: 'Desired study end date',
    cat: "Data final desitjada d'estudi",
    eus: 'Ikasketa bukaeraren data zehaztutakoa'
  },
  PLANIFICATION_DEACTIVATE_TITLE: {
    es: 'Desactivar planificación del estudio',
    en: 'Deactivate study plan',
    cat: 'Desactivar pla de treball',
    eus: 'Ikasketa planifikazioa desaktibatu'
  },
  PLANIFICATION_DEACTIVATE_SUBTITLE: {
    es: 'Dejarás de ver las tareas diarias recomendadas y tu planificación del contenido en el calendario',
    en: 'You will no longer see the daily recommended tasks and your planification content in the calendar',
    cat: 'No veurem les tasques diàries recomanades i el teu pla de treball en el calendari',
    eus: 'Ez duzu eguneko zerbitzuak aurreikusitakoak eta zure planifikazioaren edukia kalendarioan ikusi'
  },
  PLANIFICATION_DEACTIVATE_SWITCH_LABEL_ACTIVE: {
    es: 'Activada',
    en: 'Active',
    cat: 'Activada',
    eus: 'Aktibatua'
  },
  PLANIFICATION_DEACTIVATE_SWITCH_LABEL_INACTIVE: {
    es: 'Desactivada',
    en: 'Inactive',
    cat: 'Desactivada',
    eus: 'Desaktibatua'
  },
  PLANIFICATION_HOW_IT_WORKS: {
    es: '¿Cómo funciona la planificación?',
    en: 'How does the plan work?',
    cat: 'Com funciona el pla?',
    eus: 'Nola funtzionatzen du planifikazioa?'
  }
};
