import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  gap: 16px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  color: ${({ theme }) => theme.colors.black9};
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const SkeletonImageWrapper = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;
