import { EQuizMode } from '@constants';

export const StudentQuizzesTranslations = {
  CREATE_QUIZ: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear prova',
    eus: 'Sortu proba'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  CONTENT: {
    es: 'Cursos y temas que quieres que tenga el test',
    en: 'Courses and sections you want the test to cover',
    cat: 'Cursos i temes que vols que tingui el test',
    eus: 'Ikastaroak eta atalak zeukazun probak hartu nahi dituzu'
  },
  QUIZ_CREATED_SUCCESSFULLY: {
    es: 'Test creado correctamente, puedes verlo en la sección de test con el nombre: {{title}}',
    en: 'Test created successfully, you can see it in the test section with the name: {{title}}',
    cat: 'Prova creada amb èxit, la pots veure a la secció de proves amb el nom: {{title}}',
    eus: 'Proba ongi sortu da, proba atalean ikusi dezakezu izenarekin: {{title}}'
  },
  QUESTION_NUMBER: {
    es: 'Número de preguntas',
    en: 'Question number',
    cat: 'Nombre de preguntes',
    eus: 'Galdera kopurua'
  },
  TIMER: {
    es: 'Temporizador',
    en: 'Timer',
    cat: 'Temporitzador',
    eus: 'Denbora-aldia'
  },
  STUDY_TEST_MODE: {
    es: 'Modo repaso',
    en: 'Study mode',
    cat: 'Mode revisió',
    eus: 'Azterketa modoan'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  QUIZZES_TOTAL_COUNT: {
    es: '{{total}} elementos',
    en: '{{total}} elements',
    cat: '{{total}} elements',
    eus: '{{total}} elementu'
  },
  NO_QUIZZES_CREATED_OR_AVAILABLE: {
    es: 'No has creado ningún test o no hay test disponibles',
    en: 'You have not created any test or there are no tests available',
    cat: 'No has creat cap prova o no hi ha proves disponibles',
    eus: 'Ez duzu inongo proba sortu edo proba eskuragarriak ez daude'
  },
  CREATED_AT: {
    es: 'Fecha de creación',
    en: 'Created at',
    cat: 'Data de creació',
    eus: 'Sortu data'
  },
  DONE_AT: {
    es: 'Fecha de realización',
    en: 'Done at',
    cat: 'Data de realització',
    eus: 'Egina data'
  },
  QUIZ_MODE: {
    es: 'Tipo de test',
    en: 'Quiz type',
    cat: 'Tipus de prova',
    eus: 'Proba mota'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Modu libre'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Modo simulacro',
    en: 'Simulator mode',
    cat: 'Mode simulació',
    eus: 'Modu simulagailua'
  },
  [`${EQuizMode.FREE_MODE}_DESCRIPTION`]: {
    es: 'Crea a tu gusto infinitos tests para practicar cuanto quieras. Selecciona los temas que quieres que aparezcan y ¡a estudiar!',
    en: 'Create your own tests to practice as much as you want. Select the topics you want to appear and start studying!',
    cat: 'Crea les teves pròpies proves per practicar tot el que vulguis. Selecciona els temes que vulguis que apareguin i comença a estudiar!',
    eus: 'Sortu zure probak nahi duzun moduan praktikatzeko. Hautatu agertu nahi dituzun gaiak eta hasi ikasten!'
  },
  [`${EQuizMode.PRACTICE_MODE}_DESCRIPTION`]: {
    es: '¿Quieres probar cómo sería un examen real? Mismo tiempo y sistema de puntuación, para que llegues a la prueba preparado.',
    en: 'Do you want to try how a real exam would be? Same time and scoring system, so you arrive at the test prepared.',
    cat: 'Vols provar com seria un examen real? Mateix temps i sistema de puntuació, perquè arribis a la prova preparat.',
    eus: 'Nahi duzu proba erreal bat nola izango zen jakitea? Denbora eta puntuazio sistema berbera, proban prest heldu arte.'
  },
  SCORE: {
    es: 'Nota',
    en: 'Score',
    cat: 'Puntuació',
    eus: 'Puntuazioa'
  },
  OWNER: {
    es: 'Yo',
    en: 'Me',
    cat: 'Jo',
    eus: 'Ni'
  },
  OWNER_TITLE: {
    es: 'Creador',
    en: 'Owner',
    cat: 'Creador',
    eus: 'Sortzailea'
  },
  THIS_TEST_HAS_TIMER: {
    es: 'Este test lleva temporizador.',
    en: 'This test has a timer.',
    cat: 'Aquesta prova té temporitzador.',
    eus: 'Proba honetan denbora-aldia dago.'
  },
  SURE_TO_START_IT: {
    es: '¿Seguro que quieres comenzar ya?',
    en: 'Are you sure to start it right now?',
    cat: 'Segur que vols començar ara mateix?',
    eus: 'Ziur zaude orain hasi nahi duzula?'
  },
  ACCEPT: {
    es: 'Aceptar',
    en: 'Accept',
    cat: 'Accepta',
    eus: 'Onartu'
  },
  DELETE_QUIZ_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este test?',
    en: 'Are you sure you want to delete this test?',
    cat: "Estàs segur d'eliminar aquesta prova?",
    eus: 'Ziur zaude proba hau ezabatu nahi duzula?'
  },
  EXISTING_QUESTIONS: {
    es: 'Preguntas disponibles',
    en: 'Available questions',
    cat: 'Preguntes disponibles',
    eus: 'Galderak eskuragarri'
  },
  NON_EXISTING_QUESTIONS: {
    es: 'Por favor selecciona otro curso o sección',
    en: 'Please select another course or section',
    cat: 'Per favor selecciona un altre curs o secció',
    eus: 'Mesedez hautatu beste ikastaro bat edo atala'
  },
  NUMBER_OF_QUESTIONS_EXCEEDED: {
    es: 'El número de preguntas requeridas es mayor que las existentes',
    en: 'The number of required questions is greater than the existing ones',
    cat: 'El nombre de preguntes requerides és més gran que les existents',
    eus: 'Galderen kopurua eskatutakoa baino handiagoa da eskuragarriak'
  },
  YOUR_PROGRESS: {
    es: 'Tu progreso',
    en: 'Your progress',
    cat: 'El teu progrés',
    eus: 'Zure aurrerapena'
  },
  QUIZ_PROGRESS: {
    es: 'Progreso en tests',
    en: 'Progress in tests',
    cat: 'Progrés en proves',
    eus: 'Proben aurrerapena'
  },
  CORRECTS: {
    es: 'Aciertos',
    en: 'Corrects',
    cat: 'Encerts',
    eus: 'Zuzenak'
  },
  WRONGS: {
    es: 'Fallos',
    en: 'Wrongs',
    cat: 'Errors',
    eus: 'Akatsak'
  },
  BLANKS: {
    es: 'Blancos',
    en: 'Blanks',
    cat: 'En blanc',
    eus: 'Hutsik'
  },
  TOTAL_QUESTIONS: {
    es: 'Preguntas realizadas',
    en: 'Total questions',
    cat: 'Preguntes realitzades',
    eus: 'Galderak eginak'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota'
  },
  CONTENT_V2: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  KNOWLEDGE: {
    es: 'Conocimiento',
    en: 'Knowledge',
    cat: 'Coneixement',
    eus: 'Ezagutzak'
  },
  GAMIFICATION_INFO: {
    es: 'Consigue puntos de conocimiento para tener un extra en el ranking. Para saber cómo conseguir más, puedes preguntar por el chat.',
    en: 'Get knowledge points to have an extra in the ranking. To know how to get more, you can ask for the chat.',
    cat: 'Aconsegueix punts de coneixement per tenir un extra en el ranking. Per saber com aconseguir-ne més, pots preguntar pel xat.',
    eus: 'Lortu ezagutza puntuak rankingean gehigarri bat izateko. Gehiago lortzeko, txat bidez galdetu dezakezu.'
  },
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Proba'
  },
  FREE_MODE_TITLE: {
    es: 'Creador de tests',
    en: 'Test creator',
    cat: 'Creador de proves',
    eus: 'Proba sortzailea'
  },
  REVIEW_MODE_TITLE: {
    es: 'Modo repaso',
    en: 'Review mode',
    cat: 'Mode repàs',
    eus: 'Berrikusketa modua'
  },
  PRACTICE_MODE_TITLE: {
    es: 'Simulacros',
    en: 'Simulators',
    cat: 'Simulacres',
    eus: 'Simulakroak'
  },
  SELECT_COURSE: {
    es: 'Elige el curso',
    en: 'Choose the course',
    cat: 'Tria el curs',
    eus: 'Aukeratu ikastaroa'
  },
  SELECT_SECTION: {
    es: 'Elige el temario',
    en: 'Choose the section',
    cat: 'Tria el temari',
    eus: 'Aukeratu gaiak'
  },
  SELECT_NUMBER_OF_QUESTIONS: {
    es: 'Elige el número de preguntas',
    en: 'Choose the number of questions',
    cat: 'Tria el nombre de preguntes',
    eus: 'Aukeratu galderen kopurua'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  GENERATING_QUESTIONS: {
    es: 'Generando preguntas...',
    en: 'Generating questions...',
    cat: 'Generant preguntes...',
    eus: 'Galderak sortzen...'
  },
  FREE_QUESTIONS_INFO_TITLE: {
    es: 'Información sobre las preguntas',
    en: 'Information about questions',
    cat: 'Informació sobre les preguntes',
    eus: 'Galderen informazioa'
  },
  FREE_QUESTIONS_INFO_CONTENT: {
    es: 'La cantidad de preguntas es menor a la solicitada.',
    en: 'The number of questions is less than requested.',
    cat: 'La quantitat de preguntes és menor a la sol·licitada.',
    eus: 'Galderen kopurua eskatutakoa baino txikiagoa da.'
  },
  FREE_QUESTIONS_ERROR_TITLE: {
    es: 'Error al generar preguntas',
    en: 'Error generating questions',
    cat: 'Error al generar preguntes',
    eus: 'Errorea galderak sortzean'
  },
  FREE_QUESTIONS_ERROR_CONTENT: {
    es: 'Ha ocurrido un error al generar las preguntas. Por favor, inténtelo de nuevo.',
    en: 'An error has occurred while generating the questions. Please try again.',
    cat: 'Ha ocorregut un error al generar les preguntes. Si us plau, intenti-ho de nou.',
    eus: 'Errorea gertatu da galderak sortzean. Saiatu berriro, mesedez.'
  },
  LOADING_SECTIONS: {
    es: 'Cargando temas con preguntas...',
    en: 'Loading topics with questions...',
    cat: 'Carregant temes amb preguntes...',
    eus: 'Galderak dituzten gaiak kargatzen...'
  },
  REVIEW_MODE_TOTAL_FAILED_QUESTIONS: {
    es: '{{total}} preguntas falladas',
    en: '{{total}} failed questions',
    cat: '{{total}} preguntes fallades',
    eus: '{{total}} galderek huts egin dira'
  },
  PRACTICE_TESTS: {
    es: 'Simulacros',
    en: 'Simulator tests',
    cat: 'Simulacres',
    eus: 'Simulakroak'
  },
  PRACTICE_QUIZZES_TOTAL_COUNT: {
    es: '{{total}} tests',
    en: '{{total}} tests',
    cat: '{{total}} tests',
    eus: '{{total}} tests'
  },
  NO_PRACTICE_QUIZZES: {
    es: 'No hay simulacros disponibles',
    en: 'No simulator tests available',
    cat: 'No hi ha simulacres disponibles',
    eus: 'Ez daude simulakroak eskuragarri'
  },
  TAGS: {
    es: 'Formación',
    en: 'Category',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  FREE_MODE_TESTS: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Modo libre'
  },
  QUIZ_MODES: {
    es: 'Modos de práctica',
    en: 'Practice modes',
    cat: 'Modes de pràctica',
    eus: 'Praktika moduak'
  },
  QUIZ_HISTORY: {
    es: 'Historial de tests realizados',
    en: 'History of tests taken',
    cat: 'Historial de proves realitzades',
    eus: 'Egin diren proben historia'
  },
  COMPLETION_DATE: {
    es: 'Fecha de realización',
    en: 'Completion date',
    cat: 'Data de realització',
    eus: 'Egina data'
  },
  QUIZ_COMPLETED: {
    es: 'Test realizado',
    en: 'Test completed',
    cat: 'Prova realitzada',
    eus: 'Proba egin da'
  },
  LAST_SCORE: {
    es: 'Última nota',
    en: 'Last score',
    cat: 'Darrera puntuació',
    eus: 'Azken puntuazioa'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  QUIZ_CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  REVIEW: {
    es: 'Revisar',
    en: 'Review',
    cat: 'Revisar',
    eus: 'Berrikusi'
  },
  RETAKE: {
    es: 'Repetir',
    en: 'Retake',
    cat: 'Repetir',
    eus: 'Errepikatu'
  },
  QUIZ_HISTORY_TOTAL: {
    es: '{{total}} tests realizados',
    en: '{{total}} tests taken',
    cat: '{{total}} proves realitzades',
    eus: '{{total}} probak egin dira'
  },
  RETURN_HOME: {
    es: 'Volver al inicio',
    en: 'Return to home',
    cat: 'Torna a inici',
    eus: 'Itzuli has'
  },
  HAS_REACHED_MAX_RETRIES: {
    es: 'Número máximo de intentos alcanzado',
    en: 'You have reached the maximum of retries',
    cat: 'Has assolit el màxim d`intents',
    eus: 'Saio kopurua maximoa da'
  }
};
