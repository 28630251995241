import { dateProvider } from '@utils';
import { TFunction } from 'i18next';

export const getFormattedDatesDifference = (
  date1: Date | string,
  date2: Date | string,
  t: TFunction
): string => {
  const dateParsed1 = dateProvider.parse(date1);
  const dateParsed2 = dateProvider.parse(date2);

  const difference = Math.abs(dateParsed1.getTime() - dateParsed2.getTime());
  const minutes = Math.floor(difference / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  const resultArr: string[] = [];

  const monthsFormatted = months % 12;
  const daysFormatted = days % 30;
  const hoursFormatted = hours % 24;
  const minutesFormatted = minutes % 60;

  if (years > 0) resultArr.push(t('RESOLUTION_TIME_YEARS', { years }));
  if (monthsFormatted > 0) resultArr.push(t('RESOLUTION_TIME_MONTHS', { months: monthsFormatted }));
  if (daysFormatted > 0) resultArr.push(t('RESOLUTION_TIME_DAYS', { days: daysFormatted }));
  if (hoursFormatted > 0) resultArr.push(t('RESOLUTION_TIME_HOURS', { hours: hoursFormatted }));
  if (minutesFormatted > 0)
    resultArr.push(t('RESOLUTION_TIME_MINUTES', { minutes: minutesFormatted }));

  return resultArr.join(' ');
};
