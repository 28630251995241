import { Button } from 'antd';
import styled from 'styled-components';

type CommentCountProps = {
  disabled: boolean;
};

export const AttachmentCountWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CommentCountWrapper = styled.div<CommentCountProps>`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.black4 : theme.colors.black9)};
`;

export const TitleWrapper = styled.div`
  display: flex;

  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TitleText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const GamificationWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.orange6};
  display: flex;
  align-items: center;
`;
