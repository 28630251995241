import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const StyledButton = styled(Button)`
  z-index: 10;
  border-radius: 50px;
`;
