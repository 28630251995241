import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useStudentSubscriptions = (studentId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['students', 'subscriptions', studentId],
    queryFn: () => SubscriptionService.getUserSubscriptions(studentId)
  });

  return {
    subscriptions: data,
    isLoading
  };
};
