import { EActivityAuditStatus, EActivityType } from '@constants';

import { defaultTheme } from '../styles/theme';

import { EIconType } from '@/components/Icon';

export const ACTIVITY_ICONS: { [key in EActivityType]: EIconType } = {
  [EActivityType.VIDEO]: 'Video',
  [EActivityType.PDF]: 'Document',
  [EActivityType.INTERACTIVE_VIDEO]: 'TouchInteraction',
  [EActivityType.DELIVERABLE]: 'DocumentExport',
  [EActivityType.QUIZ]: 'TestTool'
};

export const ACTIVITY_AUDIT_STATUS_TAG_INFO: Record<
  EActivityAuditStatus,
  { color: string; icon: EIconType }
> = {
  [EActivityAuditStatus.CORRECT]: {
    color: defaultTheme.colors.green6,
    icon: 'Checkmark'
  },
  [EActivityAuditStatus.OBSOLETE]: {
    color: defaultTheme.colors.black7,
    icon: 'PortOutput'
  },
  [EActivityAuditStatus.OLD_FORMAT]: {
    color: defaultTheme.colors.purple6,
    icon: 'ResultOld'
  },
  [EActivityAuditStatus.NON_COMPLIANT_STANDARDS]: {
    color: defaultTheme.colors.red6,
    icon: 'ErrorFilled'
  }
};
