import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
  value?: string;
  onChange?: (content: string) => void;
  disabled?: boolean;
};

export const HtmlEditor: React.FC<Props> = ({ value, onChange, disabled = false }) => {
  const handleChange = (content: string) => {
    onChange?.(content);
  };

  return <ReactQuill value={value} onChange={handleChange} readOnly={disabled} />;
};
