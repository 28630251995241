import { RequestService } from '../request';

import { ICreateExamResult, IExamResult, IUpdateExamResult } from './exam-result.contract';

export class ExamResultService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async createExamResult(data: ICreateExamResult) {
    return this.poster<IExamResult, ICreateExamResult>('/exam-results', data);
  }

  async updateExamResult(data: IUpdateExamResult) {
    return this.putter<IExamResult, ICreateExamResult>(`/exam-results/${data._id}`, data);
  }

  async findExamResultByStudent(examId: string, studentId: string) {
    return this.fetcher<IExamResult>(`/exam-results/student/${studentId}/exam/${examId}`);
  }
}
