import styled from 'styled-components';

export const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  & > :first-child {
    padding-right: 5px;
  }
  padding: 12px 0;
  border-bottom: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.green6 : 'transparent')};

  & > :last-child {
    transition: all 0.2s ease-in-out;
  }
`;
