import { IPlanification } from '@sdk/contracts';
import { dateProvider } from '@utils';

import { PLANIFICATION_EVENTS_COLORS } from '@/constants';

export const getPlanificationEvents = (data?: IPlanification) => {
  if (!data || !data.items.length || !data.active) return [];

  const eventsMap = new Map<
    string,
    { id: string; title: string; date: Date; allDay: boolean; color: string }
  >();
  let colorIndex = 0;

  data.items
    .map(item => {
      const subscription = data.subscriptions.find(s => s._id === item.subscription);
      const section = subscription?.course.sections.find(s => s._id === item.section);
      const activity = section?.activities.find(a => a._id === item.activity);
      return {
        ...item,
        activity,
        section,
        subscription
      };
    })
    .forEach(item => {
      const date = dateProvider.format(item.dueDate, 'yyyy-MM-dd');
      const title =
        data.subscriptions.length > 1 ? item.subscription!.course.name : item.section!.name;
      const key = `${date}-${title}`;
      if (!eventsMap.has(key)) {
        eventsMap.set(key, {
          id: key,
          title,
          date: item.dueDate,
          allDay: true,
          color: PLANIFICATION_EVENTS_COLORS[colorIndex]
        });
        colorIndex = colorIndex === PLANIFICATION_EVENTS_COLORS.length - 1 ? 0 : colorIndex + 1;
      }
    });

  return Array.from(eventsMap.values()).flat();
};
