import { StepButtonContainer, StyledLi, StyledUl } from './Stepper.styles';

interface Props {
  steps: number;
  step: number;
  onStepClicked?: (step: number) => void;
}

export const Stepper: React.FC<Props> = ({ steps, step, onStepClicked }) => {
  return (
    <>
      <StepButtonContainer>
        <StyledUl>
          {Array(steps)
            .fill('')
            .map((_, index) => (
              <StyledLi
                selected={index === step}
                key={index}
                onClick={() => {
                  onStepClicked?.(index);
                }}
              />
            ))}
        </StyledUl>
      </StepButtonContainer>
    </>
  );
};
