import styled from 'styled-components';

export const MessageSenderWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    gap: 16px;
  }

  .ant-input {
    border-radius: 4px;
  }
`;
