import { Badge } from 'antd';

import { useStudentPendingChatMessages } from '@/store';

export const PendingChatsBadge = () => {
  const { pendingMessages } = useStudentPendingChatMessages();
  if (!pendingMessages) return null;

  return <Badge dot={pendingMessages.length > 0} />;
};
