import { Flex, Tag } from 'antd';
import styled from 'styled-components';

export const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const TagWrapper = styled(Tag)`
  width: fit-content;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.black2};
  margin-inline-end: 0 !important;
`;

export const DividerWrapper = styled(Flex)`
  width: 100%;

  .ant-divider-horizontal {
    margin: 0 0 8px 0;
  }
`;

export const ItemWrapper = styled(Flex)`
  width: 100%;
  padding: 16px 0;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.black2};
  }
`;

export const SectionWrapper = styled.span`
  align-self: stretch;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const TextWrapper = styled.span`
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
