import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  video {
    position: relative;
  }
`;

export const Description = styled.p`
  margin-top: 24px;
  height: 130px;
  overflow: scroll;
  padding: 10px 15px;
  box-shadow: inset 0px -4px 3px 1px rgb(0 0 0 / 5%);
  text-align: justify;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
