import { IFile } from '@sdk/contracts';
import { Image } from 'antd';
import isEmpty from 'lodash/isEmpty';
import ReactPlayer from 'react-player';

import DeleteUpload from '../DeleteUpload/DeleteUpload';

import { UploadItem } from './UploadPreview.style';

import { AudioPlayer } from '@/components/AudioPlayer';
import { Icon } from '@/components/Icon';
import { isImage, isVideo, isAudio } from '@/utils';

type PreviewProps = {
  files: IFile[];
  isMultiple?: boolean;
  width: number | string;
  height: number | string;
  onDelete?: (fileId: string) => void;
};

const getFileTypeIcon = (file: IFile) => {
  const { mimetype } = file;
  if (isImage(mimetype)) return 'Image';
  if (isVideo(mimetype)) return 'Video';
  if (isAudio(mimetype)) return 'Radio';
  return 'Document';
};

const UploadPreview: React.FC<PreviewProps> = ({ files, isMultiple, width, height, onDelete }) => {
  if (!files || isEmpty(files)) return null;

  if (!isMultiple) {
    const file = files.find(f => f?.mimetype);
    if (!file) return <></>;

    const { mimetype, url } = file;

    if (isImage(mimetype))
      return (
        <>
          <Image
            width={width}
            src={url}
            height={height}
            preview={false}
            className="upload-preview"
            style={{ objectFit: 'cover' }}
          />
          {onDelete && <DeleteUpload file={file} onDelete={onDelete} />}
        </>
      );

    if (isVideo(mimetype))
      return (
        <>
          <ReactPlayer
            controls
            volume={1}
            muted={false}
            width={width}
            url={url}
            height={height}
            playIcon={<Image src="/assets/play.svg" />}
          />
          {onDelete && <DeleteUpload file={file} onDelete={onDelete} />}
        </>
      );
    if (isAudio(mimetype))
      return (
        <>
          <AudioPlayer file={file} />
          {onDelete && <DeleteUpload file={file} onDelete={onDelete} />}
        </>
      );
  }

  return (
    <>
      {files
        .filter(f => !!f)
        .map(f => (
          <UploadItem key={`_${f._id}`}>
            <Icon i={getFileTypeIcon(f)} />
            <div> {f.name}</div>
            {onDelete && <DeleteUpload isMultiple danger={false} file={f} onDelete={onDelete} />}
          </UploadItem>
        ))}
    </>
  );
};

export default UploadPreview;
