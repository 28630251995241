import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
