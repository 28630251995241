import { Wrapper } from './LoadingBar.styles';

type Props = {
  width?: string;
};

export const LoadingBar: React.FC<Props> = ({ width = '100%' }) => {
  return (
    <Wrapper data-testid="loading-bar" width={width}>
      <div id="loading-line-base">
        <div id="loading-line" />
      </div>
    </Wrapper>
  );
};
