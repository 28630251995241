import styled from 'styled-components';

export const Wrapper = styled.div<{ showtabs: boolean }>`
  .ant-tabs-nav {
    display: ${({ showtabs }) => (showtabs ? 'flex' : 'none')};
  }

  overflow: auto;
  max-height: 720px;
  height: 100%;
`;
