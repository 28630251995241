import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin: 8px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
