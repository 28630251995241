import { useTranslation } from 'react-i18next';

import { Wrapper } from './StudentTitle.styles';

type Props = {
  name: string;
};

export const StudentTitle: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return <Wrapper>{t('HI_STUDENT', { name })}</Wrapper>;
};
