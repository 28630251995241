import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const TitleWrapper = styled.div`
  font-weight: 500;
`;

export const QuizItemWrapper = styled.li`
  cursor: pointer;
  padding-left: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.green6};
    text-decoration: underline;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
