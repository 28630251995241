import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizResultService = sdk.getService('QuizResultService');

export const useMyQuizzesProgress = () => {
  const queryKey = ['quizzes-progress', 'me'];
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => QuizResultService.getUserProgress()
  });

  return {
    isLoading,
    quizzesProgress: data
  };
};
