import { IUser } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  data?: Partial<IUser> | null;
  setData: (user: Partial<IUser> | null) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useUserForm = createStore<IStore>(set => ({
  isOpen: false,
  data: null,
  setData: (data: Partial<IUser> | null) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
