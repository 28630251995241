import styled from 'styled-components';

export const Wrapper = styled.div``;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 0;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > :first-child {
    margin-bottom: 0;
  }
`;
