import { Badge, Flex } from 'antd';

import { useTeacherPendingMessages } from '@/store';

export const PendingMessagesBadge = () => {
  const { pendingMessages, isLoading } = useTeacherPendingMessages();

  if (isLoading) return null;

  return (
    <Flex align="center" justify="center">
      <Badge count={pendingMessages} />
    </Flex>
  );
};
