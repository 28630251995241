import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const OptionWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  border: ${({ theme, isSelected }) =>
    isSelected ? `2px solid ${theme.colors.purple6}` : `1px solid ${theme.colors.black5}`};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.purple2 : theme.colors.white};
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;
