import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  .ant-form-item {
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.black5};
`;
