import { IFileInfo } from 'react-csv-reader';
import { create as createStore } from 'zustand';

interface IStore {
  csvData: { [key: string]: string }[] | null;
  csvDataInfo: IFileInfo | null;
  setCsvData: (csvData: { [key: string]: string }[] | null, csvDataInfo: IFileInfo | null) => void;
}

export const useImportCsv = createStore<IStore>(set => ({
  csvData: null,
  csvDataInfo: null,
  setCsvData: (csvData: { [key: string]: string }[] | null, csvDataInfo: IFileInfo | null) =>
    set({ csvData, csvDataInfo })
}));
