import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const NpsVoteService = sdk.getService('NpsVoteService');

export const useCheckNpsVote = () => {
  const queryKey = ['nps-vote', 'check'];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => NpsVoteService.checkNpsVote()
  });

  return {
    voteCheck: data,
    isPending
  };
};
