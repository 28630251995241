import { useTranslation } from 'react-i18next';

import { OptionsWrapper, OptionWrapper, TitleWrapper } from './HasExamDatePicker.styles';

type Props = {
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const HasExamDatePicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('DefaultTranslations');
  return (
    <OptionsWrapper>
      <OptionWrapper isSelected={!!value} onClick={() => onChange?.(true)}>
        <TitleWrapper>{t('YES')}</TitleWrapper>
        <p>{t('I_HAVE_A_DATE')}</p>
      </OptionWrapper>
      <OptionWrapper isSelected={value === false} onClick={() => onChange?.(false)}>
        <TitleWrapper>{t('NO')}</TitleWrapper>
        <p>{t('I_DONT_HAVE_A_DATE')}</p>
      </OptionWrapper>
    </OptionsWrapper>
  );
};
