import { lazy } from 'react';

import { IRoute } from '../../types/routes';

const DashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);
export const SUPPORT: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: DashboardPage,
    showInMenu: true
  }
];
