import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const SessionLogService = sdk.getService('SessionLogService');

export const useLastConnection = (userId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['session-logs', userId],
    queryFn: () => SessionLogService.getLastConnection(userId)
  });

  return {
    lastConnection: data,
    isLoading
  };
};
