import { EStudentsTableTabs } from '@constants';

export const UsersTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last Name',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contrasenya',
    eus: 'Pasahitza'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Erantzukizuna'
  },
  AVATAR: {
    es: 'Avatar',
    en: 'Avatar',
    cat: 'Avatar',
    eus: 'Avatarra'
  },
  NEW_USER: {
    es: 'Nuevo Usuario',
    en: 'New User',
    cat: 'Nou Usuari',
    eus: 'Erabiltzaile Berria'
  },
  EDIT_USER: {
    es: 'Editar Usuario',
    en: 'Edit User',
    cat: 'Editar Usuari',
    eus: 'Erabiltzailea Aldatu'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde'
  },
  CREATE_USER: {
    es: 'Crear Usuario',
    en: 'Create User',
    cat: 'Crear Usuari',
    eus: 'Erabiltzailea Sortu'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea'
  },
  TEACHER: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  ADMIN: {
    es: 'Administrador',
    en: 'Admin',
    cat: 'Administrador',
    eus: 'Administrazioa'
  },
  MANAGER: {
    es: 'Gerente',
    en: 'Manager',
    cat: 'Gerent',
    eus: 'Kudeatzailea'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia'
  },
  USERS_TOTAL_COUNT: {
    es: '{{total}} usuarios',
    en: '{{total}} users',
    cat: '{{total}} usuaris',
    eus: '{{total}} erabiltzaile'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  TAGS: {
    es: 'Formación',
    en: 'Category',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  DATE_RANGE: {
    es: 'Rango de fechas',
    en: 'Date range',
    cat: 'Rang de dates',
    eus: 'Dataren tartea'
  },
  [EStudentsTableTabs.ALL_STUDENTS]: {
    es: 'Todos',
    en: 'All',
    cat: 'Tots',
    eus: 'Ditugu'
  },
  [EStudentsTableTabs.PROMOTIONS]: {
    es: 'Promociones',
    en: 'Promotions',
    cat: 'Promocions',
    eus: 'Promozioak'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  STUDENTS_COUNT: {
    es: 'Nº Alumnos',
    en: 'Students Count',
    cat: 'Nº d`alumnes',
    eus: 'Ikasleen kopurua'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  PROMOTIONS_TOTAL_COUNT: {
    es: '{{total}} promociones',
    en: '{{total}} promotions',
    cat: '{{total}} promocions',
    eus: '{{total}} promozioak'
  },
  NEW_PROMOTION: {
    es: 'Nueva promoción',
    en: 'New Promotion',
    cat: 'Nova promoció',
    eus: 'Promozio Berria'
  },
  EDIT_PROMOTION: {
    es: 'Editar promoción',
    en: 'Edit Promotion',
    cat: 'Editar promoció',
    eus: 'Promozioa editatu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancelar',
    eus: 'Utzi'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde'
  },
  DELETE_PROMOTION: {
    es: 'Sacar alumnos y eliminar',
    en: 'Remove students and delete',
    cat: 'Eliminar alumnes i treure',
    eus: 'Ikasleen kentzea eta ezabatzea'
  },
  DELETE_PROMOTION_CONFIRMATION: {
    es: '¿Estás seguro de que quieres sacar a los alumnos y eliminar la promoción? Esto eliminará todos los exámenes asociados a la promoción.',
    en: 'Are you sure you want to remove the students and delete the promotion? This will delete all exams associated with the promotion.',
    cat: 'Estàs segur que vols treure els alumnes i eliminar la promoció? Aquesta acció eliminarà tots els exàmens associats a la promoció.',
    eus: 'Ziur zaude ikasleen kentzea eta promozioa ezabatzea? Honela egin daiteke ezabatu ikasleak eta ezabatu promozioa.'
  },
  CANT_UNDO: {
    es: 'Esta acción no se puede deshacer',
    en: 'This action cannot be undone',
    cat: 'Aquesta acció no es pot desfer',
    eus: 'Ez da inoiz egin daiteke'
  }
};
