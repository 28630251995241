import styled from 'styled-components';

export const SectionNameWrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  width: 100%;
`;

export const ActivitiesNumberWrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const MoreSectionsWrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;
