import { ALL_WEEKDAYS, EEventWeekdays } from '@constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemWrapper, OptionsWrapper } from './WeekDaysPicker.styles';

type Props = {
  value?: EEventWeekdays[];
  onChange?: (value: EEventWeekdays[]) => void;
};

export const WeekDaysPicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('DefaultTranslations');

  const options = useMemo(
    () =>
      ALL_WEEKDAYS.map(item => ({
        label: t(item),
        value: item
      })),
    []
  );

  const handleSelectOption = (selectedValue: EEventWeekdays) => {
    if (value?.includes(selectedValue)) {
      onChange?.(value?.filter(item => item !== selectedValue));
    } else {
      onChange?.([...(value || []), selectedValue]);
    }
  };

  return (
    <OptionsWrapper>
      {options.map(option => (
        <ItemWrapper
          key={option.value}
          onClick={() => handleSelectOption(option.value)}
          isSelected={value?.includes(option.value)}>
          {option.label}
        </ItemWrapper>
      ))}
    </OptionsWrapper>
  );
};
