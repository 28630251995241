import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
  width: 100%;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 288px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
