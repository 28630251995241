export const ManagerExamsTranslations = {
  EXAMS: {
    es: 'Exámenes',
    en: 'Exams',
    cat: 'Exàmens',
    eus: 'Ekzamenak'
  },
  NEW_EXAM: {
    es: 'Nuevo examen',
    en: 'New exam',
    cat: 'Nou examen',
    eus: 'Ekzamen berria'
  },
  EXAMS_EMPTY: {
    es: 'No tienes exámenes creados',
    en: 'You have no exams created',
    cat: 'No tens exàmens creats',
    eus: 'Ez duzu ekzamen sorturik'
  },
  EXAMS_TOTAL_COUNT_one: {
    es: '{{count}} examen',
    en: '{{count}} exam',
    cat: '{{count}} examen',
    eus: '{{count}} ekzamen'
  },
  EXAMS_TOTAL_COUNT_other: {
    es: '{{count}} exámenes',
    en: '{{count}} exams',
    cat: '{{count}} exàmens',
    eus: '{{count}} ekzamenak'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  TAG: {
    es: 'Formación',
    en: 'Training',
    cat: 'Formació',
    eus: 'Formazioa'
  },
  CREATED_BY: {
    es: 'Creado por',
    en: 'Created by',
    cat: 'Creat per',
    eus: 'Sortua'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  PROMOTION: {
    es: 'Promoción',
    en: 'Promotion',
    cat: 'Promoció',
    eus: 'Promozioa'
  },
  EDIT_EXAM: {
    es: 'Editar examen',
    en: 'Edit exam',
    cat: 'Editar examen',
    eus: 'Ekzamen aldatu'
  },
  CREATE_EXAM: {
    es: 'Crear examen',
    en: 'Create exam',
    cat: 'Crear examen',
    eus: 'Ekzamen sortu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Canelar',
    eus: 'Utzi'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Guardar canvis',
    eus: 'Aldatu gorde'
  },
  QUIZ: {
    es: 'Test asociado de tipo Simulacro (en el caso de que sea online)',
    en: 'Associated test of Practice type (in case it is online)',
    cat: 'Test associat de tipus Simulació (en el cas de que sigui online)',
    eus: 'Asociatutako testua mota Simulakro (kasuan online izatean)'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  DELETE_EXAM: {
    es: 'Eliminar examen',
    en: 'Delete exam',
    cat: 'Eliminar examen',
    eus: 'Ekzamen ezabatu'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_EXAM: {
    es: '¿Estás seguro de que quieres eliminar este examen?',
    en: 'Are you sure you want to remove this exam?',
    cat: 'Estàs segur que vols eliminar aquest examen?',
    eus: 'Zer moduz, ez dago zuzenik?'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Fitxategiak'
  }
};
