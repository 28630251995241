import { getGamificationPoints } from '@utils';
import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  ItemWrapper,
  TextWrapper,
  PointsWrapper,
  DescriptionWrapper,
  DividerWrapper,
  ProgressBarContainer,
  ProgressBarFiller,
  ProgressBarLabel,
  ProgressBarFillerDetail
} from './GamificationSummary.styles';

import CHALLENGE_COMPLETE from '@/assets/challenge_complete.svg';
import CHALLENGE_INCOMPLETE from '@/assets/challenge_incomplete.svg';
import CHEST_CLOSED from '@/assets/chest_closed.svg';
import CHEST_OPENED from '@/assets/chest_opened.svg';
import GAMIFICATION_RAY_ADD from '@/assets/gamification_ray_add.svg';
import { useMyGamificationRewards, useStreak } from '@/store';
import { getDailyActionsSteps, isRewardClaimedToday } from '@/utils';

const DAILY_POINTS = 20;

export const GamificationSummary = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { rewards, todayGamificationPoints } = useMyGamificationRewards();
  const { streak, multiplier } = useStreak();

  if (!rewards) return null;

  const steps = getDailyActionsSteps(rewards.rewards, streak);
  const progressPercentage = (todayGamificationPoints * 100) / DAILY_POINTS;

  return (
    <Wrapper>
      {steps.map((step, index) => {
        const isClaimed = isRewardClaimedToday(rewards.rewards, step);
        return (
          <DividerWrapper key={`${step}-${index}-item`}>
            <ItemWrapper>
              <img src={isClaimed ? CHALLENGE_COMPLETE : CHALLENGE_INCOMPLETE} alt="Challenge" />
              <TextWrapper>
                <DescriptionWrapper>{t(step)}</DescriptionWrapper>
                <PointsWrapper>
                  {getGamificationPoints(multiplier, step)}
                  <img src={GAMIFICATION_RAY_ADD} alt="Gamification ray" />
                </PointsWrapper>
              </TextWrapper>
            </ItemWrapper>
            <Divider type="horizontal" />
          </DividerWrapper>
        );
      })}
      <Flex vertical gap={8} style={{ width: '100%' }}>
        <DescriptionWrapper>{t('GET_20_POINTS')}</DescriptionWrapper>
        <Flex gap={16} style={{ width: '100%' }} align="center">
          <ProgressBarContainer>
            <ProgressBarFiller percentage={progressPercentage}>
              <ProgressBarFillerDetail percentage={progressPercentage} />
              <ProgressBarLabel>{`${todayGamificationPoints.toFixed(1)}/20`}</ProgressBarLabel>
            </ProgressBarFiller>
          </ProgressBarContainer>
          <img
            src={todayGamificationPoints >= DAILY_POINTS ? CHEST_OPENED : CHEST_CLOSED}
            alt="Chest"
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};
