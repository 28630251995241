import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black4};
  padding: 12px 0px;

  &:last-child {
    border-bottom: none;
  }
`;

export const DocWrapper = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: break-word;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  i {
    padding: 12px;
    font-size: 22px;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black7};
`;
