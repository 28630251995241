import styled from 'styled-components';

export const TitleWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    width: 800px;
  }

  .free-mode:hover {
    background: ${({ theme }) => theme.colors.purple2};
    border: 1px solid ${({ theme }) => theme.colors.purple7};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.purple5};
  }
  .review-mode:hover {
    background: ${({ theme }) => theme.colors.green1};
    border: 1px solid ${({ theme }) => theme.colors.green7};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.green6};
  }
  .practice-mode:hover {
    background: ${({ theme }) => theme.colors.red2};
    border: 1px solid ${({ theme }) => theme.colors.red6};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.red6};
  }
`;
