import { IFile } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  data?: IFile[];
  setData: (data: IFile[] | undefined) => void;
}

export const useMoveOrRemoveMaterial = createStore<IStore>(set => ({
  data: undefined,
  setData: (data: IFile[] | undefined) => set({ data })
}));
