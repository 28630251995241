import { RequestService } from '../request';

export class UserStatsByCourseService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getMyAverageScore() {
    return this.fetcher<number>(`/user-stats-by-course/users/me/average-score`);
  }

  public getUserAverageScore(userId: string) {
    return this.fetcher<number>(`/user-stats-by-course/users/${userId}/average-score`);
  }
}
