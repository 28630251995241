import { RequestService } from '../request';

import { ICourseCertificate } from './certificate.contract';

export class CertificateService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getUserCertificates() {
    return this.fetcher<ICourseCertificate[]>(`/certificates/me`);
  }
}
