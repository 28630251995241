export const StudentTicketsTranslations = {
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  OPEN_DOUBT: {
    es: 'Abrir duda',
    en: 'Open doubt',
    cat: 'Obre dubte',
    eus: 'Ireki zalantza'
  },
  FILTER: {
    es: 'Filtrar',
    en: 'Filter',
    cat: 'Filtra',
    eus: 'Iragazi'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Envia',
    eus: 'Bidali'
  },
  SUBJECT: {
    es: 'Asunto',
    en: 'Subject',
    cat: 'Assumpte',
    eus: 'Gaia'
  },
  WRITE_HERE_YOUR_DOUBT: {
    es: 'Escribe aquí tu duda',
    en: 'Write here your doubt',
    cat: 'Escriu aquí el teu dubte',
    eus: 'Idatzi hemen zure zalantza'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Ticket creado correctamente',
    en: 'Ticket created successfully',
    cat: 'Ticket creat correctament',
    eus: 'Ticketa ongi sortu da'
  },
  SET_TICKET_AS_RESOLVED: {
    es: 'Marcar como resuelto',
    en: 'Set as resolved',
    cat: 'Marcar com a resolt',
    eus: 'Markatu ebaztuta'
  },
  TICKET_HAS_BEEN_SET_AS_RESOLVED: {
    es: 'El ticket ha sido marcado como resuelto',
    en: 'The ticket has been set as resolved',
    cat: 'El ticket ha estat marcat com a resolt',
    eus: 'Ticketa ebaztuta markatu da'
  },
  DESCRIPTION_REQUIRED: {
    es: 'La descripción es requerida',
    en: 'Description is required',
    cat: 'La descripció és requerida',
    eus: 'Deskribapena beharrezkoa da'
  },
  DELIVER: {
    es: 'Entregar',
    en: 'Deliver',
    cat: 'Lliurar',
    eus: 'Entregatu'
  },
  CSAT_RATING_TITLE: {
    es: '¿Estás satisfecho con la resolución de tu duda?',
    en: 'Are you satisfied with the resolution of your doubt?',
    cat: 'Estàs satisfet amb la resolució del teu dubte?',
    eus: 'Zalantzaren ebazpenarekin pozik zaude?'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: 'Gracias por tu feedback 😊',
    en: 'Thanks for your feedback 😊',
    cat: 'Gràcies pel teu feedback 😊',
    eus: 'Mila esker zure feedbackagatik 😊'
  },
  TICKETS_FORM_ADVICE: {
    es: 'Solo puedes subir archivos y texto por separado',
    en: 'You can only upload files and text separately',
    cat: 'Només pots pujar fitxers i text per separat',
    eus: 'Fitxategiak eta testua bakarrik igo ditzakezu bereizita'
  },
  COURSE_REQUIRED: {
    es: 'El curso es requerido',
    en: 'Course is required',
    cat: 'El curs és requerit',
    eus: 'Ikastaroa beharrezkoa da'
  },
  CHECK_YOUR_TICKETS: {
    es: 'Consulta todas las preguntas que has hecho en los temas de estudio.',
    en: 'Check all the questions you have made in the study topics.',
    cat: "Consulta totes les preguntes que has fet als temes d'estudi.",
    eus: 'Ikusi zure ikastetxean egin dituzun gaietako gai guztiak.'
  },
  TO_OPEN_A_TICKET: {
    es: 'Para abrir una nueva duda, entra a una lección y escribe tu pregunta en los comentarios.',
    en: 'To open a new doubt, go to a lesson and write your question in the comments.',
    cat: 'Per obrir un dubte nou, accedeix a una lliçó i escriu la teva pregunta als comentaris.',
    eus: 'Zalantza berria irekitzeko, lekziora joan eta zure galdera komentarioetan idatzi.'
  },
  YOU_DIDNT_ASK_ANY_QUESTIONS: {
    es: 'Aún no has hecho ninguna pregunta',
    en: "You didn't ask any questions",
    cat: 'Encara no has fet cap pregunta',
    eus: 'Ez zenuen galdera bat egin'
  },
  TO_RESOLVE_ANY_QUESTION: {
    es: 'Para resolver una duda, entra a una lección y escribe tu pregunta en los comentarios. Todas las preguntas que hagas se guardarán aquí para que puedas revisarlas cuando necesites.',
    en: 'To resolve a doubt, go to a lesson and write your question in the comments. All the questions you make will be saved here so you can review them when you need.',
    cat: 'Per resoldre un dubte, accedeix a una lliçó i escriu la teva pregunta als comentaris. Totes les preguntes que fas es guardaran aquí per que puguis revisar-les quan necessites.',
    eus: 'Zalantza bat ebazteko, lekziora joan eta zure galdera komentarioetan idatzi. Egin dituzun gai guztiak hemen gordetu dira, beraz, behar duzun unean ebatzi ditzakezu.'
  }
};
