import { RequestService } from '../request/request.service';
import { IUser } from '../user';

import { IAcademy, IAcademyStats } from './academy.contract';

export class MyAcademyService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getMyAcademy = async () => {
    return this.fetcher<IAcademy>('/my-academy');
  };

  public updateMyAcademy = async (academy: Partial<IAcademy>) => {
    return this.putter<IAcademy, Partial<IAcademy>>('/my-academy', academy);
  };

  public sendNewCredentials = async (studentId: string) => {
    return this.poster(`/my-academy/send-new-credentials/${studentId}`);
  };

  public removeUser = async (userId: string) => {
    return this.deleter(`/users/${userId}`);
  };

  public getAcademyStats = async () => {
    return this.fetcher<IAcademyStats>('/my-academy/stats');
  };

  public toggleActivateUser = async (userId: string) => {
    return this.putter<IUser, undefined>(`/my-academy/toggle-activate-user/${userId}`);
  };
}
