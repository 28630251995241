/* eslint-disable indent */
import { EPlanificationStatus } from '@constants';
import { IPlanification } from '@sdk/contracts';
import { dateProvider } from '@utils';

import { isActivityCompleted } from './isActivityCompleted';

export const getHowIsPlanificationGoing = (planification?: IPlanification) => {
  if (!planification) return null;

  const today = dateProvider.startOfDay();
  const startDate = dateProvider.startOfDay(dateProvider.parse(planification.startDate));
  const daysElapsed = dateProvider.diff(startDate, today, 'days');

  if (daysElapsed < 0) return null;

  const activitiesUntilToday = planification.items.filter(
    item =>
      dateProvider.startOfDay(dateProvider.parse(item.dueDate)) <= dateProvider.endOfDay(today)
  );

  const uniqueTotalActivities = planification.items.filter(
    (item, index, array) => array.findIndex(i => i.activity === item.activity) === index
  ).length;

  const uniqueActivitiesUntilToday = activitiesUntilToday.filter(
    (item, index, array) => array.findIndex(i => i.activity === item.activity) === index
  );

  const completedActivitiesUntilToday = uniqueActivitiesUntilToday.filter(item =>
    isActivityCompleted(
      item.activity,
      planification.subscriptions.find(s => s._id === item.subscription)!
    )
  );

  const totalCompletedStudyTimeUntilToday =
    completedActivitiesUntilToday.reduce((acc, item) => acc + item.timeSpent, 0) / 3600;

  const averageDailyStudyTime =
    daysElapsed > 0 ? totalCompletedStudyTimeUntilToday / daysElapsed : 0;
  const plannedDailyStudyTime = planification.dailyStudyTime / 3600;

  const completedActivities = completedActivitiesUntilToday.length;
  const totalActivities = uniqueActivitiesUntilToday.length;
  const completionPercentage =
    totalActivities === 0 ? 0 : (completedActivities / uniqueTotalActivities) * 100;

  const accumulatedActivities = uniqueActivitiesUntilToday.length - completedActivities;

  const accumulatedActivitiesTimeUntilToday =
    uniqueActivitiesUntilToday.reduce((acc, item) => acc + item.timeSpent, 0) -
    completedActivitiesUntilToday.reduce((acc, item) => acc + item.timeSpent, 0);

  const oneWeekAccumulated = planification.dailyStudyTime * 7;
  const oneMonthAccumulated = planification.dailyStudyTime * 30;

  const status =
    accumulatedActivitiesTimeUntilToday < oneWeekAccumulated
      ? EPlanificationStatus.GOOD
      : accumulatedActivitiesTimeUntilToday < oneMonthAccumulated
      ? EPlanificationStatus.NORMAL
      : EPlanificationStatus.BAD;

  return {
    averageDailyStudyTime,
    plannedDailyStudyTime,
    completedActivities,
    completionPercentage,
    accumulatedActivities,
    status
  };
};
