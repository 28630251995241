import { ERoles } from '@constants';
import { ISocketUser } from '@sdk/contracts';
import { Badge, Drawer, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOnlineUsersList } from '@/store';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const OnlineUsers: React.FC<Props> = ({ isOpen, onClose }) => {
  const { onlineUsers = [] } = useOnlineUsersList();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openUserDetails = (user: ISocketUser) => {
    navigate(`/academy/students?userId=${user._id}`);
  };

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: t('ROLE'),
      dataIndex: 'role',
      key: 'role'
    }
  ];

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      width={500}
      title={
        <strong>
          {t('ONLINE_USERS')}: <Badge count={onlineUsers.length} />
        </strong>
      }>
      <div style={{ height: '100%' }}>
        <Table
          size="small"
          columns={columns}
          dataSource={onlineUsers}
          pagination={{ pageSize: 50 }}
          onRow={record => ({
            onClick: () => {
              if (record.role === ERoles.STUDENT) {
                openUserDetails(record);
              }
            }
          })}
        />
      </div>
    </Drawer>
  );
};
