import { IExam } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  open: boolean;
  exam?: IExam;
  openDrawer: (exam?: IExam) => void;
  closeDrawer: () => void;
}

export const useExamDrawer = create<IStore>(set => ({
  open: false,
  openDrawer: (exam?: IExam) => set({ open: true, exam }),
  closeDrawer: () => set({ open: false, exam: undefined })
}));
