import { ICourse, ISubscription } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { dateProvider } from '@utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { useMySubscriptions } from '../subscriptions';

import { sdk } from '@/services';

interface IStore {
  course?: ICourse;
  myPosition: number;
  subscriptionsList: ISubscription[];
  selectedMonth: number;
  selectedYear: number;
  setCourse: (course: ICourse) => void;
  setMyPosition: (position: number) => void;
  setSubscriptionsList: (subscriptions: ISubscription[]) => void;
  setSelectedMonth: (month: number) => void;
  setSelectedYear: (year: number) => void;
}

const store = createStore<IStore>(set => ({
  subscriptionsList: [],
  myPosition: -1,
  selectedMonth: dateProvider.now().getMonth(),
  selectedYear: dateProvider.now().getFullYear(),
  setCourse: (course: ICourse) => set({ course }),
  setMyPosition: (position: number) => set({ myPosition: position }),
  setSubscriptionsList: (subscriptions: ISubscription[]) =>
    set({ subscriptionsList: subscriptions }),
  setSelectedMonth: (month: number) => set({ selectedMonth: month }),
  setSelectedYear: (year: number) => set({ selectedYear: year })
}));

const SubscriptionService = sdk.getService('SubscriptionService');

export const useRankingByCourse = () => {
  const { _id } = useParams<{ _id: string }>();
  const {
    course,
    myPosition,
    subscriptionsList,
    selectedMonth,
    selectedYear,
    setCourse,
    setMyPosition,
    setSubscriptionsList,
    setSelectedMonth,
    setSelectedYear
  } = store();
  const { filteredSubscriptions, selectedSubscription } = useMySubscriptions();

  const courseId = _id ?? course?._id;

  const { isLoading, data, error } = useQuery({
    queryKey: ['ranking', courseId, selectedMonth, selectedYear],
    queryFn: () => SubscriptionService.getRankingByPeriod(courseId!, selectedMonth, selectedYear),
    enabled: !!course,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (!course && filteredSubscriptions?.length && selectedSubscription) {
      setCourse(selectedSubscription.course);
      setSubscriptionsList(filteredSubscriptions);
    }
  }, [filteredSubscriptions, selectedSubscription]);

  useEffect(() => {
    if (data) {
      const position = data.findIndex(r => r._id === selectedSubscription?.user?._id);
      setMyPosition(position);
    }
  }, [data]);

  return {
    ranking: data,
    course,
    myPosition,
    subscriptionsList,
    selectedMonth,
    selectedYear,
    setCourse,
    setSelectedMonth,
    setSelectedYear,
    isLoading,
    error
  };
};
