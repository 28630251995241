import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 8px 0px;

  .ant-avatar {
    cursor: pointer;
  }

  & > div {
    flex: 1 1 0%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  }
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
`;
