import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));

export const useProgressModal = () => {
  const { isOpen, setIsOpen } = store();

  return {
    isOpen,
    setIsOpen
  };
};
