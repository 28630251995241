import { ERoles } from '@constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useProfile } from '../profile';

import { sdk } from '@/services';
import { calculateTodayGamificationPoints } from '@/utils';

const GamificationService = sdk.getService('GamificationService');

export const useMyGamificationRewards = () => {
  const queryKey = ['gamification'];
  const { profile } = useProfile();
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey });

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => GamificationService.getMyRewards(),
    enabled: profile?.role === ERoles.STUDENT
  });

  return {
    rewards: data,
    todayGamificationPoints: calculateTodayGamificationPoints(data?.rewards),
    invalidate,
    isLoading
  };
};
