import { IVoteLiveClass } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const LiveClassVoteService = sdk.getService('LiveClassVoteService');

export const useMutateLiveClassVote = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['live-class-vote'] });

  const { mutate: voteLiveClass, isPending: isVoting } = useMutation({
    mutationFn: (vote: IVoteLiveClass) => LiveClassVoteService.voteLiveClass(vote),
    onSuccess
  });

  return {
    voteLiveClass,
    isVoting
  };
};
