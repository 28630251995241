import { EProblemReportStatus } from '@constants';
import { IProblemReportFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 20;

const ProblemReportService = sdk.getService('ProblemReportService');

interface IStore {
  page: number;
  limit: number;
  search?: string;
  filter?: IProblemReportFilters;
  setFilter: (filter?: IProblemReportFilters) => void;
  setSearch: (search?: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = create<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  setFilter: (filter?: IProblemReportFilters) =>
    set(state => ({ ...state, page: INITIAL_PAGE, filter })),
  setSearch: (search?: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

export const useProblemReportsList = (status: EProblemReportStatus) => {
  const { page, limit, setPageAndLimit, filter, setFilter, setSearch, search } = store();

  const queryKey = ['problem-reports', page, limit, filter, search, status];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () =>
      ProblemReportService.getProblemReports({ page, limit }, search, { ...filter, status })
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    problemReports: data?.docs,
    setPageAndLimit,
    pagination,
    isLoading,
    error,
    filter,
    setFilter,
    search,
    setSearch
  };
};
