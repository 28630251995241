import styled from 'styled-components';

export const Wrapper = styled.div<{ isstudent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: ${({ isstudent }) => (isstudent ? '1200px' : '100%')};
  margin: 0 auto;
  padding: 16px;

  .layout {
    width: 100%;
  }

  & > :first-child {
    margin-bottom: 8px !important;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
