import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    align-items: center;
  }
`;
