import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  max-width: 670px;
  margin: 0 auto;
`;

export const DomainWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > input {
    max-width: 60px;
    margin-right: 2px;
  }
`;
