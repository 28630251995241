export const ChatTranslations = {
  CHAT: {
    es: 'Chat',
    en: 'Chat',
    cat: 'Xat',
    eus: 'Txat'
  },
  DELIVERABLES: {
    es: 'Entregables',
    en: 'Deliverables',
    cat: 'Lliurables',
    eus: 'Entregagarriak'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  TUTORES: {
    es: 'Tutores',
    en: 'Tutors',
    cat: 'Tutors',
    eus: 'Tutoreak'
  },
  YOUR_TUTOR: {
    es: 'Tu tutor',
    en: 'Your tutor',
    cat: 'El teu tutor',
    eus: 'Zure tutorea'
  },
  STUDENT_FROM: {
    es: 'Alumno de:',
    en: 'Student from:',
    cat: 'Alumne de:',
    eus: 'Ikaslea:'
  },
  THERE_ARE_NO_MESSAGES: {
    es: 'No hay mensajes',
    en: 'There are no messages',
    cat: 'No hi ha missatges',
    eus: 'Ez dago mezurik'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur'
  },
  NO_MESSAGES: {
    es: 'Todavía no le has escrito ningún mensaje a tu tutor',
    en: 'You have not written any messages to your tutor yet',
    cat: 'Encara no li has escrit cap missatge al teu tutor',
    eus: 'Oraindik ez duzu zure tutoreari mezu bat idatzi'
  }
};
