import { Button, Carousel, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNpsVoteLogic } from '../../hooks/useNpsVoteLogic';

import { NpsReason } from './NpsReason';
import { NpsScore } from './NpsScore';
import { FooterWrapper } from './NpsVote.styles';
import { PostNpsActions } from './PostNpsActions';

import { useIsMobile } from '@/hooks';

const MODAL_WIDTH = '50vw';

export const NpsVote = () => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const isMobile = useIsMobile('sm');
  const {
    form,
    step,
    isOpen,
    isCreating,
    carouselRef,
    isNextDisabled,
    handleFinish,
    handleCancel,
    handleNextStep
  } = useNpsVoteLogic();

  return (
    <Modal
      centered
      open={isOpen}
      closable={step !== 0}
      onClose={form.submit}
      onCancel={handleCancel}
      maskClosable={step !== 0}
      width={isMobile ? '100vw' : MODAL_WIDTH}
      footer={
        step !== 2 && (
          <FooterWrapper>
            <Button
              type="primary"
              loading={isCreating}
              disabled={isNextDisabled}
              onClick={handleNextStep}>
              {t('NEXT')}
            </Button>
          </FooterWrapper>
        )
      }>
      <Form preserve form={form} onFinish={handleFinish}>
        <Carousel ref={carouselRef} dots={false}>
          <NpsScore />
          <NpsReason />
          <PostNpsActions />
        </Carousel>
      </Form>
    </Modal>
  );
};
