import { EEventType, EProductTourTargets } from '@constants';
import ListPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { dateProvider } from '@utils';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { CreateLiveClass } from './CreateLiveClass';
import { LoadingWrapper, Wrapper } from './LiveClassesWidget.styles';

import UMY_WTF from '@/assets/umy_wtf.svg';
import { EmptyWidgetContent } from '@/components/EmptyWidgetContent';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { WidgetBase } from '@/components/WidgetBase';
import { useEventsList, useCalendar } from '@/store';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const LiveClassesWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const calendarRef = useRef<FullCalendar>(null);
  const { events, isLoading } = useEventsList();
  const { colors } = useTheme();

  const { goToEvent } = useCalendar(calendarRef!);

  const calendarEvents = useMemo(() => {
    return events?.map(event => ({
      id: event._id,
      title: event.title,
      start: dateProvider.zonedTimeToUtc(event.startDate).toISOString(),
      end: dateProvider.zonedTimeToUtc(event.endDate).toISOString(),
      color: event.type === EEventType.EVENT ? colors.purple6 : colors.green6
    }));
  }, [events]);

  return (
    <WidgetBase
      id={EProductTourTargets.LIVE_CLASSES_WIDGET}
      title={t('NEXT_LIVE_CLASSES')}
      addon={<CreateLiveClass />}
      {...props}>
      <Wrapper>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingCircleSpinner />
          </LoadingWrapper>
        ) : events?.length ? (
          <FullCalendar
            eventClick={({ event }) => goToEvent(event.id, event.title)}
            locale={'es'}
            ref={calendarRef}
            firstDay={1}
            plugins={[ListPlugin]}
            initialView="listMonth"
            events={calendarEvents}
            headerToolbar={false}
            navLinks={false}
          />
        ) : (
          <EmptyWidgetContent
            title={t('NO_SCHEDULED_EVENTS_TITLE')}
            description={t('NO_SCHEDULED_EVENTS_DESCRIPTION')}
            umyImage={UMY_WTF}
          />
        )}
      </Wrapper>
    </WidgetBase>
  );
};
