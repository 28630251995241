import { APP_STORE_URL, PLAY_STORE_URL } from '@constants';
import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import { Wrapper, Title, Subtitle } from './AppWidget.style';

import appStore from '@/assets/app_store_big_badge.svg';
import googlePlay from '@/assets/play_store_big_badge.svg';
import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

export const AppWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation();

  const goToPlayStore = () => {
    window.open(PLAY_STORE_URL, '_blank');
  };

  const goToAppStore = () => {
    window.open(APP_STORE_URL, '_blank');
  };
  return (
    <WidgetBase {...props} special>
      <Wrapper>
        <Flex
          align="center"
          justify="space-between"
          style={{ width: '100%', height: '100%' }}
          gap={8}>
          <Flex vertical>
            <Subtitle>Ucademy&GO</Subtitle>
            <Title>{t('DOWNLOAD_APP')}</Title>
            <Flex vertical>
              <ul>
                <li>{t('ALL_TESTS')}</li>
                <li>{t('REVIEW_FAILURES')}</li>
                <li>{t('STATISTICS')}</li>
              </ul>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex align="center" justify="space-between" gap={8} style={{ width: '100%' }}>
          <img src={appStore} alt="logo" onClick={goToAppStore} />
          <img src={googlePlay} alt="logo" onClick={goToPlayStore} />
        </Flex>
      </Wrapper>
    </WidgetBase>
  );
};
