export const DeliverableViewerTranslations = {
  CLOSE_DELIVERABLE_PREVIEW: {
    es: 'Cerrar vista previa',
    en: 'Close deliverable preview',
    cat: 'Tancar vista prèvia',
    eus: 'Itxi aurreikuspena'
  },
  CSAT_RATING_TITLE: {
    es: '¿Estás satisfecho con la resolución de tu duda?',
    en: 'Are you satisfied with the resolution of your doubt?',
    cat: 'Estàs satisfet amb la resolució del teu dubte?',
    eus: 'Zalantzaren ebazpenarekin pozik zaude?'
  }
};
