import { EditableMathField } from 'react-mathquill';
import styled from 'styled-components';

export const FormulaWrapper = styled(EditableMathField)`
  width: 100%;
  display: flex;
  min-height: 100px;
  font-size: 16px;
  text-align: center;
  align-items: center;
`;
