import { AnalyticsTrackEvents, EQuizMode } from '@constants';
import { ICreateQuizResult } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMixpanel } from '@/hooks';
import { sdk } from '@/services';
import { useQuizActivity } from '@/store';

const QuizResultService = sdk.getService('QuizResultService');

export const useMutateQuizResults = () => {
  const queryClient = useQueryClient();
  const { fireEvent } = useMixpanel();
  const { setActivityAsCompleted } = useQuizActivity();

  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['quizResult']
    });

  const { mutate: calculateQuizResults, isPending: isLoading } = useMutation({
    mutationFn: (quizData: ICreateQuizResult) => QuizResultService.calculateQuizResults(quizData),
    onSuccess: ({ quiz }) => {
      onSuccess();
      if (quiz.mode === EQuizMode.ACTIVITY_MODE) setActivityAsCompleted();
      fireEvent(AnalyticsTrackEvents.QUIZ_ANSWERS_SENT, {
        quizName: quiz.title,
        tags: quiz.tags.map(tag => tag.name).join(', '),
        testType: quiz.mode
      });
    }
  });

  return {
    calculateQuizResults,
    isWorking: isLoading
  };
};
