import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  gap: 24px;

  & > :first-child {
    flex: 1;
    overflow-y: auto;
  }
`;
