import Lottie from 'lottie-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledLottie = styled(Lottie)`
  width: 230px;
  height: 230px;
  align-self: center;
`;

export const GeneratingAnswerTextWrapper = styled.div`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;
