import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 20px;
  & > * {
    flex-grow: 1;
  }

  & > :last-child {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }
`;
