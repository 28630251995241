import { AnalyticsTrackEvents } from '@constants';
import {
  ICreateSection,
  IMoveActivityBetweenSections,
  IRemoveSection,
  IUpdateSection
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMixpanel } from '@/hooks';
import { sdk } from '@/services';

const SectionService = sdk.getService('SectionService');

export const useMutateSection = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['courses'] });
  const { fireEvent } = useMixpanel();
  const { t } = useTranslation('CourseTranslations');

  const { mutate: createSection, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateSection) => SectionService.createSection(create),
    onSuccess: (_, { course, section }) => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.TEACHER_SECTION_NEW, {
        courseName: course.name,
        sectionName: section.name
      });
    }
  });

  const { mutate: updateSection, isPending: isUpdating } = useMutation({
    mutationFn: (section: IUpdateSection) => SectionService.updateSection(section),
    onSuccess: () => {
      onSuccess();
      message.info(t('SECTION_UPDATED'));
    }
  });

  const { mutate: removeSection, isPending: isRemoving } = useMutation({
    mutationFn: (remove: IRemoveSection) => SectionService.removeSection(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: moveActivityBetweenSections, isPending: isMoving } = useMutation({
    mutationFn: (move: IMoveActivityBetweenSections) =>
      SectionService.moveActivityBetweenSections(move),
    onSuccess: () => {
      onSuccess();
      message.success(t('ELEMENTS_MOVED_SUCCESS'));
    }
  });

  return {
    createSection,
    updateSection,
    removeSection,
    moveActivityBetweenSections,
    isWorking: isCreating || isUpdating || isRemoving || isMoving
  };
};
