import { SOCKET_EVENTS } from '@constants';
import { IUser } from '@sdk/contracts';
import { useEffect } from 'react';

import { useSocket } from '@/hooks';
import { useProfile } from '@/store';

export const UserUpdateHandler = () => {
  const { profile, refetch } = useProfile();
  const { socket } = useSocket();

  const handleUpdateUser = (user: IUser) => {
    if (user._id !== profile?._id) return;
    refetch();
  };

  useEffect(() => {
    if (!socket) return;
    socket?.on(SOCKET_EVENTS.UPDATE_USER, (user: IUser) => {
      handleUpdateUser(user);
    });
  }, [socket, profile]);
  return null;
};
