import { IUser } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  data?: IUser;
  setData: (user?: IUser) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useInternalUserForm = createStore<IStore>(set => ({
  isOpen: false,
  setData: (data?: IUser) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
