import { create } from 'zustand';

interface IStore {
  date?: string;
  setDate: (date?: string) => void;
}

export const useDatePlanModal = create<IStore>(set => ({
  setDate: (date?: string) => set({ date })
}));
