import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :nth-child(2) {
    padding: 10px;
    flex-direction: column;
    gap: 16px;
    display: none;
    height: 100%;
    overflow-y: scroll;
    flex-basis: 30%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    column-gap: 46px;
    row-gap: 20px;
    flex-direction: row;
    & > :nth-child(1) {
      flex-basis: 70%;
    }
    & > :nth-child(2) {
      display: flex;
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;

  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TitleText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
