import { Button } from 'antd';
import styled from 'styled-components';

type WrapperProps = {
  height: number;
  paddingLeft?: number;
};

export const Wrapper = styled.div<WrapperProps>`
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : 36)}px;
  position: relative;
  margin: 8px 0px;

  img,
  .ant-image-mask {
    border-radius: 10px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;
