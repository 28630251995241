import { IFile, INebulonQuestionAnswer, ITicket } from '@sdk/contracts';
import { create as createStore } from 'zustand';

import { smoothlyScrollIntoDiv } from '../utils';

interface IStore {
  newTicket?: string;
  setNewTicket: (ticket?: string) => void;
  isCreateOpen: boolean;
  setIsCreateOpen: (isOpen: boolean) => void;
  step: number;
  setStep: (step: number) => void;
  ticketTitle?: string;
  ticketDescription?: string;
  setTicketTitleAndDescription: (title?: string, description?: string) => void;
  file?: IFile;
  setFile: (file?: IFile) => void;
  answer?: INebulonQuestionAnswer;
  setAnswer: (answer?: INebulonQuestionAnswer) => void;
  isAnswerUnuseful: boolean;
  setIsAnswerUnuseful: (isUnuseful: boolean) => void;
}

const store = createStore<IStore>(set => ({
  setNewTicket: (newTicket?: string) => set(state => ({ ...state, newTicket })),
  isCreateOpen: false,
  setIsCreateOpen: (isOpen: boolean) => set(state => ({ ...state, isCreateOpen: isOpen })),
  step: 0,
  setStep: (step: number) => set(state => ({ ...state, step })),
  setTicketTitleAndDescription: (ticketTitle?: string, ticketDescription?: string) =>
    set(state => ({ ...state, ticketTitle, ticketDescription })),
  setFile: (file?: IFile) => set(state => ({ ...state, file })),
  setAnswer: answer => set({ answer }),
  isAnswerUnuseful: false,
  setIsAnswerUnuseful: isAnswerUnuseful => set({ isAnswerUnuseful })
}));

export const useCreateTicketForm = () => {
  const {
    newTicket,
    setNewTicket,
    isCreateOpen,
    setIsCreateOpen,
    setStep,
    step,
    setTicketTitleAndDescription,
    ticketDescription,
    ticketTitle,
    file,
    setFile,
    answer,
    setAnswer,
    isAnswerUnuseful,
    setIsAnswerUnuseful
  } = store();

  const resetStore = () => {
    setIsCreateOpen(false);
    setStep(0);
    setFile();
    setTicketTitleAndDescription();
  };

  const handleTicketCreated = (ticket: ITicket) => {
    setIsCreateOpen(false);
    setStep(0);
    setNewTicket(ticket._id);
    setTicketTitleAndDescription();
    smoothlyScrollIntoDiv(ticket._id);
    setFile();
  };

  const handleCancel = () => {
    setIsCreateOpen(false);
    setTicketTitleAndDescription();
    setAnswer();
    setStep(0);
    setFile();
    setIsAnswerUnuseful(false);
  };

  return {
    newTicket,
    setNewTicket,
    isCreateOpen,
    setIsCreateOpen,
    setStep,
    step,
    setTicketTitleAndDescription,
    ticketDescription,
    ticketTitle,
    file,
    setFile,
    resetStore,
    handleTicketCreated,
    handleCancel,
    answer,
    setAnswer,
    isAnswerUnuseful,
    setIsAnswerUnuseful
  };
};
