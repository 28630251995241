import { ETicketStatus } from '@constants';

import { defaultTheme } from '../styles/theme';

export const TICKET_STATUS_COLORS = {
  [ETicketStatus.NEW]: defaultTheme.colors.purple6,
  [ETicketStatus.IN_PROGRESS]: defaultTheme.colors.orange6,
  [ETicketStatus.CLOSED_NOT_RESOLVED]: defaultTheme.colors.red6,
  [ETicketStatus.CLOSED_RESOLVED]: defaultTheme.colors.green7
};
