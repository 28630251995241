import { EBadgeTypes } from '@constants';
import { IBadge } from '@sdk/contracts';

const DEFAULT_BADGES = { [EBadgeTypes.GOLD]: 0, [EBadgeTypes.SILVER]: 0, [EBadgeTypes.BRONZE]: 0 };

export const groupBadgesByType = (badges?: IBadge[]): { [key in EBadgeTypes]: number } => {
  if (!badges) return { ...DEFAULT_BADGES };

  return badges.reduce(
    (acc, badge) => {
      if (!acc[badge.type]) {
        acc[badge.type] = 0;
      }
      acc[badge.type]++;
      return acc;
    },
    { ...DEFAULT_BADGES }
  );
};
