import { IFile } from '@sdk/contracts';

import { PdfViewer } from '@/components/PdfViewer';

type Props = {
  file: IFile;
};

export const PdfPreview: React.FC<Props> = ({ file }) => {
  if (!file.url) return null;

  return <PdfViewer url={file.url} />;
};
