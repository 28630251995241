import { Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    justify-content: flex-end;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    gap: 49px;
  }
`;

export const DoughnutWrapper = styled.div`
  margin-top: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    margin-top: 40px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column;
  }
`;

export const TotalQuestionsWrapper = styled.span`
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const TotalWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const AnswersTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AnswersContentWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ScoreTimeWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ScoreTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const StyledTag = styled(Tag)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: transparent;
  margin-inline-end: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding: 0px 8px;
  }
`;

export const TagTextWrapper = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const AnswersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const TagsTitleWrapper = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const TagWrapper = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.black2};
`;
