import styled from 'styled-components';

export const UploadItem = styled.div`
  width: 100%;
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black9} 50%;

  & > i {
    margin-right: 4px;
  }

  & > :last-child {
    & > button {
      color: ${({ theme }) => theme.colors.white};
    }
    margin-left: auto;
  }
`;
