import { RequestService } from '../request';

import { ICreatePostTopic, IPostTopic } from './post-topic.contract';

export class PostTopicService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getAllTopics() {
    return this.fetcher<IPostTopic[]>('/blog/post-topics');
  }

  async createTopic(topic: ICreatePostTopic) {
    return this.poster<IPostTopic, ICreatePostTopic>('/blog/post-topics', topic);
  }

  async remove(topicId: string) {
    return this.deleter(`/blog/post-topics/${topicId}`);
  }
}
