import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  & > :first-child {
    width: 100% !important;
    height: auto !important;
    video {
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal {
    min-height: 100%;
  }

  .ant-modal > :nth-child(2) {
    height: calc(100% - 10px) !important;
  }

  @media (min-width: 768px) {
    & > :first-child {
      width: 100% !important;
      height: 100% !important;
    }

    .ant-modal-mask,
    .ant-modal-wrap {
      position: absolute;
    }
    .ant-modal {
      width: calc(100% - 10px) !important;
      height: calc(100% - 15px) !important;
      min-height: 100% !important;
      top: 5px;
      bottom: 10px;
      padding: 0;
      .ant-modal-content {
        min-height: 100% !important;
        display: flex;
        flex-direction: column;
      }
      .ant-modal-footer {
        margin-top: auto;
      }
    }
  }
`;
