import { IAnswer, IQuestionReview } from '@sdk/contracts';
import { FormInstance, Radio } from 'antd';

import { FormulaWrapper, Image, ImageLink, ImageWrapper } from './ReviewedQuestions.styles';

import { Icon } from '@/components/Icon';
import { Box } from '@/components/Utils';

interface ReviewedQuestionProps {
  answers: IAnswer[];
  currentQuestionToReview: IQuestionReview;
  form: FormInstance;
}

const OPTION_ICON_MARGIN = 8;
const MOCKED_CLASS_NAME = 'ant-radio';

export const ReviewedQuestion: React.FC<ReviewedQuestionProps> = ({
  answers,
  currentQuestionToReview,
  form
}) => {
  const showReview = (currentAnswer: number) => {
    const { _id, question } = currentQuestionToReview;
    if (currentAnswer === question.correctAnswer) {
      return (
        <Icon
          color="green6"
          i="CheckmarkFilled"
          className={MOCKED_CLASS_NAME}
          style={{ marginRight: OPTION_ICON_MARGIN }}
        />
      );
    }
    if (currentAnswer === form.getFieldValue(['responses', _id])) {
      if (form.getFieldValue(['responses', _id]) !== question.correctAnswer) {
        return (
          <Icon
            color="red6"
            i="CloseFilled"
            className={MOCKED_CLASS_NAME}
            style={{ marginRight: OPTION_ICON_MARGIN }}
          />
        );
      }
    }
    return <Radio disabled />;
  };

  return (
    <div>
      {answers.map((answer, value) => {
        const { file } = answer;
        return (
          <Box
            alignItems="center"
            marginBottom={20}
            key={`answers-review-${currentQuestionToReview._id}-${value}`}>
            {showReview(value)}
            <div>{answer.text}</div>
            {answer?.formula && (
              <FormulaWrapper dangerouslySetInnerHTML={{ __html: answer.formula }} />
            )}
            {file && (
              <ImageWrapper>
                <ImageLink href={file.url} target="_blank">
                  <Image src={file.url} alt={file.name} />
                </ImageLink>
              </ImageWrapper>
            )}
          </Box>
        );
      })}
    </div>
  );
};
