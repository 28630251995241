import { motion } from 'framer-motion';

type Props = {
  x?: number;
  y?: number;
  duration?: number;
  children: React.ReactNode;
};

export const Animated: React.FC<Props> = ({ children, x = 100, y = 0, duration = 0.2 }) => {
  return (
    <motion.div
      animate={{ opacity: 1, x: 0, y: 0 }}
      initial={{ opacity: 0, x, y }}
      transition={{
        duration
      }}>
      {children}
    </motion.div>
  );
};
