import { Flex } from 'antd';
import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TextWrapper = styled.div`
  text-align: center;
  text-justify: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;

export const TitleWrapper = styled.span`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const WrongTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const PlanificationWrapper = styled(Flex)`
  border-radius: 4px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.purple6};
`;

export const StudyTimeWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StudyTimeInfoWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};
`;
