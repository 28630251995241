import styled from 'styled-components';

export const AddItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .ant-input {
    width: 100%;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;
