import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-popover-content {
    width: 350px;
  }

  .ant-btn:hover {
    background: none !important;
  }
`;
