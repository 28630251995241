import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 52px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding-top: 24px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding-top: 0px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    display: grid;
    grid-template-columns: 60fr 40fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    column-gap: 24px;
    row-gap: 32px;
    & > :nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    & > :nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    & > :nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
  }
`;

export const WidgetWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 20px;
`;
