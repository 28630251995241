import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ItemWrapper = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding: 4px 32px;
  border-radius: 4px;
  border: ${({ theme, isSelected }) =>
    isSelected ? `2px solid ${theme.colors.purple6}` : `1px solid ${theme.colors.black5}`};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.purple2 : theme.colors.white};
  cursor: pointer;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
`;
