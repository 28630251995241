import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request/request.service';
import {
  IUser,
  ITutoringGroupStudentForSelector,
  IChangeStatusInBulk,
  IStudentsFilterDto
} from '../user';

export class MyAcademyStudentsService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getStudents = async (
    search: string,
    pagination: IPagination,
    bypass: boolean,
    filters?: IStudentsFilterDto
  ) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<IUser>>(`/my-academy/students?${query}`);
  };

  public getStudent = async (studentId: string) => {
    return this.fetcher<IUser>(`/my-academy/students/${studentId}`);
  };

  public createStudent = async (newStudent: Partial<IUser>) => {
    return this.poster<IUser & { alreadyExists?: boolean }, Partial<IUser>>(
      '/my-academy/students',
      newStudent
    );
  };

  public updateStudent = async (student: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>(`/my-academy/students/${student._id}`, student);
  };

  public findAllStudentsForTutoringGroupsSelectors = async (search: string) => {
    const query = createQueryString({ search });

    return this.fetcher<ITutoringGroupStudentForSelector[]>(
      `/my-academy/students/tutoring-groups/selectors/all?${query}`
    );
  };

  public changeStatusInBulk = async (updateStatusInBulk: IChangeStatusInBulk) => {
    const { studentIds, status } = updateStatusInBulk;
    return this.putter<IUser, IChangeStatusInBulk>(`/my-academy/students/bulk-update-status`, {
      studentIds,
      status
    });
  };
}
