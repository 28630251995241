import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  max-width: 440px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 auto;

  & > :nth-child(2) {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
  }

  & > :nth-child(3) {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
