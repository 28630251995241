import { createQueryString } from '@utils';
import { Dayjs } from 'dayjs';

import { IPaginatedResponse, IPagination } from '../common';
import { ICreateMessage } from '../messages';
import { RequestService } from '../request';

import {
  IClosingAverageTicket,
  ITicket,
  ITicketsFilters,
  IOpenTickets,
  ICsatStats,
  ISetTicketReview,
  IUpdateAssignee,
  ISendTicketMessage,
  ICreateAutomaticResponseTicket,
  IDownloadCsatData,
  IDownloadTicketsByTagData
} from './ticket.contract';

export class TicketService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTickets(
    search?: string,
    pagination?: IPagination,
    bypass?: boolean,
    filters?: ITicketsFilters
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<ITicket>>(`/tickets?${query}`);
  }

  public getCourseTickets(search?: string, pagination?: IPagination, filters?: ITicketsFilters) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters
    });
    return this.fetcher<IPaginatedResponse<ITicket>>(`/tickets/course?${query}`);
  }

  public getTicket(ticketId: string) {
    return this.fetcher<ITicket>(`/tickets/${ticketId}`);
  }

  public createAutomaticResponseTicket(newTicket: ICreateAutomaticResponseTicket) {
    return this.poster<ITicket, ICreateAutomaticResponseTicket>(`/tickets/automatic`, newTicket);
  }

  public updateTicket(updatedTicket: Partial<ITicket>) {
    return this.putter<ITicket, Partial<ITicket>>(`/tickets/${updatedTicket._id}`, updatedTicket);
  }

  public removeTicket(ticketId: string) {
    return this.deleter(`/tickets/${ticketId}`);
  }

  public sendMessage({ ticketId, createMessage }: ISendTicketMessage) {
    return this.poster<ITicket, ICreateMessage>(`/tickets/${ticketId}/messages`, createMessage);
  }

  public setTicketAsResolved(ticketId: string) {
    return this.putter<Partial<ITicket>, string>(`/tickets/${ticketId}/resolve`);
  }

  public getCsatStats(dateFilter: [Dayjs, Dayjs], assignee?: string) {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        assignee
      }
    });
    return this.fetcher<ICsatStats>(`/tickets/stats/csat?${query}`);
  }
  public getClosingAverage = async (dateFilter: [Dayjs, Dayjs], teacherId?: string) => {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        teacherId
      }
    });
    return this.fetcher<IClosingAverageTicket>(`/tickets/stats/closing-average?${query}`);
  };

  public getOpenTickets = async (dateFilter: [Dayjs, Dayjs], teacherId?: string) => {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        teacherId
      }
    });
    return this.fetcher<IOpenTickets>(`/tickets/stats/open-tickets?${query}`);
  };

  public setTicketReview({ ticketId, review }: ISetTicketReview) {
    return this.poster(`/tickets/${ticketId}/review`, review);
  }

  public updateTicketAssignee({ ticketId, assignee }: IUpdateAssignee) {
    return this.poster(`/tickets/${ticketId}/assignee`, {
      assignee
    });
  }

  public changeUsefulTicketVote(ticketId: string) {
    return this.putter(`/tickets/${ticketId}/vote`);
  }

  public downloadCsatStats() {
    return this.poster<IDownloadCsatData[], undefined>(`/tickets/stats/csat/download`);
  }

  public downloadTicketsByTag() {
    return this.poster<IDownloadTicketsByTagData[], undefined>(`/tickets/stats/by-tag/download`);
  }
}
