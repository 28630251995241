import { Tag } from 'antd';
import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black9};
  font-feature-settings: 'liga' off, 'clig' off;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const SubtitleWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black6};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  img {
    object-fit: cover;
  }
`;

export const PostContentWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const FeaturedTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.purple6};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  margin-inline-end: 0 !important;
`;

export const DateWrapper = styled.div<{ isscheduled: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ isscheduled }) =>
    isscheduled === 'true' &&
    css`
      color: ${({ theme }) => theme.colors.orange7};
    `}
`;
