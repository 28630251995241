import styled from 'styled-components';

export const Wrapper = styled.div<{ shape?: 'circle' | 'square'; isMultiple?: boolean }>`
  position: relative;
  .ant-upload {
    overflow: ${({ isMultiple }) => (isMultiple ? 'auto' : 'hidden')};
    border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '16px')};
    padding: ${({ isMultiple }) => (isMultiple ? '16px 0px' : 'unset')} !important;
  }
  .upload-preview {
    border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '16px')};
  }
`;

export const UploadButton = styled.div<{ block?: boolean }>`
  .ant-upload {
    width: 100%;
  }
`;
