import { IUser } from '@sdk/contracts';
import { DrawerProps } from 'antd';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  props?: DrawerProps;
  data?: IUser;
  setData: (user?: IUser) => void;
  setIsOpen: (isOpen: boolean, props?: DrawerProps) => void;
}

export const useEditStudentDrawer = createStore<IStore>(set => ({
  props: {},
  isOpen: false,
  setData: (data?: IUser) => set({ data }),
  setIsOpen: (isOpen: boolean, props?: DrawerProps) => set({ isOpen, props })
}));
