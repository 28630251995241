import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 8px;
`;

export const TitleTextWrapper = styled.div`
  font-weight: 500;
`;

export const LinkListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LinkTextWrapper = styled.a`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Muestra solo 2 líneas de texto */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
`;

export const LinkIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
