import styled from 'styled-components';

export const StyledH1 = styled.h1`
  padding: 8px;
  max-width: 500px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: unset;
  }
`;

export const StarWrapper = styled.img`
  position: relative;
  top: 15px;
`;

export const ExclamationWrapper = styled.img`
  position: relative;
  bottom: -30px;
`;

export const FormWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-checkbox {
    align-self: baseline;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
