import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 8px;
  width: 100%;
  overflow-x: auto;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding: 0 16px;
    flex-direction: column;
    flex: 1;
  }
`;

export const ListItemWrapper = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  transition: background-color 0.2s;
  font-size: 16px;
  font-weight ${({ selected }) => (selected ? '700' : '400')};
  text-align: center;

  &:hover {
    font-weight: 700;
  }
`;
