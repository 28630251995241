import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 24px;

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  word-wrap: break-word;
`;

export const SubtitleWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`;

export const DescriptionWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  color: ${({ theme }) => theme.colors.black5};
  margin-bottom: 24px;
`;
