import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { BoldWrapper, InfoWrapper, ListItem, Wrapper } from './StudySteps.styles';

import GAMIFUCATION_RAY from '@/assets/gamification_ray_add.svg';
import STAR_VECTOR from '@/assets/star_vector.svg';
import { Icon } from '@/components/Icon';

const ICON_SIZE = 32;

export const StudySteps = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');

  return (
    <StepWrapper>
      <h2>
        {t('STUDY_STEPS_TITLE')}
        <img src={STAR_VECTOR} alt="Star Vector" />
      </h2>
      <InfoWrapper>
        <div>{t('STUDY_STEPS_SUBTITLE')}</div>
        <Wrapper>
          <ListItem>
            <Icon size={ICON_SIZE} i="CertificateCheck" color="green6" />
            <div>
              <BoldWrapper>{t('STUDY_PLAN_TITLE')}</BoldWrapper>
              <div>{t('STUDY_PLAN_SUBTITLE')}</div>
            </div>
          </ListItem>
          <ListItem>
            <Icon size={ICON_SIZE} i="DataPlayer" color="green6" />
            <div>
              <BoldWrapper>{t('ONBOARDING_FOLLOW_UP_TITLE')}</BoldWrapper>
              <div>{t('ONBOARDING_FOLLOW_UP_SUBTITLE')}</div>
            </div>
          </ListItem>
          <ListItem>
            <img height={ICON_SIZE} src={GAMIFUCATION_RAY} alt="Onboarding Rewards Logo" />
            <div>
              <BoldWrapper>{t('ONBOARDING_REWARDS_TITLE')}</BoldWrapper>
              <div>{t('ONBOARDING_REWARDS_SUBTITLE')}</div>
            </div>
          </ListItem>
        </Wrapper>
      </InfoWrapper>
    </StepWrapper>
  );
};
