import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    height: 180px;
  }
`;

export const SideWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: column;
    justify-content: center;
    transition: transform 0.4s;
  }
`;

export const FrontSideWrapper = styled(SideWrapper)`
  padding: 8px 16px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    transform: rotateY(0deg);
    padding: unset;
  }
`;

export const BackSideWrapper = styled(SideWrapper)<{
  backgroundColor: string;
  borderColor: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 0px 8px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    transform: rotateY(180deg);
  }
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const DescriptionWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
  word-wrap: break-word;
  text-align: center;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: column;
  }
`;
