import styled from 'styled-components';

export const ListItem = styled.div`
  display: flex;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    max-width: 700px;
    padding: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BoldWrapper = styled.div`
  font-weight: 700;
`;
