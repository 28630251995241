import { EOnboardingDiscoverySource } from '@constants';
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonWrapper,
  ItemWrapper,
  ModalWrapper,
  OptionsWrapper
} from './DiscoverySourcePicker.styles';
import { ReferredByModal } from './ReferredByModal';

type Props = {
  value?: EOnboardingDiscoverySource;
  onChange?: (value: EOnboardingDiscoverySource) => void;
};
const OPTIONS = Object.values(EOnboardingDiscoverySource);

export const DiscoverySourcePicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('StudentOnboardingTranslations');
  const [otherSource, setOtherSource] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isReferralOpen, setIsReferralOpen] = useState(false);
  const [name, setName] = useState('');

  const handleChange = (option: EOnboardingDiscoverySource) => {
    if (option === EOnboardingDiscoverySource.OTHER) return setIsOpen(true);
    if (option === EOnboardingDiscoverySource.FRIEND) return setIsReferralOpen(true);
    onChange?.(option);
  };

  return (
    <>
      <OptionsWrapper>
        {OPTIONS.map(option => (
          <ItemWrapper
            isSelected={value === option}
            key={option}
            onClick={() => handleChange(option)}>
            {t(option)}
          </ItemWrapper>
        ))}
      </OptionsWrapper>
      <Modal
        centered
        open={isOpen}
        closable
        title={t('TELL_US')}
        footer={null}
        onCancel={() => setIsOpen(false)}>
        <ModalWrapper>
          <div>{t('HOW_DID_YOU_DISCOVER_US')}</div>
          <Form.Item name={['onBoarding', 'otherSource']}>
            <Input.TextArea rows={4} onChange={({ target: { value: v } }) => setOtherSource(v)} />
          </Form.Item>
          <ButtonWrapper>
            <Button
              type="primary"
              disabled={otherSource.length < 3}
              onClick={() => {
                setIsOpen(false);
                onChange?.(EOnboardingDiscoverySource.OTHER);
              }}>
              {t('CONTINUE')}
            </Button>
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
      <ReferredByModal
        isOpen={isReferralOpen}
        setIsOpen={setIsReferralOpen}
        okDisabled={name.length < 2}
        onInputChange={setName}
        onOk={onChange}
      />
    </>
  );
};
