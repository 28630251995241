import { Button, Popconfirm } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  TitleWrapper,
  FooterWrapper,
  ButtonLinkWrapper,
  StyledUl
} from './BulletPointVideoSummary.styles';

import { Icon } from '@/components/Icon';
import { useUmy } from '@/hooks';
import {
  useActivityTrack,
  useMutateProfile,
  useProfile,
  useTranscriptionDetailByActivity
} from '@/store';

export const BulletPointVideoSummary = () => {
  const { t } = useTranslation();
  const { profile } = useProfile();
  const { getCurrentActivity } = useActivityTrack();
  const { activity } = getCurrentActivity();
  const { transcription } = useTranscriptionDetailByActivity(activity?._id);
  const { updateProfile } = useMutateProfile();
  const { setType, onFinish } = useUmy();

  const name = useMemo(() => {
    if (!profile) return '';
    return profile.name.charAt(0).toLocaleUpperCase() + profile.name.slice(1);
  }, [profile]);

  if (!transcription || !activity) return null;

  const { keyPoints, summary } = transcription;

  const handleDisableUmy = () => {
    updateProfile({ isUmyDisabled: true });
    handleCancel();
  };

  const handleCancel = () => {
    onFinish?.();
    setType();
  };

  return (
    <Wrapper>
      <TitleWrapper>
        {t('BULLET_POINT_VIDEO_SUMMARY_TITLE', { name })}
        <Icon onClick={handleCancel} i="Close" />
      </TitleWrapper>
      {keyPoints && keyPoints.length > 0 ? (
        <StyledUl>
          {keyPoints.map((keyPoint, index) => (
            <li key={index + 1}>{keyPoint.explanation}</li>
          ))}
        </StyledUl>
      ) : (
        <div>{summary}</div>
      )}
      <FooterWrapper>
        <Popconfirm
          title={t('DONT_SHOW_AGAIN_CONFIRMATION')}
          placement="bottomLeft"
          onConfirm={handleDisableUmy}>
          <ButtonLinkWrapper>{t('DONT_SHOW_AGAIN')}</ButtonLinkWrapper>
        </Popconfirm>
        <Button onClick={handleCancel}>{t('GREAT_THANK_YOU')}</Button>
      </FooterWrapper>
    </Wrapper>
  );
};
