import { EVerticalNames } from '@constants';
import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TagService = sdk.getService('TagService');

export const useTagsByVertical = (vertical: EVerticalNames) => {
  const { isLoading, data } = useQuery({
    queryKey: ['tags', 'by-vertical', vertical],
    queryFn: () => TagService.getTagsByVertical(vertical)
  });

  return {
    tags: data,
    isLoading
  };
};
