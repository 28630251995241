import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLocalCourse } from './useLocalCourse';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useCourseDetail = (courseIdSelected?: string) => {
  const { _id } = useParams<{ _id: string }>();
  const courseId = _id ?? courseIdSelected;
  const { update } = useLocalCourse();

  const { isLoading, data, error } = useQuery({
    queryKey: ['courses', courseId],
    queryFn: () => CourseService.getCourse(courseId!),
    enabled: !!courseId
  });

  useEffect(() => {
    if (!data) return;
    update(data);
  }, [data]);

  return {
    courseId,
    course: data,
    isLoading,
    error
  };
};
