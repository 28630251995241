// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeQueryParams = (data?: Record<string, any>) => {
  const ret = [];
  for (const d in data) {
    if (Array.isArray(data[d])) {
      for (const arrD in data[d]) {
        ret.push(`${encodeURIComponent(d)}[]=${encodeURIComponent(data[d][arrD])}`);
      }
    } else if (typeof data[d] === 'undefined') {
      ret.push(encodeURIComponent(d));
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join('&');
};
