/* eslint-disable indent */
import { dateProvider } from '@utils';
import { Button, Flex, Tooltip } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { StudyPlanBadge } from '../StudyPlanBadge';

import {
  ContentWrapper,
  HeaderWrapper,
  PlanificationWrapper,
  StudyTimeInfoWrapper,
  StudyTimeWrapper,
  TitleWrapper,
  WrongTimeWrapper
} from './PlanificationSummaryWidget.styles';

import { Icon } from '@/components/Icon';
import { useMyPlanification } from '@/store';
import { formatSecondsInHoursAndMinutes } from '@/utils';

export const PlanificationSummaryWidget = () => {
  const { t } = useTranslation('DefaultTranslations');
  const { planification, isLessThanMinTime, isMaxTimeExceeded } = useMyPlanification();
  const hasHoursAndMinutes = planification?.studyHours !== undefined;

  if (!planification) return null;

  const getResultTitle = () => {
    if (hasHoursAndMinutes) return t('PLANIFICATION_CORRECT_TIME_HOURS');
    if (isLessThanMinTime)
      return (
        <>
          <span>{t('PLANIFICATION_RESULT_TITLE')}</span>
          <span>{t('PLANIFICATION_RESULT_UNDER_TIME')}</span>
        </>
      );
    if (isMaxTimeExceeded)
      return (
        <>
          <span>{t('PLANIFICATION_RESULT_TITLE')}</span>
          <span>{t('PLANIFICATION_RESULT_OVER_TIME')}</span>
        </>
      );
    return t('PLANIFICATION_CORRECT_TIME_END_DATE');
  };

  const getResultContent = () => {
    const { hours, minutes } = formatSecondsInHoursAndMinutes(planification.dailyStudyTime);

    if (hasHoursAndMinutes)
      return (
        <Flex vertical gap={48}>
          <span>{t('PLANIFICATION_RESULT_CORRECT_TIME_HOURS_INFO')}</span>
          <PlanificationWrapper vertical gap={4}>
            <Flex gap={4} align="center">
              <StudyTimeInfoWrapper>
                {t('PLANIFICATION_RESULT_CORRECT_TIME_HOURS')}
              </StudyTimeInfoWrapper>
              <Tooltip title={t('PLANIFICATION_RESULT_CORRECT_TIME_TOOLTIP')} placement="top">
                <Button size="small" type="link" icon={<Icon i="Information" color="white" />} />
              </Tooltip>
            </Flex>
            <StudyTimeWrapper>
              {dateProvider.format(planification.endDate, 'EEEE d - MMMM yyyy')}
            </StudyTimeWrapper>
          </PlanificationWrapper>
        </Flex>
      );

    if (isLessThanMinTime)
      return (
        <WrongTimeWrapper>
          <span>{t('PLANIFICATION_RESULT_CONTENT')}</span>
          <span>
            <Trans
              i18nKey="PLANIFICATION_RESULT_CONTENT_UNDER_TIME"
              components={{ a: <b /> }}
              values={{ minutes }}
            />
          </span>
          <span>{t('PLANIFICATION_RESULT_CONTENT_UNDER_TIME_EXPLANATION_FIRST')}</span>
          <span>{t('PLANIFICATION_RESULT_CONTENT_UNDER_TIME_EXPLANATION_SECOND')}</span>
        </WrongTimeWrapper>
      );
    if (isMaxTimeExceeded)
      return (
        <WrongTimeWrapper>
          <span>{t('PLANIFICATION_RESULT_CONTENT')}</span>
          <span>
            <Trans
              i18nKey="PLANIFICATION_RESULT_CONTENT_OVER_TIME"
              components={{ a: <b /> }}
              values={{
                minutes,
                hours
              }}
            />
          </span>
          <span>{t('PLANIFICATION_RESULT_CONTENT_OVER_TIME_EXPLANATION')}</span>
        </WrongTimeWrapper>
      );

    return (
      <Flex vertical gap={48}>
        <span>{t('PLANIFICATION_RESULT_CORRECT_TIME_END_DATE_INFO')}</span>
        <PlanificationWrapper vertical gap={4}>
          <Flex gap={4} align="center">
            <StudyTimeInfoWrapper>
              {t('PLANIFICATION_RESULT_CORRECT_TIME_END_DATE')}
            </StudyTimeInfoWrapper>
            <Tooltip title={t('PLANIFICATION_RESULT_CORRECT_TIME_TOOLTIP')} placement="top">
              <Button size="small" type="link" icon={<Icon i="Information" color="white" />} />
            </Tooltip>
          </Flex>
          <StudyTimeWrapper>
            {t('PLANIFICATION_RESULT_CORRECT_TIME_END_DATE_STUDY_TIME', { hours, minutes })}
          </StudyTimeWrapper>
        </PlanificationWrapper>
      </Flex>
    );
  };

  return (
    <>
      <HeaderWrapper>
        <StudyPlanBadge />
        <TitleWrapper>{getResultTitle()}</TitleWrapper>
        <ContentWrapper>{getResultContent()}</ContentWrapper>
      </HeaderWrapper>
    </>
  );
};
