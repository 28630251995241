import { ECourseStatus } from '@constants';
import { ICourseStats, ICoursesStatsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { SorterResult } from 'antd/lib/table/interface';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 20;

const CoursesStatsService = sdk.getService('CoursesStatsService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filters?: ICoursesStatsFilters;
  sort?: SorterResult<ICourseStats>;
  setFilters: (filters: ICoursesStatsFilters) => void;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
  setSort: (sort: SorterResult<ICourseStats>) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  filters: { status: [ECourseStatus.DRAFT, ECourseStatus.PUBLISHED] },
  setFilters: (filters: ICoursesStatsFilters) =>
    set(state => ({ ...state, page: INITIAL_PAGE, filters })),
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setSort: (sort: SorterResult<ICourseStats>) => set(state => ({ ...state, sort }))
}));

export const useCoursesStatsList = () => {
  const { page, limit, search, filters, setFilters, setSearch, setPageAndLimit, sort, setSort } =
    store();
  const { data, isLoading } = useQuery({
    queryKey: ['courses', 'stats', `page-${page}`, `limit-${limit}`, search, filters, sort],
    queryFn: () => CoursesStatsService.getCoursesStats(search, { page, limit }, filters, sort)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    coursesStatsList: data?.docs,
    isLoading,
    pagination,
    search,
    filters,
    setPageAndLimit,
    setSearch,
    setFilters,
    setSort
  };
};
