import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';
import { formatDataAndDownloadCsv } from '@/utils';

const LiveClassVoteService = sdk.getService('LiveClassVoteService');

export const useDownloadLiveClassVoteReport = () => {
  const { isPending: isLiveClassVoteReportLoading, mutate: downloadLiveClassVoteReport } =
    useMutation({
      mutationFn: () => LiveClassVoteService.downloadLiveClassVoteReport(),
      onSuccess: data => formatDataAndDownloadCsv(data, 'live_classes_vote_report.csv')
    });

  return {
    isLiveClassVoteReportLoading,
    downloadLiveClassVoteReport
  };
};
