/* eslint-disable @typescript-eslint/no-explicit-any */
import { ETicketFilter, ETicketStatus } from '@constants';
import { ITicketsFilters } from '@sdk/contracts';
import { Button, DatePicker, Popover } from 'antd';
import { Checkbox, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { Wrapper, ClearSearch } from './FiltersHandler.styles';

import {
  StudentSearchSelector,
  TagSelector,
  TeacherSelector,
  VisibilitySelector
} from '@/components/Form';
import { CourseSearchSelector } from '@/components/Form/CourseSearchSelector';
import { Icon } from '@/components/Icon';
import { useMyTicketsList, useProfile } from '@/store';

export const FiltersHandler = () => {
  const { t } = useTranslation(['CommonTicketsTranslations', 'DefaultTranslations']);
  const {
    roleCheck: { isManager }
  } = useProfile();
  const { filter, setFilter } = useMyTicketsList(ETicketFilter.OPEN);

  const { issuer, status, course, tag, assignee, visibility } = filter;

  const options = Object.values(ETicketStatus).map(s => ({
    label: t(`DefaultTranslations:${s}`),
    value: s
  }));

  const handleSetFilters = (key: keyof ITicketsFilters, value: any) => {
    if (!value) {
      clearFilter(key);
    } else {
      setFilter({ ...filter, [key]: value });
    }
  };

  const clearFilter = (key: keyof ITicketsFilters) => {
    const updatedFilters = { ...filter };
    delete updatedFilters[key];
    setFilter({ ...updatedFilters });
  };

  const content = (
    <Wrapper>
      <Collapse
        ghost
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => <Icon i={isActive ? 'SubtractAlt' : 'AddAlt'} />}>
        <Collapse.Panel key="status" header={t('CommonTicketsTranslations:STATUS')}>
          <Checkbox.Group
            value={status}
            options={options}
            onChange={v => handleSetFilters('status', v)}
          />
        </Collapse.Panel>
        <Collapse.Panel key="student" header={t('CommonTicketsTranslations:STUDENT')}>
          <StudentSearchSelector
            value={issuer}
            onChange={u => handleSetFilters('issuer', u)}
            placeholder={t('CommonTicketsTranslations:SELECT_A_STUDENT_TO_FILTER')}
          />
          <ClearSearch onClick={() => clearFilter('issuer')}>
            {t('CommonTicketsTranslations:CLEAR_SEARCH_FILTER')}
          </ClearSearch>
        </Collapse.Panel>
        <Collapse.Panel key="date" header={t('CommonTicketsTranslations:DATE')}>
          <DatePicker.RangePicker onChange={d => handleSetFilters('date', d)} />
        </Collapse.Panel>
        <Collapse.Panel key="course" header={t('CommonTicketsTranslations:COURSE')}>
          <CourseSearchSelector
            value={course}
            onChange={c => handleSetFilters('course', c)}
            placeholder={t('CommonTicketsTranslations:SELECT_A_COURSE_TO_FILTER')}
          />
          <ClearSearch onClick={() => clearFilter('course')}>
            {t('CommonTicketsTranslations:CLEAR_SEARCH_FILTER')}
          </ClearSearch>
        </Collapse.Panel>
        {isManager && (
          <>
            <Collapse.Panel key="tag" header={t('CommonTicketsTranslations:TAG')}>
              <TagSelector
                value={tag}
                onChange={tagSelected => {
                  handleSetFilters('tag', tagSelected);
                }}
              />
              <ClearSearch onClick={() => clearFilter('tag')}>
                {t('CommonTicketsTranslations:CLEAR_SEARCH_FILTER')}
              </ClearSearch>
            </Collapse.Panel>
            <Collapse.Panel key="assignee" header={t('CommonTicketsTranslations:ASSIGNEE')}>
              <TeacherSelector
                value={assignee}
                onChange={teacher => handleSetFilters('assignee', teacher)}
                allowClear
              />
            </Collapse.Panel>
            <Collapse.Panel key="isPublic" header={t('CommonTicketsTranslations:VISIBILITY')}>
              <VisibilitySelector
                value={visibility}
                onChange={v => handleSetFilters('visibility', v)}
              />
              <ClearSearch onClick={() => clearFilter('visibility')}>
                {t('CommonTicketsTranslations:CLEAR_SEARCH_FILTER')}
              </ClearSearch>
            </Collapse.Panel>
          </>
        )}
      </Collapse>
    </Wrapper>
  );

  return (
    <Popover trigger={['click']} content={content} placement="bottomRight">
      <Button icon={<Icon i="Filter" />} size="large">
        {t('CommonTicketsTranslations:FILTERS')}
      </Button>
    </Popover>
  );
};
