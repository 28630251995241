export enum EOnboardingDiscoverySource {
  GOOGLE = 'GOOGLE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  ADVERTISEMENT = 'ADVERTISEMENT',
  FRIEND = 'FRIEND',
  FLYER = 'FLYER',
  OTHER = 'OTHER'
}

export enum EOnboardingChosenReason {
  QUIZ = 'QUIZ',
  MICROLESSON = 'MICROLESSON',
  TEACHER_FOLLOW_UP = 'TEACHER_FOLLOW_UP',
  SUCCESS_GUARANTEE = 'SUCCESS_GUARANTEE',
  ATTENTION = 'ATTENTION',
  HELP_IN_EVERYTHING = 'HELP_IN_EVERYTHING',
  OTHER = 'OTHER'
}
