import { SOCKET_EVENTS } from '@constants';
import { ILaunchUmy } from '@sdk/contracts';
import { useEffect } from 'react';

import { useSocket, useUmy } from '@/hooks';

export const UmyHandler = () => {
  const { socket } = useSocket();
  const { setType } = useUmy();

  useEffect(() => {
    if (!socket || socket.hasListeners(SOCKET_EVENTS.LAUNCH_UMY)) return;

    socket.on(SOCKET_EVENTS.LAUNCH_UMY, ({ type }: ILaunchUmy) => {
      setType(type);
    });

    return () => {
      socket?.off(SOCKET_EVENTS.LAUNCH_UMY);
    };
  }, [socket]);

  return <></>;
};
