import { RequestService } from '../request';

import { IFile } from './file.contract';

export class FileService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async createUploadSignedUrl(file: Partial<IFile>) {
    return this.poster<IFile, Partial<IFile>>('/files', file);
  }

  async getFileWithUrl(fileId: string) {
    return this.fetcher<IFile>(`/files/${fileId}`);
  }

  async removeFile(fileId: string) {
    return this.deleter(`/files/${fileId}`);
  }
}
