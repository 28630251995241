import { encodeQueryParams } from '@utils';

import { RequestService } from '../request';

import { IQuestionReviewResume, IQuestionReview, ICheckAnswer } from './question-review.contract';

export class QuestionReviewService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async findQuestionReviewResume() {
    return this.fetcher<IQuestionReviewResume[]>('/questions-review/resume');
  }
  async findQuestionReviewBySections(sections: string[]) {
    const query = encodeQueryParams({
      filter: sections
    });
    return this.fetcher<IQuestionReview[]>(`/questions-review/resume/sections?${query}`);
  }
  async checkAnswer({ questionId, givenAnswer }: ICheckAnswer) {
    return this.putter<IQuestionReview, Pick<ICheckAnswer, 'givenAnswer'>>(
      `/questions-review/check-answer/${questionId}`,
      {
        givenAnswer
      }
    );
  }
}
