import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 80vh;
  align-self: stretch;
  gap: 16px;
  overflow: scroll;
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black9};
`;
