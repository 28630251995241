import { Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-self: stretch;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    gap: 48px;
    align-items: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    justify-content: unset;
  }
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  align-items: center;
  gap: 32px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    display: flex;
    height: 100%;
    gap: 16px;
    justify-content: center;
    flex-direction: column;
  }
`;

export const DoughnutWrapper = styled.div`
  width: 146px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const StyledTag = styled(Tag)<{ backgroundcolor: string }>`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  border: transparent;
  margin-inline-end: 0px;

  text-align: center;
`;

export const TagTextWrapper = styled.div<{ color: string }>`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: ${({ color }) => color};
`;
