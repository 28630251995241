import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
  }
`;
