import { ISocketUser } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  socketUsers: ISocketUser[];
  setSocketUser: (socketUsers: ISocketUser[]) => void;
}

export const useSocketUsers = createStore<IStore>(set => ({
  socketUsers: [],
  setSocketUser: (socketUsers: ISocketUser[]) => set({ socketUsers })
}));
