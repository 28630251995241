import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { IFile } from '@sdk/contracts';
import { Button, Form, Input, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Answers } from '../Answers';
import { QuestionCounter } from '../QuestionCounter';

import {
  GenerateVideoCheckPointTitle,
  Wrapper,
  RemoveIcon,
  GenerateVideoCheckPointIcon,
  AddQuestion,
  GenerateVideoCheckPointWrapper
} from './VideoType.styles';

import { Animated } from '@/components/Animated';
import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { RULES } from '@/constants';
import { useMutateActivity, useTranscriptionDetailByActivity, useActivityForm } from '@/store';
import { IUpload } from '@/types';

const { TextArea } = Input;
const { Title } = Typography;

type Props = {
  value?: { file: IFile };
  onChange?: ({ file }: { file: IUpload['value'] }) => void;
};

const UPLOAD_WIDTH = '100%';
const UPLOAD_HEIGHT = 250;

export const VideoType: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CourseTranslations');
  const {
    data,
    hasVideoCheckPoint: hasRecap,
    setHasVideoCheckPoint: setHasRecap,
    setGeneratedVideoCheckpoints
  } = useActivityForm();
  const { generateVideoCheckPoints, isWorking } = useMutateActivity();
  const { transcription } = useTranscriptionDetailByActivity(data?._id);

  const [selectedQuestion, setSelectedQuestion] = useState(0);

  const resetVideoCheckPoint = () => {
    setHasRecap(false);
    setGeneratedVideoCheckpoints();
    setSelectedQuestion(0);
  };

  const generateVideoCheckPoint = async () => {
    if (!transcription) return;
    generateVideoCheckPoints(transcription, {
      onSuccess: ({ questions }) => {
        setGeneratedVideoCheckpoints(questions);
        setHasRecap(true);
      }
    });
  };

  const handleChange = (file: IUpload['value']) => onChange?.({ file });

  return (
    <Animated>
      <Upload
        preview
        accept="video/mp4"
        value={value?.file}
        width={UPLOAD_WIDTH}
        height={UPLOAD_HEIGHT}
        onChange={handleChange}>
        <div>
          <Icon i="Video" />
          <p>{t('ACTIVITY_VIDEO_TYPE_DESCRIPTION')}</p>
        </div>
      </Upload>
      <Wrapper>
        <GenerateVideoCheckPointTitle>
          <Title level={5}>
            {t('ACTIVITY_RECAP_QUESTION_TITLE')} <GenerateVideoCheckPointIcon i="TestTool" />
          </Title>
          {hasRecap ? (
            <Button type="link" onClick={resetVideoCheckPoint} icon={<RemoveIcon i="TrashCan" />} />
          ) : (
            <GenerateVideoCheckPointWrapper>
              <Tooltip title={t('ACTIVITY_GENERATE_VIDEO_CHECKPOINT_TOOLTIP')}>
                <Button
                  type="text"
                  size="small"
                  loading={isWorking}
                  disabled={!transcription}
                  onClick={generateVideoCheckPoint}
                  icon={<BulbOutlined />}>
                  {t('ACTIVITY_GENERATE_VIDEO_CHECKPOINT')}
                </Button>
              </Tooltip>
              <Button
                type="dashed"
                size="small"
                onClick={() => setHasRecap(true)}
                icon={<PlusOutlined />}>
                {t('ACTIVITY_ADD_RECAP')}
              </Button>
            </GenerateVideoCheckPointWrapper>
          )}
        </GenerateVideoCheckPointTitle>
        {hasRecap && (
          <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                <p>{t('ACTIVITY_VIDEO_CHECKPOINT_DESCRIPTION')}</p>
                <AddQuestion>
                  <QuestionCounter
                    current={selectedQuestion}
                    amount={fields.length}
                    onChange={setSelectedQuestion}
                  />
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        if (fields.length !== 0) {
                          setSelectedQuestion(selectedQuestion + 1);
                        }
                      }}
                      icon={<PlusOutlined />}>
                      {t('ACTIVITY_ADD_QUESTION')}
                    </Button>
                  </Form.Item>
                </AddQuestion>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: selectedQuestion === name ? 'block' : 'none'
                    }}>
                    <Form.Item {...restField} name={[name, '_id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      label={
                        <>
                          {t('QUESTION')}
                          <Button
                            type="link"
                            onClick={() => {
                              if (name !== 0) {
                                setSelectedQuestion(name - 1);
                              }
                              remove(name);
                            }}
                            icon={<RemoveIcon i="TrashCan" />}
                          />
                        </>
                      }
                      rules={[RULES.REQUIRED]}
                      style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextArea rows={2} />
                    </Form.Item>
                    <Answers currentName={name} />
                  </div>
                ))}
              </>
            )}
          </Form.List>
        )}
      </Wrapper>
    </Animated>
  );
};
