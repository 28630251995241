import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :first-child {
    font-size: 30px;
    font-weight: 500;
    & > :nth-child(2) {
      display: flex;
      gap: 20px;
    }
  }
`;
