import { EditStudentToolbarTab, ERoles } from '@constants';

export const ManagerTeachersTranslations = {
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  NAME_AND_LASTNAME: {
    es: 'Nombre y apellido',
    en: 'Name and lastname',
    cat: 'Nom i cognom',
    eus: 'Izena eta abizena'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  ACTIVITY_STATUS: {
    es: 'Estado actividad',
    en: 'Activity status',
    cat: 'Estat activitat',
    eus: 'Jarduera egoera'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktiboa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu desaktibatua'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Emaila'
  },
  INSCRIPTION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Inscription date',
    cat: "Data d'inscripció",
    eus: 'Izena eman den data'
  },
  THERE_ARE_NOT_COURSES_ASOCIATED_TO_THIS_USER: {
    es: 'No hay cursos asociados a este usuario',
    en: 'There are not courses asociated to this user',
    cat: 'No hi ha cursos associats a aquest usuari',
    eus: 'Ez dago erabiltzaile honi lotutako ikastaroik'
  },
  NEW_TEACHER_ADDED: {
    es: 'Nuevo profesor agregado a la academia',
    en: 'New teacher added to the academy',
    cat: "Nou professor afegit a l'acadèmia",
    eus: 'Irakasle berria akademiara gehitu da'
  },
  CREATE_TEACHER: {
    es: 'Crear profesor',
    en: 'Create teacher',
    cat: 'Crear professor',
    eus: 'Irakasle berria sortu'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  USERNAME: {
    es: 'Usuario',
    en: 'Username',
    cat: 'Usuari',
    eus: 'Erabiltzaile izena'
  },
  NEW_TEACHER: {
    es: 'Nuevo profesor',
    en: 'New teacher',
    cat: 'Nou professor',
    eus: 'Irakasle berria'
  },
  TEACHERS_TOTAL_COUNT: {
    es: '{{total}} profesor/es',
    en: '{{total}} teacher/s',
    cat: '{{total}} professor/s',
    eus: '{{total}} irakasle/ak'
  },
  TEACHER_UPDATED_SUCCESSFULLY: {
    es: 'Profesor actualizado correctamente',
    en: 'Teacher updated successfully',
    cat: 'Professor actualitzat correctament',
    eus: 'Irakasle eguneratua ongi'
  },
  USER_PREVIEW_COULD_NOT_BE_LOADED: {
    es: 'No se pudo cargar la vista previa del usuario',
    en: 'User preview could not be loaded',
    cat: "No es va poder carregar la vista prèvia de l'usuari",
    eus: 'Erabiltzailearen aurrebista ezin izan da kargatu'
  },
  ASSIGN_COURSE: {
    es: 'Asignar curso',
    en: 'Assign course',
    cat: 'Assignar curs',
    eus: 'Ikastaroa asignatu'
  },
  CANCEL_EDIT_TEACHER: {
    es: 'Cancelar edición',
    en: 'Cancel edit',
    cat: 'Cancel·lar edició',
    eus: 'Ezabatu editatu'
  },
  EDIT_TEACHER: {
    es: 'Editar profesor',
    en: 'Edit teacher',
    cat: 'Editar professor',
    eus: 'Editatu irakaslea'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  CONNECTION: {
    es: 'Conexión',
    en: 'Connection',
    cat: 'Connexió',
    eus: 'Konexioa'
  },
  THERE_ARE_NOT_CURSES_ASOCIATED_TO_THIS_TEACHER: {
    es: 'No hay cursos asociados a este profesor',
    en: 'There are not curses asociated to this teacher',
    cat: 'No hi ha cursos associats a aquest professor',
    eus: 'Ez dago irakasle honi lotutako ikastarorik'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol'
  },
  [ERoles.MANAGER]: {
    es: 'Manager',
    en: 'Manager',
    cat: 'Manager',
    eus: 'Kudeatzailea'
  },
  [ERoles.TEACHER]: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  [ERoles.SALES]: {
    es: 'Ventas',
    en: 'Sales',
    cat: 'Vendes',
    eus: 'Saltzak'
  },
  [ERoles.SUPPORT]: {
    es: 'Soporte',
    en: 'Support',
    cat: 'Suport',
    eus: 'Laguntza'
  },
  [ERoles.INSPECTOR]: {
    es: 'Inspector',
    en: 'Inspector',
    cat: 'Inspector',
    eus: 'Azterketa'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA irakaslea'
  },
  ARE_YOU_SURE_TO_DELETE_USER: {
    es: '¿Estás seguro que deseas eliminar al usuario?',
    en: 'Are you sure you want to delete the user?',
    cat: "Estàs segur que vols eliminar l'usuari?",
    eus: 'Ziur zaude erabiltzailea ezabatu nahi duzula?'
  },
  USER_DELETED_SUCCESSFULLY: {
    es: 'Usuario eliminado correctamente',
    en: 'User deleted successfully',
    cat: 'Usuari eliminat correctament',
    eus: 'Erabiltzailea ongi ezabatu da'
  },
  REMOVE_STUDENT: {
    es: 'Eliminar teacher',
    en: 'Remove teacher',
    cat: 'Eliminar professor',
    eus: 'Irakaslea kendu'
  },
  REMOVE: {
    es: 'Eliminar',
    en: 'Remove',
    cat: 'Eliminar',
    eus: 'Kendu'
  },
  RESET_STUDENT_PASSWORD: {
    es: 'Reiniciar contraseña',
    en: 'Reset teacher password',
    cat: 'Reiniciar contrasenya',
    eus: 'Berrezarri irakaslearen pasahitza'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro que deseas reiniciar la contraseña?',
    en: 'Are you sure to reset password?',
    cat: 'Estàs segur que vols reiniciar la contrasenya?',
    eus: 'Ziur zaude pasahitza berrezarri nahi duzula?'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Contraseña reiniciada correctamente, el usuario recibirá un correo con la nueva contraseña',
    en: 'Password reset successfully, the user will receive an email with the new password',
    cat: "Contrasenya reiniciada correctament, l'usuari rebrà un correu amb la nova contrasenya",
    eus: 'Pasahitza berrezarri da, erabiltzaileak mezu bat jasoko du berriro pasahitzarekin'
  },
  [EditStudentToolbarTab.GENERAL_INFO]: {
    es: 'Información general',
    en: 'General information',
    cat: 'Informació general',
    eus: 'Orokorra informazioa'
  },
  [EditStudentToolbarTab.SPECIFIC_INFO]: {
    es: 'Documentación FP',
    en: 'FP documentation',
    cat: 'Documentació FP',
    eus: 'FP dokumentazioa'
  },
  ACADEMIC_TITLES: {
    es: 'Titulación académica',
    en: 'Academic titles',
    cat: 'Titulacions acadèmiques',
    eus: 'Akademik tituluak'
  },
  TEACHING_MASTERS: {
    es: 'Máster del profesorado y/o CAP',
    en: 'Teaching masters and/or CAP',
    cat: 'Màsters del professorat i/o CAP',
    eus: 'Irakasle-màster eta/edo CAP'
  },
  SEXUAL_HARASSMENT_CERTIFICATE: {
    es: 'Certificado de no delitos sexuales',
    en: 'Sexual harassment certificate',
    cat: 'Certificat de no delits sexuals',
    eus: 'Sexual harassment certificate'
  },
  CRIMINAL_RECORD: {
    es: 'Certificado de no antecedentes penales',
    en: 'Criminal record certificate',
    cat: 'Certificat de no antecedents penals',
    eus: 'Antekets penala ez duen sertifikatua'
  },
  IDENTITY_FILES: {
    es: 'Fotocopia del DNI/NIE',
    en: 'DNI/NIE copy',
    cat: 'Còpia del DNI/NIE',
    eus: 'DNI/NIE kopia'
  },
  RESUME: {
    es: 'Currículum Vitae',
    en: 'Resume',
    cat: 'CV',
    eus: 'CV'
  }
};
