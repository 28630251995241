import { EEventType, EFeatures } from '@constants';
import { dateProvider } from '@utils';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { create as createStore } from 'zustand';

import { useTagFeatures } from '@/hooks';
import { useEventsList, useMyPlanification } from '@/store';

type ICalendarEvent = {
  id: string;
  title: string;
  start?: string;
  end?: string;
  color: string;
  allDay?: boolean;
  date?: Date;
};

type IStore = {
  showEvents: boolean;
  showPlanification: boolean;
  setShowEvents: (value: boolean) => void;
  setShowPlanification: (value: boolean) => void;
};

const store = createStore<IStore>(set => ({
  showEvents: true,
  showPlanification: true,
  setShowEvents: value => set({ showEvents: value }),
  setShowPlanification: value => set({ showPlanification: value })
}));

export const useStudentCalendarEvents = () => {
  const { colors } = useTheme();
  const { events, isLoading: isEventsLoading } = useEventsList();
  const { planificationEvents, isLoading: isPlanificationLoading } = useMyPlanification();
  const { canRender } = useTagFeatures();
  const { showEvents, showPlanification, setShowEvents, setShowPlanification } = store();

  const isEventsEnabled = useMemo(() => canRender(EFeatures.LIVE_CLASSES), [canRender]);
  const isPlanificationEnabled = useMemo(() => canRender(EFeatures.PLANIFICATION), [canRender]);

  const calendarEvents = useMemo(() => {
    return (events ?? []).map(event => ({
      id: event._id,
      title: event.title,
      start: dateProvider.zonedTimeToUtc(event.startDate).toISOString(),
      end: dateProvider.zonedTimeToUtc(event.endDate).toISOString(),
      color: event.type === EEventType.EVENT ? colors.purple6 : colors.green6
    }));
  }, [events]);

  const allEvents = useMemo(() => {
    const eventArray: ICalendarEvent[] = [];
    if (isPlanificationEnabled && showPlanification) {
      eventArray.push(...planificationEvents);
    }
    if (isEventsEnabled && showEvents) {
      eventArray.push(...calendarEvents);
    }
    return eventArray;
  }, [planificationEvents, calendarEvents, showEvents, showPlanification]);

  return {
    events: allEvents,
    isLoading: isEventsLoading || isPlanificationLoading,
    showEvents,
    showPlanification,
    setShowEvents,
    setShowPlanification
  };
};
