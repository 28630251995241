import { AnalyticsTrackEvents } from '@constants';
import { IEvent } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Button, message } from 'antd';
import Lottie from 'lottie-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Wrapper, InfoWrapper, TitleWrapper, TimeWrapper } from './OngoingEventItem.styles';

import LIVE_EVENT from '@/assets/lotties/live_event.json';
import { useMixpanel } from '@/hooks';
import { useMutateEvents, useProfile } from '@/store';

const LOTTIE_SIZE = '16px';

type Props = {
  events: IEvent[];
};

export const OngoingEventItem: React.FC<Props> = ({ events }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const ongoingEvent = useMemo(() => events[0], [events]);
  const { fireEvent } = useMixpanel();
  const { addAttendee, isWorking } = useMutateEvents();
  const { profile } = useProfile();
  const navigateTo = useNavigate();

  const now = dateProvider.now();

  const isStarted = useMemo(() => {
    return dateProvider.isLive(ongoingEvent.startDate, ongoingEvent.endDate);
  }, [ongoingEvent]);

  const timeStartedTitle = useMemo(() => {
    const time = dateProvider.diff(
      dateProvider.zonedTimeToUtc(ongoingEvent.startDate),
      now,
      'minutes'
    );
    if (isStarted) {
      return t('CLASS_STARTED_AT', { time });
    }
    return t('CLASS_STARTING_IN', { time });
  }, [isStarted, ongoingEvent, now]);

  const isUserAttending = useMemo(() => {
    if (!profile) return false;
    return ongoingEvent.attendees.some(attendee => attendee._id === profile._id);
  }, [profile, ongoingEvent]);

  const fireWidgetEvent = () =>
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'EVENTS'
    });

  const goToEvent = () => {
    navigateTo(`/content/calendar/${ongoingEvent._id}`);
    fireWidgetEvent();
  };

  const handleJoinEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!profile) return;
    if (!isUserAttending) addAttendee({ eventId: ongoingEvent._id, attendeeId: profile._id });
    if (!ongoingEvent.googleMeet?.link) return message.error(t('NO_GOOGLE_MEET_LINK'));
    return window.open(ongoingEvent.googleMeet.link, '_blank');
  };

  return (
    <Wrapper onClick={goToEvent}>
      <InfoWrapper>
        <TitleWrapper>{ongoingEvent.title}</TitleWrapper>
        <TimeWrapper>{timeStartedTitle}</TimeWrapper>
      </InfoWrapper>
      <Button
        type="primary"
        loading={isWorking}
        onClick={handleJoinEvent}
        icon={
          <div style={{ width: LOTTIE_SIZE, height: LOTTIE_SIZE }}>
            <Lottie animationData={LIVE_EVENT} />
          </div>
        }>
        {t('JOIN_CLASS')}
      </Button>
    </Wrapper>
  );
};
