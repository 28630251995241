import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 16px 0px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.green1};
  border: 1px solid ${({ theme }) => theme.colors.black4};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    display: flex;
    grid-template-columns: unset;
    flex-direction: row;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;
