export const RankingTranslations = {
  RANKING_TITLE: {
    es: 'Ranking de {{month}}',
    en: '{{month}} ranking',
    cat: 'Rànquing de {{month}}',
    eus: '{{month}} ranking'
  },
  RANKING_REMAINING_DAYS: {
    es: 'Quedan <b>{{days}} días</b> para decidir los ganadores',
    en: 'There are <b>{{days}} days</b> left to decide the winners',
    cat: 'Queden <b>{{days}} dies</b> per decidir els guanyadors',
    eus: 'Quedan <b>{{days}} días</b> para decidir los ganadores'
  },
  RANKING_REMAINING_HOURS: {
    es: 'Quedan <b>{{hours}} horas</b> para decidir los ganadores',
    en: 'There are <b>{{hours}} hours</b> left to decide the winners',
    cat: 'Queden <b>{{hours}} hores</b> per decidir els guanyadors',
    eus: 'Quedan <b>{{hours}} horas</b> para decidir los ganadores'
  },
  RANKING_DATA_FROM: {
    es: 'Datos de {{month}} de {{year}}',
    en: 'Data from {{month}} of {{year}}',
    cat: 'Dades de {{month}} de {{year}}',
    eus: 'Datos de {{month}} de {{year}}'
  },
  HOW_DO_RANKING_WORK: {
    es: '¿Cómo funciona el ranking?',
    en: 'How does the ranking work?',
    cat: 'Com funciona el rànquing?',
    eus: '¿Cómo funciona el ranking?'
  },
  RANKING_EXPLANATION_1: {
    es: 'Los puntos que ganes durante el mes y tu puntuación media de los tests que hayas hecho en esta formación es lo que tenemos en cuenta para las posiciones.',
    en: 'The points you earn during the month and your average score of the tests you have taken in this training is what we take into account for the positions.',
    cat: 'Els punts que guanyis durant el mes i la teva puntuació mitjana dels tests que hagis fet en aquesta formació és el que tenim en compte per a les posicions.',
    eus: 'Los puntos que ganes durante el mes y tu puntuación media de los tests que hayas hecho en esta formación es lo que tenemos en cuenta para las posiciones.'
  },
  RANKING_EXPLANATION_2: {
    es: 'Para la posición del ranking, cuentan un 70% los puntos y un 30% la nota.',
    en: 'For the ranking position, 70% of the points and 30% of the grade count.',
    cat: 'Per a la posició del rànquing, compten un 70% els punts i un 30% la nota.',
    eus: 'Para la posición del ranking, cuentan un 70% los puntos y un 30% la nota.'
  },
  RANKING_EXPLANATION_3: {
    es: 'Los puntos se reinician cada mes, y para mejorar tu nota media, puedes repetir los tests que peor te hayan salido o hacer nuevos.',
    en: 'The points are reset every month, and to improve your average score, you can repeat the tests that have gone worst for you or do new ones.',
    cat: 'Els punts es reinicien cada mes, i per millorar la teva nota mitjana, pots repetir els tests que pitjor t’hagin sortit o fer-ne de nous.',
    eus: 'Los puntos se reinician cada mes, y para mejorar tu nota media, puedes repetir los tests que peor te hayan salido o hacer nuevos.'
  },
  RANKING_EXPLANATION_4: {
    es: 'El ranking se actualiza una vez al día.',
    en: 'The ranking is updated once a day.',
    cat: 'El rànquing s’actualitza un cop al dia.',
    eus: 'El ranking se actualiza una vez al día.'
  },
  GET_POINTS_TO_ENTER_RANKING: {
    es: 'Consigue {{points}}⚡ para entrar al ranking mensual',
    en: 'Get {{points}}⚡ to enter the monthly ranking',
    cat: 'Aconsegueix {{points}}⚡ per entrar al rànquing mensual',
    eus: 'Consigue {{points}}⚡ para entrar al ranking mensual'
  },
  STUDY_AND_GET_POINTS: {
    es: 'Estudiar y conseguir puntos',
    en: 'Study and get points',
    cat: 'Estudiar i aconseguir punts',
    eus: 'Estudiar y conseguir puntos'
  },
  PREPARING_RANKING_1: {
    es: 'Estamos preparando el ranking de este mes.',
    en: 'We are preparing this month’s ranking.',
    cat: 'Estem preparant el rànquing d’aquest mes.',
    eus: 'Estamos preparando el ranking de este mes.'
  },
  PREPARING_RANKING_2: {
    es: 'El día {{day}} lo publicaremos.',
    en: 'We will publish it on {{day}}.',
    cat: 'El dia {{day}} el publicarem.',
    eus: 'El día {{day}} lo publicaremos.'
  },
  THERE_ARE_NO_RANKING_FOR_THIS_MONTH: {
    es: 'No hay ranking disponible para este mes.',
    en: 'There is no ranking available for this month.',
    cat: 'No hi ha rànquing disponible per aquest mes.',
    eus: 'No hay ranking disponible para este mes.'
  },
  SORRY_FOR_THE_INCONVENIENCE: {
    es: 'Disculpa las molestias',
    en: 'Sorry for the inconvenience',
    cat: 'Disculpa les molèsties',
    eus: 'Disculpa las molestias'
  }
};
