import { EQuestionType } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div<{ type: EQuestionType }>`
  height: 20px;
  text-align: center;
  border-radius: 5px;
  font-weight: 300;
  background-color: ${({ type }) =>
    type === EQuestionType.AI ? 'rgba(250, 217, 0, 0.2)' : 'rgba(96, 110, 235, 0.2)'};
  margin: auto;
`;
