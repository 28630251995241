import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TicketService = sdk.getService('TicketService');

export const useMyTicketDetail = (ticketId: string | null) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['tickets', ticketId],
    queryFn: () => TicketService.getTicket(ticketId!),
    enabled: !!ticketId
  });

  return {
    ticket: data,
    isLoading,
    error
  };
};
