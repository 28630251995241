import { create } from 'zustand';

interface IStore {
  isFormOpen: boolean;
  setIsFormOpen: (isFormOpen: boolean) => void;
}

export const useCourseForm = create<IStore>(set => ({
  isFormOpen: false,
  setIsFormOpen: isFormOpen => set({ isFormOpen })
}));
