import { isAudio, isVideo } from './mimeTypeCheck';

window.URL = window.URL || window.webkitURL;

/**
 * It takes a file, creates a video element, sets the video's source to the file, and then returns the
 * video's duration
 * @param {File} file - File - The file object that you want to get the duration of.
 * @returns A promise that resolves to the duration of the file.
 */
export const getFileDuration = (file: File): Promise<number> => {
  return new Promise(resolve => {
    const isVideoFile = isVideo(file.type);
    const isAudioFile = isAudio(file.type);

    const type = isVideoFile ? 'video' : isAudioFile ? 'audio' : null;

    if (!type) resolve(0);

    document.querySelector(`.ant-upload-drag-container > div > ${type}`) as HTMLMediaElement;

    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      resolve(duration);
      video.remove();
    };

    video.src = URL.createObjectURL(file as File);
  });
};
