import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .ant-table {
    box-shadow: unset !important;
  }

  .ant-pagination {
    background: ${({ theme }) => theme.colors.black2};
    box-shadow: unset !important;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const TagWrapper = styled.div`
  max-width: 340px;
  overflow: hidden;
`;
