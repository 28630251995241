import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 16px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
`;

export const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black9};

  & > :nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
  }

  & > :nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: ${({ theme }) => theme.fontFamily.primary};
  }
`;

export const Emoji = styled.span`
  font-size: 18px;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const BadgeCountWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
