export const ManagerInternalUsersTranslations = {
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  NAME_AND_LASTNAME: {
    es: 'Nombre y apellido',
    en: 'Name and lastname',
    cat: 'Nom i cognom',
    eus: 'Izena eta abizena'
  },
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktiboa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu desaktibatua'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Emaila'
  },
  INSCRIPTION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Inscription date',
    cat: "Data d'inscripció",
    eus: 'Izena eman den data'
  },
  THERE_ARE_NOT_COURSES_ASOCIATED_TO_THIS_USER: {
    es: 'No hay cursos asociados a este usuario',
    en: 'There are not courses asociated to this user',
    cat: 'No hi ha cursos associats a aquest usuari',
    eus: 'Ez dago erabiltzaile honi lotutako ikastaroik'
  },
  CREATE_USER: {
    es: 'Crear usuario',
    en: 'Create user',
    cat: 'Crear usuari',
    eus: 'Sortu erabiltzailea'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  USERNAME: {
    es: 'Usuario',
    en: 'Username',
    cat: 'Usuari',
    eus: 'Erabiltzaile izena'
  },
  NEW_USER: {
    es: 'Nuevo usuario',
    en: 'New user',
    cat: 'Nou usuari',
    eus: 'Erabiltzaile berria'
  },
  USERS_TOTAL_COUNT: {
    es: '{{total}} usuario/s',
    en: '{{total}} user/s',
    cat: '{{total}} usuari/s',
    eus: '{{total}} erabiltzaile'
  },
  ASSIGN_COURSE: {
    es: 'Asignar curso',
    en: 'Assign course',
    cat: 'Assignar curs',
    eus: 'Ikastaroa asignatu'
  },
  EDIT_USER: {
    es: 'Editar usuario',
    en: 'Edit user',
    cat: 'Editar usuari',
    eus: 'Editatu erabiltzailea'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  CONNECTION: {
    es: 'Conexión',
    en: 'Connection',
    cat: 'Connexió',
    eus: 'Konexioa'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol'
  },
  ARE_YOU_SURE_TO_DELETE_USER: {
    es: '¿Estás seguro que deseas eliminar al usuario?',
    en: 'Are you sure you want to delete the user?',
    cat: "Estàs segur que vols eliminar l'usuari?",
    eus: 'Ziur zaude erabiltzailea ezabatu nahi duzula?'
  },
  USER_DELETED_SUCCESSFULLY: {
    es: 'Usuario eliminado correctamente',
    en: 'User deleted successfully',
    cat: 'Usuari eliminat correctament',
    eus: 'Erabiltzailea ongi ezabatu da'
  },
  REMOVE: {
    es: 'Eliminar',
    en: 'Remove',
    cat: 'Eliminar',
    eus: 'Kendu'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro que deseas reiniciar la contraseña?',
    en: 'Are you sure to reset password?',
    cat: 'Estàs segur que vols reiniciar la contrasenya?',
    eus: 'Ziur zaude pasahitza berrezarri nahi duzula?'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Contraseña reiniciada correctamente, el usuario recibirá un correo con la nueva contraseña',
    en: 'Password reset successfully, the user will receive an email with the new password',
    cat: "Contrasenya reiniciada correctament, l'usuari rebrà un correu amb la nova contrasenya",
    eus: 'Pasahitza berrezarri da, erabiltzaileak mezu bat jasoko du berriro pasahitzarekin'
  }
};
