import { EDeliverableStatus, ETicketStatus } from '@constants';
import { Badge } from 'antd';
import { isNumber } from 'lodash';
import { FC } from 'react';

import { Wrapper } from './CsatReview.styles';

import { Icon } from '@/components/Icon';
import { useProfile } from '@/store';
import { checkUserRole } from '@/utils';

type Props = {
  status: ETicketStatus | EDeliverableStatus;
  csat?: number;
};

const ICON_SIZE = 20;

export const CsatReview: FC<Props> = ({ csat, status }) => {
  const { profile } = useProfile();
  const { isStudent } = checkUserRole(profile);

  const getCsatIcon = (value: number) => {
    switch (value) {
      case 0:
        return 'FaceNeutralFilled';
      case -1:
        return 'FaceDissatisfiedFilled';
      case 1:
        return 'FaceSatisfiedFilled';
      default:
        return 'FaceSatisfied';
    }
  };

  const isSolved =
    status === ETicketStatus.CLOSED_RESOLVED || status === EDeliverableStatus.COMPLETED;

  if (!isNumber(csat) && isSolved && isStudent)
    return (
      <Wrapper>
        <Badge dot>
          <Icon i="FaceSatisfied" />
        </Badge>
      </Wrapper>
    );

  if (!isNumber(csat)) return null;

  return (
    <Wrapper>
      <Icon size={ICON_SIZE} i={getCsatIcon(csat)} />
    </Wrapper>
  );
};
