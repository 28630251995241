import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    gap: 8px;
  }

  .ant-collapse-header-text {
    color: ${({ theme }) => theme.colors.black9};
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;
