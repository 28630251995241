import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TaskService = sdk.getService('TaskService');

export const usePendingTasksList = () => {
  const queryKey = ['tasks', 'pending'];
  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => TaskService.findPendingTasks()
  });

  return {
    tasks: data,
    isPending
  };
};
