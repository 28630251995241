import { create as createStore } from 'zustand';

interface IStore {
  postId?: string;
  setPostId: (postId?: string) => void;
}

export const usePostViewer = createStore<IStore>(set => ({
  postId: undefined,
  setPostId: (postId?: string) => set({ postId })
}));
