import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const DeliverableService = sdk.getService('DeliverableService');

export const useDeliverableByActivity = (activityId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['deliverables', 'by-activity', activityId],
    queryFn: () => DeliverableService.getDeliverableByActivity(activityId),
    enabled: !!activityId
  });

  return {
    deliverable: data,
    isLoading,
    error
  };
};
