import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

type Props = {
  isLocked?: boolean;
};

export const TitleWrapper = styled.span<Props>`
  color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
`;

export const DescriptionWrapper = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black7};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0 !important;
`;
