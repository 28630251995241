import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.span`
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
`;

export const SubtitleWrapper = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;
