import { RequestService } from '../request';

import { ITask } from './task.contract';

export class TaskService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public findPendingTasks() {
    return this.fetcher<ITask[]>('/tasks/pending');
  }
}
