import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const IconWrapper = styled(Flex)<{ danger: boolean }>`
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 100px;
  background-color: ${({ theme, danger }) => (danger ? theme.colors.white : theme.colors.black9)};
  border: 1px solid ${({ theme, danger }) => (danger ? theme.colors.red6 : theme.colors.black9)};
`;

export const NameWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
