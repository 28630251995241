import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
