import { useMutation } from '@tanstack/react-query';

import { useCreateTicketForm } from '@/pages/Student/Course/store';
import { sdk } from '@/services';

const NebulonQuestionAnsweringService = sdk.getService('NebulonQuestionAnsweringService');

export const useGenerateAnswer = (courseId = '') => {
  const { setAnswer } = useCreateTicketForm();
  const filter = { courseId };

  const { mutate: generateAnswer, isPending } = useMutation({
    mutationFn: (query: { title?: string; description: string }) =>
      NebulonQuestionAnsweringService.generateQuestionAnswer(
        `${query.title}\n${query.description}`,
        filter
      ),
    onSuccess: data => setAnswer(data)
  });

  return {
    generateAnswer,
    isPending
  };
};
