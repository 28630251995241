import { create as createStore } from 'zustand';

interface IStore {
  transcriptionId: string | null;
  isOpen: boolean;
  setTranscriptionId: (transcriptionId: string | null) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useTranscriptionPreview = createStore<IStore>(set => ({
  transcriptionId: null,
  isOpen: false,
  setTranscriptionId: (transcriptionId: string | null) => set({ transcriptionId }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
