import { IExam } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ExamService = sdk.getService('ExamService');

export const useMutateExams = () => {
  const { t } = useTranslation('DefaultTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['exams'] });

  const { mutate: createExam, isPending: isCreating } = useMutation({
    mutationFn: (create: Partial<IExam>) => ExamService.createExam(create),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateExam, isPending: isUpdating } = useMutation({
    mutationFn: (update: Partial<IExam>) => ExamService.updateExam(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: deleteExam, isPending: isDeleting } = useMutation({
    mutationFn: (examId: string) => ExamService.deleteExam(examId),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    createExam,
    updateExam,
    deleteExam,
    isWorking: isCreating || isUpdating || isDeleting
  };
};
