import { IExamGroup } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Button, Divider } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  ItemWrapper,
  InfoWrapper,
  TitleWrapper,
  DateWrapper
} from './ExamGroupItem.styles';

import CHALLENGE_COMPLETE from '@/assets/challenge_complete.svg';
import { Icon } from '@/components/Icon';
import { useMutateExamGroup, useProfile } from '@/store';

type Props = {
  examGroup: IExamGroup;
  onClick: (examGroupId: string) => void;
};

export const ExamGroupItem: React.FC<Props> = ({ examGroup, onClick }) => {
  const { t } = useTranslation();
  const { profile } = useProfile();
  const { addMembers, removeMember, isWorking } = useMutateExamGroup();

  const isUserMember = useMemo(() => {
    return examGroup.members.some(member => member._id === profile?._id);
  }, [profile, examGroup]);

  if (!profile) return null;

  const handleJoinExamGroup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isUserMember) {
      removeMember({ groupId: examGroup._id, memberId: profile._id });
    } else {
      addMembers({ groupId: examGroup._id, membersIds: [profile._id] });
    }
  };

  return (
    <Wrapper>
      <ItemWrapper key={examGroup._id} onClick={() => onClick(examGroup._id)}>
        <InfoWrapper>
          <TitleWrapper>{examGroup.name}</TitleWrapper>
          <DateWrapper>
            {t('PUBLISHED_AT', { date: dateProvider.format(examGroup.publishedAt, 'dd/MM/yyyy') })}
          </DateWrapper>
        </InfoWrapper>
        {isUserMember ? (
          <img
            src={CHALLENGE_COMPLETE}
            alt="user joined exam group icon"
            onClick={handleJoinExamGroup}
          />
        ) : (
          <Button
            onClick={handleJoinExamGroup}
            shape="circle"
            icon={<Icon i="Add" />}
            size="small"
            loading={isWorking}
          />
        )}
      </ItemWrapper>
      <Divider type="horizontal" />
    </Wrapper>
  );
};
