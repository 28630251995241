import { Button } from 'antd';

import { Icon } from '@/components/Icon';

type NavigationButtonProps = {
  direction: 'next' | 'prev';
  onClick: () => void;
  disabled: boolean;
};

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  direction,
  onClick,
  disabled
}) => {
  return (
    <Button
      type="primary"
      onClick={onClick}
      icon={direction === 'next' ? <Icon i="ArrowRight" /> : <Icon i="ArrowLeft" />}
      disabled={disabled}
    />
  );
};
