import { CSSProperties } from 'react';

import { Wrapper } from './Box.styles';

type Props = {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  space?: 'between' | 'around' | 'evenly';
  margin?: string;
} & CSSProperties;

export const Box: React.FC<Props> = ({ children, ...props }) => {
  return <Wrapper style={{ ...props }}>{children}</Wrapper>;
};
