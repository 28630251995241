import { ILast14DaysActivity } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useLast14DaysActivity = (subscriptionId?: string) => {
  const queryKey = ['subscriptions', subscriptionId, 'last-14-days-activity'];

  const {
    data: last14DaysActivity,
    isLoading,
    error
  } = useQuery({
    queryKey,
    queryFn: () => SubscriptionService.findLast14DaysActivity(subscriptionId!),
    enabled: !!subscriptionId
  });

  const groupedBySection = last14DaysActivity?.reduce((acc, activityTrack) => {
    const section = activityTrack.section;
    if (!acc[section._id]) {
      acc[section._id] = [];
    }
    acc[section._id].push(activityTrack);
    return acc;
  }, {} as Record<string, ILast14DaysActivity[]>);

  return {
    last14DaysActivity,
    groupedBySection,
    isLoading,
    error
  };
};
