import { ENpsVoteType } from '@constants';

import { useNpsVoteLogic } from '../../../hooks';
import { Wrapper } from '../NpsVote.styles';

import { OnboardingVoteReason } from './OnboardingVoteReason';
import { VoteReason } from './VoteReason';

import { useCheckNpsVote } from '@/store';

export const NpsReason: React.FC = () => {
  const { vote, reasons } = useNpsVoteLogic();
  const { voteCheck } = useCheckNpsVote();
  return (
    <>
      {voteCheck?.type === ENpsVoteType.REGULAR ? (
        <Wrapper>
          <VoteReason vote={vote} reasons={reasons} />
        </Wrapper>
      ) : (
        <Wrapper>
          <OnboardingVoteReason />
        </Wrapper>
      )}
    </>
  );
};
