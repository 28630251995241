import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px 24px 24px;
  gap: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
