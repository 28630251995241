import { IActivity } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  selectedMaterialActivity?: IActivity;
  setSelectedMaterialActivity: (activity?: IActivity) => void;
}

export const useComplementaryMaterialViewer = create<IStore>(set => ({
  setSelectedMaterialActivity: activity => set({ selectedMaterialActivity: activity })
}));
