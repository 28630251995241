export const SectionReviewTranslations = {
  REVIEW_THE_SECTION: {
    es: 'Valora la sección',
    en: 'Review the section',
    cat: 'Revisa la secció',
    eus: 'Aztertu atala'
  },
  HELP_US_TO_IMPROVE: {
    es: 'Ayúdanos a mejorar respondiendo las siguientes preguntas:',
    en: 'Help us improve by answering the following questions:',
    cat: 'Ajuda`ns a millorar responent les següents preguntes:',
    eus: 'Mendekatu gure hobekuntza erantzun atal honetako galderak:'
  },
  COURSE_WAS_WELL_ORGANIZED: {
    es: 'El curso estuvo bien organizado (información, cumplimiento de fechas y de horarios)',
    en: 'The course was well organized (information, compliance with dates and schedules)',
    cat: 'El curs va estar ben organitzat (informació, compliment de dates i horaris)',
    eus: 'Kurtsuak ondo antolatuta egon zen (informazioa, egutegi eta ordutegiak betetzea)'
  },
  SATISFIED_WITH_THE_CONTENT: {
    es: 'Los contenidos del curso han respondido a mis necesidades formativas',
    en: 'The course content has met my training needs',
    cat: 'Els continguts del curs han respost a les meves necessitats formatives',
    eus: 'Kursuaren edukiak nire prestakuntza beharrak erantzuten dio'
  },
  THEORY_AND_PRACTICE_BALANCE: {
    es: 'Ha habido una combinación adecuada de teoría y aplicación práctica',
    en: 'There has been an adequate combination of theory and practical application',
    cat: 'Hi ha hagut una combinació adequada de teoria i aplicació pràctica',
    eus: 'Teoria eta praktika aplikatua nahikoa nahizkoa izan da'
  },
  WAY_INFORMATION_WAS_PRESENTED: {
    es: 'Me ha resultado interesante y llamativa la manera de presentar la información',
    en: 'I found the way the information was presented interesting and appealing',
    cat: 'M`ha resultat interessant i cridaner la manera de presentar la informació',
    eus: 'Interesgarria eta deigarria izan zait informazioa aurkeztea'
  },
  VIRTUAL_MEDIA_FLEXIBILITY: {
    es: 'A la hora de realizar el curso, ¿le ha resultado cómodo poder realizarlo de manera flexible a través de los medios virtuales?',
    en: 'When taking the course, did you find it comfortable to be able to take it flexibly through virtual media?',
    cat: 'A l`hora de realitzar el curs, li ha resultat còmode poder realitzar-lo de manera flexible a través dels mitjans virtuals?',
    eus: 'Kursua egiteko orduan, zaila izan zaitu flexiblea izan ahal izatea birtualen bidez'
  },
  TOTALLY_DISAGREE: {
    es: 'Completamente en desacuerdo',
    en: 'Totally disagree',
    cat: 'Totalment en desacord',
    eus: 'Desakordio osoa'
  },
  DISAGREE: {
    es: 'En desacuerdo',
    en: 'Disagree',
    cat: 'En desacord',
    eus: 'Desakordioan'
  },
  AGREE: {
    es: 'De acuerdo',
    en: 'Agree',
    cat: 'D`acord',
    eus: 'Ados'
  },
  TOTALLY_AGREE: {
    es: 'Completemente de acuerdo',
    en: 'Totally agree',
    cat: 'Totalment d`acord',
    eus: 'Ados osoa'
  },
  SECTION_REVIEW_SUBMITTED: {
    es: 'Valoración enviada',
    en: 'Review submitted',
    cat: 'Revisió enviada',
    eus: 'Azterketa bidalia'
  },
  NO_REVIEWS: {
    es: 'No hay valoraciones',
    en: 'No reviews',
    cat: 'No hi ha revisions',
    eus: 'Ez dago azterketarik'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  SECTION_REVIEWS: {
    es: 'Valoraciones de secciones',
    en: 'Section reviews',
    cat: 'Valoracions de seccions',
    eus: 'Atalak baloratu'
  }
};
