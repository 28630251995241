import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  i + span {
    margin-left: 10px;
  }

  & > :first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  & > :last-child {
    display: flex;
    gap: 16px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
