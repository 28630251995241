import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';
import { formatDataAndDownloadCsv } from '@/utils';

const CourseService = sdk.getService('CourseService');

export const useDownloadUploadedFilesData = () => {
  const { isPending: isUploadedFilesLoading, mutate: downloadUploadedFilesData } = useMutation({
    mutationFn: () => CourseService.downloadUploadedFilesData(),
    onSuccess: data => formatDataAndDownloadCsv(data, 'uploaded_files.csv')
  });

  return {
    isUploadedFilesLoading,
    downloadUploadedFilesData
  };
};
