import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PopoverWrapper = styled.div`
  width: 300px;
  .ant-select {
    width: 100%;
  }
`;
