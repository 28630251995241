import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

export const useCookies = (key: string, initialValue: string) => {
  const [value, setValue] = useState(() => {
    try {
      const cookieValue = Cookies.get(key);
      return cookieValue ? JSON.parse(cookieValue) : JSON.parse(initialValue);
    } catch (error) {
      console.error(`Error parsing JSON value for cookie ${key}:`, error);
      return initialValue;
    }
  });

  useEffect(() => {
    Cookies.set(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
