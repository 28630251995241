import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :nth-child(2) {
    & > :first-child {
      margin-bottom: 30px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
  }
`;

export const StudentSelectorWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    max-width: 150px;
    margin-left: auto;
    margin-right: 10px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    max-width: 300px;
  }
`;
