import { Flex } from 'antd';
import styled from 'styled-components';

type Props = {
  border: string;
  background: string;
  color: string;
};

export const Wrapper = styled(Flex)<Props>`
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.2px;
  border: 1px solid ${({ border }) => border};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;
