import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 52px;
  & > :first-child {
    font-size: 30px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      flex-direction: row;
    }
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding-top: 24px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding-top: 0px;
  }
`;

export const SubTitleTextWrapper = styled.div`
  margin-bottom: 16px;
`;
