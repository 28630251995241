import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import {
  ICreateProblemReport,
  IProblemReport,
  IProblemReportDetail,
  IProblemReportFilters,
  IUpdateProblemReportStatus
} from './problem-report.contract';

export class ProblemReportService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getProblemReports(
    pagination: IPagination,
    search?: string,
    filters?: IProblemReportFilters
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters
    });
    return this.fetcher<IPaginatedResponse<IProblemReport>>(`/problem-reports?${query}`);
  }

  public createProblemReport(problemReport: ICreateProblemReport) {
    return this.poster('/problem-reports', problemReport);
  }

  public getProblemReportDetail(problemReportId: string) {
    return this.fetcher<IProblemReportDetail>(`/problem-reports/${problemReportId}`);
  }

  public updateProblemReportStatus({ _id, reportId, newStatus }: IUpdateProblemReportStatus) {
    return this.putter(`/problem-reports/${_id}/report/${reportId}`, { status: newStatus });
  }

  public removeProblemReport(problemReportId: string) {
    return this.deleter(`/problem-reports/${problemReportId}`);
  }
}
