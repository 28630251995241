import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 16px;
  flex: 1 0 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black2};
  min-height: 610px;
  height: 100%;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const TagsWrapper = styled(Flex)`
  width: 100%;
  max-width: 396px;
  flex-wrap: wrap;
  overflow: auto;
`;
