import { SOCKET_EVENTS } from '@constants';
import { destroyCookie } from '@utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSocket } from '@/hooks';
import { useProfile } from '@/store';

const TIMEOUT = 500;

export const EjectionHandler = () => {
  const { socket } = useSocket();
  const { profile } = useProfile();
  const navigateTo = useNavigate();

  const handleEjectUser = (userId: string) => {
    if (userId !== profile?._id) return;
    destroyCookie('token', import.meta.env.VITE_COOKIE_DOMAIN);
    setTimeout(() => {
      navigateTo('/login');
      window.location.reload();
    }, TIMEOUT);
  };

  useEffect(() => {
    if (!socket) return;
    socket?.on(SOCKET_EVENTS.EJECT_USER, (userId: string) => {
      handleEjectUser(userId);
    });
  }, [socket, profile]);
  return null;
};
