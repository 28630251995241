import { IMessage } from '../messages/messages.contract';
import { RequestService } from '../request';

import { IChat } from './chat.contract';

export class ChatService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getChatByParticipants(tutorId?: string, studentId?: string) {
    return this.fetcher<IChat>(`/chats/${tutorId}/${studentId}`);
  }

  public setAllMessagesAsRead(chatId: string) {
    return this.fetcher(`/chats/messages/read-confirmation/${chatId}`);
  }

  public findStudentPendingMessages() {
    return this.fetcher<IMessage[]>(`/chats/pending-messages`);
  }
}
