import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const SessionLogService = sdk.getService('SessionLogService');

export const useLast14DaysConnectedTime = (userId: string) => {
  const queryKey = ['session-log', 'last-14-days', userId];

  const { data: last14DaysConnectedTime, isLoading: isLoadingLast14DaysConnectedTime } = useQuery({
    queryKey,
    queryFn: () => SessionLogService.getLast14DaysConnectedTime(userId)
  });

  return {
    last14DaysConnectedTime,
    isLoadingLast14DaysConnectedTime
  };
};
