import { IBasicFile, IFile } from '@sdk/contracts';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

type MediaProps = {
  file: IFile | IBasicFile;
  isDownloadable?: boolean;
};

const MAX_WIDTH = 472;
const MAX_HEIGHT = 255;

export const VideoPreview: React.FC<MediaProps> = ({ file, isDownloadable = false }) => {
  const { t } = useTranslation('CourseTranslations');

  if (!file || !file?.url)
    return <Alert type="error" showIcon message={t('CANT_LOAD_ACTIVITY_RESOURCE')} />;

  const config = isDownloadable
    ? {}
    : {
        file: {
          attributes: {
            controlsList: 'nodownload',
            onContextMenu: function () {
              return false;
            }
          }
        }
      };

  return (
    <ReactPlayer
      controls
      width="100%"
      height="100%"
      url={file.url}
      style={{ maxWidth: MAX_WIDTH, maxHeight: MAX_HEIGHT }}
      config={config}
    />
  );
};
