import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  & > i {
    font-size: 32px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
