import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 10;

interface IStore {
  page: number;
  limit: number;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

const QuizResultService = sdk.getService('QuizResultService');

export const useMyQuizResultList = () => {
  const { page, limit, setPageAndLimit } = store();
  const queryKey = ['quiz-results', `page-${page}`, `limit-${limit}`];

  const { isPending, data } = useQuery({
    queryKey,
    queryFn: () => QuizResultService.getQuizResults({ page, limit })
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    isPending,
    quizResults: data?.docs,
    pagination,
    setPageAndLimit
  };
};
