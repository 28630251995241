import {
  EGamificationRewardType,
  FIRST_DAY_ACTIONS,
  SECOND_DAY_ACTIONS,
  THIRD_DAY_ACTIONS
} from '@constants';

export const isDailyAction = (rewardType: EGamificationRewardType): boolean =>
  (
    [...FIRST_DAY_ACTIONS, ...SECOND_DAY_ACTIONS, ...THIRD_DAY_ACTIONS] as EGamificationRewardType[]
  ).includes(rewardType);
