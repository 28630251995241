import { ITaskItem } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  taskItem?: ITaskItem;
  setTaskItem: (taskItem?: ITaskItem) => void;
}

export const useChangeStatusForm = create<IStore>(set => ({
  setTaskItem: taskItem => set({ taskItem })
}));
