import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    & > *:only-child {
      max-width: 50%;
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const TitleWrapper = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 16px; // Añade espacio en la parte inferior

  .ant-tag-checkable-checked {
    background-color: ${({ theme }) => theme.colors.purple6};
  }
`;
