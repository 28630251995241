export const StudentEventsTranslations = {
  CALENDAR: {
    es: 'Calendario',
    en: 'Calendar',
    cat: 'Calendari',
    eus: 'Kalendarioa'
  },
  MORE_EVENTS_TEXT: {
    es: '+{{num}} Más',
    en: '+{{num}} More',
    cat: '+{{num}} Més',
    eus: '+{{num}} Gehiago'
  },
  DAY_WEEK_0: {
    es: '💆‍♀️ Domingo',
    en: '💆‍♀️ Sunday',
    cat: '💆‍♀️ Diumenge',
    eus: '💆‍♀️ Igandea'
  },
  DAY_WEEK_1: {
    es: '🧟 Lunes',
    en: '🧟 Monday',
    cat: '🧟 Dilluns',
    eus: '🧟 Astelehena'
  },
  DAY_WEEK_2: {
    es: '☕️ Martes',
    en: '☕️ Tuesday',
    cat: '☕️ Dimarts',
    eus: '☕️ Asteartea'
  },
  DAY_WEEK_3: {
    es: '📚️ Miércoles',
    en: '📚️ Wednesday',
    cat: '📚️ Dimecres',
    eus: '📚️ Asteazkena'
  },
  DAY_WEEK_4: {
    es: '🧠 Jueves',
    en: '🧠 Thursday',
    cat: '🧠 Dijous',
    eus: '🧠 Osteguna'
  },
  DAY_WEEK_5: {
    es: '🍺 Viernes',
    en: '🍺 Friday',
    cat: '🍺 Divendres',
    eus: '🍺 Ostirala'
  },
  DAY_WEEK_6: {
    es: '🎉 Sábado',
    en: '🎉 Saturday',
    cat: '🎉 Dissabte',
    eus: '🎉 Larunbata'
  },
  MONTH: {
    es: 'Mes',
    en: 'Month',
    cat: 'Mes',
    eus: 'Hilabetea'
  },
  WEEK: {
    es: 'Semana',
    en: 'Week',
    cat: 'Setmana',
    eus: 'Astea'
  },
  LIST: {
    es: 'Lista',
    en: 'List',
    cat: 'Llista',
    eus: 'Zerrenda'
  },
  DAY: {
    es: 'Día',
    en: 'Day',
    cat: 'Dia',
    eus: 'Eguna'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Fitxategiak'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Training',
    cat: 'Formació',
    eus: 'Ikasgaia'
  },
  TEACHER: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  CLEAR_FILTERS: {
    es: 'Borrar filtros',
    en: 'Clear filters',
    cat: 'Netejar filtres',
    eus: 'Ezabatu filtrotak'
  },
  CALENDAR_INFO_MODAL_TITLE: {
    es: '¿Qué puedes ver en el calendario?',
    en: 'What can you see in the calendar?',
    cat: 'Què pots veure en el calendari?',
    eus: 'Zerrendan zer moduan ikus daiteke?'
  },
  CALENDAR_INFO_MODAL_DESCRIPTION_PLANIFICATION: {
    es: 'Planificación del estudio: Son los bloques de color, lo que tienes que estudiar cada día.',
    en: 'Study plan: They are the colored blocks, what you have to study each day.',
    cat: "Pla de l'estudi: Són els blocs de color, el que has d'estudiar cada dia.",
    eus: 'Ikaslearen planifikazioa: Zerrenda-formatuak, egun bakoitzeko ikasten duzun zerbait.'
  },
  CALENDAR_INFO_MODAL_DESCRIPTION_EVENTS: {
    es: 'Clases en directo y eventos: Los eventos del calendario que empiezan a una hora concreta.',
    en: 'Live classes and events: The events of the calendar that start at a specific time.',
    cat: 'Classes en directe i esdeveniments: Els esdeveniments del calendari que comencen a una hora concreta.',
    eus: 'Erabiltzaile aktibazioak eta gela-jardunak: Zerrenda-formatuak, egun bakoitzeko ikasten duzun zerbait.'
  },
  PLANIFICATION: {
    es: 'Planificación del estudio',
    en: 'Study plan',
    cat: "Pla de l'estudi",
    eus: 'Ikaslearen planifikazioa'
  },
  LIVE_CLASSES: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Erabiltzaile aktibazioak'
  },
  CONFIGURE_PLANIFICATION: {
    es: 'Configurar planificación',
    en: 'Configure planification',
    cat: 'Configure planificació',
    eus: 'Planifikazioa konfiguratzeko'
  },
  PLANIFICATION_TOOLTIP: {
    es: 'Para visualizar tu planificación debes configurarla',
    en: 'To see your planification you must configure it',
    cat: 'Per veure la teva planificació, has de configurar-la',
    eus: 'Planifikazioa ikusteko, konfiguratu behar duzu'
  }
};
