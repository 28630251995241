import { AnalyticsTrackEvents } from '@constants';
import FullCalendar from '@fullcalendar/react';
import { ECalendarViewType } from '@sdk/contracts';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { useMixpanel } from '@/hooks';
import { useEventsList } from '@/store';

type IStore = {
  date?: string;
  setDate: (date?: string) => void;
  currentView: ECalendarViewType;
  setCurrentView: (currentView?: ECalendarViewType) => void;
};

const store = createStore<IStore>(set => ({
  setDate: date => set({ date }),
  currentView: ECalendarViewType.DAY_GRID_MONTH,
  setCurrentView: currentView => set({ currentView })
}));

export const useCalendar = (calendarRef: React.RefObject<FullCalendar>) => {
  const { date, setDate, currentView, setCurrentView } = store();
  const { setFilters, filters } = useEventsList();
  const navigateTo = useNavigate();
  const { fireEvent } = useMixpanel();

  const calendarApi = calendarRef.current?.getApi();

  useEffect(() => {
    setDate(calendarApi?.view.currentStart.toString());
  }, [calendarApi?.view.currentStart.toString()]);

  const handleNext = () => {
    const currentStart = calendarApi?.view.currentStart;
    setDate(currentStart?.toString());
    setFilters({
      ...filters,
      month: dayjs(currentStart).month(),
      year: dayjs(currentStart).year()
    });
    calendarApi?.next();
  };
  const handlePrev = () => {
    const currentStart = calendarApi?.view.currentStart;
    setDate(currentStart?.toString());
    setFilters({
      ...filters,
      month: dayjs(currentStart).month(),
      year: dayjs(currentStart).year()
    });
    calendarApi?.prev();
  };
  const handleToday = () => {
    const currentStart = calendarApi?.view.currentStart;
    setDate(currentStart?.toString());
    setFilters({
      ...filters,
      month: dayjs(currentStart).month(),
      year: dayjs(currentStart).year()
    });
    calendarApi?.today();
  };

  const handleChangeView = (view: ECalendarViewType) => {
    setCurrentView(view);
    calendarApi?.changeView(view);
  };

  const title = useMemo(() => calendarApi?.view.title, [currentView, date]);

  const handleSeeMoreEvents = () => {
    setCurrentView(ECalendarViewType.TIME_GRID_DAY);
    return ECalendarViewType.TIME_GRID_DAY;
  };

  const isDayGridMonth = useMemo(
    () => currentView === ECalendarViewType.DAY_GRID_MONTH,
    [currentView]
  );

  const isListWeek = useMemo(() => currentView === ECalendarViewType.LIST_WEEK, [currentView]);

  const goToEvent = (eventId: string, eventName: string) => {
    navigateTo(`/content/calendar/${eventId}`);
    setCurrentView(ECalendarViewType.DAY_GRID_MONTH);
    fireEvent(AnalyticsTrackEvents.EVENTS_CONSULT, {
      eventName,
      eventActive: true
    });
  };

  return {
    currentView,
    isDayGridMonth,
    isListWeek,
    handleNext,
    handlePrev,
    handleToday,
    handleChangeView,
    title,
    handleSeeMoreEvents,
    goToEvent
  };
};
