import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonDashboardTranslations } from '../../../../i18n/index';

import { ValueWrapper, Wrapper, TitleWrapper } from './InfoItem.styles';

type Props = {
  title: keyof typeof CommonDashboardTranslations;
  value: number;
};

export const InfoItem: FC<Props> = ({ title, value }) => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <Wrapper>
      <ValueWrapper>{value}</ValueWrapper>
      <TitleWrapper>{t(title)}</TitleWrapper>
    </Wrapper>
  );
};
