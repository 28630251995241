import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { CoursesWrapper } from './DashboardTitle.styles';
import { StudentTitle } from './StudentTitle';

import { PageTitle } from '@/components/PageTitle';
import { useProfile, useTeacherCourses } from '@/store';

export const DashboardTitle = () => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const {
    profile,
    roleCheck: { isStudent, isTeacher }
  } = useProfile();
  const { courses } = useTeacherCourses(profile?._id);

  if (!profile) return null;

  const { name } = profile;

  if (isStudent) return <StudentTitle name={name} />;

  return (
    <>
      <PageTitle title={t('HI', { name })} />
      {isTeacher && (
        <>
          <div>{t('YOUR_CLASSES')}</div>
          <CoursesWrapper>
            {courses?.map(({ _id, name: courseName }) => (
              <Tag key={_id}>{courseName}</Tag>
            ))}
          </CoursesWrapper>
        </>
      )}
    </>
  );
};
