import { Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const NameWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.black9};
`;

export const SubtitleWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const StyledTag = styled(Tag)`
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.black2};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;
