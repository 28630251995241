import styled from 'styled-components';

export const TasksWrapper = styled.div`
  height: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
`;
