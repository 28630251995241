import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-weight: 700;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonLinkWrapper = styled.div`
  color: ${({ theme }) => theme.colors.link};
  font-weight: 600;
  cursor: pointer;
  padding: 4px;
`;

export const StyledUl = styled.ul`
  padding-inline-start: 16px;
`;
