import { create as createStore } from 'zustand';

interface IStore {
  userId?: string;
  setUserId: (userId?: string) => void;
}

export const usePublicProfileViewer = createStore<IStore>(set => ({
  setUserId: (userId?: string) => set({ userId })
}));
