import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div<{ direction?: CSSProperties['flexDirection'] }>`
  display: flex;
  gap: 8px;
  flex-direction: ${({ direction }) => direction || 'row'};
  & > * {
    width: min-content;
  }
  overflow: scroll;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255));
    pointer-events: none;
  }
`;
