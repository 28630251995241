import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { create as createStore } from 'zustand';

interface IStore {
  userId?: string;
  isOpen: boolean;
  setUserId: (userId?: string) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setUserId: (userId?: string) => set({ userId })
}));

export const useStudentPreview = () => {
  const { isOpen, userId, setUserId } = store();
  const location = useLocation();

  const handleSetUserId = (newUserId?: string) => {
    setUserId(newUserId);
    const params = new URLSearchParams(location.search);
    if (newUserId !== undefined) {
      params.set('userId', newUserId);
    } else {
      params.delete('userId');
    }
    window.history.pushState({}, '', `${location.pathname}?${params.toString()}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const preloadedUserId = params.get('userId');
    if (preloadedUserId) {
      setUserId(preloadedUserId);
    }
  }, [location.search]);

  return { userId, setUserId: handleSetUserId, isOpen };
};
