import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-table-row:hover .shadow-background {
    background-image: linear-gradient(to right, rgb(250 250 250 / 7%), rgb(250 250 250 / 74%));
  }
`;

export const TagContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

export const ShadowTags = styled.div`
  width: 50px;
  background: rgb(255 255 255 / 37%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgb(255 255 255 / 16%), rgb(255 255 255));
`;
