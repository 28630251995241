import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { PageWrapper, StyledH2, StyledImage, Wrapper } from './NotificationSettings.styles';

import NAME_VECTOR from '@/assets/onboarding_name_vector.svg';
import UMY_ONBOARDING_NOTIFICATIONS from '@/assets/umy_onboarding_notifications.svg';
import { AutonomousCommunitySelector } from '@/components/Form';
import { useIsMobile } from '@/hooks';

export const NotificationSettings = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const isMobile = useIsMobile('xs');

  return (
    <StepWrapper>
      <PageWrapper>
        <div>
          <StyledH2>
            {t('NOTIFICATION_SETTINGS_TITLE')}
            <StyledImage alt="Name vector" src={NAME_VECTOR} />
          </StyledH2>
          <Wrapper>
            <Form.Item label={t('NOTIFICATION_SETTINGS_SUBTITLE')} name="autonomousCommunities">
              <AutonomousCommunitySelector mode="multiple" allowClear />
            </Form.Item>
          </Wrapper>
        </div>
        {!isMobile && <img src={UMY_ONBOARDING_NOTIFICATIONS} alt="Notification Settings logo" />}
      </PageWrapper>
    </StepWrapper>
  );
};
