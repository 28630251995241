import { Select, SelectProps } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllCoursesForSelectors } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: string[] | string;
  placeholder?: string;
  style?: SelectProps['style'];
  mode?: 'multiple' | 'tags';
  allowClear?: boolean;
  onChange?: (value: string[] | string) => void;
};

export const CourseSearchSelector: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  mode,
  allowClear = false,
  style
}) => {
  const { t } = useTranslation();
  const { courses } = useAllCoursesForSelectors();

  const options = useMemo(
    () =>
      courses?.map(({ _id, name }) => ({
        label: name,
        value: _id
      })),
    [courses]
  );

  return (
    <Select
      showSearch
      style={style}
      value={handleInputValue(value, mode === 'multiple')}
      onChange={onChange}
      placeholder={placeholder ?? t('SELECT_COURSE')}
      maxTagCount="responsive"
      allowClear={allowClear}
      mode={mode}
      options={options}
      filterOption={(input, option) =>
        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
      }
    />
  );
};
