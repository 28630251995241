import { ITicket } from '@sdk/contracts';
import { Button, Popover } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/Icon';
import { useMutateMyTickets } from '@/store';

type Props = {
  ticket: ITicket;
};

export const MoreActions: FC<Props> = ({ ticket }) => {
  const { removeTicket, isWorking } = useMutateMyTickets();
  const { t } = useTranslation('CommonTicketsTranslations');

  const handleRemoveTicket = () => removeTicket(ticket._id);

  return (
    <Popover
      trigger="click"
      content={
        <Button
          block
          danger
          loading={isWorking}
          onClick={e => {
            e.stopPropagation();
            handleRemoveTicket();
          }}
          icon={<Icon i="TrashCan" />}>
          {t('DELETE_TICKET')}
        </Button>
      }>
      <Button
        type="link"
        onClick={e => e.stopPropagation()}
        icon={<Icon i="OverflowMenuHorizontal" />}
      />
    </Popover>
  );
};
