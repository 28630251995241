import { IChatMessageSent } from '@sdk/contracts';
import { uniqBy } from 'lodash';
import { create } from 'zustand';

interface IStore {
  chats: Record<string, IChatMessageSent[]>;
  saveLocalChatMessage: (chatId: string, message: IChatMessageSent) => void;
}

export const useLocalChats = create<IStore>(set => ({
  chats: {},
  saveLocalChatMessage: (chatId, message) => {
    set(state => {
      const messages = state.chats[chatId] || [];
      if (messages.find(m => m._id === message._id)) return state;
      return {
        chats: {
          ...state.chats,
          [chatId]: uniqBy([...messages, message], '_id')
        }
      };
    });
  }
}));
