import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { ChosenReasonPicker } from './ChosenReasonPicker';

type Props = {
  handleNext: () => void;
};

export const ChosenReason: React.FC<Props> = ({ handleNext }) => {
  const { t } = useTranslation('StudentOnboardingTranslations');

  return (
    <StepWrapper>
      <h2>{t('CHOSEN_REASON_TITLE')}</h2>
      <Form.Item name={['onBoarding', 'chosenReason']}>
        <ChosenReasonPicker onChange={handleNext} />
      </Form.Item>
    </StepWrapper>
  );
};
