import { create as createStore } from 'zustand';

interface IStore {
  activityId?: string;
  setActivityId: (activityId?: string) => void;
}

export const useActivityPreview = createStore<IStore>(set => ({
  setActivityId: (activityId?: string) => set({ activityId })
}));
