import { IAcademy } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const MyAcademyService = sdk.getService('MyAcademyService');
const AcademyService = sdk.getService('AcademyService');

export const useMutateAcademies = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['DefaultTranslations', 'MyAcademyTranslations']);

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['academies'] });

  const { mutate: createAcademy, isPending: isCreating } = useMutation({
    mutationFn: (create: IAcademy) => AcademyService.createAcademy(create),
    onSuccess
  });

  const { mutate: updateAcademy, isPending: isUpdating } = useMutation({
    mutationFn: (update: IAcademy) => AcademyService.updateAcademy(update),
    onSuccess
  });

  const { mutate: updateMyAcademy, isPending: isUpdatingMyAcademy } = useMutation({
    mutationFn: (update: Partial<IAcademy>) => MyAcademyService.updateMyAcademy(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('MyAcademyTranslations:ACADEMY_PROFILE_UPDATED'));
    }
  });

  const { mutate: sendNewCredentials, isPending: isSendingCredentials } = useMutation({
    mutationFn: (studentId: string) => MyAcademyService.sendNewCredentials(studentId),
    onSuccess: () => message.success(t('DefaultTranslations:PASSWORD_RESET_SUCCESS'))
  });

  return {
    createAcademy,
    updateAcademy,
    updateMyAcademy,
    sendNewCredentials,
    isWorking: isCreating || isUpdating || isUpdatingMyAcademy || isSendingCredentials
  };
};
