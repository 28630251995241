import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const TranscriptionService = sdk.getService('TranscriptionService');

interface IStore {
  page: number;
  limit: number;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = create<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

export const useTranscriptionsList = () => {
  const { page, limit, setPageAndLimit } = store();

  const queryKey = ['transcriptions', `page-${page}`, `limit-${limit}`];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => TranscriptionService.getTranscriptions({ page, limit })
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    transcriptions: data?.docs,
    isLoading,
    error,
    setPageAndLimit,
    pagination
  };
};
