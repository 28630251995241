import dayjs, { Dayjs } from 'dayjs';
import { create as createStore } from 'zustand';

interface IStore {
  dateFilter: [Dayjs, Dayjs];
  setDates: (dates?: [Dayjs, Dayjs]) => void;
}

const store = createStore<IStore>(set => ({
  dateFilter: [dayjs().startOf('day').subtract(6, 'day'), dayjs().endOf('day')],
  setDates: dates => set({ dateFilter: dates })
}));

export const useDateFilter = () => {
  const { dateFilter, setDates } = store();

  return {
    dateFilter,
    setDates
  };
};
