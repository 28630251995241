import { Flex, Modal } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    padding-top: 32px;
  }
`;

export const TopWrapper = styled(Flex)`
  align-self: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black5};
`;

export const BottomWrapper = styled(Flex)`
  padding: 24px;
  align-self: stretch;
`;

export const SummaryWrapper = styled(Flex)`
  flex: 1 0 0;
  padding-left: 24px;
  padding-right: 14px;
`;

export const SummaryBoxWrapper = styled(Flex)`
  padding: 6px 8px;
  flex: 1 0 0;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.purple3};
`;

export const SummaryTextBoldWrapper = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

export const SummaryTextWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const InfoWrapper = styled(Flex)`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
`;

export const InfoTextWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const InfoTextDescriptionWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const InfoTextBaseWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.black4};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const InfoTextBaseDescriptionWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black5};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
