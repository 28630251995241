import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  gap: 48px;
  height: 100%;
  align-items: center;

  & > .ant-divider {
    margin: unset;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;
