import { ISubscription } from '@sdk/contracts';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useMemo } from 'react';

import { useMySubscriptions } from './useMySubscriptions';

import { useCookies } from '@/hooks';

const today = dayjs();
const SEEN_MATERIAL_COOKIE = 'checkedMaterial';

enum EMaterialType {
  ACTIVITY = 'ACTIVITY',
  MATERIAL = 'MATERIAL'
}

type INewMaterial = {
  [i: string]: {
    [EMaterialType.ACTIVITY]: string[];
    [EMaterialType.MATERIAL]: string[];
  };
};

const isNewerThanSevenDays = (createdAt: Date) => {
  const date = dayjs(createdAt);
  const diffDays = today.diff(date, 'day');
  return diffDays <= 7;
};

const isUnseenAndNew = (item: { _id: string; createdAt: Date }, seen: string[]) => {
  return isNewerThanSevenDays(item.createdAt) && !seen.includes(item._id);
};

const newMaterialIds = (seen: string[], subscriptions: ISubscription[]) => {
  const ids: INewMaterial = {};
  for (const subscription of subscriptions) {
    const { course } = subscription;
    ids[course._id] = {
      [EMaterialType.ACTIVITY]: [],
      [EMaterialType.MATERIAL]: []
    };
    course.sections.forEach(section => {
      section.activities.forEach(activity => {
        if (isUnseenAndNew(activity, seen)) {
          ids[course._id][EMaterialType.ACTIVITY].push(activity._id);
        }
      });
    });

    course.foldersWithMaterial.forEach(({ material = [] }) => {
      material.forEach(file => {
        if (isUnseenAndNew(file, seen)) {
          ids[course._id][EMaterialType.MATERIAL].push(file._id);
        }
      });
    });
  }
  return ids;
};

export const useNewMaterialChecker = () => {
  const { filteredSubscriptions = [], selectedSubscription } = useMySubscriptions();
  const { course } = selectedSubscription || {};

  const [seenMaterial, setSeenMaterial] = useCookies(
    SEEN_MATERIAL_COOKIE,
    Cookies.get(SEEN_MATERIAL_COOKIE) || '[]'
  );

  const newMaterial = useMemo(
    () => newMaterialIds(seenMaterial, filteredSubscriptions),
    [filteredSubscriptions, seenMaterial]
  );

  const courseKeys = useMemo(() => Object.keys(newMaterial), [newMaterial]);

  const isNew = (_id: string, type: keyof typeof EMaterialType) => {
    if (!course) return false;
    return newMaterial[course._id][type]?.includes(_id);
  };

  const markAsSeen = (_id: string) => {
    if (!seenMaterial.includes(_id)) {
      setSeenMaterial([...seenMaterial, _id]);
    }
  };

  const hasCourseNewStuff = (_id: string) => {
    if (courseKeys.includes(_id)) {
      return (
        newMaterial[_id][EMaterialType.ACTIVITY].length > 0 ||
        newMaterial[_id][EMaterialType.MATERIAL].length > 0
      );
    }
    return false;
  };

  const hasSectionNewStuff = (courseId: string, sectionId: string) => {
    if (courseKeys.includes(courseId)) {
      const { activities } = course?.sections.find(({ _id }) => _id === sectionId) || {};
      return activities?.some(({ _id }) => isNew(_id, EMaterialType.ACTIVITY));
    }
    return false;
  };

  const hasCourseNewMaterial = (_id: string) => {
    if (courseKeys.includes(_id)) {
      return newMaterial[_id][EMaterialType.MATERIAL].length > 0;
    }
    return false;
  };

  return {
    newMaterial,
    hasCourseNewMaterial,
    hasCourseNewStuff,
    hasSectionNewStuff,
    markAsSeen,
    isNew
  };
};
