import styled from 'styled-components';

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PageTitleWrapper = styled.div`
  & > :first-child {
    margin-bottom: unset;
  }
`;
