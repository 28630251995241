import styled from 'styled-components';

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 20px;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const MaterialItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  & > :last-child > button {
    & i {
      margin: auto;
    }
    :hover {
      color: ${({ theme }) => theme.colors.red6};
      background-color: rgba(227, 96, 88, 0.2);
    }
  }
`;
