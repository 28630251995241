import { Wrapper } from './TicketVotes.styles';

import { Icon } from '@/components/Icon';

type Props = {
  votes: string[];
  isDisabled: boolean;
};

export const TicketVotes: React.FC<Props> = ({ votes, isDisabled }) => {
  if (!votes?.length) return null;
  return (
    <Wrapper isDisabled={isDisabled}>
      <Icon i="ThumbsUpFilled" />
      {votes.length}
    </Wrapper>
  );
};
