import { EFeatures } from '@constants';
import { Flex, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: EFeatures[];
  onChange?: (value: string[]) => void;
};

const DEFAULT_FEATURES = Object.values(EFeatures);

export const FeaturesSelector: React.FC<Props> = ({ value = [], onChange }) => {
  const { t } = useTranslation('CourseTagsTranslations');

  const handleChecked = (checked: boolean, feature: EFeatures) => {
    if (checked) {
      onChange?.([...value, feature]);
    } else {
      onChange?.(value.filter(f => f !== feature));
    }
  };

  return (
    <Flex gap="small" vertical>
      {DEFAULT_FEATURES.map(feature => (
        <Flex justify="left" align="center" gap="small">
          <Switch
            size="small"
            checked={value.includes(feature)}
            onChange={checked => handleChecked(checked, feature)}
          />
          {t(feature)}
        </Flex>
      ))}
    </Flex>
  );
};
