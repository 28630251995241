import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ExamService = sdk.getService('ExamService');

export const useStudentExamsList = () => {
  const queryKey = ['exams', 'student'];
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => ExamService.findStudentExams()
  });
  return {
    exams: data,
    isLoading
  };
};
