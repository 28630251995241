import { RequestService } from '../request';

import { ICreateNpsVote, INpsVote, ICheckNpsVote } from './nps-vote.contract';

export class NpsVoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async checkNpsVote() {
    return this.fetcher<ICheckNpsVote>(`/nps-vote/check`);
  }

  async createNpsVote(data: ICreateNpsVote) {
    return this.poster<INpsVote, ICreateNpsVote>(`/nps-vote`, data);
  }
}
