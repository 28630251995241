import { useQuery } from '@tanstack/react-query';

import { useMySubscriptions } from '../subscriptions';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

export const useStudentLiveRecordsByCourse = () => {
  const { selectedSubscription } = useMySubscriptions();
  const courseId = selectedSubscription?.course._id;

  const { isLoading, data, error } = useQuery({
    queryKey: ['events', 'live-records', courseId],
    queryFn: () => EventService.findAllStudentLiveRecordsByCourse(courseId!),
    enabled: !!courseId
  });

  return {
    liveRecords: data,
    isLoading,
    error
  };
};
