import { useQuery } from '@tanstack/react-query';

import { useDateFilter } from '@/hooks';
import { sdk } from '@/services';
import { useProfile } from '@/store';

const SessionLogService = sdk.getService('SessionLogService');

export const useStudentPlatformTimeData = () => {
  const { dateFilter } = useDateFilter();
  const { profile } = useProfile();

  const profileId = profile?._id;

  const queryKey = ['students', 'time-stats', profileId, dateFilter];
  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => SessionLogService.getStudentTimeResults(profileId!, dateFilter),
    enabled: !!profileId
  });

  return {
    studentTimeStats: data,
    isLoading
  };
};
