import styled from 'styled-components';

export const PopoverWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 8px;

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;
