/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useDownloadMaterial = (courseId?: string) => {
  const { t } = useTranslation('StudentCourseTranslations');
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAndCreateUrl = useCallback(
    async (fetcher: Promise<any>) => {
      setIsDownloading(true);
      try {
        const response = await fetcher;
        if (!response) throw new Error('No data');
        const zipBlob = new Blob([response], { type: 'application/zip' });
        return URL.createObjectURL(zipBlob);
      } catch (error) {
        message.error(t('DOWNLOAD_MATERIAL_ERROR'));
        return null;
      } finally {
        setIsDownloading(false);
      }
    },
    [t]
  );

  const getMaterialCourse = useCallback(() => {
    if (!courseId) return null;
    return downloadAndCreateUrl(CourseService.downloadMaterial(courseId));
  }, [courseId, downloadAndCreateUrl]);

  const getMaterialFolder = useCallback(
    (folderId: string) => {
      if (!courseId) return null;
      return downloadAndCreateUrl(CourseService.downloadMaterial(courseId, folderId));
    },
    [courseId, downloadAndCreateUrl]
  );

  return {
    isDownloading,
    getMaterialCourse,
    getMaterialFolder
  };
};
