import { motion } from 'framer-motion';

export * from './Animated';

export const DEFAULT_ANIMATION = {
  as: motion.div,
  transition: { duration: 0.2 },
  animate: { opacity: 1, x: 0, y: 0 },
  initial: { opacity: 0, x: 0, y: 10 }
};
