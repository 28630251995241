import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useSectionCourseDetail = (courseId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['courses', courseId],
    queryFn: () => CourseService.getCourse(courseId!),
    enabled: !!courseId
  });

  return {
    course: data,
    isLoading,
    error
  };
};
