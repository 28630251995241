import styled from 'styled-components';

export const Wrapper = styled.div<{ empty: boolean }>`
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2;
  gap: 16px;
  padding: 16px 0;
  border-top: 2px solid ${({ theme }) => theme.colors.black4};
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    min-width: 260px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    min-width: 400px;
  }
`;
