import { Collapse } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;

  .ant-collapse-header {
    align-items: center !important;
  }

  & > button:first-of-type {
    align-self: flex-end;
  }

  & > form {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    padding-bottom: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      display: grid;
      position: relative;
      grid-template-columns: 0.7fr 0.3fr;

      & > :nth-child(2) {
        margin-bottom: auto;
        position: sticky;
        top: 0;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCollapsePanel = styled(Collapse.Panel)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 16px;
`;

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const StyledP = styled.p`
  padding-left: 16px;
`;
