import { EGamificationRewardActionType } from '@constants';
import { IReward } from '@sdk/contracts';
import { dateProvider, getGamificationPoints } from '@utils';
import { add, subtract } from 'lodash';

export const calculateTodayGamificationPoints = (rewards?: IReward[]): number => {
  if (!rewards) return 0;

  const today = dateProvider.now();
  return rewards.reduce((acc, reward) => {
    if (dateProvider.isSameDay(reward.createdAt, today)) {
      const operation = reward.action === EGamificationRewardActionType.SUBTRACT ? subtract : add;
      return operation(acc, getGamificationPoints(reward.multiplier, reward.type));
    }
    return acc;
  }, 0);
};
