import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      white: string;

      success: string;
      link: string;

      sandstorm: string;
      koi: string;
      sail: string;
      piglet: string;
      royal: string;
      belair: string;

      black1: string;
      black2: string;
      black3: string;
      black4: string;
      black5: string;
      black6: string;
      black7: string;
      black8: string;
      black9: string;
      black10: string;

      green1: string;
      green2: string;
      green3: string;
      green4: string;
      green5: string;
      green6: string;
      green7: string;
      green8: string;
      green9: string;
      green10: string;

      orange1: string;
      orange2: string;
      orange3: string;
      orange4: string;
      orange5: string;
      orange6: string;
      orange7: string;
      orange8: string;
      orange9: string;
      orange10: string;

      red1: string;
      red2: string;
      red3: string;
      red4: string;
      red5: string;
      red6: string;
      red7: string;
      red8: string;
      red9: string;
      red10: string;

      purple1: string;
      purple2: string;
      purple3: string;
      purple4: string;
      purple5: string;
      purple6: string;
      purple7: string;
      purple8: string;
      purple9: string;
      purple10: string;
    };
    fontSize: {
      small: string;
      normal: string;
      medium: string;
      large: string;
      extraLarge: string;
    };
    fontFamily: {
      primary: string;
      secondary: string;
      poppins: string;
      openSans: string;
    };
    boxShadow: {
      primary: string;
    };
    mediaQueries: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
}

export const defaultTheme: DefaultTheme = {
  colors: {
    white: '#FFFFFF',

    success: '#52C41A',
    link: '#1890FF',

    sandstorm: '#EAD339',
    koi: '#EAA44B',
    sail: '#63A7F7',
    piglet: '#EE9D9D',
    royal: '#852C3E',
    belair: '#7F99BF',

    black1: '#F8F8F8',
    black2: '#F1F1F2',
    black3: '#E4E5E6',
    black4: '#CBCCCE',
    black5: '#979A9E',
    black6: '#848A8F',
    black7: '#676C71',
    black8: '#40474F',
    black9: '#262D34',
    black10: '#0E1215',

    green1: '#E7F9F4',
    green2: '#C6E9DF',
    green3: '#91DDB9',
    green4: '#53CFAB',
    green5: '#61C497',
    green6: '#0ABB87',
    green7: '#08966C',
    green8: '#096B4E',
    green9: '#1B463A',
    green10: '#09201C',

    orange1: '#FEFCE8',
    orange2: '#FFF1D0',
    orange3: '#FBEA9C',
    orange4: '#F9DD7B',
    orange5: '#F7CE5C',
    orange6: '#FEB816',
    orange7: '#CF9635',
    orange8: '#AA7426',
    orange9: '#84571B',
    orange10: '#603C10',

    red1: '#FDF2F0',
    red2: '#FAE3DF',
    red3: '#F5BEB7',
    red4: '#F0978F',
    red5: '#ED7069',
    red6: '#FF3B3E',
    red7: '#C73A38',
    red8: '#A42A2B',
    red9: '#801C20',
    red10: '#5D1318',

    purple1: '#F1F4FE',
    purple2: '#EFF0FD',
    purple3: '#DFE2FB',
    purple4: '#BCC6FA',
    purple5: '#9099F1',
    purple6: '#616EEB',
    purple7: '#4E58BC',
    purple8: '#323598',
    purple9: '#1F1F73',
    purple10: '#16144F'
  },
  fontFamily: {
    primary: 'Open Sans',
    secondary: 'Poppins',
    poppins: 'Poppins',
    openSans: 'Open Sans'
  },
  fontSize: {
    small: '10px',
    normal: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '28px'
  },
  boxShadow: {
    primary: '0px 0px 4px rgb(0 0 0 / 20%)'
  },
  mediaQueries: {
    xs: '576px',
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xl: '1400px'
  }
};

export const darkTheme: DefaultTheme = {
  ...defaultTheme
};
