import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;

  & > :first-child {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > :first-child {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;
