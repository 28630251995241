import { ITag } from '@sdk/contracts';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { CourseTranslations } from '@/pages/Common';
import { useMyTagsList, useProfile } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: string[] | string;
  filters?: string[];
  allowClear?: boolean;
  onChange?: (value: string[] | string) => void;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  placeholder?: keyof typeof CourseTranslations;
};

export const TagSelector: React.FC<Props> = ({
  value,
  onChange,
  mode,
  filters,
  allowClear,
  maxTagCount = 'responsive',
  placeholder = 'SELECT_TAG'
}) => {
  const { t } = useTranslation('CourseTranslations');
  const { tags = [], isLoading } = useMyTagsList();
  const {
    profile,
    roleCheck: { isStudent }
  } = useProfile();

  if (!profile) return null;

  const filterTags = (tagObjects: ITag[]) => {
    if (!filters) return tagObjects.map(tag => ({ label: tag.name, value: tag._id }));
    return tagObjects
      .filter(tag => filters.includes(tag._id))
      .map(tag => ({
        label: tag.name,
        value: tag._id
      }));
  };

  const options = isStudent
    ? profile.tags.map(tag => ({ label: tag.name, value: tag._id }))
    : filterTags(tags);

  return (
    <Select
      showSearch
      allowClear={allowClear}
      mode={mode}
      maxTagCount={maxTagCount}
      options={options}
      onChange={onChange}
      loading={isLoading}
      placeholder={t(placeholder as keyof typeof CourseTranslations)}
      value={handleInputValue(value, mode === 'multiple')}
      filterOption={(input, option) =>
        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
      }
    />
  );
};
