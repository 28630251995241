export const TeacherStudentsPageTranslations = {
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  TAGS: {
    es: 'Formación',
    en: 'Tags',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  QUIZ_NUMBER: {
    es: 'Nº Test realizados',
    en: 'Quizzes number',
    cat: 'Nº Test realitzats',
    eus: 'Egindako test kopurua'
  },
  AVG_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batez besteko puntuazioa'
  },
  TICKETS_NUMBER: {
    es: 'Nº Dudas',
    en: 'Tickets number',
    cat: 'Nº Dubtes',
    eus: 'Zalantzak kopurua'
  },
  STUDENTS_TOTAL_COUNT: {
    es: '{{total}} alumnos',
    en: '{{total}} students',
    cat: '{{total}} estudiants',
    eus: '{{total}} ikasleak'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragaziak'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikasturtea'
  },
  SELECT_COURSE: {
    es: 'Selecciona un curso',
    en: 'Select a course',
    cat: 'Selecciona un curs',
    eus: 'Hautatu ikasturte bat'
  }
};
