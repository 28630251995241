import { EUmyActions, IUmyNotificationType } from '@constants';

import UMY_BULLET_POINT_SUMMARY from '@/assets/umy_bullet_point_summary.svg';
import { BulletPointVideoSummary } from '@/components/Umy/components';

type IUmyMetadata = {
  image: string;
  hasReward: boolean;
  isFullBodyImage: boolean;
  isModal: boolean;
  component: () => JSX.Element | null;
};

export const UMY_METADATA: Record<IUmyNotificationType, IUmyMetadata> = {
  [EUmyActions.SUMMARIZE_VIDEO]: {
    image: UMY_BULLET_POINT_SUMMARY,
    isFullBodyImage: false,
    hasReward: false,
    component: BulletPointVideoSummary,
    isModal: false
  }
};
