import { ERoles } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const ChatService = sdk.getService('ChatService');

export const useChatByParticipantsDetail = () => {
  const params = useParams();
  const { tutorId, studentId } = params;
  const queryKey = ['chat', tutorId, studentId];

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => ChatService.getChatByParticipants(tutorId, studentId),
    enabled: !!tutorId && !!studentId
  });

  const student = useMemo(() => {
    return data?.participants.find(user => user.role === ERoles.STUDENT);
  }, [data]);

  const teacher = useMemo(() => {
    return data?.participants.find(user => user.role === ERoles.TEACHER);
  }, [data]);

  return {
    chat: data,
    student,
    teacher,
    isLoading
  };
};
