import { Modal } from 'antd';
import katex from 'katex';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MathField, addStyles } from 'react-mathquill';

import { FormulaWrapper } from './InsertFormula.styles';
import { useInsertFormulaModal } from './store';
import { TexSymbols } from './TexSymbols';

type Props = {
  onChange: (formatedHtml?: string) => void;
};

addStyles();

export const InsertFormula: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('CommonQuizFormTranslations');
  const [formula, setFormula] = useState('');
  const { isFormulaEditorOpen, setIsFormulaEditorOpen } = useInsertFormulaModal();

  const handleChange = (mathField: MathField) => setFormula(mathField.latex());

  const handleCancel = () => {
    setIsFormulaEditorOpen(false);
    setFormula('');
  };

  const handleInsert = () => {
    if (formula === '') return onChange(undefined);
    const katexHtml = katex.renderToString(formula, { displayMode: true });
    const formatedHtml = katexHtml.match(/<span\sclass="katex-display">[\s\S]*?<\/span>/gi)?.[0];
    onChange(formatedHtml);
    handleCancel();
  };

  return (
    <Modal
      centered
      open={isFormulaEditorOpen}
      title={t('INSERT_FORMULA')}
      onCancel={handleCancel}
      onOk={handleInsert}>
      <FormulaWrapper onChange={handleChange} latex={formula} mathquillDidMount={handleChange} />
      <hr />
      <TexSymbols latex={formula} setLatex={setFormula} />
    </Modal>
  );
};
