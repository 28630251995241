import { ETicketFilter, ETicketStatus } from '@constants';
import { Tabs } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FiltersHandler } from './components/FiltersHandler';
import { Wrapper } from './styles';

import { PageTitle } from '@/components/PageTitle';
import { TicketList } from '@/components/TicketList';
import { TicketViewer } from '@/components/TicketViewer';
import { useMyTicketsList } from '@/store';

const OPEN_TICKETS_FILTER = {
  status: [ETicketStatus.NEW, ETicketStatus.IN_PROGRESS]
};
const CLOSED_TICKETS_FILTER = {
  status: [ETicketStatus.CLOSED_NOT_RESOLVED, ETicketStatus.CLOSED_RESOLVED]
};

export const TicketsPage = () => {
  const { t } = useTranslation('CommonTicketsTranslations');
  const { setFilter } = useMyTicketsList(ETicketFilter.OPEN);
  const [activeKey, setActiveKey] = useState<ETicketStatus>(ETicketStatus.NEW);

  const handleSetFilters = (key: string) => {
    setActiveKey(key as ETicketStatus);
    if (key === ETicketStatus.NEW) setFilter(OPEN_TICKETS_FILTER);
    if (key === ETicketStatus.CLOSED_RESOLVED) setFilter(CLOSED_TICKETS_FILTER);
  };

  const TABS = [
    { key: ETicketStatus.NEW, label: t('OPEN_DOUBTS') },
    { key: ETicketStatus.CLOSED_RESOLVED, label: t('RESOLVED_DOUBTS') }
  ];

  return (
    <Wrapper>
      <PageTitle title={t('DOUBTS')}>
        <div>
          <FiltersHandler />
        </div>
      </PageTitle>
      <Tabs onTabClick={handleSetFilters} items={TABS} />
      <TicketList initialFilters={ETicketFilter.OPEN} activeKey={activeKey} />
      <TicketViewer />
    </Wrapper>
  );
};
