import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';
import { formatDataAndDownloadCsv } from '@/utils';

const TicketService = sdk.getService('TicketService');

export const useTicketsStats = () => {
  const { isPending: isCsatLoading, mutate: downloadCsatData } = useMutation({
    mutationFn: () => TicketService.downloadCsatStats(),
    onSuccess: data => formatDataAndDownloadCsv(data, 'csat_stats.csv')
  });

  const { isPending: isTicketsByTagLoading, mutate: downloadTicketsByTagData } = useMutation({
    mutationFn: () => TicketService.downloadTicketsByTag(),
    onSuccess: data => formatDataAndDownloadCsv(data, 'tickets_by_tag.csv')
  });

  return {
    isCsatLoading,
    downloadCsatData,
    isTicketsByTagLoading,
    downloadTicketsByTagData
  };
};
