import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const List = styled.ul`
  list-style: none;
  padding-left: 10px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const QuestionIcon = styled(Icon)`
  font-size: 26px;
`;
