import styled from 'styled-components';

export const Wrapper = styled.div`
  gap: 4px;
  top: 50%;
  left: 50%;
  display: flex;
  font-size: 8px;
  padding: 4px 8px;
  position: absolute;
  border-radius: 8px;
  align-items: center;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.black9};
  background: ${({ theme }) => theme.colors.white};
`;
