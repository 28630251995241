import { create as createStore } from 'zustand';

interface IStore {
  open: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

export const usePlanificationStepsDrawer = createStore<IStore>(set => ({
  open: false,
  openDrawer: () => set({ open: true }),
  closeDrawer: () => set({ open: false })
}));
