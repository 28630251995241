export enum ENpsVoteType {
  ONBOARDING = 'ONBOARDING',
  REGULAR = 'REGULAR'
}

export enum EVoteReason {
  LIVE_CLASSES = 'LIVE_CLASSES',
  VIDEO_CONTENT = 'VIDEO_CONTENT',
  STUDY_MATERIAL = 'STUDY_MATERIAL',
  TEACHERS = 'TEACHERS',
  QUIZZES = 'QUIZZES',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  OTHER = 'OTHER'
}

export const NPS_VOTE_REASONS = Object.values(EVoteReason);

