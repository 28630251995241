import { createQueryString } from '@utils';
import { SorterResult } from 'antd/es/table/interface';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import { ICourseStats, ICoursesStatsFilters } from './courses-stats.contract';

export class CoursesStatsService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getCourseStats(courseId: string) {
    return this.fetcher<ICourseStats>(`/stats/courses/${courseId}`);
  }

  async getCoursesStats(
    search?: string,
    pagination?: IPagination,
    filters?: ICoursesStatsFilters,
    sort?: SorterResult<ICourseStats>
  ) {
    const query = createQueryString({
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      filters,
      sort: sort?.field ? { sort: sort.field, order: sort.order } : undefined
    });
    return this.fetcher<IPaginatedResponse<ICourseStats>>(`/stats/courses?${query}`);
  }
}
