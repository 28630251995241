import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ScrappedExamGroupService = sdk.getService('ScrappedExamGroupService');

export const useMutateScrappedExamGroups = () => {
  const { t } = useTranslation('ExamGroupsTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['exam-groups', 'scrapped']
    });

  const { mutate: removeScrappedExamGroup, isPending: isDeleting } = useMutation({
    mutationFn: (_id: string) => ScrappedExamGroupService.removeScrappedExamGroup(_id),
    onSuccess: () => {
      onSuccess();
      message.success(t('SCRAPPED_EXAM_GROUP_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeManyScrappedExamGroups, isPending: isRemovingMany } = useMutation({
    mutationFn: (groupsIds: string[]) => ScrappedExamGroupService.removeManyExamGroups(groupsIds),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_GROUPS_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    isWorking: isDeleting || isRemovingMany,
    removeScrappedExamGroup,
    removeManyScrappedExamGroups
  };
};
