import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ExamGroupService = sdk.getService('ExamGroupService');

export const useMyExamGroupsList = () => {
  const queryKey = ['exam-groups', 'me'];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => ExamGroupService.getMyExamGroups()
  });

  return {
    examGroups: data,
    isLoading,
    error
  };
};
