import { IEvent } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Button, Divider } from 'antd';
import { useMemo } from 'react';

import {
  DateMonthWrapper,
  DateDayWrapper,
  Wrapper,
  DateWrapper,
  InfoWrapper,
  TitleWrapper,
  TimeWrapper
} from './EventItem.styles';

import CHALLENGE_COMPLETE from '@/assets/challenge_complete.svg';
import { Icon } from '@/components/Icon';
import { useMutateEvents, useProfile } from '@/store';

type Props = {
  event: IEvent;
  onClick: () => void;
};
export const EventItem: React.FC<Props> = ({ event, onClick }) => {
  const { profile } = useProfile();
  const { startDate, endDate, title } = event;
  const utcStartDate = dateProvider.zonedTimeToUtc(startDate);
  const utcEndDate = dateProvider.zonedTimeToUtc(endDate);
  const { addAttendee, removeAttendee, isWorking } = useMutateEvents();

  const isUserAttending = useMemo(() => {
    if (!profile) return false;
    return event.attendees.some(attendee => attendee._id === profile._id);
  }, [profile, event]);

  const handleAttendEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!profile) return;
    if (isUserAttending) {
      removeAttendee({ eventId: event._id, attendeeId: profile._id });
    } else {
      addAttendee({ eventId: event._id, attendeeId: profile._id });
    }
  };

  return (
    <>
      <Wrapper onClick={onClick}>
        <DateWrapper>
          <DateDayWrapper>{dateProvider.format(utcStartDate, 'dd')}</DateDayWrapper>
          <DateMonthWrapper>
            {dateProvider.format(utcStartDate, 'MMM').toLocaleUpperCase()}
          </DateMonthWrapper>
        </DateWrapper>
        <InfoWrapper>
          <TitleWrapper>{title}</TitleWrapper>
          <TimeWrapper>
            {`${dateProvider.format(utcStartDate, 'HH:mm')} - ${dateProvider.format(
              utcEndDate,
              'HH:mm'
            )}`}
          </TimeWrapper>
        </InfoWrapper>
        {isUserAttending ? (
          <img
            onClick={handleAttendEvent}
            src={CHALLENGE_COMPLETE}
            alt="user attending event icon"
          />
        ) : (
          <Button
            loading={isWorking}
            onClick={handleAttendEvent}
            shape="circle"
            icon={<Icon i="Add" />}
            size="small"
          />
        )}
      </Wrapper>
      <Divider type="horizontal" />
    </>
  );
};
