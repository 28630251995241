import { EQuizDifficulty, EQuizMode, EQuizStatus } from '@constants';

export const ManagerQuizzesTranslations = {
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Test',
    eus: 'Test'
  },
  ALL_TESTS: {
    es: 'Todos los tests',
    en: 'All tests',
    cat: 'Tots els tests',
    eus: 'Test guztiak'
  },
  IMPORT_QUIZ: {
    es: 'Importar test',
    en: 'Import test',
    cat: 'Importar test',
    eus: 'Testa inportatu'
  },
  CREATE_QUIZ: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear test',
    eus: 'Testa sortu'
  },
  EDIT_QUIZ: {
    es: 'Editar test',
    en: 'Edit test',
    cat: 'Editar test',
    eus: 'Testa aldatu'
  },
  DUPLICATE_QUIZ: {
    es: 'Duplicar test',
    en: 'Duplicate test',
    cat: 'Duplicar test',
    eus: 'Testa bikoiztu'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Nº de preguntas',
    en: 'No. of questions',
    cat: 'Nº de preguntes',
    eus: 'Galdera kopurua'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  DELETE_QUIZ: {
    es: 'Eliminar test',
    en: 'Delete test',
    cat: 'Eliminar test',
    eus: 'Testa ezabatu'
  },
  ARE_YOU_SURE_WANT_TO_REMOVE_QUIZ: {
    es: '¿Estás seguro de que quieres eliminar el test?',
    en: 'Are you sure you want to remove the test?',
    cat: 'Estàs segur que vols eliminar el test?',
    eus: 'Zihur zaude testa ezabatu nahi duzula?'
  },
  QUIZ_REMOVED_SUCCESSFULLY: {
    es: 'Test eliminado correctamente',
    en: 'Test removed successfully',
    cat: 'Test eliminat correctament',
    eus: 'Testa ongi ezabatu da'
  },
  NO_QUIZZES_FOUND: {
    es: 'No se han encontrado tests',
    en: 'No quizzes found',
    cat: 'No s`han trobat tests',
    eus: 'Ez da testik aurkitu'
  },
  NO_QUIZZES_ADDED_PULSE_ADD_QUIZ: {
    es: 'No se han creado tests. Pulsa en "Crear test".',
    en: 'No tests added. Press "Create test".',
    cat: 'No s`han creat tests. Prem "Crear test".',
    eus: 'Ez da testik sortu. Sakatu "Testa sortu".'
  },
  QUIZZES_TOTAL_COUNT: {
    es: '{{total}} tests',
    en: '{{total}} tests',
    cat: '{{total}} tests',
    eus: '{{total}} test'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  ARE_YOU_SURE_DELETE_THIS_FILE: {
    es: '¿Seguro que quieres borrar este archivo?',
    en: 'Are you sure you want to delete this file?',
    cat: 'Estàs segur que vols eliminar aquest fitxer?',
    eus: 'Ziur zaude fitxategi hau ezabatu nahi duzula?'
  },
  UPLOAD_CSV_FILE: {
    es: 'Sube un archivo en formato .CSV',
    en: 'Upload a .CSV file',
    cat: 'Puja un fitxer en format .CSV',
    eus: 'Igo .CSV formatuko fitxategi bat'
  },
  QUIZ_CREATED_SUCCESSFULLY: {
    es: 'Test creado correctamente',
    en: 'Quiz created successfuly',
    cat: 'Test creat correctament',
    eus: 'Testa ongi sortu da'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  CANCEL_CONFIRMATION_MSG_1: {
    es: '¿Seguro que quieres descartar las preguntas?',
    en: 'Are you sure you want to discard the questions?',
    cat: 'Estàs segur que vols descartar les preguntes?',
    eus: 'Ziur zaude galderak baztertu nahi dituzula?'
  },
  CANCEL_CONFIRMATION_MSG_2: {
    es: 'Se borrarán las preguntas importadas',
    en: 'Imported questions will be deleted',
    cat: 'Es borraran les preguntes importades',
    eus: 'Galderak ezabatuko dira'
  },
  RETURN: {
    es: 'Volver',
    en: 'Return',
    cat: 'Tornar',
    eus: 'Itzuli'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde'
  },
  DISCARD: {
    es: 'Descartar',
    en: 'Discard',
    cat: 'Descartar',
    eus: 'Baztertu'
  },
  QUIZ_UPDATED: {
    es: 'Sección actualizada con éxito',
    en: 'Section successfully updated',
    cat: 'Secció actualitzada amb èxit',
    eus: 'Atala ongi eguneratua'
  },
  FORMAT_ERROR: {
    es: 'El archivo contiene celdas vacías o no sigue la plantilla indicada',
    en: 'File contains empty cells or does not follow the indicated template',
    cat: 'El fitxer conté cel·les buides o no segueix la plantilla indicada',
    eus: 'Fitxategiak zelula hutsak dauzka edo ez du adierazitako txantiloia jarraitzen'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  SET_AS_REVIEWED: {
    es: 'Marcar como Revisado',
    en: 'Set as Reviewed',
    cat: 'Marcar com a Revisat',
    eus: 'Markatu Berrikusita'
  },
  SET_AS_REVIEWING: {
    es: 'Marcar como en revisión',
    en: 'Set as in review',
    cat: 'Marcar com a en revisió',
    eus: 'Markatu Berrikusita'
  },
  QUIZ_UPDATED_SUCCESSFULLY: {
    es: 'Test actualizado correctamente',
    en: 'Test updated successfully',
    cat: 'Test actualitzat correctament',
    eus: 'Testa ongi eguneratua'
  },
  QUIZ_DUPLICATED_SUCCESSFULLY: {
    es: 'Test duplicado correctamente',
    en: 'Test duplicated successfully',
    cat: 'Test duplicat correctament',
    eus: 'Testa ongi bikoiztua'
  },
  MODE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Modo Libre',
    en: 'Free Mode',
    cat: 'Mode Lliure',
    eus: 'Modu Askatua'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Simulacro',
    en: 'Simulator',
    cat: 'Simulacre',
    eus: 'Simulazio'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera'
  },
  CREATOR: {
    es: 'Creador',
    en: 'Creator',
    cat: 'Creador',
    eus: 'Sortzailea'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala'
  },
  [EQuizStatus.IN_REVIEW]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikuspenetan'
  },
  [EQuizStatus.REVIEWED]: {
    es: 'Revisado',
    en: 'Reviewed',
    cat: 'Revisat',
    eus: 'Berrikusita'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragaziak'
  },
  DIFFICULTY: {
    es: 'Dificultad',
    en: 'Difficulty',
    cat: 'Dificultat',
    eus: 'Zailtasuna'
  },
  [EQuizDifficulty.HARD]: {
    es: 'Difícil',
    en: 'Hard',
    cat: 'Difícil',
    eus: 'Zaila'
  },
  [EQuizDifficulty.MEDIUM]: {
    es: 'Media',
    en: 'Medium',
    cat: 'Mitja',
    eus: 'Ertaina'
  },
  [EQuizDifficulty.EASY]: {
    es: 'Fácil',
    en: 'Easy',
    cat: 'Fàcil',
    eus: 'Errota'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batezbesteko puntuazioa'
  },
  COMPLETED_QUIZZES: {
    es: 'Veces hecho',
    en: 'Times done',
    cat: 'Veces hecho',
    eus: 'Egin daitezen aldiz'
  }
};
