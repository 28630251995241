import { IExamGroupFilter } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const ExamGroupService = sdk.getService('ExamGroupService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filter: IExamGroupFilter;
  setFilter: (filter: IExamGroupFilter) => void;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  filter: { onlyStateExams: false },
  setFilter: (filter: IExamGroupFilter) => set(state => ({ ...state, page: INITIAL_PAGE, filter })),
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

export const useExamGroupsList = () => {
  const { page, limit, search, filter, setFilter, setSearch, setPageAndLimit } = store();

  const queryKey = ['exam-groups', `page-${page}`, `limit-${limit}`, search, filter];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => ExamGroupService.getExamGroups(search, { page, limit }, filter)
  });

  const resetSearchResults = () => setSearch('');

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    examGroups: data?.docs,
    isLoading,
    error,
    setPageAndLimit,
    pagination,
    search,
    filter,
    setFilter,
    setSearch,
    resetSearchResults
  };
};
