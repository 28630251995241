import { createQueryString } from '@utils';
import { SorterResult } from 'antd/lib/table/interface';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import { IActivityStats, IActivityStatsFilters } from './activity-stats.contract';

export class ActivityStatsService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getActivityStats(
    courseId: string,
    search?: string,
    pagination?: IPagination,
    sort?: SorterResult<IActivityStats>,
    filters?: IActivityStatsFilters
  ) {
    const query = createQueryString({
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      filters,
      sort:
        sort?.order && sort?.field
          ? this.orderQuery(sort.order, {
              sort: Array.isArray(sort.field) ? sort.field.join('.') : sort.field.toString()
            })
          : undefined
    });
    return this.fetcher<IPaginatedResponse<IActivityStats>>(
      `/activity-stats/by-course/${courseId}?${query}`
    );
  }

  orderQuery(order: 'ascend' | 'descend', sortField: { sort: string }) {
    return order === 'ascend' ? sortField : { sort: `-${sortField.sort}` };
  }
}
