import { Form } from 'antd';
import { Trans } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { StyledSpan } from './DiscoverySource.styles';
import { DiscoverySourcePicker } from './DiscoverySourcePicker';

import { useProfile } from '@/store';

type Props = {
  handleNext: () => void;
};

export const DiscoverySource: React.FC<Props> = ({ handleNext }) => {
  const { profile } = useProfile();

  return (
    <StepWrapper>
      <h2>
        <Trans
          i18nKey="HOW_DID_YOU_DISCOVER_US"
          values={{ name: profile?.name }}
          components={{ a: <StyledSpan /> }}
        />
      </h2>
      <Form.Item name={['onBoarding', 'discoverySource']}>
        <DiscoverySourcePicker onChange={handleNext} />
      </Form.Item>
    </StepWrapper>
  );
};
