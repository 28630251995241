export enum ECourseStep {
  INFO = 'INFO',
  TASKS_PANEL = 'TASKS_PANEL',
  CONTENT = 'CONTENT',
  QUIZZES = 'QUIZZES',
  STUDENTS = 'STUDENTS'
}

export enum ECourseContentStep {
  INTRODUCTION = 'INTRODUCTION',
  SECTIONS = 'SECTIONS',
  MATERIAL = 'MATERIAL',
  COMPLEMENTARY_MATERIAL = 'COMPLEMENTARY_MATERIAL'
}
