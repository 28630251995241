import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    #root, html,body {
      padding: 0;
      margin: 0;
      font-family: ${({ theme }) => theme.fontFamily.primary};
      height: 100%;
      min-height: 100%;
    }

    #root > .ant-layout {
      height: 100%;
    }

    * {
      box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${({ theme }) => theme.fontFamily.primary};
      font-weight: 600;
      font-style: normal;
    }

    h1 {
      font-size: 40px;
      line-height: 48px;
    }

    h2 {
      font-size: 32px;
      line-height: 39px;
    }

    h3 {
      font-size: 28px;
      line-height: 34px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    h6 {
      font-size: 16px;
      line-height: 19px;
    }

    // BUTTONS

    .ant-btn-primary:not(.ant-btn-dangerous) {
      border: 1px solid ${({ theme }) => theme.colors.black9};
      background: ${({ theme }) => theme.colors.black9};
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.green6};
        background: ${({ theme }) => theme.colors.green6} !important;
      }

      :focus {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: ${({ theme }) => theme.colors.green7} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black4} !important;
        background: ${({ theme }) => theme.colors.black2} !important;
        color: ${({ theme }) => theme.colors.black4} !important;
      }
    }

    .ant-btn-primary.ant-btn-loading:not(.ant-btn-dangerous) {
      border: 1px solid ${({ theme }) => theme.colors.green6};
      background: ${({ theme }) => theme.colors.green6} !important;
    }

    .ant-btn-default:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: 1px solid ${({ theme }) => theme.colors.black9};
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
      background: white;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.green6} !important;
        color: ${({ theme }) => theme.colors.green6} !important;
      }

      :focus {
        border: 1px solid ${({ theme }) => theme.colors.green7} !important;
        color: ${({ theme }) => theme.colors.green7} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black4} !important;
        background: ${({ theme }) => theme.colors.black2} !important;
        color: ${({ theme }) => theme.colors.black4} !important;
      }
    }

    .ant-btn-default.ant-btn-loading:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: 1px solid ${({ theme }) => theme.colors.green6};
      color: ${({ theme }) => theme.colors.green6};
    }

    .ant-btn-link:not(.ant-btn-dangerous) {
      color: ${({ theme }) => theme.colors.black9};

      :hover {
        background: ${({ theme }) => theme.colors.green1} !important;
        border: 1px solid ${({ theme }) => theme.colors.green1} !important;
        color: ${({ theme }) => theme.colors.green6} !important;
      }

      :focus {
        background: ${({ theme }) => theme.colors.green2} !important;
        border: 1px solid ${({ theme }) => theme.colors.green2} !important;
        color: ${({ theme }) => theme.colors.green6} !important;
      }

      :disabled {
        background: ${({ theme }) => theme.colors.white} !important;
        border: 1px solid ${({ theme }) => theme.colors.white} !important;
        color: ${({ theme }) => theme.colors.black4} !important;
      }
    }

    .ant-btn-link.ant-btn-loading:not(.ant-btn-dangerous) {
        background: ${({ theme }) => theme.colors.green1} !important;
        border: 1px solid ${({ theme }) => theme.colors.green1};
        color: ${({ theme }) => theme.colors.green6} !important;
    }

    .ant-btn-text {
      border: 1px solid ${({ theme }) => theme.colors.purple6};
      color: ${({ theme }) => theme.colors.purple6};
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

      :hover {
        background: ${({ theme }) => theme.colors.purple2} !important;
        color: ${({ theme }) => theme.colors.purple6} !important;
      }

      :focus {
        border: 1px solid ${({ theme }) => theme.colors.purple7} !important;
        color: ${({ theme }) => theme.colors.purple7} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black4} !important;
        background: ${({ theme }) => theme.colors.black2} !important;
        color: ${({ theme }) => theme.colors.black4} !important;
      }
    }

    .ant-btn-text.ant-btn-loading {
      background: ${({ theme }) => theme.colors.purple2} !important;
      color: ${({ theme }) => theme.colors.purple6} !important;
    }

    // FORM LABELS
    .ant-form-item-label > label {
      font-weight: 500 !important;
    }

    // TABLE
    .ant-table-row:hover {
      cursor: pointer;
    }

    .ant-table-pagination {
      margin: 0 !important;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 14px 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-style: none;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    }

    .ant-table-content {
      overflow-x: auto;
    }

    .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table  {
      box-shadow: ${({ theme }) => theme.boxShadow.primary};
    }

    // SWITCH
    .ant-switch-checked:focus {
      box-shadow: none !important;
    }

    // ALERTS
    .ant-alert-with-description .ant-alert-message {
      font-weight: 600;
    }

    // EDITORJS
    .ct.ct--bottom.ct--shown {
      z-index: 1000;
    }

    //EMPTY
    .ant-empty-description {
      color: rgba(0, 0, 0, 0.25) !important;
    }

    // MODAL
    .ant-modal-confirm-btns {
      .ant-btn-primary {
        background: ${({ theme }) => theme.colors.black9};
      }
    }
    .ant-modal-confirm-content {
      width: 100%;
    }

    // TABS
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      background-color: ${({ theme }) => theme.colors.green6};
    }

    .ant-tabs-tab-btn {
      font-weight: 500;
    }

    // TABLE
    .ant-table-disabled-row {
      opacity: 0.4;
      pointer-events: none;
    }

    .gamification-notification {
      .ant-message-notice-content {
        box-shadow: none !important;
        background: transparent !important;
      }
    }
`;

export { GlobalStyle };
