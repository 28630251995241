import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './SpecificDaysPicker.styles';

type Props = {
  value?: Date[];
  onChange?: (value: Date[]) => void;
};

export const SpecificDaysPicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('DefaultTranslations');
  return (
    <Wrapper>
      <p>{t('SPECIFIC_DAYS')}</p>
      <DatePicker
        maxTagCount={4}
        multiple
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        minDate={dayjs().add(2, 'day')}
      />
    </Wrapper>
  );
};
