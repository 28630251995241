import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { LottieWrapper, TextWrapper, Wrapper } from './LottieClock.styles';

import CLOCK_LOTTIE from '@/assets/lotties/clock_lottie.json';

export const LottieClock = () => {
  const { t } = useTranslation('QuizResultsTranslations');
  const [searchParams, setSearchParams] = useSearchParams();
  const clock = searchParams.get('clock');
  const [show, setShow] = useState(Boolean(clock));

  useEffect(() => {
    if (!show) return;
    const timer = setTimeout(() => {
      setShow(false);
      searchParams.delete('clock');
      setSearchParams(searchParams);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!show) return null;

  return (
    <Wrapper>
      <LottieWrapper>
        <Lottie animationData={CLOCK_LOTTIE} autoPlay loop />
      </LottieWrapper>
      <TextWrapper>{t('TIME')}</TextWrapper>
    </Wrapper>
  );
};
