import styled from 'styled-components';

export const Wrapper = styled.div`
  & > i {
    font-size: 20px;
  }

  .IconFaceSatisfiedFilled {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceNeutralFilled {
    fill: ${({ theme }) => theme.colors.orange6};
  }
  .IconFaceDissatisfiedFilled {
    fill: ${({ theme }) => theme.colors.red6};
  }
`;
