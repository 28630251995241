import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TranscriptionService = sdk.getService('TranscriptionService');

export const useTranscriptionDetail = (transcriptionId: string | null) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['transcriptions', transcriptionId],
    queryFn: () => TranscriptionService.getTranscription(transcriptionId!),
    enabled: !!transcriptionId
  });

  return {
    transcription: data,
    isLoading,
    error
  };
};
