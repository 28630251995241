import { ITicket } from '@sdk/contracts';
import { Avatar, Typography } from 'antd';

import {
  UserWrapper,
  OpenTicketWrapper,
  AvatarContainer,
  TitleContainer,
  ButtonWrapper
} from './OpenTicketsItem.styles';

import { Icon } from '@/components/Icon';

type Props = {
  ticket: ITicket;
  onClick?: () => void;
};

const { Text, Paragraph } = Typography;

export const OpenTicketsItem: React.FC<Props> = ({ ticket, onClick }) => {
  const { title, issuer } = ticket;
  return (
    <OpenTicketWrapper>
      <UserWrapper>
        <AvatarContainer>
          <Avatar size={24} src={issuer.avatar?.url}>
            {issuer.name[0].toLocaleUpperCase()}
          </Avatar>
        </AvatarContainer>
        <Text ellipsis>
          {issuer.name} {issuer.lastName}
        </Text>
      </UserWrapper>
      <TitleContainer>
        <Paragraph ellipsis={{ rows: 2 }}>{title}</Paragraph>
      </TitleContainer>
      <ButtonWrapper onClick={onClick}>
        <Icon i="MailReply" />
      </ButtonWrapper>
    </OpenTicketWrapper>
  );
};
