import { AnalyticsTrackEvents, ETicketFilter } from '@constants';
import { ITicket } from '@sdk/contracts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { OpenTicketsCount } from './OpenTicketsCount';
import { OpenTicketsItem } from './OpenTicketsItem';
import { LoadingWrapper, Wrapper } from './OpenTicketsWidget.styles';

import UMY_STUDY_MODE_COMPLETED from '@/assets/umy_study_mode_completed.svg';
import { EmptyWidgetContent } from '@/components/EmptyWidgetContent';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { TicketViewer } from '@/components/TicketViewer';
import { WidgetBase } from '@/components/WidgetBase';
import { useMixpanel } from '@/hooks';
import { useMyTicketsList } from '@/store';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const OpenTicketsWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { tickets, isLoading } = useMyTicketsList(ETicketFilter.OPEN, { limit: 10 });
  const { fireEvent } = useMixpanel();
  const navigateTo = useNavigate();
  const { pathname } = useLocation();

  const onClick = (ticket: ITicket) => {
    navigateTo(`${pathname}?ticketId=${ticket._id}`);
    fireEvent(AnalyticsTrackEvents.DOUBTS_CONSULT, {
      title: ticket.title,
      courseName: ticket.ticketRelatedContent.course.name
    });
  };

  return (
    <WidgetBase
      title={t('OPEN_DOUBTS')}
      addon={<OpenTicketsCount value={tickets?.length} />}
      {...props}>
      <Wrapper>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingCircleSpinner />
          </LoadingWrapper>
        ) : tickets?.length ? (
          tickets.map(ticket => (
            <OpenTicketsItem key={ticket._id} ticket={ticket} onClick={() => onClick(ticket)} />
          ))
        ) : (
          <EmptyWidgetContent
            title={t('NO_OPEN_DOUBTS_TITLE')}
            description={t('NO_OPEN_DOUBTS_DESCRIPTION')}
            umyImage={UMY_STUDY_MODE_COMPLETED}
          />
        )}
        <TicketViewer />
      </Wrapper>
    </WidgetBase>
  );
};
