import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const AcademyService = sdk.getService('AcademyService');

export const useAcademiesList = () => {
  const queryKey = ['academies'];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => AcademyService.getAcademies()
  });

  return {
    academies: data,
    isLoading,
    error
  };
};
