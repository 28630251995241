import { Button } from 'antd';
import styled from 'styled-components';

export const Label = styled.div`
  font-weight: 500;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  width: fit-content;
`;
