/* eslint-disable indent */
import { AnalyticsTrackEvents, EPostStatus } from '@constants';
import { IPost } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePostViewer } from '../../../hooks';

import {
  DateWrapper,
  FeaturedTag,
  HeadWrapper,
  InfoWrapper,
  PostWrapper,
  StyledImage,
  TitleWrapper
} from './PostItem.styles';

import { Icon } from '@/components/Icon';
import { useMixpanel } from '@/hooks';
import { useProfile } from '@/store';

type Props = {
  post: IPost;
  isFeatured?: boolean;
};

export const PostItem: React.FC<Props> = ({ post, isFeatured }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { setPostId } = usePostViewer();
  const { fireEvent } = useMixpanel();
  const { roleCheck } = useProfile();

  const { isManager, isAdmin, isTeacher } = roleCheck;
  const canEdit = isManager || isAdmin || isTeacher;

  const { _id, title, updatedAt, featuredImage, tags = [], status, scheduledPublishDate } = post;
  const tagCount = tags.length;

  const isScheduled = canEdit && status === EPostStatus.SCHEDULED && scheduledPublishDate;

  const handleOpenPost = () => {
    setPostId(_id);
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'NEWS'
    });
  };

  return (
    <PostWrapper onClick={handleOpenPost} isfeatured={isFeatured ? 'true' : 'false'}>
      {isFeatured && <FeaturedTag>{t('FEATURED_NEWS')}</FeaturedTag>}
      {featuredImage && <StyledImage src={featuredImage?.url} alt="News preview" />}
      <InfoWrapper>
        <HeadWrapper>
          <DateWrapper isscheduled={isScheduled ? 'true' : 'false'}>
            <div>
              {isScheduled
                ? dateProvider.format(
                    dateProvider.zonedTimeToUtc(scheduledPublishDate),
                    'dd MMM, yyyy'
                  )
                : dateProvider.format(updatedAt, 'dd MMM, yyyy')}
            </div>
            {isScheduled && (
              <Tooltip
                title={dateProvider.format(
                  dateProvider.zonedTimeToUtc(scheduledPublishDate),
                  'dd/MM/yyyy HH:mm'
                )}>
                <Icon i="Time" />
              </Tooltip>
            )}
          </DateWrapper>
          {tagCount > 0 && (
            <>
              <span>|</span>
              <div>{t('POST_TAGS_COUNT', { count: tagCount })}</div>
            </>
          )}
        </HeadWrapper>
        <TitleWrapper>{title}</TitleWrapper>
      </InfoWrapper>
    </PostWrapper>
  );
};
