import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
  }
`;

export const SectionTitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;
