import { defaultTheme } from '../styles/theme';

const colors = defaultTheme.colors;

export const PLANIFICATION_EVENTS_COLORS = [
  colors.black3,
  colors.green3,
  colors.purple4,
  colors.orange4,
  colors.red4,
  colors.black4,
  colors.koi,
  colors.sail,
  colors.sandstorm
];
