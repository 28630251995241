import { IEvent } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  eventToAttend?: IEvent;
  setEventToAttend: (eventToAttend?: IEvent) => void;
}

export const useEventConfirmationModal = createStore<IStore>(set => ({
  setEventToAttend: (eventToAttend?: IEvent) => set({ eventToAttend })
}));
