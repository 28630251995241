import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';
import { useMySubscriptions } from '@/store';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useMySubscriptionDetail = () => {
  const { _id } = useParams<{ _id: string }>();
  const { selectedSubscription } = useMySubscriptions();
  const subscriptionId = _id ?? selectedSubscription?._id;

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['subscriptions', subscriptionId],
    queryFn: () => SubscriptionService.getMySubscriptionDetail(subscriptionId!),
    enabled: !!subscriptionId
  });

  return {
    subscription: data,
    refetch,
    isLoading,
    error
  };
};
