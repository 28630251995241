import { ETranscriptionStatus } from '@constants';
import { ITranscription } from '@sdk/contracts';
import { Button, Descriptions, Drawer, Spin, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useTranscriptionPreview } from '../../store';

import { TitleWrapper } from './ActivityTranscriptionPreview.styles';

import { Icon } from '@/components/Icon';
import { useMutateTranscriptions, useTranscriptionDetail } from '@/store';

const DRAWER_WIDTH = 800;
const DESCRIPTIONS_COLUMN = 1;

const { Paragraph } = Typography;

const statusColor = {
  [ETranscriptionStatus.READY]: 'green',
  [ETranscriptionStatus.PROCESSING]: 'blue',
  [ETranscriptionStatus.NOT_READY]: 'red'
};

type IEditableTranscription = Pick<ITranscription, 'content' | 'brief' | 'title' | 'slug'>;

export const ActivityTranscriptionPreview = () => {
  const { transcriptionId, setTranscriptionId } = useTranscriptionPreview();
  const { transcription } = useTranscriptionDetail(transcriptionId);
  const { isWorking, updateTranscription, generateTranscription } = useMutateTranscriptions();

  const { t } = useTranslation('CourseTranslations');

  if (!transcription) return null;

  const { activity, content, slug, title, status, brief } = transcription;

  const handleOnChange = async (key: keyof IEditableTranscription, data: string) =>
    updateTranscription({ activityId: activity, transcription: { ...transcription, [key]: data } });

  const handleGenerateTranscription = async () =>
    generateTranscription(activity, { onSuccess: () => setTranscriptionId(null) });

  const url = `${import.meta.env.VITE_COURSES_INDEX_URL}/${slug}`;

  return (
    <Drawer
      title={
        <TitleWrapper status={status}>
          <span>{t('TRANSCRIPTION_PREVIEW')}</span>
          <Tag color={statusColor[status]}>{t(status)}</Tag>
        </TitleWrapper>
      }
      width={DRAWER_WIDTH}
      open={!!transcription}
      footer={
        <Button
          size="small"
          type="primary"
          loading={isWorking}
          onClick={handleGenerateTranscription}
          icon={<Icon i="Translate" />}>
          {t('RE_GENERATE_TRANSCRIPTION')}
        </Button>
      }
      onClose={() => setTranscriptionId(null)}
      closeIcon={isWorking ? <Spin size="small" /> : undefined}>
      <Descriptions layout="vertical" bordered column={DESCRIPTIONS_COLUMN}>
        <Descriptions.Item label={t('TITLE')}>
          <Paragraph strong editable={{ onChange: data => handleOnChange('title', data) }}>
            {title}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label={t('SLUG')}>
          <Paragraph
            editable={{
              text: slug,
              onChange: data => handleOnChange('slug', data)
            }}>
            <Button type="link" target="_blank" icon={<Icon i="Link" />} href={url}>
              {slug}
            </Button>
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label={t('BRIEF')}>
          <Paragraph editable={{ onChange: data => handleOnChange('brief', data) }}>
            {brief}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label={t('CONTENT')}>
          <Paragraph editable={{ onChange: data => handleOnChange('content', data) }}>
            {content}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};
