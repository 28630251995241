import { create as createStore } from 'zustand';

interface IStore {
  deliverableId: string | null;
  setDeliverableId: (deliverableId: string | null) => void;
}

export const useDeliverableViewer = createStore<IStore>(set => ({
  deliverableId: null,
  setDeliverableId: (deliverableId: string | null) => set({ deliverableId })
}));
