import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    box-shadow: none;
  }
  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black5};
  }
  .ant-table-tbody > tr > td {
    background-color: ${({ theme }) => theme.colors.purple2};
  }
  .ant-table {
    box-shadow: none;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

export const HeaderWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;
