export enum ESectionReviewQuestion {
  COURSE_WAS_WELL_ORGANIZED = 'COURSE_WAS_WELL_ORGANIZED',
  SATISFIED_WITH_THE_CONTENT = 'SATISFIED_WITH_THE_CONTENT',
  THEORY_AND_PRACTICE_BALANCE = 'THEORY_AND_PRACTICE_BALANCE',
  WAY_INFORMATION_WAS_PRESENTED = 'WAY_INFORMATION_WAS_PRESENTED',
  VIRTUAL_MEDIA_FLEXIBILITY = 'VIRTUAL_MEDIA_FLEXIBILITY'
}

export enum ESectionReviewAnswer {
  TOTALLY_DISAGREE = 'TOTALLY_DISAGREE',
  DISAGREE = 'DISAGREE',
  AGREE = 'AGREE',
  TOTALLY_AGREE = 'TOTALLY_AGREE'
}

export enum ESectionReviewRequirement {
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY'
}
