import { IFile } from '@sdk/contracts';
import { Alert } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';

import { Wrapper, Controls, Cover } from './AudioPlayer.styles';

type Props = {
  file: IFile;
};

export const AudioPlayer: React.FC<Props> = ({ file }) => {
  const { t } = useTranslation('DefaultTranslations');
  const [progress, setProgress] = useState(0);
  const player = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) player.current?.pause();
    else player.current?.play();
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setProgress((player.current?.currentTime || 0) / (player.current?.duration || 1));
    if (player.current?.currentTime === player.current?.duration) {
      setIsPlaying(false);
    }
  };

  const handleSeek = (e: React.MouseEvent<HTMLProgressElement, MouseEvent>) => {
    if (!player.current) return;
    const { clientX } = e;
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const newTime = ((clientX - left) / width) * (player.current?.duration || 1);
    player.current.currentTime = newTime;
  };

  useEffect(() => {
    if (player.current) player.current.addEventListener('timeupdate', handleTimeUpdate);
  }, [player.current]);

  if (!file.url) return <Alert type="warning" message={t('AUDIO_CAN_NOT_BE_LOADED')} />;

  return (
    <Wrapper>
      <Cover>
        <Icon i="Headphones" size="32" color="white" />
      </Cover>
      <progress onClick={handleSeek} value={progress} max="1"></progress>
      <Controls>
        <Icon i="SkipBack" size="32" color="white" />
        <Icon i={isPlaying ? 'Pause' : 'Play'} size="32" color="white" onClick={togglePlay} />
        <Icon i="SkipForward" size="32" color="white" />
      </Controls>
      <audio ref={player} src={file.url} controls />
    </Wrapper>
  );
};
