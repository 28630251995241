import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import memoize from 'lodash.memoize';
import { initReactI18next } from 'react-i18next';

import { translations, namespaces } from './locales';
const memoizedKeys = memoize(Object.keys);

const ns = memoizedKeys(namespaces);

export const defaultNS = ns[0];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns,
    react: {
      useSuspense: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b']
    },
    defaultNS,
    fallbackLng: 'es',
    load: 'languageOnly',
    resources: translations,
    interpolation: {
      escapeValue: false
    },
    initImmediate: true,
    debug: import.meta.env.DEV
  });

export default i18next;

export * from './locales';
