import {
  EmptyTitleWrapper,
  EmptyDescriptionWrapper,
  EmptyWidgetWrapper,
  ImageWrapper,
  TextWrapper
} from './EmptyWidgetContent.styles';

import UMY_WTF from '@/assets/umy_wtf.svg';

type Props = {
  title: string;
  description: string;
  umyImage?: string;
};

export const EmptyWidgetContent: React.FC<Props> = ({ title, description, umyImage = UMY_WTF }) => {
  return (
    <EmptyWidgetWrapper>
      <ImageWrapper>
        <img alt="Empty" src={umyImage} />
      </ImageWrapper>
      <TextWrapper>
        <EmptyTitleWrapper>{title}</EmptyTitleWrapper>
        <EmptyDescriptionWrapper>{description}</EmptyDescriptionWrapper>
      </TextWrapper>
    </EmptyWidgetWrapper>
  );
};
