import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request/request.service';
import { ITeacherFilters, IUser } from '../user';

export class MyAcademyTeachersService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTeachers = async (
    search?: string,
    pagination?: IPagination,
    filters?: ITeacherFilters,
    bypass?: boolean
  ) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters,
      bypass
    });
    return this.fetcher<IPaginatedResponse<IUser>>(`/my-academy/teachers?${query}`);
  };

  public getTeacher = async (teacherId: string) => {
    return this.fetcher<IUser>(`/my-academy/teachers/${teacherId}`);
  };

  public createTeacher = async (newTeacher: Partial<IUser>) => {
    return this.poster<IUser, Partial<IUser>>('/my-academy/teachers', newTeacher);
  };

  public updateTeacher = async (teacher: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>(`/my-academy/teachers/${teacher._id}`, teacher);
  };
}
