import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :last-child {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
`;

interface Props {
  disabled: boolean;
}

export const FileWrapper = styled.div<Props>`
  margin-bottom: 15px;
  ${({ disabled }) =>
    disabled
      ? css`
          & * {
            cursor: not-allowed;
          }
        `
      : css``}
`;
