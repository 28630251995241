import { IFile } from '@sdk/contracts';
import { useTranslation } from 'react-i18next';

import { Animated } from '@/components/Animated';
import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { IUpload } from '@/types';

type Props = {
  value?: { file: IFile };
  onChange?: ({ file }: { file: IUpload['value'] }) => void;
};

const UPLOAD_WIDTH = '100%';
const UPLOAD_HEIGHT = 250;

export const DeliverableType: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CourseTranslations');

  const handleChange = (file: IUpload['value']) => onChange?.({ file });

  return (
    <Animated>
      <Upload
        preview
        value={value?.file}
        width={UPLOAD_WIDTH}
        height={UPLOAD_HEIGHT}
        onChange={handleChange}
        accept="application/pdf">
        <div>
          <Icon i="TaskView" />
          <p>{t('ACTIVITY_DELIVERABLE_TYPE_DESCRIPTION')}</p>
        </div>
      </Upload>
    </Animated>
  );
};
