import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  & > :nth-child(2) {
    i {
      font-size: 72px;
      cursor: pointer;
    }
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 31px;
    .IconFaceSatisfied:hover {
      fill: ${({ theme }) => theme.colors.green6};
    }
    .IconFaceSatisfiedFilled {
      fill: ${({ theme }) => theme.colors.green6};
    }
    .IconFaceNeutral:hover {
      fill: ${({ theme }) => theme.colors.orange6};
    }
    .IconFaceNeutralFilled {
      fill: ${({ theme }) => theme.colors.green6};
    }
    .IconFaceDissatisfied:hover {
      fill: ${({ theme }) => theme.colors.red6};
    }
    .IconFaceDissatisfiedFilled {
      fill: ${({ theme }) => theme.colors.green6};
    }
  }
`;
