import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useTeacherCourses = (teacherId?: string) => {
  const queryKey = ['teacher-courses', teacherId];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => CourseService.findAllTeacherCourses(teacherId!),
    enabled: !!teacherId
  });

  return {
    courses: data,
    isLoading,
    error
  };
};
