import { StyledSpinner } from './LoadingCircleSpinner.styes';

type Props = {
  size?: number;
};

export const LoadingCircleSpinner: React.FC<Props> = ({ size = 80 }) => {
  return (
    <StyledSpinner viewBox="0 0 50 50" size={size}>
      <circle className="path" cx="25" cy="25" r="20" />
    </StyledSpinner>
  );
};
