import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > .ant-divider-horizontal {
    margin: unset;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-evenly;
`;

export const ItemTitle = styled.div`
  font-weight: 600;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
