import { Form } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNpsVoteLogic } from '../../../../hooks';
import { ReasonPicker } from '../ReasonPicker';

import { EverythingClearSelector } from './EverythingClearSelector';

export const OnboardingVoteReason: React.FC = () => {
  const { step, vote, isEverythingClear } = useNpsVoteLogic();
  const { t } = useTranslation('CommonDashboardTranslations');

  const title = useMemo(() => {
    if (isEverythingClear === false) return t('WHAT_DO_YOU_NOT_UNDERSTAND');
    if (vote < 9) return t('ONBOARDING_NPS_DETRACTOR_TITLE');
    return t('ONBOARDING_NPS_PROMOTER_TITLE');
  }, [vote, isEverythingClear]);

  return (
    <>
      <h3>{t('IS_EVERYTHING_CLEAR')}</h3>
      <Form.Item name="isEverythingClear">
        <EverythingClearSelector />
      </Form.Item>
      {isEverythingClear !== undefined && (
        <>
          <h3>{title}</h3>
          <Form.Item hidden={step !== 1} name="reasons">
            <ReasonPicker vote={vote} />
          </Form.Item>
        </>
      )}
    </>
  );
};
