import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TagService = sdk.getService('TagService');

export const useTagCoursesList = (tagId?: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['tags', 'tags-with-courses', tagId],
    queryFn: () => TagService.getTagCourses(tagId),
    enabled: !!tagId
  });

  return {
    courses: data,
    isLoading
  };
};
