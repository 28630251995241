import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StudyPlanBadge } from '../StudyPlanBadge';

import { HeaderWrapper, Wrapper } from './DoYouHaveExamDate.styles';
import { HasExamDatePicker } from './HasExamDatePicker';

type Props = {
  handleNext: () => void;
};

export const DoYouHaveExamDate: React.FC<Props> = ({ handleNext }) => {
  const { t } = useTranslation('DefaultTranslations');
  return (
    <Wrapper>
      <HeaderWrapper>
        <StudyPlanBadge />
        <h2>{t('DO_YOU_HAVE_AN_EXAM_DATE')}</h2>
        <p>{t('DO_YOU_HAVE_AN_EXAM_DATE_SUBTITLE')}</p>
      </HeaderWrapper>
      <Form.Item name={['planification', 'hasExamDate']}>
        <HasExamDatePicker onChange={handleNext} />
      </Form.Item>
    </Wrapper>
  );
};
