import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  width: fit-content;
`;

export const TitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ContentTextWrapper = styled.div`
  display: flex;
  padding: 1px 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.black3};

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
