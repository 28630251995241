import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PostService = sdk.getService('PostService');

export const useRelatedPosts = (postId?: string) => {
  const queryKey = ['posts', postId, 'related'];

  const { isPending, data: relatedPosts } = useQuery({
    queryKey,
    queryFn: () => PostService.getRelatedPosts(postId!),
    enabled: !!postId
  });

  return { isPending, relatedPosts };
};
