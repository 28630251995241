import { Collapse } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  height: 100%;

  .ant-collapse {
    width: 100%;
    .ant-collapse-content {
      border-top: none;
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  .ant-collapse-item {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black3};

    .ant-collapse-header {
      padding: 16px 0;
    }
  }

  .ant-collapse.ant-collapse-icon-position-start {
    border: none;
    & > :last-child {
      border-radius: 0;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledCollapsePanel = styled(Collapse.Panel)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 32px;
  margin-block-start: 0;
`;
