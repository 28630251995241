import { create } from 'zustand';

interface IStore {
  questionIndex: number;
  responses: Record<string, number>;
  setQuestionIndex: (questionIndex: number) => void;
  prevQuestion: () => void;
  nextQuestion: () => void;
  setResponses: (responses: Record<string, number>) => void;
  resetQuizInfo: () => void;
}

export const useQuizInfo = create<IStore>(set => ({
  questionIndex: 0,
  responses: {},
  setQuestionIndex: questionIndex => set({ questionIndex }),
  prevQuestion: () => set(state => ({ questionIndex: state.questionIndex - 1 })),
  nextQuestion: () => set(state => ({ questionIndex: state.questionIndex + 1 })),
  setResponses: responses => set({ responses }),
  resetQuizInfo: () => set({ questionIndex: 0, responses: {} })
}));
