import styled from 'styled-components';

export const CollapseWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-collapse {
    width: 100%;
  }
`;

export const CollapseHeaderWrapper = styled.div`
  width: 100%;
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
