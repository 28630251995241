import { IActivity } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  data: IActivity[];
  setData: (data: IActivity[]) => void;
}

export const useMoveActivity = createStore<IStore>(set => ({
  data: [],
  setData: (data: IActivity[]) => set({ data })
}));
