interface ICookie {
  name: string;
  value: string;
  maxAge?: number;
  domain?: string;
  path?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const setCookie = (cookie: ICookie): void => {
  let cookieString = `${encodeURIComponent(cookie.name)}=${encodeURIComponent(cookie.value)};`;

  if (cookie.maxAge) {
    cookieString += `max-age=${cookie.maxAge};`;
  }

  if (cookie.domain) {
    cookieString += `domain=${cookie.domain};`;
  }

  if (cookie.path) {
    cookieString += `path=${cookie.path};`;
  }

  if (cookie.secure) {
    cookieString += 'secure;';
  }

  if (cookie.httpOnly) {
    cookieString += 'httponly;';
  }

  if (cookie.sameSite) {
    cookieString += `samesite=${cookie.sameSite};`;
  }

  document.cookie = cookieString;
};

export const parseCookies = (): Record<string, string> => {
  const cookies: Record<string, string> = {};
  const cookieStrings = document.cookie.split('; ');

  cookieStrings.forEach(cookieString => {
    const [name, value] = cookieString.split('=');
    cookies[decodeURIComponent(name)] = decodeURIComponent(value);
  });

  return cookies;
};

export const destroyCookie = (cname: string, domain: string) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/`;
};
