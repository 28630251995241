import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  pointer-events: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const SubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const DateTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
  text-align: right;
`;

export const DateValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black5};
  text-align: right;
`;
