import { RequestService } from '../request/request.service';
import { IUser } from '../user';

import {
  IAuthResponse,
  ILoginRequest,
  IAccountToRecover,
  IResetPasswordRequest,
  IChangePasswordRequest
} from './auth.contract';

export class AuthService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public async login(data: ILoginRequest) {
    return this.poster<IAuthResponse, ILoginRequest>('/auth/login', data);
  }

  public async recoverPassword(accountToRecover: IAccountToRecover) {
    return this.poster<{ email: string }, IAccountToRecover>(
      '/auth/recover-password',
      accountToRecover
    );
  }

  public async resetPassword(resetPasswordRequest: IResetPasswordRequest) {
    return this.poster<IAuthResponse, IResetPasswordRequest>(
      `/auth/reset-password`,
      resetPasswordRequest
    );
  }

  public async changePassword(passwords: IChangePasswordRequest) {
    return this.poster<IUser, IChangePasswordRequest>('/auth/change-password', passwords);
  }

  public async getUserIp() {
    return this.fetcher<string>(`/auth/ip`);
  }

  public async impersonate(email: string) {
    return this.poster<{ token: string }, { email: string }>(`/auth/impersonate`, { email });
  }
}
