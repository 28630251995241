import { EGamificationRewardActionType, SOCKET_EVENTS } from '@constants';
import { IAchievedRewardNotification } from '@sdk/contracts';
import { message } from 'antd';
import { useCallback, useEffect } from 'react';

import { GamificationNotificationContent } from '@/components/GamificationNotificationContent';
import { useSocket } from '@/hooks';
import { useMyGamificationRewards } from '@/store/gamification';

export const GamificationNotifications = () => {
  const [messageInstance, contextHolder] = message.useMessage();
  const { invalidate } = useMyGamificationRewards();
  const { socket } = useSocket();

  const showNotification = useCallback(
    (notification: IAchievedRewardNotification['notification']) => {
      const { action, points } = notification;
      const isAdding = action === EGamificationRewardActionType.ADD;
      messageInstance.open({
        content: <GamificationNotificationContent isAdding={isAdding} points={points} />,
        className: isAdding ? 'gamification-notification' : '',
        duration: 2
      });
    },
    [messageInstance]
  );

  useEffect(() => {
    if (!socket || socket.hasListeners(SOCKET_EVENTS.SEND_USER_REWARD)) return;

    socket.on(SOCKET_EVENTS.SEND_USER_REWARD, ({ notification }: IAchievedRewardNotification) => {
      invalidate();
      showNotification(notification);
    });

    return () => {
      socket?.off(SOCKET_EVENTS.SEND_USER_REWARD);
    };
  }, [socket]);

  return <>{contextHolder}</>;
};
