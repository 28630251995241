import { IScrappedExamGroup } from '@sdk/contracts';
import { create as createStore } from 'zustand';

type IStore = {
  scrappedExamGroup?: IScrappedExamGroup;
  isOpen: boolean;
  setScrappedExamGroup: (scrappedExamGroup?: IScrappedExamGroup) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const store = createStore<IStore>(set => ({
  isOpen: false,
  setScrappedExamGroup: scrappedExamGroup => set({ scrappedExamGroup }),
  setIsOpen: isOpen => set({ isOpen })
}));

export const useScrappedExamGroupForm = () => {
  const { isOpen, scrappedExamGroup, setScrappedExamGroup, setIsOpen } = store();

  return {
    isOpen,
    scrappedExamGroup,
    setScrappedExamGroup,
    setIsOpen
  };
};
