import { Tag } from 'antd';
import styled, { css } from 'styled-components';

export const PostWrapper = styled.div<{ isfeatured?: 'true' | 'false' }>`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  cursor: pointer;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black2};
  ${({ isfeatured }) =>
    isfeatured === 'true' &&
    css`
      background: ${({ theme }) => theme.colors.purple1};
      border: 1px solid ${({ theme }) => theme.colors.purple6};
    `}
`;

export const FeaturedTag = styled(Tag)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.purple6};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  margin-inline-end: 0 !important;
  border-radius: 0px 2px 0px 4px;
`;

export const StyledImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  flex: none;
  object-fit: cover;
`;

export const InfoWrapper = styled.div`
  display: flex;
  max-height: 108px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
`;

export const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${({ theme }) => theme.colors.black5};
  text-overflow: ellipsis;

  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
`;

export const DateWrapper = styled.div<{ isscheduled: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ isscheduled }) =>
    isscheduled === 'true' &&
    css`
      color: ${({ theme }) => theme.colors.orange7};
    `}
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black9};
  text-overflow: ellipsis;

  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
