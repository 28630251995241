import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.green6} !important;
  border-color: ${({ theme }) => theme.colors.green6} !important;
  color: ${({ theme }) => theme.colors.white} !important;

  :hover {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
