export const formatDataAndDownloadCsv = <T extends object>(data: T[], fileName: string) => {
  if (!data || data.length === 0) return;
  const separator = ',';

  const columHeaders = Object.keys(data[0]) as (keyof T)[];

  const csvContent = `${columHeaders.join(separator)}\n${data
    .map(row => {
      return columHeaders
        .map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];

          cell = cell instanceof Date ? cell.toLocaleString() : String(cell).replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator);
    })
    .join('\n')}`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
