import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    & > :last-child {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`;
