import { create as createStore } from 'zustand';

interface IStore {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useHowPlanificationWorksModal = createStore<IStore>(set => ({
  open: false,
  openModal: () => set({ open: true }),
  closeModal: () => set({ open: false })
}));
