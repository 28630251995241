import { useTranslation } from 'react-i18next';

import { SubstractWrapper, TextWrapper, Wrapper } from './GamificationNotificationContent.styles';

import GAMIFICATION_RAY_SUBSTRACT from '@/assets/gamification_ray_substract.svg';
import UMY_ADD_POINTS from '@/assets/umy_add_points.svg';

type Props = {
  isAdding: boolean;
  points: number;
};

const IMG_SIZE = 20;

export const GamificationNotificationContent: React.FC<Props> = ({ isAdding, points }) => {
  const { t } = useTranslation();

  if (!isAdding) {
    return (
      <SubstractWrapper>
        <span>{`${'-'} ${points}`}</span>
        <img width={IMG_SIZE} height={IMG_SIZE} src={GAMIFICATION_RAY_SUBSTRACT} alt="ray" />
      </SubstractWrapper>
    );
  }

  return (
    <Wrapper>
      <img src={UMY_ADD_POINTS} alt="Gamification ray" />
      <TextWrapper>{t('ADD_POINTS', { points })}</TextWrapper>
    </Wrapper>
  );
};
