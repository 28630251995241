import { IQuestion } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  question?: Partial<IQuestion>;
  setQuestion: (question?: Partial<IQuestion>) => void;
}

const store = createStore<IStore>(set => ({
  setQuestion: (question?: Partial<IQuestion>) => set({ question })
}));

export const useReportQuestionForm = () => {
  const { question, setQuestion } = store();

  return {
    question,
    setQuestion
  };
};
