import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  width: 100%;

  .ant-collapse-header {
    display: none !important;
  }
`;

export const ButtonWrapper = styled(Flex)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black7};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
