import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black2};
  }
`;

export const VideoWrapper = styled.div`
  max-width: 160px;
  max-height: 100px;

  & > div > video {
    border-radius: 8px;
  }
`;

export const TitleWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const ContentWrapper = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black7};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
