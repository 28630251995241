import { Select, SelectProps } from 'antd';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/Icon';
import { useTeachersList } from '@/store';

type Props = {
  value?: string;
  placeholder?: string;
  filter?: string;
  style?: SelectProps['style'];
  onChange?: (value: string) => void;
};

export const TeacherSearchSelector: React.FC<Props> = ({
  value,
  onChange,
  filter,
  placeholder,
  style
}) => {
  const { t } = useTranslation();
  const { teachers = [], setSearch } = useTeachersList();

  useEffect(() => {
    setSearch(filter ?? '');
  }, [filter]);

  const onSearch = debounce((search: string) => setSearch(search), 500);

  return (
    <Select
      showSearch
      style={style}
      value={value}
      onChange={onChange}
      placeholder={placeholder ?? t('SELECT_USER')}
      onSearch={onSearch}
      defaultActiveFirstOption={false}
      suffixIcon={<Icon i="Search" />}
      filterOption={false}
      options={teachers.map(({ _id, name, lastName }) => ({
        label: `${name} ${lastName}`,
        value: _id
      }))}
    />
  );
};
