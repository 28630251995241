import styled from 'styled-components';

export const ChangeStatusConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-select-single {
    height: 38px;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
`;
