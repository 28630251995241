export const MyAcademyTranslations = {
  ACADEMY_NAME: {
    es: 'Nombre de la academia',
    en: 'Academy name',
    cat: 'Nom de l`acadèmia',
    eus: 'Akademiaren izena'
  },
  ACADEMY_DESCRIPTION: {
    es: 'Descripción de la academia',
    en: 'Academy description',
    cat: 'Descripció de l`acadèmia',
    eus: 'Akademiaren deskribapena'
  },
  ACADEMY_DOMAIN: {
    es: 'Dominio de la academia',
    en: 'Academy domain',
    cat: 'Domini de l`acadèmia',
    eus: 'Akademiaren domeinua'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Desar canvis',
    eus: 'Aldaketak gorde'
  },
  ACADEMY_PROFILE_UPDATED: {
    es: 'Perfil de la academia actualizado',
    en: 'Academy profile updated',
    cat: 'Perfil de l`acadèmia actualitzat',
    eus: 'Akademiaren profilak eguneratu da'
  },
  CERTIFICATE_TEMPLATE: {
    es: 'Plantilla de certificado',
    en: 'Certificate template',
    cat: 'Plantilla de certificat',
    eus: 'Agiriaren txantiloia'
  },
  WEBSITE: {
    es: 'Sitio web',
    en: 'Website',
    cat: 'Lloc web',
    eus: 'Webgunea'
  }
};
