export const LoginTranslations = {
  SIGN_IN: {
    es: 'Iniciar sesión',
    en: 'Sign in',
    cat: 'Inicia sessió',
    eus: 'Hasi saioa'
  },
  REMEMBER_ME: {
    es: 'Recuérdame',
    en: 'Remember me',
    cat: 'Recorda`m',
    eus: 'Gogoratu'
  },
  FORGET_MY_PASSWORD: {
    es: 'Olvidé mi contraseña',
    en: 'Forget my password',
    cat: 'He oblidat la meva contrasenya',
    eus: 'Pasahitza ahaztu dut'
  }
};
