import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  align-self: stretch;

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column;
  }
`;
