import { IReportFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 20;

const UserService = sdk.getService('UserService');

interface IStore {
  selectedStudentsIds: string[];
  page: number;
  limit: number;
  search: string;
  setSearch: (search: string) => void;
  addSelectedStudentId: (id: string) => void;
  removeSelectedStudentId: (id: string) => void;
  selectBulkSelectedStudentIds: (id: string[]) => void;
  filter?: IReportFilters;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: IReportFilters) => void;
}

const store = createStore<IStore>(set => ({
  selectedStudentsIds: [],
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  addSelectedStudentId: (id: string) =>
    set(state => ({ ...state, selectedStudentsIds: [...state.selectedStudentsIds, id] })),
  removeSelectedStudentId: (id: string) =>
    set(state => ({
      ...state,
      selectedStudentsIds: state.selectedStudentsIds.filter(sId => sId !== id)
    })),
  selectBulkSelectedStudentIds: (ids: string[]) =>
    set(state => ({ ...state, selectedStudentsIds: ids })),
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: IReportFilters) => set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

export const useStudentsReportsList = () => {
  const {
    page,
    limit,
    search,
    filter,
    setPageAndLimit,
    setFilter,
    setSearch,
    selectedStudentsIds,
    addSelectedStudentId,
    removeSelectedStudentId,
    selectBulkSelectedStudentIds
  } = store();
  const queryKey = ['reports', 'students', `page-${page}`, `limit-${limit}`, search, filter];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => UserService.getUsersReport(search, { page, limit }, filter)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    studentReports: data?.docs,
    selectedStudentsIds,
    addSelectedStudentId,
    removeSelectedStudentId,
    selectBulkSelectedStudentIds,
    isLoading,
    error,
    setPageAndLimit,
    pagination,
    setSearch,
    filter,
    setFilter
  };
};
