import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  & > :first-child {
    color: ${({ theme }) => theme.colors.black5};
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const EmojiWrapper = styled.div`
  font-size: 38px;
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;
