export enum EQuestionReportOptions {
  GRAMMAR = 'GRAMMAR',
  WRONG_ANSWER = 'WRONG_ANSWER',
  QUESTION_INCOMPLETE = 'QUESTION_INCOMPLETE',
  WRONG_RELATED_COMTENT = 'WRONG_RELATED_COMTENT',
  OTHER_QUESTION_REPORT = 'OTHER_QUESTION_REPORT'
}

export const QUESTION_REPORT_OPTIONS = Object.values(EQuestionReportOptions);

export enum EActivityReportOptions {
  AUDIO_OR_VIDEO = 'AUDIO_OR_VIDEO',
  LESSON_CONTENT = 'LESSON_CONTENT',
  INCOMPLETE_CONTENT = 'INCOMPLETE_CONTENT',
  TEACHER_EXPLANATION = 'TEACHER_EXPLANATION',
  REPEATED_VIDEO = 'REPEATED_VIDEO',
  WRONG_INFORMATION = 'WRONG_INFORMATION',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  OTHER_ACTIVITY_REPORT = 'OTHER_ACTIVITY_REPORT'
}

export const ACTIVITY_REPORT_OPTIONS = Object.values(EActivityReportOptions);

export type IProblemReportOptions = EQuestionReportOptions | EActivityReportOptions;

export enum EProblemReportStatus {
  NOT_REVIEWED = 'NOT_REVIEWED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED'
}
