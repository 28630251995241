import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ItemWrapper } from '../ExamDate.styles';

type Props = {
  value?: number;
  onChange?: (value: number) => void;
};

const MINUTES_OPTIONS = Array.from({ length: 60 }, (_, i) => ({
  label: i.toString(),
  value: i
}));

export const DailyMinutesPicker: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('DefaultTranslations');

  return (
    <ItemWrapper>
      <Select
        value={value}
        onChange={onChange}
        showSearch
        options={MINUTES_OPTIONS}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <div>{t('MINUTES')}</div>
    </ItemWrapper>
  );
};
