import { IQuestion } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuestionService = sdk.getService('QuestionService');

export const useQuestionQuizzes = (question?: IQuestion) => {
  const queryKey = ['question', question?._id, 'quizzes'];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => QuestionService.getQuestionQuizzes(question?._id),
    enabled: !!question?._id
  });

  return { quizzes: data, isPending };
};
