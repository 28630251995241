import { useTranslation } from 'react-i18next';

import { Wrapper } from './EmptyChatMessages.styles';

import { Icon } from '@/components/Icon';

const ICON_SIZE = 24;

export const EmptyChatMessages = () => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <Wrapper>
      <Icon i="Chat" size={ICON_SIZE} />
      <div>{t('NO_MESSAGES')}</div>
    </Wrapper>
  );
};
