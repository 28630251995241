import { useQuery } from '@tanstack/react-query';
import { dateProvider } from '@utils';
import { useEffect } from 'react';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

type Props = {
  isAnyEventLive: boolean;
  setIsAnyEventLive: (isAnyEventLive: boolean) => void;
};

const store = createStore<Props>(set => ({
  isAnyEventLive: false,
  setIsAnyEventLive: isAnyEventLive => set({ isAnyEventLive })
}));

export const useCurrentLiveEvents = () => {
  const { isAnyEventLive, setIsAnyEventLive } = store();

  const { data } = useQuery({
    queryKey: ['events', 'today'],
    queryFn: () => EventService.getTodayEvents()
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const liveEvents = data?.some(event => dateProvider.isLive(event.startDate, event.endDate));
      if (liveEvents !== undefined) setIsAnyEventLive(liveEvents);
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  return { isAnyEventLive, setIsAnyEventLive };
};
