import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useAllCoursesForSelectors = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['courses', 'all'],
    queryFn: () => CourseService.findAllCoursesForSelectors()
  });

  return {
    courses: data,
    isLoading,
    error
  };
};
