import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import { INotification, INotificationFilter, IUpdateNotification } from './notification.contract';

export class NotificationService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getNotifications(
    search?: string,
    pagination?: IPagination,
    filters?: INotificationFilter,
    bypass?: boolean
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<INotification>>(`/notifications?${query}`);
  }

  public updateNotification({ notificationId, notification }: IUpdateNotification) {
    return this.putter(`/notifications/${notificationId}`, notification);
  }

  public removeNotifications(notifications: string[]) {
    return this.deleter(`/notifications`, {
      data: { notifications }
    });
  }
}
