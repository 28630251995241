import { ETranscriptionStatus } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div<{ status?: ETranscriptionStatus; isIndexed?: boolean }>`
  width: 100% !important;
  margin-left: auto;
  .fa-message-text {
    color: ${({ theme, status }) =>
      ({
        [ETranscriptionStatus.NOT_READY]: theme.colors.red6,
        [ETranscriptionStatus.PROCESSING]: theme.colors.purple6,
        [ETranscriptionStatus.READY]: theme.colors.green6
      }[status || ETranscriptionStatus.NOT_READY])};
  }
  .fa-CheckmarkOutline {
    color: ${({ theme, isIndexed }) => (isIndexed ? theme.colors.green6 : theme.colors.red6)};
  }
`;
