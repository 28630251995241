export const CommonQuizFormTranslations = {
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde'
  },
  CREATE_TEST: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear test',
    eus: 'Testa sortu'
  },
  EDIT_TEST: {
    es: 'Editar test',
    en: 'Edit test',
    cat: 'Editar test',
    eus: 'Testa editatu'
  },
  IMPORT_TEST: {
    es: 'Importar test',
    en: 'Import test',
    cat: 'Importar test',
    eus: 'Testa inportatu'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  QUIZ_TITLE: {
    es: 'Título del test',
    en: 'Quiz title',
    cat: 'Títol del test',
    eus: 'Testaren izenburua'
  },
  TAGS: {
    es: 'Etiquetas',
    en: 'Tags',
    cat: 'Etiquetes',
    eus: 'Etiketak'
  },
  TIMER: {
    es: 'Temporizador',
    en: 'Timer',
    cat: 'Temporitzador',
    eus: 'Denboragailua'
  },
  SELECT_QUESTIONS: {
    es: 'Seleccionar preguntas',
    en: 'Select questions',
    cat: 'Seleccionar preguntes',
    eus: 'Galderak hautatu'
  },
  QUESTIONS_TOTAL_COUNT: {
    es: '{{total}} preguntas',
    en: '{{total}} questions',
    cat: '{{total}} preguntes',
    eus: '{{total}} galdera'
  },
  QUESTIONS_TOTAL_SELECTED: {
    es: '{{total}} preguntas seleccionadas',
    en: '{{total}} questions selected',
    cat: '{{total}} preguntes seleccionades',
    eus: '{{total}} galdera hautatuak'
  },
  QUESTIONS: {
    es: 'Preguntas',
    en: 'Questions',
    cat: 'Preguntes',
    eus: 'Galderak'
  },
  QUIZ_CREATED: {
    es: 'Test creado',
    en: 'Test created',
    cat: 'Test creat',
    eus: 'Testa sortua'
  },
  QUIZ_UPDATED: {
    es: 'Test actualizado',
    en: 'Test updated',
    cat: 'Test actualitzat',
    eus: 'Testa eguneratua'
  },
  FAMILY: {
    es: 'Familia',
    en: 'Family',
    cat: 'Família',
    eus: 'Familia'
  },
  USE_THE_FILTER_TO_SELECT_QUESTIONS: {
    es: 'Usa el filtro para seleccionar preguntas',
    en: 'Use the filter to select questions',
    cat: 'Utilitza el filtre per seleccionar preguntes',
    eus: 'Erabili iragazkia galderak hautatzeko'
  },
  NO_QUESTIONS_FOUND: {
    es: 'No se encontraron preguntas',
    en: 'No questions found',
    cat: 'No s`han trobat preguntes',
    eus: 'Ez da galderarik aurkitu'
  },
  TEST_CONFIGURATION: {
    es: 'Configuración del test',
    en: 'Test configuration',
    cat: 'Configuració del test',
    eus: 'Testaren konfigurazioa'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  DISCARD: {
    es: 'Descartar',
    en: 'Discard',
    cat: 'Descartar',
    eus: 'Ezabatu'
  },
  CANCEL_CONFIRMATION: {
    es: '¿Seguro que quieres descartar el test?',
    en: 'Are you sure you want to discard the test?',
    cat: 'Estàs segur que vols descartar el test?',
    eus: 'Ziur zaude testa baztertu nahi duzula?'
  },
  QUIZ_MODE: {
    es: 'Tipo',
    en: 'Mode',
    cat: 'Tipus',
    eus: 'Mota'
  },
  SAVE_QUIZ: {
    es: 'Guardar test',
    en: 'Save test',
    cat: 'Desar test',
    eus: 'Testa gorde'
  },
  ADD_QUESTION: {
    es: 'Añadir pregunta',
    en: 'Add question',
    cat: 'Afegir pregunta',
    eus: 'Galdera gehitu'
  },
  QUESTION_TITLE: {
    es: 'Enunciado de la pregunta',
    en: 'Question title',
    cat: 'Enunciat de la pregunta',
    eus: 'Galderaren enuntziatua'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  QUESTION_TITLE_PLACEHOLDER: {
    es: 'Introduce el título de la pregunta',
    en: 'Enter the question title',
    cat: 'Introdueix el títol de la pregunta',
    eus: 'Sartu galderaren izenburua'
  },
  INSERT_FORMULA: {
    es: 'Insertar fórmula',
    en: 'Insert formula',
    cat: 'Inserir fórmula',
    eus: 'Txertatu formula'
  },
  INSERT_IMAGE: {
    es: 'Insertar imagen',
    en: 'Insert image',
    cat: 'Inserir imatge',
    eus: 'Txertatu irudia'
  },
  QUESTION_DELETE_CONFIRMATION: {
    es: '¿Seguro que quieres borrar la pregunta?',
    en: 'Are you sure you want to delete the question?',
    cat: 'Estàs segur que vols esborrar la pregunta?',
    eus: 'Ziur zaude galdera ezabatu nahi duzula?'
  },
  ADD_ANSWER: {
    es: 'Añadir respuesta',
    en: 'Add answer',
    cat: 'Afegir resposta',
    eus: 'Erantzun gehitu'
  },
  ANSWER_DELETE_CONFIRMATION: {
    es: '¿Seguro que quieres borrar la respuesta?',
    en: 'Are you sure you want to delete the answer?',
    cat: 'Estàs segur que vols esborrar la resposta?',
    eus: 'Ziur zaude erantzuna ezabatu nahi duzula?'
  },
  ANSWER_TITLE_PLACEHOLDER: {
    es: 'Introduce el título de la respuesta',
    en: 'Enter the answer title',
    cat: 'Introdueix el títol de la resposta',
    eus: 'Sartu erantzunaren izenburua'
  },
  QUESTION_COUNT: {
    es: '{{count}} pregunta(s)',
    en: '{{count}} question(s)',
    cat: '{{count}} pregunta(es)',
    eus: '{{count}} galdera(k)'
  },
  CREATE_QUESTION: {
    es: 'Crear pregunta',
    en: 'Create question',
    cat: 'Crear pregunta',
    eus: 'Galdera sortu'
  },
  ADD_FROM_QUESTION_BANK: {
    es: 'Añadir desde banco de preguntas',
    en: 'Add from question bank',
    cat: 'Afegir des del banc de preguntes',
    eus: 'Galdera bankutik gehitu'
  },
  CONFIGURATION: {
    es: 'Configuración',
    en: 'Configuration',
    cat: 'Configuració',
    eus: 'Konfigurazioa'
  },
  UPDATE_QUESTION: {
    es: 'Actualizar pregunta',
    en: 'Update question',
    cat: 'Actualitzar pregunta',
    eus: 'Eguneratu galdera'
  },
  SELECT_FROM_QUESTION_BANK: {
    es: 'Seleccionar preguntas',
    en: 'Select questions',
    cat: 'Barrejar preguntes',
    eus: 'Hautatu galderak'
  },
  NO_QUESTIONS_ADDED: {
    es: 'No se han añadido preguntas.',
    en: 'No questions added.',
    cat: 'No s`han afegit preguntes.',
    eus: 'Galderarik ez da gehitu.'
  },
  QUESTION: {
    es: 'Pregunta',
    en: 'Question',
    cat: 'Pregunta',
    eus: 'Galdera'
  },
  YEAR: {
    es: 'Año',
    en: 'Year',
    cat: 'Any',
    eus: 'Urtea'
  },
  DIFFICULTY: {
    es: 'Dificultad',
    en: 'Difficulty',
    cat: 'Dificultat',
    eus: 'Zailtasuna'
  },
  ANSWERS: {
    es: 'Respuestas',
    en: 'Answers',
    cat: 'Respostes',
    eus: 'Erantzunak'
  },
  EXPLANATION: {
    es: 'Explicación',
    en: 'Explanation',
    cat: 'Explicació',
    eus: 'Azalpena'
  },
  SELECT_IMAGE: {
    es: 'Seleccionar imagen',
    en: 'Select image',
    cat: 'Seleccionar imatge',
    eus: 'Irudi bat hautatu'
  },
  EASY: {
    es: 'Fácil',
    en: 'Easy',
    cat: 'Fàcil',
    eus: 'Erraza'
  },
  MEDIUM: {
    es: 'Medio',
    en: 'Medium',
    cat: 'Mitjà',
    eus: 'Ertaina'
  },
  HARD: {
    es: 'Difícil',
    en: 'Hard',
    cat: 'Difícil',
    eus: 'Zaila'
  },
  QUESTION_CREATED: {
    es: 'Pregunta creada.',
    en: 'Question created.',
    cat: 'Pregunta creada.',
    eus: 'Galdera sortua.'
  },
  QUESTION_UPDATED: {
    es: 'Pregunta actualizada.',
    en: 'Question updated.',
    cat: 'Pregunta actualitzada.',
    eus: 'Galdera eguneratua.'
  },
  QUESTION_DELETED: {
    es: 'Pregunta eliminada.',
    en: 'Question deleted.',
    cat: 'Pregunta eliminada.',
    eus: 'Galdera ezabatua.'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cerca',
    eus: 'Bilatu'
  },
  EDIT_QUESTION: {
    es: 'Editar pregunta',
    en: 'Edit question',
    cat: 'Editar pregunta',
    eus: 'Editatu galdera'
  },
  DELETE_QUESTION: {
    es: 'Eliminar pregunta',
    en: 'Delete question',
    cat: 'Eliminar pregunta',
    eus: 'Galdera ezabatu'
  },
  DELETE_QUESTION_CONFIRMATION: {
    es: '¿Estás seguro de eliminar esta pregunta?',
    en: 'Are you sure you want to delete this question?',
    cat: 'Segur que vols eliminar aquesta pregunta?',
    eus: 'Zihur zaude galdera hau ezabatu nahi duzula?'
  },
  DELETE_QUESTION_SUCCESS: {
    es: 'Pregunta eliminada con éxito',
    en: 'Question deleted successfully',
    cat: 'Pregunta eliminada amb èxit',
    eus: 'Galdera ongi ezabatu da'
  },
  DELETE_QUESTIONS_SUCCESS: {
    es: 'Preguntas eliminadas con éxito',
    en: 'Questions deleted successfully',
    cat: 'Preguntes eliminades amb èxit',
    eus: 'Galderak ongi ezabatu dira'
  },
  WRITE_HERE_THE_ANSWER: {
    es: 'Escribe aquí la respuesta',
    en: 'Write here the answer',
    cat: 'Escriu aquí la resposta',
    eus: 'Idatzi hemen erantzuna'
  },
  ARE_YOU_SURE_TO_DELETE_ANSWER: {
    es: '¿Estás seguro de eliminar esta respuesta?',
    en: 'Are you sure to delete this answer?',
    cat: 'Segur que vols eliminar aquesta resposta?',
    eus: 'Zihur zaude erantzun hau ezabatu nahi duzula?'
  },
  ADD_NEW_EMPTY_ANSWER: {
    es: 'Agregar respuesta',
    en: 'Add new response',
    cat: 'Afegir resposta nova',
    eus: 'Erantzun berria gehitu'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Kurtsua'
  },
  SECTION: {
    es: 'Tema',
    en: 'Section',
    cat: 'Tema',
    eus: 'Gaia'
  },
  MANUAL: {
    es: 'Generar preguntas de forma Manual',
    en: 'Generate questions manually',
    cat: 'Generar preguntes de forma manual',
    eus: 'Galderak eskuz sortu'
  },
  UPLOAD_DOCUMENT: {
    es: 'Sube un archivo',
    en: 'Upload document',
    cat: 'Pujar un arxiu',
    eus: 'Artxiboa igo'
  },
  BACK: {
    es: 'Atrás',
    en: 'Back',
    cat: 'Enrere',
    eus: 'Atzera'
  },
  QUESTIONS_PROCESSED_SUCCESSFULLY: {
    es: 'Preguntas procesadas correctamente',
    en: 'Questions processed successfully',
    cat: 'Preguntes processades correctament',
    eus: 'Galderak ongi prozesatu dira'
  },
  QUESTION_TYPE: {
    es: 'Tipo de pregunta',
    en: 'Question type',
    cat: 'Tipus de pregunta',
    eus: 'Galdera mota'
  },
  TYPE_AI: {
    es: 'IA',
    en: 'AI',
    cat: 'IA',
    eus: 'IA'
  },
  TYPE_MANUAL: {
    es: 'Manual',
    en: 'Manual',
    cat: 'Manual',
    eus: 'Eskuzko'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continua',
    eus: 'Jarraitu'
  },
  PREVIEW: {
    es: 'Vista previa',
    en: 'Preview',
    cat: 'Vista prèvia',
    eus: 'Aurrebista'
  },
  SELECT_QUESTION: {
    es: 'Selecciona una pregunta',
    en: 'Select a question',
    cat: 'Selecciona una pregunta',
    eus: 'Galdera bat hautatu'
  },
  SEARCH_A_QUESTION_TO_FILTER: {
    es: 'Busca una pregunta para filtrar',
    en: 'Search a question to filter',
    cat: 'Cerca una pregunta per filtrar',
    eus: 'Bilatu galdera bat iragazteko'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  INSERT_MEDIA: {
    es: 'Insertar recurso',
    en: 'Insert media',
    cat: 'Inserir recurs',
    eus: 'Baliabidea txertatu'
  },
  ACTIVITY_MATERIAL: {
    es: 'Material en actividades',
    en: 'Activity material',
    cat: 'Material en activitats',
    eus: 'Materiala jardueretan'
  },
  RELATED_CONTENT: {
    es: 'Contenido relacionado',
    en: 'Related content',
    cat: 'Contingut relacionat',
    eus: 'Eduki lotura'
  },
  UPDATE_QUESTION_WARNING: {
    es: 'AVISO: Si modificas esta pregunta, afectará a todos los tests en los que esté',
    en: 'WARNING: If you modify this question, it will affect all the tests in which it is',
    cat: 'AVÍS: Si modifiques aquesta pregunta, afectarà a tots els tests en els que estigui',
    eus: 'KONTUZ: Galdera hau aldatzen baduzu, test guztietan eragina izango du'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Kurtsoak'
  },
  SECTIONS: {
    es: 'Temas',
    en: 'Sections',
    cat: 'Temes',
    eus: 'Gaiei'
  },
  QUIZZES_CONTAINING_QUESTION: {
    es: 'Tests que contienen esta pregunta',
    en: 'Quizzes containing this question',
    cat: 'Tests que contenen aquesta pregunta',
    eus: 'Galdera hau duten testak'
  },
  MAX_RETRIES: {
    es: 'Máximo de intentos',
    en: 'Max attempts',
    cat: 'Màxim d`intents',
    eus: 'Saio kopurua'
  },
  EXPLANATION_TOOLTIP: {
    es: 'Añade una explicación para que el alumno comprenda la respuesta correcta.',
    en: 'Add an explanation so that the student understands the correct answer.',
    cat: 'Afegeix una explicació perquè l`alumne entengui la resposta correcta.',
    eus: 'Azalpena gehitu ikasleak zuzena ulertzeko.'
  }
};
