import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 64px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    width: auto;
  }
`;

export const AvatarWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const NameWrapper = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

export const TagWrapper = styled.div`
  max-width: 60vw;
  overflow: scroll;
`;
