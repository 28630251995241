import { EPostStatus } from '@constants';
import { IPost } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Button, Form, Input, Modal, Flex, DatePicker, Switch, Divider, Tooltip } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostForm } from '../../../hooks';

import { NoTagsSelectedAlert, TitleWrapper } from './PostForm.styles';
import { StatusTag } from './StatusTag';
import { TopicSelector } from './TopicSelector';

import { HtmlEditor, TagSelector, TimeZoneSelector } from '@/components/Form';
import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { RULES } from '@/constants';
import { useMutatePost } from '@/store';

type Props = {
  isEditable?: boolean;
};

const PREVIEW_SIZE = {
  width: '100%',
  height: 200
};

const MODAL_MAX_WIDTH = 760;

export const PostForm: React.FC<Props> = ({ isEditable }) => {
  const [showSchedulePost, setShowSchedulePost] = useState(false);
  const { createPost, updatePost, isPending } = useMutatePost();
  const { t } = useTranslation('CommonDashboardTranslations');
  const { isOpen, post, setPost, setIsOpen } = usePostForm();
  const [form] = Form.useForm();

  const tags = useWatch('tags', form);
  const hasTagsSelected = !!tags?.length;

  const handleFinish = async (values: IPost) => {
    const postData = { ...values, tags: values.tags || [] };
    if (post?._id) {
      updatePost(postData, { onSuccess: handleCancel });
    } else {
      createPost(postData, { onSuccess: handleCancel });
    }
  };

  const handleSaveDraft = () => {
    if (post) return;
    form.setFieldsValue({ status: EPostStatus.DRAFT });
    form.submit();
  };

  const handleCancel = () => {
    setPost();
    setIsOpen(false);
    form.resetFields();
    setShowSchedulePost(false);
  };

  useEffect(() => {
    if (post) {
      form.setFieldsValue({
        ...post,
        topic: post.topic?._id
      });
      setShowSchedulePost(!!post.scheduledPublishDate);
    }
  }, [post]);

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  if (!isEditable) return null;

  return (
    <Modal
      forceRender
      open={isOpen}
      destroyOnClose
      style={{ top: 0 }}
      onCancel={handleCancel}
      width={MODAL_MAX_WIDTH}
      title={<TitleWrapper>{t(post ? 'EDIT_NEWS' : 'CREATE_NEWS')}</TitleWrapper>}
      footer={
        <>
          <Divider />
          <Flex justify="space-between" align="center">
            <StatusTag status={post?.status} />
            <Flex justify="flex-end" gap={8}>
              {!post && (
                <Button loading={isPending} onClick={handleSaveDraft}>
                  {t('SAVE_DRAFT')}
                </Button>
              )}
              <Button loading={isPending} type="primary" onClick={form.submit}>
                {post ? t('SAVE_CHANGES') : t('PUBLISH_NEWS')}
              </Button>
            </Flex>
          </Flex>
        </>
      }>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>
        <Flex gap={16} flex="1 1 0">
          <Form.Item
            label={t('IMAGE')}
            name="featuredImage"
            rules={[RULES.REQUIRED]}
            style={{ flex: '1 1 0' }}>
            <Upload
              accept="image/*"
              preview
              width={PREVIEW_SIZE.width}
              height={PREVIEW_SIZE.height}>
              <Icon i="ImageReference" />
            </Upload>
          </Form.Item>
          <Flex vertical flex="1 1 0">
            <Form.Item name="title" label={t('POST_TITLE')} rules={[RULES.REQUIRED]}>
              <Input />
            </Form.Item>
            <Form.Item label={t('TOPIC')} name="topic" rules={[RULES.REQUIRED]}>
              <TopicSelector />
            </Form.Item>
            <Form.Item name="tags" label={t('TAGS')}>
              <TagSelector mode="multiple" />
            </Form.Item>
          </Flex>
        </Flex>
        {!hasTagsSelected && (
          <NoTagsSelectedAlert>{t('NO_TAGS_SELECTED_ALERT')}</NoTagsSelectedAlert>
        )}
        <Divider />
        <Form.Item name="content" label={t('POST_CONTENT')} rules={[RULES.REQUIRED]}>
          <HtmlEditor />
        </Form.Item>
        <Flex gap={8} align="center">
          <Switch
            checked={showSchedulePost}
            onChange={setShowSchedulePost}
            disabled={!!post?.scheduledPublishDate}
          />
          <div>{t('SCHEDULE_POST')}</div>
        </Flex>
        <Divider />
        {showSchedulePost && (
          <Flex vertical>
            <Flex gap={16}>
              <Form.Item
                getValueProps={value => ({ value: value ? dayjs(value) : '' })}
                label={t('SCHEDULE_POST_DATE')}
                rules={[RULES.REQUIRED]}
                name={['scheduledPublishDate', 'dateTime']}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  disabledDate={date => dateProvider.isBeforeToday(date.toDate())}
                />
              </Form.Item>
              <Form.Item
                label={t('SCHEDULE_POST_TIMEZONE')}
                rules={[RULES.REQUIRED]}
                name={['scheduledPublishDate', 'timeZone']}>
                <TimeZoneSelector />
              </Form.Item>
            </Flex>
            <Divider />
          </Flex>
        )}
        <Flex gap={8} align="center">
          <Form.Item noStyle name="isFeatured" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Flex gap={4} align="center">
            <div>{t('FEATURE_POST')}</div>
            <Tooltip title={t('FEATURE_POST_TOOLTIP')}>
              <Icon i="Information" />
            </Tooltip>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};
