import { ERoles } from '@constants';
import { ISocketUser, IUser } from '@sdk/contracts';

/**
 * It takes a user object and returns an object with boolean properties that indicate whether the user
 * is an admin, manager, student, or teacher
 * @param {Partial<User>} user - Partial<User>
 * @returns An object with 4 properties.
 */
export const checkUserRole = (user?: Partial<IUser> | ISocketUser) => {
  return {
    isAdmin: user?.role === ERoles.ADMIN,
    isManager: user?.role === ERoles.MANAGER,
    isStudent: user?.role === ERoles.STUDENT,
    isTeacher: user?.role === ERoles.TEACHER,
    isInspector: user?.role === ERoles.INSPECTOR,
    isAITeacher: user?.role === ERoles.AI_TEACHER,
    isSales: user?.role === ERoles.SALES
  };
};
