import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs .ant-tabs-content {
    height: 100%;
    background: ${({ theme }) => theme.colors.white};
    padding: 16px;
  }
  .ant-tabs .ant-tabs-tabpane {
    height: 100%;
  }
`;
