import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { HeaderWrapper, HighlightWrapper, StyledImage } from './DoYouKnowOtherPeople.styles';
import { KnowOtherPeoplePicker } from './KnowOtherPeoplePicker';

import NAME_VECTOR from '@/assets/name_vector.svg';

type Props = {
  handleNext: () => void;
};

export const DoYouKnowOtherPeople: React.FC<Props> = ({ handleNext }) => {
  const { t } = useTranslation('StudentOnboardingTranslations');

  return (
    <StepWrapper>
      <HeaderWrapper>
        <HighlightWrapper>
          <h2>{t('GREAT')}</h2>
          <StyledImage src={NAME_VECTOR} alt="name_vector" />
        </HighlightWrapper>
        <h2>{t('DO_YOU_KNOW_OTHER_PEOPLE')}</h2>
      </HeaderWrapper>
      <Form.Item name={['onBoarding', 'knowOtherStudents']}>
        <KnowOtherPeoplePicker onChange={handleNext} />
      </Form.Item>
    </StepWrapper>
  );
};
