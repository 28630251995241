import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ProductTourService = sdk.getService('ProductTourService');

export const useUserProductTour = () => {
  const queryKey = ['product-tour'];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => ProductTourService.getUserProductTour()
  });

  return {
    productTour: data,
    isLoading,
    error
  };
};
