import { IPaginatedResponse, IPagination } from '@sdk/contracts';
import { createQueryString } from '@utils';

import { RequestService } from '../request';

import { ICreatePromotion, IPromotion, IPromotionsListFilters } from './promotion.contract';

export class PromotionService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async createPromotion(data: ICreatePromotion) {
    return this.poster<IPromotion, ICreatePromotion>('/promotions', data);
  }

  async updatePromotion(data: ICreatePromotion, _id?: string) {
    return this.putter<IPromotion, ICreatePromotion>(`/promotions/${_id}`, data);
  }

  async findAllPromotions(
    pagination: IPagination,
    search: string,
    bypass: boolean,
    filters?: IPromotionsListFilters
  ) {
    const query = createQueryString({
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<IPromotion>>(`/promotions?${query}`);
  }

  async deletePromotion(_id: string) {
    return this.deleter<IPromotion>(`/promotions/${_id}`);
  }
}
