import styled from 'styled-components';

export const Wrapper = styled.div`
  .rpv-default-layout__container {
    border-radius: 8px;
    overflow: auto;
  }
  // TODO: workaround for the issue with the full screen mode until @react-pdf-viewer v4 is released
  // (https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1714)
  .rpv-full-screen__overlay {
    display: none;
  }
`;

export const ToolbarWrapper = styled.div`
  align-items: center;
  background-color: #292929;
  border-bottom: 1px solid #000;
  display: flex;
  color: #fff;
  height: 2.5rem;
  width: 100%;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0 1rem;
  .rpv-core__minimal-button,
  .rpv-toolbar__label {
    color: #fff !important;
  }
  .rpv-core__textbox {
    background-color: #121212;
    border: 1px solid #121212;
    box-sizing: border-box;
    border-radius: 0.25rem;
    color: #fff;
    padding: 0 0.5rem;
    height: 2rem;
    width: 100%;
  }
`;
