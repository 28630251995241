import { ISubscription } from '@sdk/contracts';

export const getActivityTrace = (subscription: ISubscription, activityId: string) => {
  const { course } = subscription;
  const { sections = [] } = course;
  const section = sections.find(s => s.activities.some(a => a._id === activityId));
  if (!section) {
    return {
      activity: sections[0].activities[0]._id,
      section: sections[0]._id,
      course: course._id
    };
  }
  return {
    activity: activityId,
    section: section._id,
    course: course._id
  };
};
