import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black2};
`;

export const ActivityStatus = styled.div<{ active: boolean }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.colors.green6 : theme.colors.red6)};
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Cover = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black9};
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  width: 42px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  i {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.green6};
  }

  .fa-circle-exclamation {
    font-size: 6px;
    border-radius: 50%;
    position: absolute;
    color: ${({ theme }) => theme.colors.orange6};
    top: 2px;
    left: 2px;
  }
`;
