import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.black1};
  overflow: hidden;
  max-height: 180px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  width: 100%;
`;

export const SenderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const NameWrapper = styled.div`
  font-weight: 600;
`;

export const DateWrapper = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const ContentWrapper = styled.div`
  & > p {
    margin: 0;
  }
  word-break: break-word;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: linear-gradient(180deg, #ffffff60, white);
    pointer-events: none;
  }
`;
