import { Tag } from 'antd';
import styled from 'styled-components';

type Props = {
  color: string;
  size?: number;
};

export const StyledTag = styled(Tag)<{ bordercolor: string; backgroundcolor: string }>`
  border: transparent;
  padding: 4px 8px;
  background: ${({ backgroundcolor }) => backgroundcolor};
  width: fit-content;
  border: 1px solid ${({ bordercolor }) => bordercolor};
`;

export const TextWrapper = styled.div<Props>`
  font-weight: 600;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size ?? 16}px;
`;
