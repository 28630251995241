import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  .ant-upload {
    border: none;
    background-color: ${({ theme }) => theme.colors.black1};
    border-top-left-radius: 13px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-upload:hover {
    border: 1px dashed ${({ theme }) => theme.colors.black1};
  }
`;
