export enum ESectionTrackStatus {
  NOT_STARTED = 'NOT_STARTED',
  SEEN = 'SEEN',
  SUMMARISED = 'SUMMARISED',
  STUDIED = 'STUDIED',
  REVISED = 'REVISED',
  KNOWN = 'KNOWN'
}

export const SECTION_TRACK_STATUSES = Object.values(ESectionTrackStatus);
