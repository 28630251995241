import { ITag } from '@sdk/contracts';
import { useEffect } from 'react';
import { create as createStore } from 'zustand';

import { useProfile } from '@/store';

interface IStore {
  tagSelected?: ITag;
  setTagSelected: (data: ITag) => void;
}

const store = createStore<IStore>(set => ({
  setTagSelected: (tagSelected: ITag) => set({ tagSelected })
}));

export const useTagSelected = () => {
  const { tagSelected, setTagSelected } = store();
  const { profile } = useProfile();

  useEffect(() => {
    if (profile && profile.tags.length > 0 && !tagSelected) setTagSelected(profile.tags[0]);
  }, [profile]);

  return {
    tagSelected,
    setTagSelected
  };
};
