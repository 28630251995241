import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const CoursesStatsService = sdk.getService('CoursesStatsService');

export const useCourseStats = () => {
  const { _id } = useParams<{ _id: string }>();

  const { data, isLoading } = useQuery({
    queryKey: ['courses', 'stats', _id],
    queryFn: () => CoursesStatsService.getCourseStats(_id!),
    enabled: !!_id
  });

  return { courseStats: data, isLoading };
};
