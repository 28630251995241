import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BoxWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow.primary};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const QuestionWrapper = styled.div`
  & > :nth-child(2) {
    padding: 24px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.black2};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black2};
  gap: 24px;
  & > :first-child {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    display: flex;
    margin-left: 24px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black9};
  }

  & > :nth-child(2) {
    margin-right: auto;
  }

  & > :last-child {
    flex-shrink: 0;
    margin-right: 24px;
  }
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .fa-CheckmarkOutline {
    color: ${({ theme }) => theme.colors.green6};
  }

  .fa-CloseOutline {
    color: ${({ theme }) => theme.colors.red6};
  }
`;

export const ReportButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding-top: 24px;
`;

export const PausedCover = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  cursor: not-allowed;
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const PausedCoverText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  height: auto;
  width: 200px;
  margin: 12px;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    justify-content: space-between;
  }
`;

export const TipWrapper = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export const TipTitleWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black7};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const TipTextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black7};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ExplanationWrapper = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.purple6};
  background-color: ${({ theme }) => theme.colors.purple2};
`;
