import { create as createStore } from 'zustand';

type IStore = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const useSkipPlanModal = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen })
}));
