import { Spin } from 'antd';

import { Wrapper } from './Uploading.styles';

import { UploadingProps } from '@/types';

export const Uploading: React.FC<UploadingProps> = ({ uploading = false, progress }) => {
  if (!uploading) return null;

  const calculateProgress = () => {
    const { loaded = 0, total = 0 } = progress || {};
    const percent = Math.round((loaded * 100) / total);
    return Number.isNaN(percent) ? 0 : percent;
  };
  return (
    <Wrapper>
      <Spin size="small" spinning />
      <span>{`${calculateProgress()}%`}</span>
    </Wrapper>
  );
};
