import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const MyAcademyInternalUsersService = sdk.getService('MyAcademyInternalUsersService');

export const useInternalUserDetail = (internalUserId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['internal-users', internalUserId],
    queryFn: () => MyAcademyInternalUsersService.getInternalUser(internalUserId!),
    enabled: !!internalUserId
  });

  return {
    internalUser: data,
    isLoading,
    error
  };
};
