import { Input } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    .ant-tabs-nav {
      gap: 24px;
      align-items: start;
    }

    .ant-tabs-nav-wrap {
      flex: none !important;
    }
  }
`;

export const EditProfileWrapper = styled.div`
  display: flex;
  padding: 32px 32px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  border-radius: 8px;
  height: 80vh;
  overflow: scroll;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const InputWrapper = styled(Input)`
  width: 60vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 35vw;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
    width: 550px;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }

  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.green6};
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.green6};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.green6};
    background-color: transparent;
    border-width: 2px;
    :after {
      background-color: ${({ theme }) => theme.colors.green6};
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TextWrapper = styled.div`
  font-weight: 600;
  line-height: 24px;
  width: 145px;
`;

export const StyledIcon = styled(Icon)`
  font-size: 32px;
`;
