import { ERoles } from '@constants';

export const AITeachersTranslations = {
  AI_TEACHERS: {
    es: 'Profesores de IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak'
  },
  EMPTY_LIST: {
    es: 'No hay profesores de IA',
    en: 'No AI Teachers',
    cat: 'No hi ha professors IA',
    eus: 'Ez dago IA irakaslerik'
  },
  CREATE_TEACHER: {
    es: 'Crear profesor de IA',
    en: 'Create AI Teacher',
    cat: 'Crear professor IA',
    eus: 'IA Irakasle bat sortu'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last name',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  TEACHER_CREATED_SUCCESSFULLY: {
    es: 'Profesor de IA creado correctamente',
    en: 'AI Teacher created successfully',
    cat: 'Professor IA creat correctament',
    eus: 'IA Irakasle bat ongi sortu da'
  },
  TEACHER_UPDATED_SUCCESSFULLY: {
    es: 'Profesor de IA actualizado correctamente',
    en: 'AI Teacher updated successfully',
    cat: 'Professor IA actualitzat correctament',
    eus: 'IA Irakasle bat ongi eguneratu da'
  },
  PROMPT: {
    es: 'Prompt',
    en: 'Prompt',
    cat: 'Prompt',
    eus: 'Prompt'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktiboa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu inaktiboa'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor de IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA Irakaslea'
  },
  EDIT_TEACHER: {
    es: 'Editar profesor de IA',
    en: 'Edit AI Teacher',
    cat: 'Editar professor IA',
    eus: 'Editatu IA Irakaslea'
  },
  NEW_TEACHER: {
    es: 'Nuevo profesor de IA',
    en: 'New AI Teacher',
    cat: 'Nou professor IA',
    eus: 'IA Irakasle berria'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol'
  },
  AVATAR: {
    es: 'Avatar',
    en: 'Avatar',
    cat: 'Avatar',
    eus: 'Avatar'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_TEACHER: {
    es: '¿Estás seguro que deseas eliminar este profesor de IA?',
    en: 'Are you sure you want to remove this AI Teacher?',
    cat: 'Estàs segur que vols eliminar aquest professor IA?',
    eus: 'Ziur zaude IA Irakasle hau ezabatu nahi duzula?'
  },
  TEACHER_DELETED_SUCCESSFULLY: {
    es: 'Profesor de IA eliminado correctamente',
    en: 'AI Teacher deleted successfully',
    cat: 'Professor IA eliminat correctament',
    eus: 'IA Irakasle bat ongi ezabatu da'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  }
};
