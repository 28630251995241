import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: auto;
  text-align: center;

  & > :first-child {
    margin-bottom: 20px;
    font-size: 55px;
  }

  & > :last-child {
    & > :first-child {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
`;
