import { Tag } from 'antd';
import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  overflow: auto;
  max-width: 200px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    max-width: 400px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    max-width: 600px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;

  & > * {
    width: min-content;
  }
`;

export const TagWrapper = styled(Tag)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.black2};
  margin-inline-end: 0 !important;
`;
