import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { TeacherSearchSelector } from '@/components/Form';
import { useProfile, useMutateMyTickets } from '@/store';

interface Props {
  teacherId?: string;
  name?: string;
}

export const ReassignTeacher: React.FC<Props> = ({ teacherId, name }) => {
  const [value, setValue] = useState<string>();
  const { t } = useTranslation('CommonTicketsTranslations');
  const {
    roleCheck: { isManager }
  } = useProfile();
  const { updateTicketAssignee } = useMutateMyTickets();
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('ticketId');

  useEffect(() => {
    setValue(teacherId);
  }, [teacherId]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (ticketId) updateTicketAssignee({ ticketId, assignee: newValue });
  };

  if (!isManager) return null;

  return (
    <div>
      <hr />
      <h5>{t('ASSIGN_TEACHER')}</h5>
      <TeacherSearchSelector
        value={value}
        onChange={handleChange}
        style={{ width: '100%' }}
        filter={name}
      />
    </div>
  );
};
