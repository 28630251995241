import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black2};
  height: 157px;
`;

export const LottieWrapper = styled.div`
  width: 116px;
  height: 96px;
`;

export const TextWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;
