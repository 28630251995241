import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.purple2};
  position: relative;
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  align-self: stretch;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > :nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const SubtitleWrapper = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.purple6};
`;

export const DescriptionWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const Emoji = styled.span`
  font-size: 54px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
