import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizResultService = sdk.getService('QuizResultService');

export const useCanStudentRetakeAQuiz = (quizId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['can-student-retake-a-quiz', quizId],
    queryFn: () => QuizResultService.canUserRetakeQuiz(quizId),
    enabled: !!quizId
  });

  return {
    checkIfCanStudentRetakeAQuiz: refetch,
    canStudentRetakeAQuiz: data,
    isLoading
  };
};
