import { useTranslation } from 'react-i18next';

import { InfoWrapper, Wrapper } from './StudyPlanIntro.styles';

import STAR from '@/assets/exam_vector.svg';

const IMG_SIZE = 40;

export const StudyPlanIntro = () => {
  const { t } = useTranslation('DefaultTranslations');

  return (
    <Wrapper>
      <h2>
        {t('DO_YOU_WANT_TO_PLAN_YOUR_STUDIES')}
        <img height={IMG_SIZE} src={STAR} alt="Star" />
      </h2>
      <InfoWrapper>
        <p>{t('IT_WILL_ONLY_TAKE_A_MINUTE')}</p>
        <p>{t('YOU_CAN_LEAVE_IT_FOR_LATER')}</p>
      </InfoWrapper>
    </Wrapper>
  );
};
