import { RequestService } from '../request/request.service';
import { ITranscription } from '../transcription/transcription.contract';

import {
  IActivity,
  ICreateActivity,
  IVideoCheckPoint,
  IRemoveActivity,
  IRemoveTask,
  IValidateTask,
  IUpdateActivityAuditStatus
} from './activity.contract';

export class ActivityService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public createActivity = async ({ sectionId, activity }: ICreateActivity) => {
    return this.poster<IActivity, IActivity>(`/sections/${sectionId}/activity`, activity);
  };

  public getActivity = async (activityId: string) => {
    return this.fetcher<IActivity>(`/activities/${activityId}`);
  };

  public updateActivity = async (activity: Partial<IActivity>) => {
    return this.putter<IActivity, Partial<IActivity>>(`/activities/${activity._id}`, activity);
  };

  public updateActivityAuditStatus = async (activity: IUpdateActivityAuditStatus) => {
    const { activityId, auditStatus } = activity;
    return this.putter<IActivity, Pick<IUpdateActivityAuditStatus, 'auditStatus'>>(
      `/activities/${activityId}/audit-status`,
      {
        auditStatus
      }
    );
  };

  public removeActivity = async ({ sectionId, activityId }: IRemoveActivity) => {
    return this.deleter(`/sections/${sectionId}/activity/${activityId}`);
  };

  public removeTask = async ({ activityId, taskId }: IRemoveTask) => {
    return this.deleter(`/activities/${activityId}/task/${taskId}`);
  };

  public validateTask = async ({ activityId, status, taskId, validation }: IValidateTask) => {
    return this.poster(`/activities/${activityId}/task/${taskId}/validate`, {
      status,
      ...validation
    });
  };

  public generateVideoCheckPoints = async (
    transcription: ITranscription
  ): Promise<{ questions: IVideoCheckPoint[] }> => {
    return this.putter(`/activities/${transcription.activity}/generate-video-checkpoints`, {
      content: transcription.content
    });
  };
}
