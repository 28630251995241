/* eslint-disable @typescript-eslint/no-explicit-any */
import { isObjectLike } from 'lodash';

/**
 * It takes a value and returns the value if it's not an object, otherwise it returns the value's _id
 * property
 * @param {any} [v] - The value of the input
 * @param [isArray=false] - If the value is an array of objects, then set this to true.
 */
export const handleInputValue = (v?: any, isArray = false) => {
  if (v && isArray) return v.map((val: any) => (isObjectLike(val) ? val._id : val));
  else if (isObjectLike(v)) return v._id;
  return v;
};
