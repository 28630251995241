import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StudyPlanBadge } from '../StudyPlanBadge';

import { ContentWrapper, HeaderWrapper, Wrapper } from './RestDays.styles';
import { RestDaysConfirmationModal } from './RestDaysConfirmationModal';
import { SpecificDaysPicker } from './SpecificDaysPicker';
import { WeekDaysPicker } from './WeekDaysPicker';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

export const RestDays: React.FC<Props> = ({ isOpen, setIsOpen, onConfirm }) => {
  const { t } = useTranslation('DefaultTranslations');

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <StudyPlanBadge />
          <h2>{t('REST_DAYS_TITLE')}</h2>
          <p>{t('REST_DAYS_SUBTITLE')}</p>
        </HeaderWrapper>
        <ContentWrapper>
          <Form.Item name={['planification', 'restWeekdays']}>
            <WeekDaysPicker />
          </Form.Item>
          <Form.Item name={['planification', 'restDates']}>
            <SpecificDaysPicker />
          </Form.Item>
        </ContentWrapper>
      </Wrapper>
      <RestDaysConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} onConfirm={onConfirm} />
    </>
  );
};
