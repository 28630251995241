import { EProblemReportStatus } from '@constants';

export const ProblemReportsTranslations = {
  PROBLEM_REPORTS: {
    es: 'Informes de problemas',
    en: 'Problem reports',
    cat: 'Informes de problemes',
    eus: 'Arazoen txostenak'
  },
  PROBLEM_REPORTS_TOTAL: {
    es: '{{total}} reportes',
    en: '{{total}} reports',
    cat: '{{total}} reportis',
    eus: '{{total}} txostenak'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Titulazioa'
  },
  [EProblemReportStatus.REVIEWED]: {
    es: 'Revisado',
    en: 'Reviewed',
    cat: 'Revisat',
    eus: 'Berrikusita'
  },
  [EProblemReportStatus.IN_REVIEW]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikuspenan'
  },
  [EProblemReportStatus.NOT_REVIEWED]: {
    es: 'No revisado',
    en: 'Not reviewed',
    cat: 'No revisat',
    eus: 'Ez da berrikusi'
  },
  NO_PROBLEM_REPORTS: {
    es: '¡Todo limpio! No tienes reportes. 😀',
    en: 'All clean! You have no reports. 😀',
    cat: 'Tot net! No tens reportis. 😀',
    eus: 'Guztia garbia! Ez duzu txostenak. 😀'
  },
  DRAWER_TITLE: {
    es: 'Reporte de problema',
    en: 'Report',
    cat: 'Reporti',
    eus: 'Txostena'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  REPORTED_BY: {
    es: 'Reportado por',
    en: 'Reported by',
    cat: 'Reportat per',
    eus: 'Txostenak'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  CREATED_AT: {
    es: 'Fecha de creación',
    en: 'Creation date',
    cat: 'Data de creació',
    eus: 'Sortze data'
  },
  REPORT_SENT_SUCCESSFULLY: {
    es: 'Hemos recibido tu reporte y estamos trabajando para resolver el problema lo antes posible.',
    en: 'We have received your report and are working to resolve the issue as soon as possible.',
    cat: 'Hem rebut la teva informació i estem treballant per resoldre el problema el més aviat possible.',
    eus: 'Jaso dugu zure txostena eta aprobetxatzen ari gara arazoak azkartu dezaten.'
  },
  STATUS_SUCCESSFULLY_UPDATED: {
    es: 'El estado del reporte se ha actualizado correctamente.',
    en: 'The status of the report has been updated correctly.',
    cat: "L'estat del reporti s'ha actualitzat correctament.",
    eus: 'Txostenaren egoera behar bezala eguneratu da.'
  },
  QUESTION_TITLE: {
    es: 'Título pregunta',
    en: 'Question title',
    cat: 'Títol de la pregunta',
    eus: 'Galderaren titulua'
  },
  QUESTION_ANSWERS: {
    es: 'Respuestas',
    en: 'Answers',
    cat: 'Respostes',
    eus: 'Erantzunak'
  },
  QUESTION_DETAIL: {
    es: 'Detalle pregunta',
    en: 'Question detail',
    cat: 'Detall de la pregunta',
    eus: 'Galderaren xehetasunak'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Kurtsua'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala'
  },
  ACTIVITY_NAME: {
    es: 'Nombre actividad',
    en: 'Activity name',
    cat: "Nom de l'activitat",
    eus: 'Jardueraren izena'
  },
  ACTIVITY_DESCRIPTION: {
    es: 'Descripción actividad',
    en: 'Activity description',
    cat: "Descripció de l'activitat",
    eus: 'Jardueraren deskribapena'
  },
  COURSE_DETAIL: {
    es: 'Detalle del curso',
    en: 'Course detail',
    cat: 'Detall del curs',
    eus: 'Kurtsuaren xehetasunak'
  },
  REPORT_DELETED_SUCCESSFULLY: {
    es: 'El reporte se ha eliminado correctamente.',
    en: 'The report has been deleted correctly.',
    cat: "El reporti s'ha eliminat correctament.",
    eus: 'Txostena behar bezala ezabatu da.'
  },
  DELETE_REPORT: {
    es: 'Eliminar reporte',
    en: 'Delete report',
    cat: 'Eliminar reporti',
    eus: 'Txostena ezabatu'
  },
  DELETE_REPORT_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar estos reportes? Esta acción no se puede deshacer.',
    en: 'Are you sure you want to delete these reports? This action cannot be undone.',
    cat: '¿Estàs segur que vols eliminar aquests reportis? Aquesta acció no es pot desfer.',
    eus: '¿Ziur zaude txosten hauek ezabatu nahi dituzula? Ekintza hau ezin da desegin.'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  ACTIVITY: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduerak'
  },
  QUESTION: {
    es: 'Pregunta',
    en: 'Question',
    cat: 'Pregunta',
    eus: 'Galdera'
  },
  SELECT_A_COURSE_TO_FILTER: {
    es: 'Selecciona un curso',
    en: 'Select a course',
    cat: 'Selecciona un curs',
    eus: 'Hautatu kurtsu bat'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Tags',
    cat: 'Formacions',
    eus: 'Formakuntzak'
  },
  SELECT_A_TAG_TO_FILTER: {
    es: 'Selecciona una formación',
    en: 'Select a tag',
    cat: 'Selecciona una formació',
    eus: 'Hautatu formakuntza bat'
  },
  PROBLEM_REPORT_NOT_FOUND: {
    es: 'No se ha encontrado el recurso asociado al reporte de problema. Por favor, borra este reporte.',
    en: 'The resource associated with the problem report was not found. Please delete this report.',
    cat: "No s'ha trobat el recurs associat al reporti de problema. Si us plau, esborra aquest reporti.",
    eus: 'Ez da aurkitu arazoaren txostenarekin erlazionatutako baliabidea. Mesedez, ezabatu txostena hau.'
  }
};
