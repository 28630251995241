import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

export const useEventDetail = (event?: string) => {
  const { _id } = useParams<{ _id: string }>();
  const eventId = _id ?? event;

  const { isLoading, data, error } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => EventService.getEvent(eventId!),
    enabled: !!eventId
  });

  return {
    event: data,
    isLoading,
    error
  };
};
