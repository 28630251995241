import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 32px;
  height: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.black2};
`;
