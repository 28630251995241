import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { Contract } from './components/Contract';
import { ExclamationWrapper, StarWrapper, StyledH1, Wrapper } from './ContractSign.styles';

import EXCLAMATION_VECTOR from '@/assets/exclamation_vector.svg';
import STAR_VECTOR from '@/assets/star_vector.svg';
import { useIsMobile } from '@/hooks';

interface Props {
  step: number;
  isContractSigned: boolean;
  setIsContractSigned: (isContractSigned: boolean) => void;
}

export const ContractSign: FC<Props> = ({ step, isContractSigned, setIsContractSigned }) => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const isMobile = useIsMobile('xs');

  return (
    <StepWrapper>
      <Wrapper>
        <StyledH1>
          <StarWrapper src={STAR_VECTOR} />
          {t('CONTRACT_TITLE')}
          {!isMobile && <ExclamationWrapper src={EXCLAMATION_VECTOR} />}
        </StyledH1>
        <div>{t('CONTRACT_SUBTITLE')}</div>
        <Contract
          step={step}
          isContractSigned={isContractSigned}
          setIsContractSigned={setIsContractSigned}
        />
      </Wrapper>
    </StepWrapper>
  );
};
