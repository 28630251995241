import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 90vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 700px;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 0;
  }
`;
