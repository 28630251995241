import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const MyAcademyTeachersService = sdk.getService('MyAcademyTeachersService');

export const useTeachersDetail = (teacherId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['teachers', teacherId],
    queryFn: () => MyAcademyTeachersService.getTeacher(teacherId!),
    enabled: !!teacherId
  });

  return {
    teacher: data,
    isLoading,
    error
  };
};
