import { IUmyNotificationType } from '@constants';
import { create as createStore } from 'zustand';

type IStore = {
  type?: IUmyNotificationType;
  setType: (type?: IUmyNotificationType) => void;
  onFinish?: () => void;
  setOnFinish: (onFinish?: () => void) => void;
};

export const useUmy = createStore<IStore>(set => ({
  setType: type => set({ type }),
  setOnFinish: onFinish => set({ onFinish })
}));
