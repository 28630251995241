import { Flex } from 'antd';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { DashboardTitle } from './components/DashboardTitle';
import { LiveClassVote } from './components/LiveClassVote';
import { NpsVote } from './components/NpsVote';
import { WIDGETS } from './constants';
import { LoadingWrapper, Wrapper } from './styles';

import { LoadingCircleSpinner } from '@/components/Loaders';
import { useTagFeatures } from '@/hooks';
import { useProfile } from '@/store';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const ROW_HEIGHT = 285;

export const DashboardPage = () => {
  const { profile, roleCheck } = useProfile();
  const { isLoading, canRender } = useTagFeatures();

  if (!profile || isLoading)
    return (
      <LoadingWrapper>
        <LoadingCircleSpinner />
      </LoadingWrapper>
    );

  const { role } = profile;
  const widgets = WIDGETS[role];
  const { isAdmin, isManager, isStudent } = roleCheck;

  const isEditable = isAdmin || isManager;

  const layouts = {
    lg: widgets.map(({ position }, i) => {
      const { x, y, w, h } = position;
      return {
        i: i.toString(),
        x,
        y,
        w,
        h,
        minH: h,
        minW: w
      };
    })
  };

  return (
    <Wrapper isstudent={isStudent}>
      <DashboardTitle />
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        isDraggable={false}
        isResizable={false}
        rowHeight={ROW_HEIGHT}
        cols={{ lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}>
        {widgets.map(
          ({ Widget, availableForFeature }, i) =>
            canRender(availableForFeature) && (
              <Flex key={i}>
                <Widget editable={isEditable} />
              </Flex>
            )
        )}
      </ResponsiveGridLayout>
      {isStudent && (
        <>
          <NpsVote />
          <LiveClassVote />
        </>
      )}
    </Wrapper>
  );
};
