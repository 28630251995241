import { useCheckLiveClassVote, useMutateLiveClassVote } from '@/store';

export const useLiveClassVoteLogic = () => {
  const { voteLiveClass, isVoting } = useMutateLiveClassVote();
  const { pendingVotes, isPending } = useCheckLiveClassVote();

  return {
    isVoting,
    pendingVotes,
    isPending,
    voteLiveClass
  };
};
