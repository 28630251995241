import { EVerticalNames } from '@constants';
import { useQuery } from '@tanstack/react-query';

import { useTagsByVertical } from '../tags';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useAllCoursesByVertical = (vertical: EVerticalNames) => {
  const { tags = [] } = useTagsByVertical(vertical);
  const { isLoading, data, error } = useQuery({
    queryKey: ['courses', 'by-tags', tags],
    queryFn: () => CourseService.findAllCoursesByTags(tags.map(tag => tag._id)),
    enabled: !!tags.length
  });

  return {
    courses: data,
    isLoading,
    error
  };
};
