export enum SOCKET_EVENTS {
  CONNECTED_USERS = 'CONNECTED_USERS',
  PRIVATE_MESSAGE = 'PRIVATE_MESSAGE',
  NOTIFICATION = 'NOTIFICATION',
  LOGS = 'LOGS',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  EJECT_USER = 'EJECT_USER',
  VIDEO_TRANSCRIPTION = 'VIDEO_TRANSCRIPTION',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
  SEND_USER_REWARD = 'SEND_USER_REWARD',
  LAUNCH_UMY = 'LAUNCH_UMY',
  REQUEST_SECTION_REVIEW = 'REQUEST_SECTION_REVIEW'
}
