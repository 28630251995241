import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {
  color: string;
  backgroundColor: string;
};

export const ScoreWrapper = styled.div<Props>`
  width: fit-content;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
