import { ITeacherStudentsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 10;

const UserService = sdk.getService('UserService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filter?: ITeacherStudentsFilters;
  setPageAndLimit: (page: number, limit: number) => void;
  setSearch: (search: string) => void;
  setFilter: (filter: ITeacherStudentsFilters) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setFilter: (filter: ITeacherStudentsFilters) =>
    set(state => ({ ...state, filter, page: INITIAL_PAGE }))
}));

export const useTeacherStudentList = () => {
  const { page, limit, search, setPageAndLimit, setSearch, filter, setFilter } = store();
  const queryKey = ['users', 'students', `page-${page}`, `limit-${limit}`, search, filter];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => UserService.getTeacherStudentsStats(search, { page, limit }, filter),
    refetchOnWindowFocus: false
  });

  const resetSearchResults = () => setSearch('');

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    students: data?.docs,
    pagination,
    isPending,
    search,
    filter,
    setFilter,
    setSearch,
    resetSearchResults,
    setPageAndLimit
  };
};
