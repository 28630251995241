import styled from 'styled-components';

type WrapperProps = {
  maxWidth?: number;
};

export const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};

  .ant-input {
    min-width: 250px;
  }
`;
