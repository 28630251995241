import { IUser } from '@sdk/contracts';
import { DrawerProps, FormInstance } from 'antd';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  form: FormInstance;
  props?: DrawerProps;
  data?: IUser | null;
  setForm: (form: FormInstance) => void;
  setData: (user: IUser | null) => void;
  setIsOpen: (isOpen: boolean, props?: DrawerProps) => void;
}

export const useTeacherForm = createStore<IStore>(set => ({
  props: {},
  data: null,
  isOpen: false,
  form: {} as FormInstance,
  setForm: (form: FormInstance) => set({ form }),
  setData: (data: IUser | null) => set({ data }),
  setIsOpen: (isOpen: boolean, props?: DrawerProps) => set({ isOpen, props })
}));
