import { ICoursesByTag } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  selectedTag?: ICoursesByTag;
  setSelectedTag: (selectedTag?: ICoursesByTag) => void;
  isEditOpen: boolean;
  setIsEditOpen: (isEditOpen: boolean) => void;
}

export const useTagForm = createStore<IStore>(set => ({
  setSelectedTag: selectedTag => set({ selectedTag }),
  isEditOpen: false,
  setIsEditOpen: isEditOpen => set({ isEditOpen })
}));
