import { EBadgeTypes } from '@constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BadgeCountWrapper,
  BadgesWrapper,
  Emoji,
  ItemInfoWrapper,
  ItemWrapper,
  Wrapper
} from './StudentStats.styles';

import CHEST_CLOSED from '@/assets/chest_closed.svg';
import GAMIFICATION_RAY_ADD from '@/assets/gamification_ray_add.svg';
import LEAGUE_BRONZE from '@/assets/league-bronze.svg';
import LEAGUE_GOLD from '@/assets/league-gold.svg';
import LEAGUE_SILVER from '@/assets/league-silver.svg';
import LEAGUE_UCA from '@/assets/league-ucademy.svg';
import { Icon } from '@/components/Icon';
import {
  useStudentGamificationRewards,
  useStudentAverageQuizScore,
  useStudentSubscriptions,
  useStudentStreak
} from '@/store';
import { groupBadgesByType, isDailyAction } from '@/utils';

const ICON_SIZE = 24;

const badgeImages: Record<EBadgeTypes, string> = {
  [EBadgeTypes.GOLD]: LEAGUE_GOLD,
  [EBadgeTypes.SILVER]: LEAGUE_SILVER,
  [EBadgeTypes.BRONZE]: LEAGUE_BRONZE
};

type Props = {
  userId: string;
};

export const StudentStats: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation(['DefaultTranslations', 'StudentProfileTranslations']);
  const { subscriptions } = useStudentSubscriptions(userId);
  const { averageScore } = useStudentAverageQuizScore(userId);
  const { rewards } = useStudentGamificationRewards(userId);
  const { streak } = useStudentStreak(userId);

  const progressAverage = useMemo(
    () =>
      subscriptions && subscriptions.length > 0
        ? subscriptions.reduce((acc, { progress }) => acc + progress, 0) / subscriptions.length
        : 0,
    [subscriptions]
  );
  const points = useMemo(() => rewards?.points ?? 0, [rewards]);

  const badges = useMemo(() => {
    const groupedBadges = groupBadgesByType(rewards?.badges);
    return Object.entries(groupedBadges).filter(([_, count]) => count > 0);
  }, [rewards]);

  const dailyActionsCount = useMemo(
    () => rewards?.rewards.filter(reward => isDailyAction(reward.type)).length,
    [rewards?.rewards]
  );

  const formatPoints = (value: number) => (value % 1 === 0 ? value.toFixed(0) : value.toFixed(1));

  return (
    <Wrapper>
      <ItemWrapper>
        <Icon i="Book" size={ICON_SIZE} />
        <ItemInfoWrapper>
          <span>{`${progressAverage.toFixed(2)}%`}</span>
          <span>{t('StudentProfileTranslations:TOTAL_PROGRESS')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <Icon i="TestTool" size={ICON_SIZE} />
        <ItemInfoWrapper>
          <span>{averageScore?.toFixed(2)}</span>
          <span>{t('StudentProfileTranslations:AVERAGE_SCORE')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <img src={GAMIFICATION_RAY_ADD} alt="Gamification ray" />
        <ItemInfoWrapper>
          <span>{formatPoints(points)}</span>
          <span>{t('StudentProfileTranslations:TOTAL_POINTS')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <img src={LEAGUE_UCA} alt="Ucademy league" />
        <ItemInfoWrapper>
          <BadgesWrapper>
            {badges.length > 0 ? (
              badges.map(([type, count]) => (
                <BadgeCountWrapper key={type}>
                  <span>{count}</span>
                  <img
                    src={badgeImages[type as EBadgeTypes]}
                    alt={`${type.toLowerCase()} badges`}
                    width={13}
                  />
                </BadgeCountWrapper>
              ))
            ) : (
              <span>-</span>
            )}
          </BadgesWrapper>
          <span>{t('StudentProfileTranslations:BADGES')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <Emoji role="img">{'🔥'}</Emoji>
        <ItemInfoWrapper>
          <span>{t('StudentProfileTranslations:STREAK_DAYS', { days: streak })}</span>
          <span>{t('StudentProfileTranslations:DAILY_STREAK')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <img src={CHEST_CLOSED} alt="Closed chest" />
        <ItemInfoWrapper>
          <span>{dailyActionsCount}</span>
          <span>{t('StudentProfileTranslations:DAILY_COMPLETED_TASKS')}</span>
        </ItemInfoWrapper>
      </ItemWrapper>
    </Wrapper>
  );
};
