import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
  .ant-select,
  .ant-picker {
    width: 100%;
  }
`;

export const ClearSearch = styled(Button)`
  margin-top: 5px;
`;

export const CheckboxWrapper = styled.div`
  padding: 8px 12px;
`;
