export enum ERoles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  SALES = 'SALES',
  SUPPORT = 'SUPPORT',
  INSPECTOR = 'INSPECTOR',
  AI_TEACHER = 'AI_TEACHER'
}

export const INTERNAL_ROLES = [ERoles.MANAGER, ERoles.SALES, ERoles.SUPPORT, ERoles.INSPECTOR];

export type IInternalRoles = (typeof INTERNAL_ROLES)[number];
