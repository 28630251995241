import { IPrompt } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  prompt?: IPrompt;
  setPrompt: (prompt?: IPrompt) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
  setPrompt: prompt => set({ prompt })
}));

export const usePromptForm = () => {
  const { isOpen, setIsOpen, prompt, setPrompt } = store();

  return {
    isOpen,
    setIsOpen,
    prompt,
    setPrompt
  };
};
