import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column-reverse;
  padding-top: 20px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    justify-content: flex-end;
    flex-direction: row;
  }
`;

export const TabWrapper = styled.div`
  max-width: 800px;
  width: 100%;

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 200px;
`;

export const ItemTabWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.black3 : theme.colors.black2)};
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.black3};
  }
`;
