import { IScrappedExam } from '@sdk/contracts';
import { create as createStore } from 'zustand';

type IStore = {
  scrappedExam?: IScrappedExam;
  setScrappedExam: (scrappedExam?: IScrappedExam) => void;
};

const store = createStore<IStore>(set => ({
  setScrappedExam: scrappedExam => set({ scrappedExam })
}));
export const useScrappedExamForm = () => {
  const { scrappedExam, setScrappedExam } = store();
  return {
    scrappedExam,
    setScrappedExam
  };
};
