import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePostForm } from '../../../hooks';

import { Icon } from '@/components/Icon';

type Props = {
  editable?: boolean;
};

export const CreatePost: React.FC<Props> = ({ editable }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { setIsOpen } = usePostForm();
  if (!editable) return null;
  return (
    <Button type="link" size="large" onClick={() => setIsOpen(true)} icon={<Icon i="AddAlt" />}>
      {t('CREATE')}
    </Button>
  );
};
