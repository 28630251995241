export const texSymbols = [
  { label: 'greater than equals', mathCode: '\\ge', useJax: 'true' },
  { label: 'lesser than equals', mathCode: '\\le', useJax: 'true' },
  { label: 'square-root', mathCode: '\\sqrt{x}', useJax: 'true' },
  { label: 'nth-root', mathCode: '\\sqrt[n]{x}', useJax: 'true' },
  { label: 'inequality', mathCode: '\\neq', useJax: 'true' },
  { label: 'bracket', mathCode: '\\left(\\right)' },
  { label: 'modulus bracket', mathCode: '\\left|\\right|' },
  { label: 'f(x)', mathCode: '{f(x)}', useJax: 'true' },
  { label: 'pi', mathCode: '\\pi', useJax: 'true' },
  { label: 'natural logarithm', mathCode: '\\ln \\left(\\right)' },
  { label: 'exponential', mathCode: 'e^{ }' },
  { label: 'transpose bracket', mathCode: "\\left(\\right)^{'}" },
  { label: 'partial derivative', mathCode: '\\frac{\\partial}{\\partial x}\\left(\\right)' },
  { label: 'Integration', mathCode: '\\int_{}^{}' },
  { label: 'limit', charCode: '\\lim', mathCode: '\\lim_{x\\to\\infty}\\left(\\right)' },
  { label: 'summation', mathCode: '\\sum_{n=0}^{\\infty}' },
  { label: 'sine', mathCode: '\\sin \\left(\\right)' },
  { label: 'cosine', mathCode: '\\cos \\left(\\right)' },
  { label: 'tangent', mathCode: '\\tan \\left(\\right)' },
  { label: 'co-tangent', mathCode: '\\cot \\left(\\right)' },
  { label: 'secent', mathCode: '\\sec \\left(\\right)' },
  { label: 'co-secent', mathCode: '\\csc \\left(\\right)' }
];
