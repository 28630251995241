import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizResultService = sdk.getService('QuizResultService');

export const useLast14DaysQuizResults = (userId?: string) => {
  const {
    data: last14DaysQuizResults,
    isLoading,
    error
  } = useQuery({
    queryKey: ['quiz-results', 'last-14-days', userId],
    queryFn: () => QuizResultService.findUserCompletedQuizzesInLast14Days(userId!),
    enabled: !!userId
  });

  return {
    last14DaysQuizResults,
    isLoading,
    error
  };
};
