import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;
