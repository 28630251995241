import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ActivityService = sdk.getService('ActivityService');

export const useActivityDetail = (activityId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['activities', activityId],
    queryFn: () => ActivityService.getActivity(activityId!),
    enabled: !!activityId
  });

  return {
    activity: data,
    isLoading,
    error
  };
};
