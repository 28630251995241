import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  width: 100%;
  padding: 8px 12px;
  align-self: stretch;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
`;
