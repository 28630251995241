import styled from 'styled-components';

export const ActivityProblemReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 600;

  & > h4 {
    margin: 0px;
  }

  & > .problem-selector {
    & > span {
      display: inline-block;
      margin-bottom: 8px;
    }

    & > :last-child {
      width: 100%;
    }
  }

  & > :last-child {
    align-self: self-end;
  }

  & > .problem-description {
    & > span {
      display: inline-block;
      margin-bottom: 8px;
    }
  }
`;

export const ActivityReportSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 102 141;
  gap: 24px;

  & > i {
    margin-top: 24px;
    font-size: 52px;
  }

  & > :nth-child(2) {
    font-size: 24px;
    line-height: 32px;
  }

  & > :last-child {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }
`;
