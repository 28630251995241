import { ICreateNpsVote } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const NpsVoteService = sdk.getService('NpsVoteService');

export const useMutateNpsVote = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['nps-vote'] });

  const { mutate: createNpsVote, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateNpsVote) => NpsVoteService.createNpsVote(create),
    onSuccess
  });

  return {
    createNpsVote,
    isCreating
  };
};
