import { IPaginatedResponse, IPagination } from '@sdk/contracts';
import { createQueryString } from '@utils';

import { RequestService } from '../request';

import { IExam, IExamsListFilters } from './exam.contract';

export class ExamService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async createExam(data: Partial<IExam>) {
    return this.poster<IExam, Partial<IExam>>('/exams', data);
  }

  async updateExam(data: Partial<IExam>) {
    return this.putter<IExam, Partial<IExam>>(`/exams/${data._id}`, data);
  }

  async findAllExams(pagination: IPagination, search: string, filters?: IExamsListFilters) {
    const query = createQueryString({
      search,
      page: pagination.page,
      limit: pagination.limit,
      filters
    });
    return this.fetcher<IPaginatedResponse<IExam>>(`/exams?${query}`);
  }

  async getExam(_id: string) {
    return this.fetcher<IExam>(`/exams/${_id}`);
  }

  async deleteExam(_id: string) {
    return this.deleter(`/exams/${_id}`);
  }

  async findStudentExams() {
    return this.fetcher<IExam[]>(`/exams/student`);
  }
}
