import { ETicketStatus, ETicketFilter } from '@constants';
import { ITicketsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const TicketService = sdk.getService('TicketService');

export type IInitialFilters = keyof typeof STATUS_INITIAL_FILTERS;

const STATUS_INITIAL_FILTERS = {
  [ETicketFilter.ALL]: { status: Object.values(ETicketStatus) },
  [ETicketFilter.OPEN]: {
    status: [ETicketStatus.NEW, ETicketStatus.IN_PROGRESS]
  }
};

interface ITicketsListOptions {
  page?: number;
  limit?: number;
}

interface IStore {
  page?: number;
  limit?: number;
  filter?: ITicketsFilters;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: ITicketsFilters) => void;
}

const store = createStore<IStore>(set => ({
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: ITicketsFilters) => set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

export const useMyTicketsList = (propFilter: IInitialFilters, options?: ITicketsListOptions) => {
  const [search, setSearch] = useState('');
  const {
    page = options?.page || INITIAL_PAGE,
    limit = options?.limit || INITIAL_LIMIT,
    filter = STATUS_INITIAL_FILTERS[propFilter] as ITicketsFilters,
    setPageAndLimit,
    setFilter
  } = store();

  const queryKey = ['tickets', `page-${page}`, `limit-${limit}`, search, JSON.stringify(filter)];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => TicketService.getTickets(search, { page, limit }, undefined, filter)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    tickets: data?.docs,
    isLoading,
    error,
    setPageAndLimit,
    pagination,
    setSearch,
    filter,
    setFilter
  };
};
