import { useMutation } from '@tanstack/react-query';

import { sdk } from '@/services';
import { formatDataAndDownloadCsv } from '@/utils';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useSubscriptionsStats = () => {
  const { isPending, mutate: downloadActivityRating } = useMutation({
    mutationFn: () => SubscriptionService.downloadActyivityRatingData(),
    onSuccess: data => formatDataAndDownloadCsv(data, 'activity_rating.csv')
  });

  return {
    isDownloadActivityRatingLoading: isPending,
    downloadActivityRating
  };
};
