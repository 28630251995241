import { IExamGroup } from '@sdk/contracts';
import { create as createStore } from 'zustand';

type IStore = {
  examGroup?: IExamGroup;
  setExamGroup: (examGroup?: IExamGroup) => void;
};

const store = createStore<IStore>(set => ({
  setExamGroup: examGroup => set({ examGroup })
}));

export const useExamGroupInfo = () => {
  const { examGroup, setExamGroup } = store();

  return {
    examGroup,
    setExamGroup
  };
};
