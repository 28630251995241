import { SOCKET_EVENTS } from '@constants';
import { IRequestSectionReview } from '@sdk/contracts';
import { useEffect } from 'react';

import { useSectionReviewModal, useSocket } from '@/hooks';

export const SectionReviewHandler = () => {
  const { socket } = useSocket();
  const { setSectionIdToReview, setSectionCourseId } = useSectionReviewModal();

  useEffect(() => {
    if (!socket || socket.hasListeners(SOCKET_EVENTS.REQUEST_SECTION_REVIEW)) return;

    socket.on(
      SOCKET_EVENTS.REQUEST_SECTION_REVIEW,
      ({ sectionId, courseId }: IRequestSectionReview) => {
        setSectionIdToReview(sectionId);
        setSectionCourseId(courseId);
      }
    );

    return () => {
      socket?.off(SOCKET_EVENTS.REQUEST_SECTION_REVIEW);
    };
  }, [socket]);

  return <></>;
};
