import { AnalyticsTrackEvents } from '@constants';
import { ICreatePlanification, IUpdatePlanification } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMixpanel } from '@/hooks';
import { sdk } from '@/services';

const PlanificationService = sdk.getService('PlanificationService');

export const useMutatePlanification = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('DefaultTranslations');
  const { fireEvent } = useMixpanel();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['planification'] });

  const { mutate: createPlanification, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreatePlanification) => PlanificationService.createPlanification(create),
    onSuccess: data => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.PLAN_CREATED, {
        duration: data.totalStudyTime / 3600,
        byHours: data.studyHours !== undefined,
        endDate: data.endDate,
        spentTime: data.dailyStudyTime / 60
      });
    }
  });

  const { mutate: updatePlanification, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdatePlanification) => PlanificationService.updatePlanification(update),
    onSuccess
  });

  const { mutate: removePlanification, isPending: isRemoving } = useMutation({
    mutationFn: () => PlanificationService.removePlanification(),
    onSuccess: (userId: string) =>
      queryClient.removeQueries({ queryKey: ['planification', userId] })
  });

  const { mutate: sendFeedback, isPending: isSendingFeedback } = useMutation({
    mutationFn: (feedback: string) => PlanificationService.sendFeedback(feedback),
    onSuccess: () => message.success(t('PLANIFICATION_HOW_IT_WORKS_SEND_FEEDBACK_SUCCESS'))
  });

  return {
    createPlanification,
    updatePlanification,
    removePlanification,
    sendFeedback,
    isWorking: isCreating || isUpdating || isRemoving || isSendingFeedback
  };
};
