import styled from 'styled-components';

export const ResourceContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

export const ResourceTitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const ResourceContentWrapper = styled.div`
  font-size: 14px;
`;
