export const AdminPromptsTranslations = {
  PROMPTS: {
    es: 'Prompts',
    en: 'Prompts',
    cat: 'Prompts',
    eus: 'Prompts'
  },
  CREATE_PROMPT: {
    es: 'Crear prompt',
    en: 'Create prompt',
    cat: 'Crear prompt',
    eus: 'Prompt bat sortu'
  },
  EDIT_PROMPT: {
    es: 'Editar prompt',
    en: 'Edit prompt',
    cat: 'Editar prompt',
    eus: 'Prompt bat editatu'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  EMPTY_LIST: {
    es: 'No has creado ningun prompt',
    en: 'You have not created any prompts',
    cat: 'No has creat cap prompt',
    eus: 'Ez duzu promptik sortu'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde'
  },
  AI_TEACHERS: {
    es: 'Profesores de IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak'
  },
  PROMPT_CREATED_SUCCESSFULLY: {
    es: 'Prompt creado exitosamente',
    en: 'Prompt created successfully',
    cat: 'Prompt creat correctament',
    eus: 'Prompt bat ongi sortu da'
  },
  PROMPT_UPDATED_SUCCESSFULLY: {
    es: 'Prompt actualizado exitosamente',
    en: 'Prompt updated successfully',
    cat: 'Prompt actualitzat correctament',
    eus: 'Prompt bat ongi eguneratu da'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  PROMPT_REMOVED_SUCCESSFULLY: {
    es: 'Prompt eliminado exitosamente',
    en: 'Prompt removed successfully',
    cat: 'Prompt eliminat correctament',
    eus: 'Prompt bat ongi ezabatu da'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PROMPT: {
    es: '¿Estás seguro que deseas eliminar este prompt?',
    en: 'Are you sure you want to remove this prompt?',
    cat: 'Estàs segur que vols eliminar aquest prompt?',
    eus: 'Ziur zaude prompt hau ezabatu nahi duzula?'
  }
};
