import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PostService = sdk.getService('PostService');

export const usePostDetail = (postId?: string) => {
  const queryKey = ['posts', postId];

  const { isPending, data: post } = useQuery({
    queryKey,
    queryFn: () => PostService.getPost(postId!),
    enabled: !!postId
  });

  return { isPending, post };
};
