import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useActivityQuizzesForSelector = (courseId?: string, sectionId?: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['quizzes', 'activity', courseId, sectionId],
    queryFn: () => QuizService.getActivityQuizzesForSelector(courseId!, sectionId!),
    enabled: !!courseId && !!sectionId
  });

  return {
    quizzes: data,
    isLoading
  };
};
