import { EOnboardingDiscoverySource } from '@constants';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalWrapper, ButtonWrapper } from './ReferredByModal.styles';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onInputChange: (value: string) => void;
  okDisabled: boolean;
  onOk?: (value: EOnboardingDiscoverySource) => void;
};

export const ReferredByModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onOk,
  onInputChange,
  okDisabled
}) => {
  const { t } = useTranslation('StudentOnboardingTranslations');
  return (
    <Modal
      open={isOpen}
      centered
      closable
      title={t('CAN_YOU_TELL_US_HIS_NAME')}
      footer={null}
      onCancel={() => setIsOpen(false)}>
      <ModalWrapper>
        <div>{t('ONLY_TO_KNOW_IF_WE_KNOW_HIM')}</div>
        <Form.Item
          layout="vertical"
          name={['onBoarding', 'referredBy']}
          label={t('NAME_AND_SURNAME')}>
          <Input onChange={({ target: { value: v } }) => onInputChange(v)} />
        </Form.Item>
        <ButtonWrapper>
          <Button
            type="primary"
            disabled={okDisabled}
            onClick={() => {
              setIsOpen(false);
              onOk?.(EOnboardingDiscoverySource.FRIEND);
            }}>
            {t('CONTINUE')}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};
