import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px !important;
  align-items: center;
`;

export const AllDayEventWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
  max-width: 100%;
`;
