import { IUser } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  teacher?: IUser;
  setTeacher: (teacher?: IUser) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
  setTeacher: teacher => set({ teacher })
}));

export const useAITeacherForm = () => {
  const { teacher, setTeacher, isOpen, setIsOpen } = store();

  return {
    teacher,
    setTeacher,
    isOpen,
    setIsOpen
  };
};
