import { RequestService } from '../request/request.service';

import { ICreateSectionReview, ISectionReview } from './section-review.contract';

export class SectionReviewService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public createSectionReview = async ({ sectionId, courseId, answers }: ICreateSectionReview) => {
    return this.poster(`/section-reviews`, { sectionId, courseId, answers });
  };

  public getSectionReviews = async (sectionId: string) => {
    return this.fetcher<ISectionReview[]>(`/section-reviews/${sectionId}`);
  };
}
