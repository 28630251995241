import styled from 'styled-components';

export const StatsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StatElement = styled.div`
  display: flex;
  align-items: baseline;
`;

export const IconWrapper = styled.div`
  margin: 0 10px;
`;

export const StatTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: 400;
  margin: 0;
`;

export const StatValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
`;
