import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-radius: 19px;
  background-color: ${({ theme }) => theme.colors.green1};
  padding: 2px 8px 2px 8px;
`;
