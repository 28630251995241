import { IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const MyAcademyInternalUsersService = sdk.getService('MyAcademyInternalUsersService');
const MyAcademyService = sdk.getService('MyAcademyService');
const UserService = sdk.getService('UserService');

export const useMutateInternalUsers = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['internal-users'] });
  const { t } = useTranslation();

  const { mutate: createInternalUser, isPending: isCreating } = useMutation({
    mutationFn: (newInternalUser: Partial<IUser>) =>
      MyAcademyInternalUsersService.createInternalUser(newInternalUser),
    onSuccess: () => {
      onSuccess();
      message.success(t('NEW_USER_ADDED'));
    }
  });

  const { mutate: updateInternalUser, isPending: isUpdating } = useMutation({
    mutationFn: (updatedUser: Partial<IUser>) =>
      MyAcademyInternalUsersService.updateInternalUser(updatedUser),
    onSuccess: () => {
      onSuccess();
      message.success(t('USER_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeInternalUser, isPending: isRemoving } = useMutation({
    mutationFn: (userId: string) => MyAcademyService.removeUser(userId),
    onSuccess: () => {
      onSuccess();
      message.warning(t('USER_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: toggleActivateUser, isPending: isToggling } = useMutation({
    mutationFn: (userId: string) => UserService.toggleActivateUser(userId),
    onSuccess
  });

  return {
    createInternalUser,
    updateInternalUser,
    removeInternalUser,
    toggleActivateUser,
    isWorking: isCreating || isUpdating || isRemoving || isToggling
  };
};
