import { IAnswer } from '@sdk/contracts';
import { Form, Input, Button, Popconfirm, Radio } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnswerWrapper, Title, Wrapper } from './Answers.styles';

import { Icon } from '@/components/Icon';

type Props = {
  currentName: number;
};

export const Answers: React.FC<Props> = ({ currentName }) => {
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const { t } = useTranslation(['DefaultTranslations', 'CommonQuizFormTranslations']);

  const checkAnswers = async (_: unknown, val: IAnswer[]) => {
    if (val.length > 0 && val.every(answer => answer.text.length > 0)) {
      return Promise.resolve();
    }
    return Promise.reject(t('DefaultTranslations:REQUIRED_FIELD'));
  };

  return (
    <Wrapper>
      <Title>{t('CommonQuizFormTranslations:ANSWERS')}</Title>
      <Form.Item name={[currentName, 'correctAnswer']}>
        <Radio.Group>
          <Form.List name={[currentName, 'answers']} rules={[{ validator: checkAnswers }]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => {
                  const isDisabled = name === 0 || name === correctAnswer;
                  const isChecked = name === correctAnswer;
                  return (
                    <Fragment key={key}>
                      <AnswerWrapper>
                        <Radio
                          value={name}
                          name="checked"
                          checked={isChecked}
                          onChange={() => {
                            setCorrectAnswer(name);
                          }}
                        />
                        <Form.Item
                          name={[name, 'text']}
                          rules={[
                            { required: true, message: t('DefaultTranslations:REQUIRED_FIELD') }
                          ]}>
                          <Input
                            placeholder={t('CommonQuizFormTranslations:WRITE_HERE_THE_ANSWER')}
                          />
                        </Form.Item>
                        <Popconfirm
                          placement="left"
                          disabled={isDisabled}
                          onConfirm={() => remove(name)}
                          title={t('CommonQuizFormTranslations:ARE_YOU_SURE_TO_DELETE_ANSWER')}>
                          <Button type="link" disabled={isDisabled} icon={<Icon i="TrashCan" />} />
                        </Popconfirm>
                      </AnswerWrapper>
                    </Fragment>
                  );
                })}
                <Button type="dashed" onClick={() => add()} icon={<Icon i="AddAlt" />}>
                  {t('CommonQuizFormTranslations:ADD_NEW_EMPTY_ANSWER')}
                </Button>
              </>
            )}
          </Form.List>
        </Radio.Group>
      </Form.Item>
    </Wrapper>
  );
};
