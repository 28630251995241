import { ETaskStatus } from '@constants';
import { DefaultTheme } from 'styled-components';

export const getTaskColorsByStatus = (status: ETaskStatus, colors: DefaultTheme['colors']) => {
  switch (status) {
    case ETaskStatus.APPROVED:
      return {
        backgroundColor: colors.green1,
        color: colors.green6,
        borderColor: colors.green6
      };
    case ETaskStatus.IN_REVIEW:
      return {
        backgroundColor: 'rgba(254, 184, 22, 0.10)',
        color: colors.orange6,
        borderColor: colors.orange6
      };
    case ETaskStatus.PENDING:
      return {
        backgroundColor: colors.black2,
        color: colors.black9,
        borderColor: colors.black3
      };
    case ETaskStatus.REJECTED:
      return {
        backgroundColor: 'rgba(227, 96, 88, 0.20)',
        color: colors.red6,
        borderColor: colors.red6
      };
  }
};
