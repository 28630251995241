import styled from 'styled-components';

export const SubtitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const LinkWrapper = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
`;
