import { Flex } from 'antd';
import styled from 'styled-components';

export const CardWrapper = styled.div<{ isrest: string }>`
  width: 100%;
  height: 160px;
  display: flex !important;
  flex-direction: column;
  gap: 8px;
  cursor: ${({ isrest }) => (isrest === 'true' ? 'default' : 'pointer')};
  padding: 16px;
  border-radius: 4px;
  border: ${({ theme, isrest }) =>
    isrest === 'true' ? `1px solid ${theme.colors.black4}` : 'none'};
  background-color: ${({ theme, isrest }) =>
    isrest === 'true' ? theme.colors.black2 : theme.colors.purple6};
`;

export const CardCompletedWrapper = styled.div`
  height: 160px;
  display: flex !important;
  flex-direction: column;
  position: relative;
  gap: 8px;
  cursor: pointer;
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.green6};
`;

export const DateWrapper = styled.span<{ isrest: string }>`
  color: ${({ theme, isrest }) => (isrest === 'true' ? theme.colors.black5 : theme.colors.white)};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const RestWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black5};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const SectionNameWrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  width: 100%;
`;

export const ActivitiesNumberWrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ContentWrapper = styled(Flex)`
  height: 100%;
`;

export const AllDayCompletedWrapper = styled.span`
  max-width: 50%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) and (max-width: ${({ theme }) =>
      theme.mediaQueries.lg}) {
    max-width: 100%;
  }
`;

export const PlanificationDayCompletedWrapper = styled.img`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -2.667px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) and (max-width: ${({ theme }) =>
      theme.mediaQueries.lg}) {
    display: none !important;
  }
`;
