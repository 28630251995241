import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ExamResultService = sdk.getService('ExamResultService');

export const useExamResultByStudent = (examId?: string, studentId?: string) => {
  const queryKey = ['exam-results', examId, studentId];

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => ExamResultService.findExamResultByStudent(examId!, studentId!),
    enabled: !!studentId && !!examId
  });

  return { examResult: data, isLoading };
};
