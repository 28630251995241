import { ITutoringGroupWithChatInfo } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

type IStore = {
  searchResults?: ITutoringGroupWithChatInfo['members'];
  setSearchResults: (results?: ITutoringGroupWithChatInfo['members']) => void;
};

const store = createStore<IStore>(set => ({
  setSearchResults: (searchResults?: ITutoringGroupWithChatInfo['members']) =>
    set({ searchResults })
}));

const TutoringGroupService = sdk.getService('TutoringGroupService');

export const useTutoringGroupDetail = (tutoringGroupIdSelected?: string) => {
  const { tutoringGroupId } = useParams<{ tutoringGroupId: string }>();

  const groupId = tutoringGroupId ?? tutoringGroupIdSelected;

  const { searchResults, setSearchResults } = store();

  const { isLoading, data, error } = useQuery({
    queryKey: ['tutoring-groups', groupId],
    queryFn: () => (groupId ? TutoringGroupService.getTutoringGroup(groupId) : undefined),
    enabled: !!groupId
  });

  return {
    tutoringGroup: data,
    setSearchResults,
    searchResults,
    isLoading,
    error
  };
};
