import { ERoles } from '@constants';
import { IMessage } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Avatar, Tooltip } from 'antd';
import { FC } from 'react';

import { DocWrapper, InnerWrapper, Message } from './TicketMessage.styles';

import { Icon } from '@/components/Icon';

type Props = {
  message: IMessage;
};

const AVATAR_SIZE = 38;

export const TicketMessage: FC<Props> = ({
  message: {
    content = '',
    attached,
    formula = '',
    createdAt,
    sender: { name, lastName, avatar, role },
    isAutomatic,
    usefulAnswer
  }
}) => {
  const isStudent = role === ERoles.STUDENT;

  const ProfileAvatar = () => (
    <Tooltip title={`${name} ${lastName}`}>
      <Avatar size={AVATAR_SIZE} src={avatar?.url}>
        {!avatar?.url && name[0]}
      </Avatar>
    </Tooltip>
  );

  if (isAutomatic && usefulAnswer?.length === 0) return null;

  return (
    <InnerWrapper>
      {isStudent && <ProfileAvatar />}
      <Message isStudent={isStudent}>
        <div>
          <span>{dateProvider.format(createdAt, 'dd/MM/yyyy')}</span>
          <span>{dateProvider.format(createdAt, 'HH:mm')}</span>
        </div>
        {attached ? (
          <DocWrapper href={attached.url} target="_blank">
            <Icon i="Document" />
            {attached.name}
          </DocWrapper>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: formula ? formula : content }} />
        )}
      </Message>
      {!isStudent && <ProfileAvatar />}
    </InnerWrapper>
  );
};
