import styled from 'styled-components';

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const SubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;
