import { Button, Divider, Input, Select } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddItemWrapper, OptionWrapper } from './TopicSelector.styles';

import { Icon } from '@/components/Icon';
import { useMutatePostTopic, usePostTopicList } from '@/store';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const TopicSelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { postTopics, isPending } = usePostTopicList();
  const [newTopic, setNewTopic] = useState('');
  const { createPostTopic, isPending: isWorking, removePostTopic } = useMutatePostTopic();

  const options = useMemo(
    () => postTopics?.map(topic => ({ label: topic.name, value: topic._id })),
    [postTopics]
  );

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewTopic(e.target.value);

  const handleAddTopic = () => {
    if (!newTopic) return;
    createPostTopic(
      { name: newTopic },
      {
        onSuccess: () => setNewTopic('')
      }
    );
  };

  const handleRemoveTopic = (topicId?: string) => {
    if (!topicId) return;
    removePostTopic(topicId);
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      placeholder={t('SELECT_TOPIC')}
      loading={isPending}
      optionRender={option => (
        <OptionWrapper>
          <div>{option.label}</div>
          <Button
            loading={isWorking}
            danger
            size="small"
            type="link"
            icon={<Icon i="TrashCan" />}
            onClick={e => {
              e.stopPropagation();
              handleRemoveTopic(option.value as string);
            }}
          />
        </OptionWrapper>
      )}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <AddItemWrapper>
            <Input
              disabled={isWorking}
              placeholder={t('NEW_TOPIC')}
              onChange={onNameChange}
              value={newTopic}
              onKeyDown={e => e.stopPropagation()}
            />
            <Button
              onClick={handleAddTopic}
              loading={isWorking}
              disabled={!newTopic}
              type="primary"
              icon={<Icon i="AddAlt" />}>
              {t('ADD')}
            </Button>
          </AddItemWrapper>
        </>
      )}
    />
  );
};
