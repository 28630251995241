import { Select } from 'antd';
import { useMemo } from 'react';

import { useActivityQuizzesForSelector } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: string;
  courseId?: string;
  sectionId?: string;
  placeholder?: string;
  allowClear?: boolean;
  onChange?: (value: string) => void;
};

export const QuizActivitySelector: React.FC<Props> = ({
  courseId,
  sectionId,
  allowClear,
  placeholder,
  value,
  onChange
}) => {
  const { quizzes = [] } = useActivityQuizzesForSelector(courseId, sectionId);

  const options = useMemo(
    () =>
      quizzes.map(quiz => ({
        label: quiz.title,
        value: quiz._id
      })),
    [quizzes]
  );

  return (
    <Select
      options={options}
      onChange={onChange}
      allowClear={allowClear}
      placeholder={placeholder}
      value={handleInputValue(value)}
    />
  );
};
