import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Wrapper } from './SpentTimer.styles';

import { Icon } from '@/components/Icon';

type Props = {
  spentTime: number;
};

const msToFormatedHours = (ms: number) => {
  if (ms === 0) return null;

  const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);
  return { hours, minutes, seconds };
};

export const SpentTimer: React.FC<Props> = ({ spentTime }) => {
  const { colors } = useTheme();

  const time = useMemo(() => msToFormatedHours(spentTime), [spentTime]);
  if (!time) return null;

  const { hours, minutes, seconds } = time;

  return (
    <Wrapper color={colors.black7} backgroundColor={colors.black2}>
      <Icon i={'Timer'} size="24" />
      {
        <div>{`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`}</div>
      }
    </Wrapper>
  );
};
