import { EStudentPaymentStatus } from '@constants';
import { IUserStatsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 10;
const INITIAL_FILTERS: IUserStatsFilters = {
  paymentStatus: Object.values(EStudentPaymentStatus)
};

const SubscriptionService = sdk.getService('SubscriptionService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filter?: IUserStatsFilters;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: IUserStatsFilters) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  filter: INITIAL_FILTERS,
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: IUserStatsFilters) => set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

export const useCourseStudentsStats = () => {
  const { _id: courseId } = useParams<{ _id: string }>();

  const { page, limit, search, filter, setSearch, setPageAndLimit, setFilter } = store();

  const queryKey = [
    'students',
    'stats',
    'course',
    courseId,
    `page-${page}`,
    `limit-${limit}`,
    search,
    filter
  ];
  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: () =>
      SubscriptionService.getCourseStudentsStats(courseId, search, { page, limit }, filter)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    students: data?.docs,
    isLoading: isLoading || isFetching,
    pagination,
    filter,
    setSearch,
    setPageAndLimit,
    setFilter
  };
};
