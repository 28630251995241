import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TagService = sdk.getService('TagService');

export const useMyTagsList = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['tags'],
    queryFn: () => TagService.getTags()
  });

  return {
    tags: data,
    isLoading
  };
};
