import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

export const TitleWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black5};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black5};
`;
