import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

export const useWidgetNextEvents = () => {
  const queryKey = ['events', 'widget', 'next-events'];
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => EventService.getEventsWidgetNextEvents()
  });

  return {
    events: data,
    isLoading
  };
};
