import { create } from 'zustand';

type IStore = {
  currentSectionId?: string;
  setCurrentSectionId: (section: string) => void;
};

export const useCurrentSection = create<IStore>(set => ({
  setCurrentSectionId: section => set({ currentSectionId: section })
}));
