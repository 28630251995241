import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: end;
  }
`;
