import { ITicket } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Avatar } from 'antd';

import { TicketMessage } from './TicketMessage';
import {
  Subject,
  Wrapper,
  InnerWrapper,
  TitleWrapper,
  DateSpanWrapper,
  HourSpanWrapper
} from './TicketMessages.styles';

type Props = {
  ticket: ITicket;
};

const AVATAR_SIZE = 38;

export const TicketMessages: React.FC<Props> = ({ ticket }) => {
  const { title, description, messages = [], issuer, createdAt } = ticket;

  return (
    <Wrapper>
      <InnerWrapper>
        <Avatar size={AVATAR_SIZE} src={issuer.avatar?.url}>
          {!issuer.avatar?.url && issuer.name[0]}
        </Avatar>
        <Subject>
          <TitleWrapper>
            {title}
            <div>
              <DateSpanWrapper>{dateProvider.format(createdAt, 'dd/MM/yyyy')}</DateSpanWrapper>
              <HourSpanWrapper>{dateProvider.format(createdAt, 'HH:mm')}</HourSpanWrapper>
            </div>
          </TitleWrapper>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Subject>
      </InnerWrapper>
      {messages.map(message => (
        <TicketMessage key={message._id} message={message} />
      ))}
    </Wrapper>
  );
};
