import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  & > :first-child {
    display: flex;
    gap: 10px;
  }

  & > :last-child {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    font-size: 12px;
    gap: 20px;
    i {
      font-size: 20px;
    }
    & > * {
      display: flex;
      gap: 10px;
      flex-direction: row;
      align-items: center;
    }
  }
`;
