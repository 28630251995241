import { services } from '../services';

type ServiceName = keyof typeof services;
type ServiceInstance<T extends ServiceName> = InstanceType<(typeof services)[T]>;

export class UcademySdk {
  private apiUrl: string;
  private initializedServices: { [key: string]: any } = {};

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
    this.initializeServices();
  }

  private initializeServices() {
    for (const [serviceName, ServiceClass] of Object.entries(services)) {
      this.initializedServices[serviceName] = new (ServiceClass as any)(this.apiUrl);
    }
  }

  public getService<T extends ServiceName>(serviceName: T): ServiceInstance<T> {
    return this.initializedServices[serviceName];
  }
}
