import { useState, useEffect, useRef, useCallback } from 'react';

const breakpoints = {
  xs: 576,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1600
};

type Props = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useIsMobile = (bp: Props = 'sm') => {
  const breakpoint = breakpoints[bp];
  const loaded = useRef(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = useCallback(() => setWidth(window.innerWidth), []);

  useEffect(() => {
    if (!loaded.current) {
      handleWindowSizeChange();
      loaded.current = true;
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= breakpoint;
};
