import { IEventsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

type IStore = {
  filters: IEventsFilters;
  setFilters: (filter?: IEventsFilters) => void;
};

const store = createStore<IStore>(set => ({
  filters: {
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  },
  setFilters: filters => set({ filters })
}));

export const useEventsList = () => {
  const { filters, setFilters } = store();

  const { isLoading, data, error } = useQuery({
    queryKey: ['events', filters],
    queryFn: () => EventService.getAllEvents(filters)
  });

  const resetFilters = () =>
    setFilters({ month: new Date().getMonth(), year: new Date().getFullYear() });

  return {
    events: data,
    isLoading,
    error,
    filters,
    setFilters,
    resetFilters
  };
};
