import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const ExamGroupService = sdk.getService('ExamGroupService');

export const useExamGroupDetail = () => {
  const { _id } = useParams<{ _id: string }>();

  const { isLoading, data, error } = useQuery({
    queryKey: ['exam-groups', _id],
    queryFn: () => ExamGroupService.getExamGroup(_id!),
    enabled: !!_id
  });

  return {
    examGroupDetail: data,
    groupId: _id,
    isLoading,
    error
  };
};
