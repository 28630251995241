import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 0 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    gap: 8px;
  }
`;
