import styled from 'styled-components';

import { Icon } from '@/components/Icon';

interface Props {
  color?: string;
  completion: number;
}

export const ActivitiesTrackingWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 4px;
  height: fit-content;

  background-color: ${({ theme, completion }) =>
    completion === 1 ? theme.colors.green1 : theme.colors.black2};
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const ActivitiesTrackingNumbers = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  color: ${({ theme, completion }) =>
    completion === 1 ? theme.colors.green6 : theme.colors.black5};
`;

export const CompletionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.green6};
`;
