import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizResultService = sdk.getService('QuizResultService');

export const useLastQuizResultByQuizId = (quizId?: string) => {
  const queryKey = ['quizResult', quizId];
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => QuizResultService.getLastQuizResultByQuizId(quizId!),
    enabled: !!quizId
  });

  return {
    isLoading,
    quizResult: data
  };
};
