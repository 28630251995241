import { IPlanification, ISortedPlanificationActivity } from '@sdk/contracts';
import { dateProvider } from '@utils';

export const getSortedPlanificationActivitiesByDate = (data?: IPlanification) => {
  if (!data) return {};

  const activitiesByDate = data.items
    .map(item => {
      const subscription = data.subscriptions.find(s => s._id === item.subscription);
      const section = subscription?.course.sections.find(s => s._id === item.section);
      const activity = section?.activities.find(a => a._id === item.activity);
      return {
        ...item,
        activity,
        section,
        subscription
      };
    })
    .reduce((acc, item) => {
      const date = dateProvider.format(item.dueDate, 'yyyy-MM-dd');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {} as Record<string, ISortedPlanificationActivity[]>);

  const sortedPlanificationActivitiesByDate = Object.fromEntries(
    Object.entries(activitiesByDate).sort(([dateA], [dateB]) => {
      return dateProvider.diff(dateB, dateA, 'days');
    })
  );

  return sortedPlanificationActivitiesByDate;
};
