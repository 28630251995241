import { IMessage } from '@sdk/contracts';

export const getFirstMessageCreationDate = (issuerId: string, messages: IMessage[]) => {
  for (const message of messages) {
    const { isAutomatic, sender, createdAt } = message;
    if (issuerId === sender._id) continue;
    if (isAutomatic) continue;
    return createdAt;
  }
  return null;
};
