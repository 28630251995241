import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  .IconViewFilled {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.black4 : theme.colors.purple6)};
  }
  .IconViewOffFilled {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.black4 : theme.colors.red6)};
  }
`;
