import { ICreateQuiz, IUpdateQuiz } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useMutateMyQuizzes = () => {
  const { t } = useTranslation('ManagerQuizzesTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['quizzes']
    });

  const { mutate: createQuiz, isPending: isCreating } = useMutation({
    mutationFn: async (newQuiz: ICreateQuiz) => QuizService.createQuiz(newQuiz),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUIZ_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateQuiz, isPending: isUpdating } = useMutation({
    mutationFn: async (quiz: IUpdateQuiz) => QuizService.updateQuiz(quiz),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUIZ_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeQuiz, isPending: isRemoving } = useMutation({
    mutationFn: (quizId: string) => QuizService.removeQuiz(quizId),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUIZ_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: toggleQuizStatus, isPending: isToogling } = useMutation({
    mutationFn: (quizId: string) => QuizService.toggleQuizStatus(quizId),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUIZ_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: duplicateQuiz, isPending: isDuplicating } = useMutation({
    mutationFn: (quizId: string) => QuizService.duplicateQuiz(quizId),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUIZ_DUPLICATED_SUCCESSFULLY'));
    }
  });

  return {
    isWorking: isCreating || isUpdating || isRemoving || isToogling || isDuplicating,
    createQuiz,
    updateQuiz,
    removeQuiz,
    toggleQuizStatus,
    duplicateQuiz
  };
};
