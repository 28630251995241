import { ETicketVisibilityOptions } from '@constants';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: ETicketVisibilityOptions;
  onChange: (value: string) => void;
};

const VISIBILITY_VALUES = [
  { label: 'PUBLIC_VISIBILITY', value: ETicketVisibilityOptions.PUBLIC },
  { label: 'PRIVATE_VISIBILITY', value: ETicketVisibilityOptions.PRIVATE }
];

export const VisibilitySelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value && t(`${value}_VISIBILITY`)}
      onChange={onChange}
      placeholder={t('SELECT_VISIBILITY')}
      options={VISIBILITY_VALUES.map(({ label, value: val }) => ({
        label: t(label),
        value: val
      }))}
    />
  );
};
