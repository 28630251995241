import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :last-child {
    gap: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;
