import styled from 'styled-components';

export const Wrapper = styled.div<{
  backgroundColor: string;
  borderColor: string;
  hasIcon: boolean;
}>`
  width: max-content;
  display: flex;
  padding: 2px 8px;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;

  & :last-child {
    color: ${({ borderColor, hasIcon }) => (hasIcon ? borderColor : '')};
  }
`;

export const TextWrapper = styled.div<{
  color: string;
}>`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ color }) => color};
`;
