import { IAssociateTagToCourse, ICreateTag, IUpdateTag } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');
const TagService = sdk.getService('TagService');

export const useMutateTags = () => {
  const queryClient = useQueryClient();
  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['tags', 'tags-with-courses']
    });
  const { t } = useTranslation('CourseTagsTranslations');

  const { mutate: createTag, isPending: isCreating } = useMutation({
    mutationFn: async (create: ICreateTag) => TagService.createTag(create),
    onSuccess: () => {
      onSuccess();
      message.success(t('SAVED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateTag, isPending: isUpdating } = useMutation({
    mutationFn: async (update: IUpdateTag) => TagService.updateTag(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('SAVED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeTag, isPending: isRemoving } = useMutation({
    mutationFn: async (tagId: string) => {
      await TagService.removeTag(tagId);
      await CourseService.removeTagFromCourses(tagId);
    },
    onSuccess: () => {
      onSuccess();
      message.success(t('SAVED_SUCCESSFULLY'));
    }
  });

  const { mutate: associateTagToCourse, isPending: isAssociatingTagToCourse } = useMutation({
    mutationFn: (data: IAssociateTagToCourse) => CourseService.associateTagToCourse(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('SAVED_SUCCESSFULLY'));
    }
  });

  return {
    createTag,
    updateTag,
    removeTag,
    associateTagToCourse,
    isWorking: isCreating || isUpdating || isRemoving || isAssociatingTagToCourse
  };
};
