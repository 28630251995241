import { Input, Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;
const { TextArea } = Input;

export const Wrapper = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black7};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const TextAreaWrapper = styled(TextArea)`
  margin-bottom: 16px;
`;
