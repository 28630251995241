import { SOCKET_EVENTS } from '@constants';
import { ISocketUser } from '@sdk/contracts';
import { useCallback, useEffect } from 'react';

import { useSocket, useSocketUsers } from '@/hooks';

export const OnlineUsersHandler = () => {
  const { setSocketUser } = useSocketUsers();
  const { socket } = useSocket();

  const handleSetOnlineUsers = useCallback(
    (users: ISocketUser[]) => {
      setSocketUser(users);
    },
    [setSocketUser]
  );

  useEffect(() => {
    if (!socket) return;
    socket?.on(SOCKET_EVENTS.CONNECTED_USERS, (users: ISocketUser[]) => {
      handleSetOnlineUsers(users);
    });
  }, [socket]);

  return null;
};
