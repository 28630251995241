import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PostTopicService = sdk.getService('PostTopicService');

export const usePostTopicList = () => {
  const queryKey = ['post-topics'];

  const { isPending, data: postTopics } = useQuery({
    queryKey,
    queryFn: () => PostTopicService.getAllTopics()
  });

  return {
    isPending,
    postTopics
  };
};
