import styled, { keyframes } from 'styled-components';

const animation = keyframes`
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
`;

export const Wrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  z-index: 10001;
  display: contents;
  #loading-line-base {
    height: 4px;
    background-color: ${({ theme }) => theme.colors.black9};
    width: ${({ width }) => width};
    overflow: hidden;
  }

  #loading-line {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.green6};
    animation: ${animation} 1s infinite linear;
    transform-origin: 0% 50%;
  }
`;
