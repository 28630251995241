import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

export const ChartContainer = styled.div`
  width: 240px;
  flex: 1 0 auto; /* Allow charts to grow (flex-grow: 1) and maintain their intrinsic size (flex-shrink: 0) */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.black1};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

export const TitleWrapper = styled.div`
  text-align: left;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  max-width: 120px;
`;

export const NoReviewsWrapper = styled.div`
  width: 100%;
  text-align: left;
`;
