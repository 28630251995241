import { RequestService } from '../request/request.service';

import { IUpdateActivityTrack } from './activity-tracking.contract';

export class ActivityTrackingService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public updateActivityTrack({ subscriptionId, activityTrack }: IUpdateActivityTrack) {
    return this.putter(`/subscriptions/${subscriptionId}/activity-track`, activityTrack);
  }
}
