import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TutoringGroupService = sdk.getService('TutoringGroupService');

export const useTeacherPendingMessages = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['tutoring-groups', 'pending-messages'],
    queryFn: () => TutoringGroupService.findTeacherPendingMessages()
  });

  return {
    pendingMessages: data,
    isLoading,
    error
  };
};
