import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';
import { IBasicUser } from '../user';

import {
  IAddTutoringGroupMembers,
  ICreateTutoringGroup,
  IMemberToRemove,
  ITutoringGroup,
  ITutoringGroupWithChatInfo,
  IUpdateTutoringGroup
} from './tutoring-group.contract';

export class TutoringGroupService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTutoringGroups(search?: string, pagination?: IPagination, bypass?: boolean) {
    const query = createQueryString({
      search,
      bypass,
      page: pagination?.page,
      limit: pagination?.limit
    });
    return this.fetcher<IPaginatedResponse<ITutoringGroup>>(`/tutoring-groups?${query}`);
  }

  public findCurrentStudentTutor() {
    return this.fetcher<IBasicUser>(`/tutoring-groups/me`);
  }

  public findStudentTutor(studentId: string) {
    return this.fetcher<IBasicUser>(`/tutoring-groups/student-tutor/${studentId}`);
  }

  public getTutoringGroup(tutoringGroupId: string) {
    return this.fetcher<ITutoringGroupWithChatInfo>(`/tutoring-groups/${tutoringGroupId}`);
  }

  public createTutoringGroup(newTutoringGroup: ICreateTutoringGroup) {
    return this.poster<ITutoringGroup, ICreateTutoringGroup>(`/tutoring-groups`, newTutoringGroup);
  }

  public updateTutoringGroup(updatedGroup: IUpdateTutoringGroup) {
    return this.putter<ITutoringGroup, IUpdateTutoringGroup>(
      `/tutoring-groups/${updatedGroup._id}`,
      updatedGroup
    );
  }

  public removeTutoringGroup(tutoringGroupId: string) {
    return this.deleter<ITutoringGroup>(`/tutoring-groups/${tutoringGroupId}`);
  }

  public addMembers(membersToAdd: IAddTutoringGroupMembers) {
    const { tutoringGroupId } = membersToAdd;
    return this.poster<ITutoringGroup, IAddTutoringGroupMembers>(
      `/tutoring-groups/${tutoringGroupId}/members`,
      membersToAdd
    );
  }

  public removeMember(memberToRemove: IMemberToRemove) {
    const { tutoringGroupId, memberId } = memberToRemove;
    return this.deleter<ITutoringGroup>(`/tutoring-groups/${tutoringGroupId}/members/${memberId}`);
  }

  public findTeacherPendingMessages() {
    return this.fetcher<number>(`/tutoring-groups/teacher-pending-messages`);
  }
}
