import styled from 'styled-components';

export const StatusSelectorWrapper = styled.div`
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
