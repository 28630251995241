import {
  IAddTutoringGroupMembers,
  ICreateTutoringGroup,
  IMemberToRemove,
  IUpdateTutoringGroup
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const TutoringGroupService = sdk.getService('TutoringGroupService');

export const useMutateTutoringGroup = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['tutoring-groups'] });

  const { t } = useTranslation('CommonTutoringGroupsTranslations');

  const { mutate: createTutoringGroup, isPending: isCreating } = useMutation({
    mutationFn: (newTutoringGroup: ICreateTutoringGroup) =>
      TutoringGroupService.createTutoringGroup(newTutoringGroup),
    onSuccess: () => {
      onSuccess();
      message.success(t('GROUP_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateTutoringGroup, isPending: isUpdating } = useMutation({
    mutationFn: (updatedGroup: IUpdateTutoringGroup) =>
      TutoringGroupService.updateTutoringGroup(updatedGroup),
    onSuccess: () => {
      onSuccess();
      message.success(t('GROUP_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeTutoringGroup, isPending: isRemoving } = useMutation({
    mutationFn: (groupId: string) => TutoringGroupService.removeTutoringGroup(groupId),
    onSuccess: () => {
      onSuccess();
      message.success(t('GROUP_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutateAsync: addMembers, isPending: isAddingMember } = useMutation({
    mutationFn: (membersToAdd: IAddTutoringGroupMembers) =>
      TutoringGroupService.addMembers(membersToAdd),
    onSuccess: () => {
      onSuccess();
      message.success(t('MEMBER_ADDED_SUCCESSFULLY'));
    }
  });

  const { mutateAsync: removeMember, isPending: isRemovingMember } = useMutation({
    mutationFn: (memberToRemove: IMemberToRemove) =>
      TutoringGroupService.removeMember(memberToRemove),
    onSuccess: () => {
      onSuccess();
      message.success(t('MEMBER_REMOVED_SUCCESSFULLY'));
    }
  });

  return {
    createTutoringGroup,
    updateTutoringGroup,
    removeTutoringGroup,
    addMembers,
    removeMember,
    isLoading: isCreating || isUpdating || isRemoving || isAddingMember || isRemovingMember
  };
};
