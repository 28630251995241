import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const CertificateService = sdk.getService('CertificateService');

export const useCourseCertificates = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['certificates'],
    queryFn: () => CertificateService.getUserCertificates()
  });

  return {
    certificates: data,
    isLoading,
    error
  };
};
