import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;

  & > :first-child {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }

  & > :last-child {
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
  }
`;
