import { IExam } from '@sdk/contracts';
import { create } from 'zustand';

type IStore = {
  selectedExam?: IExam;
  setSelectedExam: (exam?: IExam) => void;
};

export const useOnsiteExamPreview = create<IStore>(set => ({
  selectedExam: undefined,
  setSelectedExam: exam => set({ selectedExam: exam })
}));
