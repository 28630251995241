import styled from 'styled-components';

export const Wrapper = styled.div<{ showtitlestyles: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  ${({ showtitlestyles }) =>
    showtitlestyles &&
    `& > :first-child {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 30px;
    font-weight: 500;
    i {
      font-size: 20px;
    }
  }`}

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > :nth-child(2):has(button, div),
    & > :nth-child(2):has(div, button),
    & > :nth-child(2):has(button, button),
    & > :nth-child(2):has(div, div),
    & > :nth-child(2):has(div, div, div) {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
`;
