import styled from 'styled-components';

export const EmptyTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

export const EmptySubtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const Wrapper = styled.div``;
