import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
  }
`;

export const TableWrapper = styled.div`
  .ant-table-wrapper .ant-table-tbody {
    > tr.ant-table-row-selected td {
      background: ${({ theme }) => theme.colors.green2};
    }
  }

  .ant-table-wrapper tfoot tr td {
    overflow-wrap: unset;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
