import { Select } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledSelect = styled(Select)<{ ismobile: boolean }>`
  width: ${({ ismobile }) => (ismobile ? '100%' : '200px')};
`;
