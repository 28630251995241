import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ChatService = sdk.getService('ChatService');

export const useStudentPendingChatMessages = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['chat', 'pending-messages'],
    queryFn: () => ChatService.findStudentPendingMessages()
  });

  return { pendingMessages: data, isLoading };
};
