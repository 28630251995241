export const ManagerContentTotalsTranslations = {
  TOTALS: {
    es: 'Totales',
    en: 'Totals',
    cat: 'Totals',
    eus: 'Totals'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak'
  },
  TAG: {
    es: 'Formación',
    en: 'Category',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  EMAIL: {
    es: 'Correo',
    en: 'Email',
    cat: 'Correu',
    eus: 'Email'
  },
  VIDEO_MINUTES: {
    es: 'Min. vídeo',
    en: 'Video min',
    cat: 'Min. vídeo',
    eus: 'Min. bideoa'
  },
  PDF_PAGES: {
    es: 'Pág. apuntes',
    en: 'PDF pages',
    cat: 'Pág. apunts',
    eus: 'PDF orrialdeak'
  },
  QUIZ_QUESTIONS: {
    es: 'Preguntas test',
    en: 'Quiz questions',
    cat: 'Preguntes test',
    eus: 'Test galderak'
  },
  DOWNLOAD_SELECTED: {
    es: 'Descargar seleccionados ({{count}})',
    en: 'Download selected ({{count}})',
    cat: 'Descarregar seleccionats ({{count}})',
    eus: 'Hautatutakoak deskargatu ({{count}})'
  },
  EXPORT_ALL: {
    es: 'Exportar todo',
    en: 'Export all',
    cat: 'Exportar tot',
    eus: 'Denak esportatu'
  }
};
