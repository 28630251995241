import styled from 'styled-components';

export const Wrapper = styled.div<{ hasSelection: boolean }>`
  gap: 20px;
  display: grid;
  grid-template-columns: ${({ hasSelection }) =>
    hasSelection ? 'repeat(5, 1fr);' : 'repeat(3, 1fr);'};
  grid-template-rows: ${({ hasSelection }) => (hasSelection ? '1fr;' : 'repeat(2, 150px);')};

  & > i {
    font-size: ${({ hasSelection }) => (hasSelection ? '15px;' : '30px;')};
    .selected {
      background-color: ${({ theme }) => theme.colors.black1};
      border: 1px solid ${({ theme }) => theme.colors.black9};
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.green1};
    border: 1px solid ${({ theme }) => theme.colors.green6};
    color: ${({ theme }) => theme.colors.green6};
  }
`;

export const ActivityTypeWrapper = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  justify-content: center;
  padding: 10px;
`;

export const NameWrapper = styled.span<{ hasSelection: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: ${({ hasSelection }) => (hasSelection ? 'none' : 'block')};
  transition: 300ms ease-in-out;
`;
