import { ELiveClassVoteStatus } from '@constants';
import { dateProvider } from '@utils';
import { Button, Divider, Flex, message, Modal, Rate } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useLiveClassVoteLogic } from '../../hooks';

import { DescriptionWrapper, StarsRatingWrapper, TitleWrapper } from './LiveClassVote.style';

export const LiveClassVote = () => {
  const { pendingVotes = [], voteLiveClass } = useLiveClassVoteLogic();

  const { t } = useTranslation('CommonDashboardTranslations');

  const [vote, setVote] = useState({
    classDifficulty: 0,
    teacherRating: 0,
    classRating: 0
  });
  const theme = useTheme();

  const currentVote = useMemo(() => pendingVotes?.[0], [pendingVotes]);

  useEffect(() => {
    setVote({ classDifficulty: 0, teacherRating: 0, classRating: 0 });
  }, [currentVote]);

  if (!currentVote) return null;

  const handleVoteSubmit = () => {
    voteLiveClass({
      eventId: currentVote.event._id,
      status: ELiveClassVoteStatus.VOTED,
      ...vote
    });
    message.success(t('YOUR_VOTE_HAS_BEEN_SENT'));
  };

  const handleSkipVote = () => {
    voteLiveClass({
      eventId: currentVote.event._id,
      status: ELiveClassVoteStatus.SKIPPED
    });
  };

  const handleNotAttended = () => {
    voteLiveClass({
      eventId: currentVote.event._id,
      status: ELiveClassVoteStatus.NOT_ATTENDED
    });
  };

  const hasVoted = !!vote.classDifficulty && !!vote.teacherRating && !!vote.classRating;

  return (
    <Modal
      open={!!currentVote}
      onCancel={handleSkipVote}
      footer={
        <>
          <Divider />
          <Flex gap={16} justify="space-between">
            <Flex gap={8}>
              <Button onClick={handleSkipVote}>{t('SKIP_VOTE')}</Button>
              <Button onClick={handleNotAttended}>{t('I_DIDNT_ATTEND_THE_CLASS')}</Button>
            </Flex>
            <Button type="primary" disabled={!hasVoted} onClick={handleVoteSubmit}>
              {t('SEND')}
            </Button>
          </Flex>
        </>
      }>
      <Flex vertical gap={16} align="center" key={currentVote._id}>
        <TitleWrapper>
          {t('HOW_WAS_THE_CLASS', {
            name: currentVote.owner.name,
            class: currentVote.event.title,
            date: dateProvider.format(currentVote.event.startDate.dateTime, 'dd/MM/yyyy')
          })}
        </TitleWrapper>
        <DescriptionWrapper>{t('HOW_WAS_THE_CLASS_DESCRIPTION')}</DescriptionWrapper>
        <Divider />
        <StarsRatingWrapper>
          <div>{t('TEACHER_RATING')}</div>
          <Rate
            allowHalf={false}
            allowClear={false}
            value={vote.teacherRating}
            style={{ color: theme.colors.green6 }}
            onChange={value => setVote({ ...vote, teacherRating: value })}
          />
        </StarsRatingWrapper>
        <StarsRatingWrapper>
          <div>{t('CLASS_RATING')}</div>
          <Rate
            allowHalf={false}
            allowClear={false}
            value={vote.classRating}
            style={{ color: theme.colors.green6 }}
            onChange={value => setVote({ ...vote, classRating: value })}
          />
        </StarsRatingWrapper>
        <StarsRatingWrapper>
          <div>{t('CLASS_DIFFICULTY')}</div>
          <Rate
            allowHalf={false}
            allowClear={false}
            value={vote.classDifficulty}
            style={{ color: theme.colors.green6 }}
            onChange={value => setVote({ ...vote, classDifficulty: value })}
          />
        </StarsRatingWrapper>
        {pendingVotes.length > 1 && (
          <div>{t('YOU_HAVE_PENDING_VOTES', { count: pendingVotes.length })}</div>
        )}
      </Flex>
    </Modal>
  );
};
