import { ITeachersReportFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

export enum EContentTotalsTabs {
  BY_TEACHER = 'totals-by-teacher',
  BY_TAG = 'totals-by-tag'
}

const UserService = sdk.getService('UserService');
const TagService = sdk.getService('TagService');

interface IStore {
  activeTab: EContentTotalsTabs;
  filters?: ITeachersReportFilters;
  setActiveTab: (tab: EContentTotalsTabs) => void;
  setFilters: (filters?: ITeachersReportFilters) => void;
}

const store = createStore<IStore>(set => ({
  activeTab: EContentTotalsTabs.BY_TEACHER,
  setActiveTab: (tab: EContentTotalsTabs) => set({ activeTab: tab }),
  setFilters: (filters?: ITeachersReportFilters) => set({ filters })
}));

export const useTeachersContentReport = () => {
  const { activeTab, filters, setActiveTab, setFilters } = store();

  const { isLoading: isLoadingTeachers, data: teachersData } = useQuery({
    queryKey: ['content-reports', 'teachers', filters],
    queryFn: () => UserService.getTeachersContentReport(filters)
  });

  const { isLoading: isLoadingTags, data: tagsData } = useQuery({
    queryKey: ['content-reports', 'tags', filters],
    queryFn: () => TagService.getTagContentReport(filters)
  });

  return {
    teachers: teachersData,
    isLoading: isLoadingTeachers || isLoadingTags,
    filters,
    setFilters,
    tags: tagsData,
    setActiveTab,
    activeTab
  };
};
