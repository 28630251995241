import { ICreatePromotion } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePromotionForm } from '@/hooks';
import { sdk } from '@/services';

const PromotionService = sdk.getService('PromotionService');

export const useMutatePromotions = () => {
  const { closeDrawer, selectedPromotion } = usePromotionForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['promotions'] });
    closeDrawer();
  };

  const { mutate: createPromotion, isPending: isCreatingPromotion } = useMutation({
    mutationFn: (data: ICreatePromotion) => PromotionService.createPromotion(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMOTION_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updatePromotion, isPending: isUpdatingPromotion } = useMutation({
    mutationFn: (data: ICreatePromotion) =>
      PromotionService.updatePromotion(data, selectedPromotion?._id),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMOTION_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: deletePromotion, isPending: isDeletingPromotion } = useMutation({
    mutationFn: (_id: string) => PromotionService.deletePromotion(_id),
    onSuccess: () => {
      onSuccess();
      message.success(t('PROMOTION_DELETED_SUCCESSFULLY'));
    }
  });

  return {
    createPromotion,
    updatePromotion,
    deletePromotion,
    isWorking: isCreatingPromotion || isUpdatingPromotion || isDeletingPromotion
  };
};
