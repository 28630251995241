/* eslint-disable @typescript-eslint/no-explicit-any */
import { encodeQueryParams } from './encodeQueryParams';

interface IQueryParams {
  search?: string;
  limit?: number;
  page?: number;
  bypass?: boolean;
  filters?: Record<string, any>;
  sort?: Record<string, any>;
}

const DEFAULT_LIMIT = 50;
const DEFAULT_PAGE = 1;

export const createQueryString = (params: IQueryParams): string => {
  const { search = '', limit = DEFAULT_LIMIT, page = DEFAULT_PAGE, bypass, filters, sort } = params;

  let queryString = new URLSearchParams({
    limit: bypass ? '' : limit.toString(),
    page: page.toString(),
    search
  }).toString();

  if (filters) queryString += `&${encodeQueryParams(filters)}`;
  if (sort) queryString += `&${encodeQueryParams(sort)}`;

  return queryString;
};

export const parseQueryString = (queryString: string): IQueryParams => {
  const params = new URLSearchParams(queryString);
  const search = params.get('search') || '';
  const page = parseInt(params.get('page') || '1', 10);
  const limit = parseInt(params.get('limit') || '50', 10);
  return { search, limit, page };
};
