import { RequestService } from '../request';

import { IMessage } from './messages.contract';

export class MessagesService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public async createAutomaticResponseMessage(message: string) {
    return this.poster<IMessage, { message: string }>(`/messages/automatic`, { message });
  }
}
