import { Tooltip } from 'antd';
import { MathComponent } from 'mathjax-react';
import { FC } from 'react';
import { StaticMathField } from 'react-mathquill';

import { MathButtonWrapper } from './MathButton.styles';

interface IBtn {
  label: string;
  mathCode: string;
  charCode?: string;
  useJax?: string;
}

type Props = {
  btn: IBtn;
  index: number;
  latex: string;
  setLatex: (latex: string) => void;
};

export const MathButton: FC<Props> = ({ btn, index, latex, setLatex }) => {
  const getCode = () => (btn.charCode ? btn.charCode : btn.mathCode);
  return (
    <Tooltip title={btn.label} key={index}>
      <MathButtonWrapper
        type="default"
        onClick={() => {
          setLatex(`${latex}${btn.mathCode}`);
        }}>
        {btn.useJax ? (
          <MathComponent tex={getCode()} />
        ) : (
          <StaticMathField>{getCode()}</StaticMathField>
        )}
      </MathButtonWrapper>
    </Tooltip>
  );
};
