import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  & > :first-child {
    margin-bottom: 0;
  }
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
  font-weight: 500;
  padding-bottom: 8px;
`;
