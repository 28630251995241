import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;
