import { EEventType, EEventWeekdays, EVerticalNames } from '@constants';
import { Dayjs } from 'dayjs';

import { IDate } from '../date';
import { IBasicFile, IFile } from '../file';
import { IBasicMessage } from '../messages';
import { IBasicTag } from '../tag';
import { IBasicUser } from '../user';

export type IEvent = {
  _id: string;
  title: string;
  description: string;
  type: EEventType;
  attendees: IBasicUser[];
  startDate: IDate;
  endDate: IDate;
  tagsWithCourses: ITagWithCourses[];
  speakers: IBasicUser[];
  messages: IBasicMessage[];
  attachments: IBasicFile[];
  liveRecords: IBasicFile[];
  googleMeet?: IGoogleMeet;
  recurringRules?: IRecurringRules;
};

export type IEventByType = Pick<
  IEvent,
  '_id' | 'title' | 'startDate' | 'endDate' | 'attendees' | 'type'
>;

export type IEventsFilters = {
  filteredTags?: string[];
  month: number;
  year: number;
  verticals?: EVerticalNames[];
  teacher?: string;
};

export type IGoogleMeet = {
  id: string;
  link: string;
};

export type IRecurringRules = {
  parentId?: string;
  weekdays: EEventWeekdays[];
};

export type ITagWithCourses = {
  tag: IBasicTag;
  coursesWithSections: ICourseWithSections[];
};

export type ICourseWithSections = {
  course: string;
  sections: string[];
};

export type IBasicEvent = Pick<IEvent, '_id' | 'title' | 'startDate' | 'endDate' | 'type'> & {
  attendees: string[];
};

export type ICreateEventForm = Omit<IEvent, '_id' | 'attendees' | 'startDate' | 'endDate'> & {
  date: [Dayjs, Dayjs];
  dateOnly: [Dayjs, Dayjs];
  timeOnly: [Dayjs, Dayjs];
  timeZone: string;
  recurring: boolean;
};

export type ICreateEvent = Omit<IEvent, '_id' | 'attendees'>;

export type IUpdateEvent = Partial<IEvent> & { updateAllRecurring?: boolean };

export type IRemoveEvent = { eventId: string; removeRecurring: boolean };

export type IAddRemoveAttendee = {
  eventId: string;
  attendeeId: string;
};

export type ISendEventMessage = {
  eventId: string;
  message: string;
};

export type IRemoveEventAttachment = {
  eventId: string;
  attachmentId: string;
};

export type IAddEventAttachment = {
  eventId: string;
  file: IFile;
};

export type IRemoveEventLiveRecord = {
  eventId: string;
  liveRecordId: string;
};

export type IAddEventLiveRecord = {
  eventId: string;
  file: IFile;
};

export type IUpdateEventLiveRecordName = {
  eventId: string;
  liveRecordId: string;
  newName: string;
};

export type IGroupedLiveRecords = {
  sectionId: string;
  sectionName: string;
  recordings: IBasicFile[];
};

// TODO: Move to constants
export enum ECalendarViewType {
  DAY_GRID_MONTH = 'dayGridMonth',
  TIME_GRID_DAY = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek',
  LIST_WEEK = 'listWeek'
}
