import { create as createStore } from 'zustand';

interface IStore {
  userId?: string;
  isOpen: boolean;
  setUserId: (userId?: string) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useInternalUserPreview = createStore<IStore>(set => ({
  isOpen: false,
  setUserId: (userId?: string) => set({ userId }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));
