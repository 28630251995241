import { IPromotion } from '@sdk/contracts';
import { create } from 'zustand';

type IStore = {
  isDrawerOpen: boolean;
  openDrawer: (promotion?: IPromotion) => void;
  closeDrawer: () => void;
  selectedPromotion?: IPromotion;
};

export const usePromotionForm = create<IStore>(set => ({
  isDrawerOpen: false,
  openDrawer: promotion => set({ isDrawerOpen: true, selectedPromotion: promotion }),
  closeDrawer: () => set({ isDrawerOpen: false, selectedPromotion: undefined })
}));
