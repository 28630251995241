import { Select } from 'antd';
import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;

  .ant-form-item {
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const AddonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const LabelWidgetWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
