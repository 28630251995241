import React, { useMemo } from 'react';

import { LoadingWidget } from './LoadingWidget';

import { PlanificationSummaryWidget } from '@/components/Planification';

type Props = {
  visibleText: number;
};

export const PlanificationReady: React.FC<Props> = ({ visibleText }) => {
  const isLoading = useMemo(() => visibleText < 3, [visibleText]);

  return (
    <>{isLoading ? <LoadingWidget visibleText={visibleText} /> : <PlanificationSummaryWidget />}</>
  );
};
