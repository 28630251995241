import { Tag } from 'antd';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
  score: number;
};

const getBackgroundColor = (theme: DefaultTheme, score: number) => {
  if (score < 5) return `${theme.colors.red6}20`;
  if (score < 7.5) return `${theme.colors.orange6}20`;
  return theme.colors.green1;
};

const getTextColor = (theme: DefaultTheme, score: number) => {
  if (score < 5) return theme.colors.red6;
  if (score < 7.5) return theme.colors.orange6;
  return theme.colors.green6;
};

export const StyledTag = styled(Tag)<Props>`
  border-color: transparent;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  background-color: ${({ theme, score }) => getBackgroundColor(theme, score)};
  color: ${({ theme, score }) => getTextColor(theme, score)};
`;
