import { IUser } from '@sdk/contracts';
import { useState } from 'react';
import io from 'socket.io-client';

const socket = io(import.meta.env['VITE_SOCKET_URL'], {
  autoConnect: false,
  transports: ['websocket', 'polling']
});

export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(false);

  const connect = (user: IUser) => {
    if (!socket || socket.connected) return;

    const { _id, name, lastName, role, academy } = user;
    socket.auth = { _id, name, lastName, role, academy };

    socket.connect();

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      socket.disconnect();
      setIsConnected(false);
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  };

  return {
    connect,
    isConnected,
    socket
  };
};
