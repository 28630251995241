import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
