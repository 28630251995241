import { EGamificationRewardType } from '@constants';
import { IReward } from '@sdk/contracts';
import { dateProvider } from '@utils';

export const isRewardClaimedToday = (
  rewards: IReward[],
  type: EGamificationRewardType
): boolean => {
  return rewards.some(
    reward => reward.type === type && dateProvider.isSameDay(reward.createdAt, dateProvider.now())
  );
};
