import { ICreateSectionReview } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const SectionReviewService = sdk.getService('SectionReviewService');

export const useMutateSectionReview = () => {
  const { t } = useTranslation('SectionReviewTranslations');

  const { mutate: createSectionReview, isPending: isCreating } = useMutation({
    mutationFn: (newSectionReview: ICreateSectionReview) =>
      SectionReviewService.createSectionReview(newSectionReview),
    onSuccess: () => message.success(t('SECTION_REVIEW_SUBMITTED'))
  });

  return {
    createSectionReview,
    isWorking: isCreating
  };
};
