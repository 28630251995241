import styled from 'styled-components';

export const Wrapper = styled.div<{ issender: boolean }>`
  border-radius: 8px;
  margin: 8px 0;
  word-wrap: break-word;
  white-space: pre-line;
  max-width: 65%;
  width: max-content;
  color: ${({ issender, theme }) => (issender ? theme.colors.black9 : theme.colors.white)};
  background-color: ${({ issender, theme }) =>
    issender ? theme.colors.black2 : theme.colors.green6};
  align-self: ${({ issender }) => (!issender ? 'flex-start' : 'flex-end')};

  display: flex;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  flex-direction: column;
`;

export const HtmlWrapper = styled.div`
  & > p {
    margin: 0;
    img {
      max-width: 100%;
    }
  }
  word-break: break-word;
`;

export const TimeWrapper = styled.div<{ issender: boolean }>`
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  opacity: 0.5;
  color: ${({ issender, theme }) => (issender ? theme.colors.black9 : theme.colors.white)};
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.black2};
  width: fit-content;
  margin-bottom: 8px;
`;

export const FileInfoWrapper = styled.a`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.black4};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black9};
`;

export const FileIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 69px;
  background: ${({ theme }) => theme.colors.black4};
`;

export const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  /* Opcional: ajustar el espacio entre imágenes */
  gap: 8px;
`;
