import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 100000;
  position: fixed;
  bottom: 10px;
  left: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black9};
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black9};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const InnerWrapper = styled.div`
  border-radius: 9999px;
  min-width: 28px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;

  img {
    padding: 2px;
    display: flex;
  }
`;
