import { Tag } from 'antd';
import styled from 'styled-components';

type Props = {
  backgroundcolor: string;
};

export const StyledTag = styled(Tag)<Props>`
  border: transparent;
  padding: 2px 8px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
`;

export const TextWrapper = styled.div<{ color: string }>`
  font-weight: 600;
  color: ${({ color }) => color};
`;
