import { ETicketStatus } from '@constants';
import { Popover } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as key } from 'uuid';

import { SelectorWrapper, StatusWrapper } from './TicketStatusBadge.styles';

type Props = {
  status: ETicketStatus;
  editable?: boolean;
  onChange?: (status: ETicketStatus) => void;
};

export const TicketStatusBadge: React.FC<Props> = ({ status, editable, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = (o: boolean) => {
    if (!editable) return;
    setIsOpen(o);
  };

  const handleOnChange = (s: ETicketStatus) => {
    if (!editable) return;
    onChange?.(s);
    setIsOpen(false);
  };

  const content = (
    <SelectorWrapper>
      {Object.values(ETicketStatus).map(s => (
        <StatusWrapper key={key()} status={s} onClick={() => handleOnChange(s)}>
          <div>{t(s)}</div>
        </StatusWrapper>
      ))}
    </SelectorWrapper>
  );

  return (
    <Popover
      open={isOpen}
      content={content}
      placement="bottom"
      trigger={['click']}
      onOpenChange={handleOpen}>
      <StatusWrapper status={status}>
        <div>{t(status)}</div>
      </StatusWrapper>
    </Popover>
  );
};
