import styled from 'styled-components';

type Props = {
  isLocked?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.green6};
    height: 4px !important;
  }

  .ant-tabs-tab-active {
    font-weight: 500;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 16px;
  margin-bottom: 4px;
  flex-wrap: wrap;
  gap: 8px;
`;

export const RatingWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  pointer-events: ${({ isLocked }) => isLocked && 'none'};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    gap: unset;
  }
`;

export const TextWrapper = styled.span`
  margin-right: 8px;
`;
