import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useCalendarInfoModal = createStore<IStore>(set => ({
  isOpen: false,
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false })
}));
