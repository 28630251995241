import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const AcademyService = sdk.getService('AcademyService');

export const useAcademyByDomain = (domain: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['academy', 'by-domain', domain],
    queryFn: () => AcademyService.getAcademyByDomain(domain),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false
  });

  return {
    isLoading,
    academyByDomain: data
  };
};
