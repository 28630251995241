import styled from 'styled-components';

export const TicketVisibilityBadge = styled.div<{ isPublic: boolean }>`
  cursor: pointer;
  & > :first-child {
    padding: 1px 8px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid
      ${({ isPublic, theme }) => (isPublic ? theme.colors.green7 : theme.colors.black3)};
    color: ${({ isPublic, theme }) => (isPublic ? theme.colors.green7 : theme.colors.black9)};
    background-color: ${({ isPublic, theme }) =>
      isPublic ? theme.colors.green1 : theme.colors.black1};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  max-width: 300px;
`;
