import styled from 'styled-components';

export const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    margin: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    margin-bottom: 24px;
  }
`;
