import { Popconfirm, Popover } from 'antd';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TicketVisibilityBadge, TitleWrapper, Wrapper } from './TicketVisibility.styles';

import { useMutateMyTickets } from '@/store';

type Props = {
  ticketId: string;
  isPublic: boolean;
};

export const TicketVisibility: React.FC<Props> = ({ isPublic, ticketId }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { updateTicket } = useMutateMyTickets();

  const handleOnChange = (value: boolean) => {
    if (value === isPublic) return setIsOpen(false);
    updateTicket({ _id: ticketId, isPublic: value });
    setIsOpen(false);
  };

  const content = (
    <Wrapper>
      <Popconfirm
        title={
          <TitleWrapper>
            <Trans i18nKey="CHANGE_VISIBILITY_CONFIRMATION" components={{ a: <strong /> }} />
          </TitleWrapper>
        }
        onCancel={e => {
          e?.stopPropagation();
          setIsOpen(false);
        }}
        onConfirm={e => {
          e?.stopPropagation();
          handleOnChange(true);
        }}>
        <TicketVisibilityBadge isPublic={true} onClick={e => e.stopPropagation()}>
          <div>{t('PUBLIC')}</div>
        </TicketVisibilityBadge>
      </Popconfirm>
      <TicketVisibilityBadge
        isPublic={false}
        onClick={e => {
          e.stopPropagation();
          handleOnChange(false);
        }}>
        <div>{t('PRIVATE')}</div>
      </TicketVisibilityBadge>
    </Wrapper>
  );
  return (
    <Popover open={isOpen} content={content}>
      <TicketVisibilityBadge
        isPublic={isPublic}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}>
        <div>{isPublic ? t('PUBLIC') : t('PRIVATE')}</div>
      </TicketVisibilityBadge>
    </Popover>
  );
};
