export const ProductTourTranslations = {
  START_THE_TOUR: {
    es: 'Hacer el tour',
    en: 'Start the tour',
    eus: 'Erabili',
    cat: 'Fes el tour'
  },
  SKIP_THE_TOUR: {
    es: 'Después lo veo',
    en: 'Skip the tour',
    eus: 'Ez dut ikusi nahi',
    cat: 'Ometrà el tour'
  },
  PREVIOUS: {
    es: 'Anterior',
    en: 'Previous',
    eus: 'Aurrekoa',
    cat: 'Anterior'
  },
  NEXT: {
    es: 'Siguiente',
    en: 'Next',
    eus: 'Hurrengoa',
    cat: 'Hurri'
  },
  FINISH: {
    es: 'Finalizar',
    en: 'Finish',
    eus: 'Bukatzea',
    cat: 'Finalitzar'
  },
  REDO_THE_TOUR_TITLE: {
    es: '¡Esto es todo!',
    en: 'That is all!',
    eus: 'Eskerrik asko!',
    cat: 'Això és tot!'
  },
  HERE_YOU_CAN_REDO_THE_TOUR_DESCRIPTION: {
    es: 'Si en algún momento te sientes perdido, puedes volver a hacer el tour aquí. Solo tienes que pulsar el botón y te volver a explicar todo de nuevo.  ',
    en: 'If at any time you feel lost, you can do the tour again here. Just click the button and we will explain everything to you again.',
    eus: 'Ez datorrenik erabili berriro egin nahi duzun, egin daiteke hemen. Saiatu botoia sakatu eta berriro zurekin zerbait errepikatuko dugu.',
    cat: "Si en qualsevol moment vols fer el tour de nou, pots fer-ho aquí. Només has d'obrir el botó i et explicarem tot de nou."
  },
  QUIZ_MODE_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Practica con los tests',
    en: 'Practice with the tests',
    eus: 'Erabili testuak erabiltzeko',
    cat: 'Practica amb els tests'
  },
  QUIZ_MODE_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Pon a prueba lo que has aprendido haciendo los tests.',
    en: 'Test your knowledge by doing the quizzes.',
    eus: 'Zure ezagutzak frogatu testak eginez.',
    cat: 'Prova el teu coneixement fent els tests.'
  },
  LIVE_CLASSES_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Clases en directo',
    en: 'Live classes',
    eus: 'Gaiak',
    cat: 'Esdeveniments'
  },
  LIVE_CLASSES_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Reserva tu plaza en las próximas clases en directo con tus profes.',
    en: 'Reserve your place in the next live classes with your teachers.',
    eus: 'Zure profesorekin ezkutatutako gaiak erreserbatu.',
    cat: 'Reserva el teu lloc en les properes classes en directe amb els teus professors.'
  },
  EXAM_GROUPS_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Exámenes',
    en: 'Exams',
    eus: 'Eztabaidak',
    cat: 'Exàmens'
  },
  EXAM_GROUPS_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Enterate de todos lo relativo a tu convocatoria.',
    en: 'Get all the information about your exam group.',
    eus: 'Eztabaidaren informazioa zure konbokoatik lortu.',
    cat: 'Obten tots els detalls de la teva convocatòria.'
  },
  CONTENT_WIDGET_PRODUCT_TOUR_TITLE: {
    es: '¡Empieza a estudiar!',
    en: 'Start studying!',
    eus: 'Ikasten hasteko!',
    cat: 'Comença a estudiar!'
  },
  CONTENT_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Accede al contenido y las actividades del curso que estás estudiando.',
    en: 'Access the content and activities of the course you are studying.',
    eus: 'Kursua ikasten duzun kursuaren edukia eta aktibitateak erabili.',
    cat: 'Accedeix al contingut i les activitats del curs que estàs estudiant.'
  },
  DO_THE_TOUR: {
    es: 'Hacer el tour',
    en: 'Do the tour',
    eus: 'Erabili',
    cat: 'Fes el tour'
  },
  THERE_A_TOUR_IN_THIS_PAGE: {
    es: 'Hay un tour en esta página, pulsa en el botón para verlo.',
    en: 'There is a tour in this page, click the button to see it.',
    eus: 'Orrialde honetan tour bat dago, sakatu botoia ikusi nahi duzun.',
    cat: 'Hi ha un tour en aquesta pàgina, fes clic en el botó per veure-ho.'
  },
  DASHBOARD_PRODUCT_TOUR_WELCOME_TITLE: {
    es: '¡Ya estás dentro del campus virtual!',
    en: 'You are already in the virtual campus!',
    eus: 'Zer moduzt duzu campus virtualera!',
    cat: 'Ja estàs dins del campus virtual!'
  },
  DASHBOARD_PRODUCT_TOUR_WELCOME_DESCRIPTION: {
    es: '¿Damos un tour rápido para que sepas donde está todo?',
    en: 'Do a quick tour so you know where everything is.',
    eus: 'Azkenezko bidea ematen dizkizun, zer moduzt duzun.',
    cat: 'Fes un recorregut ràpid perquè coneguis on està tot.'
  },
  PLANIFICATION_WIDGET_PRODUCT_TOUR_TITLE: {
    es: '¡Te ayudamos a organizarte!',
    en: 'We help you organize yourself!',
    eus: 'Zure zerbitzua erabiltzeko laguntza',
    cat: 'Et ajudem a organitzar-te!'
  },
  PLANIFICATION_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Sigue tu plan de estudio cada día y avanza con confianza hacia el examen.',
    en: 'Follow your study plan every day and advance with confidence towards the exam.',
    eus: 'Eskatu zure ikastetxeko plan egin den bakoitzean eta ziur tasunarekin aurrera.',
    cat: "Segueix el teu pla d'estudis cada dia i avança amb confiança cap a l'examen."
  }
};
