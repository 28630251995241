import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 20px;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

export const LinkWrapper = styled.div`
  font-weight: 600;
`;

export const IconWrapper = styled(Icon)`
  margin-right: 4px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
