import { Select, SelectProps } from 'antd';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/Icon';
import { useStudentsList } from '@/store';

type Props = {
  value?: string;
  placeholder?: string;
  style?: SelectProps['style'];
  onChange?: (value: string) => void;
};

export const StudentSearchSelector: React.FC<Props> = ({ value, onChange, placeholder, style }) => {
  const { t } = useTranslation();
  const { students, setSearch } = useStudentsList();

  const onSearch = debounce((search: string) => setSearch(search), 500);

  return (
    <Select
      showSearch
      style={style}
      value={value}
      onChange={onChange}
      placeholder={placeholder ?? t('SELECT_USER')}
      onSearch={onSearch}
      defaultActiveFirstOption={false}
      suffixIcon={<Icon i="Search" />}
      filterOption={false}
      options={students?.map(({ _id, name, lastName }) => ({
        label: `${name} ${lastName}`,
        value: _id
      }))}
    />
  );
};
