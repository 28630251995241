import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useHubspotUsersList = () => {
  const [search, setSearch] = useState<string>();

  const queryKey = ['users', 'hubspot', search];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => UserService.getAllHubspotContactsBy(search!),
    enabled: !!search
  });

  return {
    hubspotUsers: data,
    isLoading,
    error,
    setSearch
  };
};
