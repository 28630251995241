import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { sdk } from '@/services';

const SectionReviewService = sdk.getService('SectionReviewService');

export const useSectionReviews = (sectionId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['section-reviews', sectionId],
    queryFn: () => SectionReviewService.getSectionReviews(sectionId!),
    enabled: !!sectionId
  });

  useEffect(() => {
    if (!data) return;
  }, [data]);

  return {
    sectionReviews: data,
    isLoading,
    error
  };
};
