import { RequestService } from '../request';

import {
  ICreatePlanification,
  IPlanification,
  IUpdatePlanification
} from './planification.contract';

export class PlanificationService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getPlanificationByUser(userId: string) {
    return this.fetcher<IPlanification>(`/planification/user/${userId}`);
  }

  async createPlanification(createPlanification: ICreatePlanification) {
    return this.poster<IPlanification, ICreatePlanification>(`/planification`, createPlanification);
  }

  async removePlanification() {
    return this.deleter<string>(`/planification`);
  }

  async updatePlanification(updatePlanification: IUpdatePlanification) {
    return this.putter('/planification', updatePlanification);
  }

  async sendFeedback(feedback: string) {
    return this.poster('/planification/feedback', { feedback });
  }
}
