import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme }) => theme.colors.black3};

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
