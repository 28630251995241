import { Tag } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

type Props = {
  color: string;
};

export const StyledTag = styled(Tag)<Props>`
  border: transparent;
  padding: 2px 8px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
`;

export const StyledIcon = styled(Icon)<{ backgroundColor: string }>`
  color: ${({ backgroundColor }) => backgroundColor};
`;

export const TextWrapper = styled.div<Props>`
  font-weight: 600;
  color: ${({ color }) => color};
`;
