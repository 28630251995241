import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  align-items: flex-start;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  & > :first-child {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
`;
