import { RequestService } from '../request';

import { ICreatePrompt, IPrompt, IUpdatePrompt } from './prompt.contract';

export class PromptService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getPrompts() {
    return this.fetcher<IPrompt[]>('/prompt');
  }

  async createPrompt(prompt: ICreatePrompt) {
    return this.poster<IPrompt, ICreatePrompt>('/prompt', prompt);
  }

  async removePrompt(_id: string) {
    return this.deleter<IPrompt>(`/prompt/${_id}`);
  }

  async updatePrompt(prompt: IUpdatePrompt) {
    return this.putter<IPrompt, IUpdatePrompt>(`/prompt/${prompt._id}`, prompt);
  }
}
