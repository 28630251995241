/* eslint-disable indent */
import { ETaskStatus } from '@constants';
import { ISectionTask, ITask } from '@sdk/contracts';
import { dateProvider } from '@utils';

import { IGanttResource, IGanttTask } from '../types';

const currentDate = dateProvider.now();

export const getTasksAndResources = (
  sectionTasks: ISectionTask[]
): { tasks: IGanttTask[]; resources: IGanttResource[] } => {
  const allTasks: IGanttTask[] = [];
  const allResources: IGanttResource[] = [];
  for (const sectionTask of sectionTasks) {
    const { section, tasks } = sectionTask;
    const { start, end } = getSectionDateRange(tasks);
    const sectionProgress = getSectionProgress(tasks);

    allTasks.push({
      TaskID: section._id,
      TaskName: section.name,
      StartDate: start,
      EndDate: end,
      Progress: sectionProgress,
      subtasks: tasks.map(task => {
        const { _id, createdAt, deadline, title, statusInfo, assignee } = task;
        allResources.push({
          resourceId: assignee._id,
          resourceName: `${assignee.name} ${assignee.lastName}`
        });
        const status = statusInfo.currentStatus;
        const progress =
          status === ETaskStatus.APPROVED
            ? 100
            : status === ETaskStatus.IN_REVIEW
            ? 50
            : status === ETaskStatus.REJECTED
            ? 25
            : 0;

        return {
          TaskID: _id,
          TaskName: title,
          StartDate: dateProvider.parse(createdAt),
          EndDate: dateProvider.parse(deadline),
          Progress: progress,
          resources: [assignee._id]
        };
      })
    });
  }

  return { tasks: allTasks, resources: allResources };
};

const getSectionDateRange = (tasks: ITask[]): { start: Date; end: Date } => {
  if (tasks.length === 0) return { start: currentDate, end: currentDate };

  const initial = {
    start: dateProvider.parse(tasks[0].createdAt),
    end: dateProvider.parse(tasks[0].deadline)
  };

  return tasks.reduce((acc, task) => {
    const createdAt = dateProvider.parse(task.createdAt);
    const deadline = dateProvider.parse(task.deadline);
    if (dateProvider.isBefore(createdAt, acc.start)) {
      acc.start = createdAt;
    }
    if (dateProvider.isAfter(deadline, acc.end)) {
      acc.end = deadline;
    }
    return acc;
  }, initial);
};

const getSectionProgress = (tasks: ITask[]): number => {
  if (tasks.length === 0) return 0;

  const totalPoints = tasks.length;
  const achievedPoints = tasks.reduce((acc, task) => {
    switch (task.statusInfo.currentStatus) {
      case ETaskStatus.APPROVED:
        return acc + 1;
      case ETaskStatus.IN_REVIEW:
        return acc + 0.5;
      default:
        return acc;
    }
  }, 0);

  return (achievedPoints / totalPoints) * 100;
};
