import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuestionReviewService = sdk.getService('QuestionReviewService');

export const useReviewQuestionsResume = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['question-review', 'resume'],
    queryFn: () => QuestionReviewService.findQuestionReviewResume()
  });

  return {
    isLoading,
    questionReviewResume: data
  };
};
