import { ELanguages } from '@constants';
import memoize from 'lodash.memoize';

import { ITranslation, ITranslationResource } from '../../../types/i18n';
import { namespaces } from '../namespaces';

type Props = Record<string, ITranslation>;

/**
 * @description This function is used to merge translations from different files.
 * the is exported and used in our i18n module as resource for the translation hook (useTranslate).
 * @param {Props} t The translations to be merged.
 */

const formatTranslations = (t: Props): ITranslationResource<typeof namespaces> =>
  Object.keys(ELanguages).reduce<ITranslationResource<typeof namespaces>>((acc, language) => {
    const lang = language as ELanguages;
    Object.keys(t).forEach(namespace => {
      const trans = Object.entries(t[namespace]).reduce<Record<string, string>>(
        (transByLanguage, [key, value]) => {
          transByLanguage[key] = value[lang];
          return transByLanguage;
        },
        {}
      );
      acc[lang] = { [namespace]: trans, ...acc[lang] };
    });
    return acc;
  }, Object.create(null));

/**
 @description Import here translation files from containers or components.
 @example
  const PageTranslations = {
     elementOne: {
       es: 'Spanish translation for elementOne',
       en: 'English translation for elementOne'
     },
     elementTwo: {
       es: 'Spanish translation for elementTwo',
       en: 'English translation for elementTwo'
     }
  };
*/

const memoizedFormatTranslations = memoize(formatTranslations);
export const translations = { ...memoizedFormatTranslations(namespaces) } as const;
