import { IFile } from '@sdk/contracts';
import { Button, ButtonProps } from 'antd';
import { useState } from 'react';

import { DeleteWrapper } from './DeleteUpload.style';

import { Icon } from '@/components/Icon';
import { sdk } from '@/services';

type DeleteProps = {
  file: IFile;
  isMultiple?: boolean;
  onDelete: (fileId: string) => void;
} & ButtonProps;

const FileService = sdk.getService('FileService');

const DeleteUpload: React.FC<DeleteProps> = ({ file, isMultiple = false, onDelete, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!file) return null;

  const handleDeleteFile = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await FileService.removeFile(file._id);
      onDelete?.(file._id);
    } catch (error) {
      console.error('handleDeleteFile error:', error);
    }
    setIsLoading(false);
  };

  return (
    <DeleteWrapper style={{ position: isMultiple ? 'inherit' : 'absolute' }}>
      <Button
        type="link"
        size="small"
        loading={isLoading}
        onClick={handleDeleteFile}
        icon={<Icon i="TrashCan" />}
        {...props}
      />
    </DeleteWrapper>
  );
};

export default DeleteUpload;
