const eventBus = new Comment('event-bus');

type EventsDefinition = {
  LOAD_ACTIVITY_REVIEWER: string;
  HIDE_ACTIVITY_REVIEWER: string;
};
type CustomEvents = keyof EventsDefinition;

/**
 * It takes an event name and an optional payload, and dispatches a CustomEvent with the given name and
 * payload
 * @param {T} eventName - The name of the event to publish.
 * @param [payload] - The data that you want to pass to the event listener.
 */
function publish<T extends CustomEvents>(eventName: T, payload?: EventsDefinition[T]): void {
  const event = payload
    ? new CustomEvent(eventName, { detail: payload })
    : new CustomEvent(eventName);
  eventBus.dispatchEvent(event);
}

type Unsubscribe = () => void;

/**
 * "If the event has a detail property, then it's a CustomEvent."
 *
 * The above function is a type guard. It narrows the type of the event parameter from Event to
 * CustomEvent
 * @param {Event} event - Event - The event object that is being passed to the function.
 * @returns A boolean
 */
function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}

/**
 * It takes an event name and a handler function, and returns a function that removes the handler from
 * the event bus
 * @param {T} eventName - The name of the event you want to subscribe to.
 * @param handlerFn - (payload: EventsDefinition[T]) => void
 * @returns A function that removes the event listener.
 */
function subscribe<T extends CustomEvents>(
  eventName: T,
  handlerFn: (payload: EventsDefinition[T]) => void
): Unsubscribe {
  const eventHandler = (event: Event) => {
    if (isCustomEvent(event)) {
      const eventPayload: EventsDefinition[T] = event.detail;
      handlerFn(eventPayload);
    }
  };
  eventBus.addEventListener(eventName, eventHandler);
  return () => {
    eventBus.removeEventListener(eventName, eventHandler);
  };
}

export { publish, subscribe };
