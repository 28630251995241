import { Button } from 'antd';

import { Wrapper } from './QuestionCounter.styles';

import { Icon } from '@/components/Icon';

type Props = {
  current: number;
  amount: number;
  onChange: (selectedQuestion: number) => void;
};

export const QuestionCounter: React.FC<Props> = ({ current, amount, onChange }) => {
  const increasePage = () => {
    if (amount - 1 > current) onChange(current + 1);
  };

  const decreasePage = () => {
    if (current > 0) onChange(current - 1);
  };

  if (amount === 0) return null;

  return (
    <Wrapper>
      <Button type="link" onClick={decreasePage} icon={<Icon i="ArrowLeft" />} />
      <p>
        {current + 1}/{amount}
      </p>
      <Button type="link" onClick={increasePage} icon={<Icon i="ArrowRight" />} />
    </Wrapper>
  );
};
