import { Carousel } from 'antd';
import styled from 'styled-components';

export const CarouselWrapper = styled(Carousel)`
  margin: 0 auto;
  max-width: 330px;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    max-width: 650px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 780px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    max-width: 1080px;
  }

  .slick-track {
    display: flex;
    gap: 4px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
      gap: 16px;
    }
  }
`;
