import { Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

export const TextAreaWrapper = styled(Input.TextArea)`
  margin-bottom: 16px;
`;
