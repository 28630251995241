import { Flex, Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  & > :first-child {
    margin-bottom: 0;
  }
`;

export const TitleWrapper = styled(Flex)`
  width: 100%;
`;

export const ExamTitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const ExamDescriptionWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const TagWrapper = styled(Tag)`
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.purple6};
  background: ${({ theme }) => theme.colors.purple3};

  color: ${({ theme }) => theme.colors.purple6};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const ExamDateWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ExamDateTextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black7};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
