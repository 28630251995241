import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  .ant-form-item {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  gap: 8px;
`;

export const LoadingSectionsWrapper = styled.div`
  padding: 16px;
`;
