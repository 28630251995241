import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNpsVoteLogic } from '../../../hooks';
import { ColumnWrapper, TextWrapper, TitleWrapper, Wrapper } from '../NpsVote.styles';

const TRUSTPILOT_URL = 'https://www.trustpilot.com/review/ucademy.com';
const WHATSAPP_URL = 'https://wa.me/34636345450';

export const PostNpsActions: React.FC = () => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { isEverythingClear, isPromoter } = useNpsVoteLogic();

  const handleOpenTrustpilot = () => window.open(TRUSTPILOT_URL, '_blank');
  const handleOpenWhatsapp = () => window.open(WHATSAPP_URL, '_blank');

  return (
    <Wrapper>
      {isEverythingClear === false ? (
        <ColumnWrapper>
          <TitleWrapper>{t('YOU_CAN_TALK_WITH_US_ON_WHATSAPP')}</TitleWrapper>
          <Button onClick={handleOpenWhatsapp}>{t('GO_TO_WHATSAPP')}</Button>
        </ColumnWrapper>
      ) : isPromoter ? (
        <ColumnWrapper>
          <TitleWrapper>{t('CAN_YOU_WRITE_A_REVIEW')}</TitleWrapper>
          <Button onClick={handleOpenTrustpilot}>{t('CLICK_HERE')}</Button>
        </ColumnWrapper>
      ) : (
        <TextWrapper>{t('NPS_THANK_YOU')}</TextWrapper>
      )}
    </Wrapper>
  );
};
