import styled from 'styled-components';

export const DeleteWrapper = styled.div`
  top: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
  i {
    background: rgba(38, 45, 52, 0.07);
    border-radius: 5px;
    padding: 6px;
    color: ${({ theme }) => theme.colors.black9};
  }
`;
