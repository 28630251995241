import { Flex } from 'antd';
import styled from 'styled-components';

export const WeekWrapper = styled.span`
  color: ${({ theme }) => theme.colors.purple6};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

export const WeekTextWrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const ActionButtonsWrapper = styled(Flex)<{ ismobile: string }>`
  flex-direction: ${({ ismobile }) => (ismobile === 'true' ? 'row-reverse' : 'row')};
`;
